import * as APIPaths from '@Constants/api-paths';
import axios from 'axios';

class ShareDA {
    create_one_share = (share) => {
        return axios
            .post(APIPaths.createOneShare, share, APIPaths.apiConfig())
            .then((response) => {
                return response.data;
            })
            .catch((err) => err.response.data);
    };
    update_one_share = (shareId, share) => {
        return axios
            .put(APIPaths.updateOneShare(shareId), share, APIPaths.apiConfig())
            .then((response) => {
                return response.data;
            })
            .catch((err) => err.response.data);
    };
    delete_one_share = (shareId) => {
        return axios
            .delete(APIPaths.deleteOneShare(shareId), APIPaths.apiConfig())
            .then((response) => {
                return response.data;
            })
            .catch((err) => err.response.data);
    };
    get_one_share = (shareId) => {
        return axios
            .get(APIPaths.getOneShare(shareId), APIPaths.apiConfig())
            .then((response) => {
                return response.data;
            })
            .catch((err) => err.response.data);
    };
}
export default new ShareDA();
