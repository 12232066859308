import React from 'react';
import { Container, Row, Col, Table, Button, Modal } from 'react-bootstrap';
import { TwitterIconRender, SocialMediaAccountRender } from '../form_template/icon-render';
import { ConnectButtonRender, DisconnectButtonRender, SettingsButtonRender, TemplateButtonRender } from '../form_template/buttons-render';
const TwitterForm = ({ responseTwitter, handleTwitterLogin, handleTwitterDisconnect, getTwitterAccountResponse, handleSocialMediaTemplate }) => {
    let twitterAccount = {};
    if (getTwitterAccountResponse?.success) {
        twitterAccount = getTwitterAccountResponse.twitterAccount;
    }
    return (
        <Row>
            <Col sm="3">
                <TwitterIconRender />
            </Col>
            {twitterAccount.name ? (
                <>
                    <Col sm="4">
                        <SocialMediaAccountRender
                            name={twitterAccount.name}
                            profilePicture={twitterAccount.profile_image_url_https}
                            link={`https://www.twitter.com/${twitterAccount.screen_name}`}
                        />
                    </Col>
                    <Col sm="4">
                        <DisconnectButtonRender
                            onClick={() => {
                                handleTwitterDisconnect();
                            }}
                            hover_title="Disconnect"
                        />
                        &nbsp;
                        <TemplateButtonRender
                            hover_title="Template"
                            onClick={() => {
                                handleSocialMediaTemplate('twitter', true);
                            }}
                        />
                    </Col>
                </>
            ) : (
                <>
                    <Col sm="2">
                        <Button
                            size="sm"
                            variant="outline-primary"
                            onClick={() => {
                                handleTwitterLogin();
                            }}
                        >
                            CONNECT
                        </Button>
                    </Col>
                    <Col sm="4"></Col>
                </>
            )}
        </Row>
    );
};
export default TwitterForm;
