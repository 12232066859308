import React from 'react';
import { Badge, Image, Button, ButtonGroup } from 'react-bootstrap';
import { ImCheckmark, ImCross } from 'react-icons/im';
import { MdSchedule } from 'react-icons/md';
import { AiOutlineMenu } from 'react-icons/ai';
import { FaFacebookSquare, FaTwitterSquare, FaInstagramSquare, FaLinkedin } from 'react-icons/fa';
export const CheckIconRender = () => {
    return (
        <Badge variant="success">
            <ImCheckmark />
        </Badge>
    );
};
export const CrossIconRender = () => {
    return (
        <Badge variant="danger">
            <ImCross />
        </Badge>
    );
};

export const ScheduledIconRender = () => {
    return (
        <Badge variant="warning">
            <MdSchedule />
        </Badge>
    );
};
export const MenuIconRender = () => {
    return (
        <Badge variant="light">
            <AiOutlineMenu />
        </Badge>
    );
};
export const FacebookIconRender = () => {
    return (
        <h4>
            <Badge variant="dark">
                <FaFacebookSquare /> FACEBOOK
            </Badge>
        </h4>
        // <ButtonGroup aria-label="Basic example" size="sm">
        //     <Button variant="dark">
        //         <FaFacebookSquare />
        //     </Button>
        //     <Button variant="outline-dark">FACEBOOK</Button>
        // </ButtonGroup>
    );
};
export const TwitterIconRender = () => {
    return (
        <h4>
            <Badge variant="dark">
                <FaTwitterSquare /> TWITTER
            </Badge>
        </h4>
    );
};
export const InstagramIconRender = () => {
    return (
        <h4>
            <Badge variant="dark">
                <FaInstagramSquare /> INSTAGRAM
            </Badge>
        </h4>
    );
};
export const LinkedInIconRender = () => {
    return (
        <h4>
            <Badge variant="dark">
                <FaLinkedin /> LINKED IN
            </Badge>
        </h4>
    );
};
export const ConnectedIconRender = () => {
    return <small style={{ color: 'green' }}>connected</small>;
};
export const DisconnectedIconRender = () => {
    return <small style={{ color: 'red' }}>disconnected</small>;
};

export const SocialMediaAccountRender = ({ name, profilePicture, link }) => {
    return (
        <a href={link} target="_blank">
            <ButtonGroup aria-label="Basic example" size="sm">
                <Button variant="primary">
                    <Image rounded src={profilePicture} width={24} />
                </Button>
                <Button variant="outline-primary">{name}</Button>
            </ButtonGroup>
            {/* <Button size="sm" variant="primary" style={{ width: '100%' }}>
                <Image src={profilePicture} width={24} /> {name}
            </Button> */}
        </a>
    );
};
