// Version 1
// export const interestTypes = {
//     event: { like: 1, interested: 1, going: 1 },
//     project: { like: 1, interested: 1 },
//     general: { like: 1 },
//     volunteering: { like: 1, interested: 1, going: 1 },
//     advocacy: { like: 1, interested: 1 },
//     'in-kind': { like: 1, interested: 1 },
// };

// Version 2(18-07-21)
export const interestTypes = {
    event: { like: 1, going: 1, addable: 1 },
    project: { interested: 1, addable: 1 },
    general: { like: 1 },
    volunteering: { interested: 1, going: 1, addable: 1 },
    advocacy: { interested: 1, addable: 1 },
    'in-kind': { interested: 1 },
};
