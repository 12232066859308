import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
const connectionWarning = {
    connected: <small style={{ color: 'green' }}>You are connected with the user. You can send message without spending coin.</small>,
    nonConnected: <small style={{ color: 'red' }}>You are not connected with the user. Coin will be charged for sending message.</small>,
};
const OrganizationMessageView = (props) => {
    const { messageText, setMessageText, connected } = props;
    return (
        <>
            <Row>
                <Col>
                    <h4>SEND MESSAGE</h4>
                    <small>{connected ? connectionWarning.connected : connectionWarning.nonConnected}</small>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col>
                    <textarea
                        type="text"
                        className="form-control"
                        rows="7"
                        placeholder="Type here"
                        value={messageText}
                        onChange={(e) => {
                            setMessageText(e.target.value);
                        }}
                    />
                </Col>
            </Row>
            <br />
            <Row>
                <Col className="right-align">
                    <Button size="sm" variant="outline-secondary">
                        Cancel
                    </Button>
                    &nbsp;
                    <Button
                        size="sm"
                        onClick={() => {
                            props.handleMessageSubmit();
                        }}
                    >
                        Send
                    </Button>
                </Col>
            </Row>
        </>
    );
};
export default OrganizationMessageView;
