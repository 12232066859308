import React, { useState } from 'react';
import { Container, Image, Row, Col, Button, Table, Dropdown, Modal } from 'react-bootstrap';
import { defaultIndividualProfilePicture, defaultOrganizationProfilePicture } from '@Constants/default-images';
import { getPostTypeByValue } from '@Constants/post-types';
import { interestTypes } from '@Constants/interest-types';
import { EditButtonRender, DeleteButtonRender, DetailsButtonRender, ListButtonRender } from '@Components/form_template/buttons-render';
import moment from 'moment';
import * as RoutePaths from '@Constants/route-paths';

import { CheckIconRender, CrossIconRender, ScheduledIconRender } from '@Components/form_template/icon-render';

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}
const PostList = (props) => {
    const allPosts = props.allPosts;
    console.log('🚀 ~ file: post-list.js ~ line 17 ~ PostList ~ allPosts', allPosts);

    const viewers = props.viewers;

    const DropdownItemRender = (variant, type, label) => {
        return <Dropdown.Item href={RoutePaths.postCreatePage(type)}>{label}</Dropdown.Item>;
    };

    return (
        <Row>
            <Modal
                show={props.viewerModal}
                onHide={() => {
                    props.setViewerModal(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Total {props.viewers.length} Viewer{props.viewer && props.viewer.length > 1 ? 's' : ''}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {viewers &&
                        viewers.length > 0 &&
                        viewers.map((viewer, i) => {
                            let name = '';
                            let profilePicture = '';
                            if (viewer.viewerUserType === 'individual') {
                                name = viewer.viewerFirstName + ' ' + viewer.viewerLastName;
                                profilePicture = viewer.viewerProfilePicture ? viewer.viewerProfilePicture : defaultIndividualProfilePicture;
                            } else if (viewer.viewerUserType === 'organization') {
                                name = viewer.viewerName;
                                profilePicture = viewer.viewerProfilePicture ? viewer.viewerProfilePicture : defaultOrganizationProfilePicture;
                            }
                            return (
                                <Container className="ppl-liked-list" key={i}>
                                    <Row>
                                        <Col sm={2}>
                                            <Image src={profilePicture} thumbnail style={{ width: '100%', height: '100%' }} />
                                        </Col>
                                        <Col sm={6} className="v-middle">
                                            {name}
                                        </Col>
                                    </Row>
                                </Container>
                            );
                        })}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            props.setViewerModal(false);
                        }}
                        size="sm"
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Col>
                {allPosts && allPosts.length > 0 && (
                    <Table striped bordered hover responsive size="sm">
                        <thead>
                            <tr className="table-active">
                                <th>#</th>
                                <th>Post Type</th>
                                <th>Title</th>
                                <th>Published On</th>
                                <th>Published</th>
                                <th>Like/Interest</th>
                                <th>Going</th>
                                <th>Views</th>
                                <th>Follows</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allPosts.map((post, i) => {
                                let liked = post.interests.filter((interest) => interest.liked).length;
                                let interested = post.interests.filter((interest) => interest.interested).length;
                                let going = post.interests.filter((interest) => interest.going).length;
                                let totalViews = post.views ? post.views.length : 0;
                                let totalFollows = post.follows ? post.follows.length : 0;

                                return (
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{getPostTypeByValue(post.postType)[0].label}</td>
                                        <td>{post.title}</td>
                                        {/* <td>{moment(post.createdAt).format('LLL')}</td> */}
                                        <td>{moment(post.scheduledPublish ? post.scheduledPublishDateTime : post.createdAt).format('LLL')}</td>

                                        <td>
                                            <h4>
                                                {/* {post.scheduledPublish && (
                                                    <>
                                                        <ScheduledIconRender />
                                                        &nbsp;
                                                    </>
                                                )}
                                                {post.isActive ? <CheckIconRender /> : <CrossIconRender />} */}
                                                {post.isActive ? <CheckIconRender /> : post.scheduledPublish ? <ScheduledIconRender /> : <CrossIconRender />}
                                                {!post.isActive && post.scheduledPublishDateTime ? (
                                                    <>
                                                        &nbsp;
                                                        <ScheduledIconRender />
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </h4>
                                        </td>
                                        <td>
                                            {interestTypes[post.postType].like && liked > 0 && (
                                                <Button
                                                    variant="outline-primary"
                                                    size="sm"
                                                    onClick={() => {
                                                        props.handleClickCommittedButtons(post._id, 'liked');
                                                    }}
                                                >
                                                    {liked}
                                                </Button>
                                            )}

                                            {interestTypes[post.postType].interested && interested > 0 && (
                                                <Button
                                                    variant="outline-secondary"
                                                    size="sm"
                                                    onClick={() => {
                                                        props.handleClickCommittedButtons(post._id, 'interested');
                                                    }}
                                                >
                                                    {interested}
                                                </Button>
                                            )}
                                        </td>
                                        <td>
                                            {interestTypes[post.postType].going && going > 0 && (
                                                <Button
                                                    variant="outline-info"
                                                    size="sm"
                                                    onClick={() => {
                                                        props.handleClickCommittedButtons(post._id, 'going');
                                                    }}
                                                >
                                                    {going}
                                                </Button>
                                            )}
                                        </td>
                                        <td>
                                            {totalViews > 0 && (
                                                <Button
                                                    variant="outline-primary"
                                                    size="sm"
                                                    onClick={() => {
                                                        props.handleViewerListShow(post._id);
                                                    }}
                                                >
                                                    {totalViews}
                                                </Button>
                                            )}
                                        </td>
                                        <td>
                                            {totalFollows > 0 && (
                                                <Button
                                                    variant="outline-primary"
                                                    size="sm"
                                                    onClick={() => {
                                                        props.handleFollowerListShow(post._id);
                                                    }}
                                                >
                                                    {totalFollows}
                                                </Button>
                                            )}
                                        </td>
                                        <td>
                                            <DetailsButtonRender
                                                onClick={() => {
                                                    props.handleGoToPostDetails(post.postType, post._id);
                                                }}
                                            />{' '}
                                            &nbsp;
                                            <EditButtonRender
                                                onClick={() => {
                                                    props.handleGoToPostEdit(post.postType, post._id);
                                                }}
                                            />{' '}
                                            &nbsp;
                                            <DeleteButtonRender
                                                onClick={() => {
                                                    alert('Not developed yet');
                                                }}
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                )}
            </Col>
        </Row>
    );
};
export default PostList;
