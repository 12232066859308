import React from 'react';
import { Container, Row, Col, DropdownButton, Dropdown } from 'react-bootstrap';
import { defaultIndividualProfilePicture, defaultOrganizationProfilePicture } from '@Constants/default-images';
import Avatar from 'react-avatar';
import { BiDotsVertical } from 'react-icons/bi';
const ConversationInfoRender = (props) => {
    const { conversation, userId, gotoUserDetailsPage } = props;
    const { handleBlockUser, handleUnBlockUser } = props;
    const { connection, oppositeConnection } = props;
    let conversationBlock = {},
        oppositeConversationBlock = {},
        isBlocked = false;
    const getOneConversationBlockResponse = props.getOneConversationBlockResponse;
    const { handleDeleteConversation } = props;
    if (getOneConversationBlockResponse.success) {
        conversationBlock = props.getOneConversationBlockResponse.conversationBlock;
        oppositeConversationBlock = props.getOneConversationBlockResponse.oppositeConversationBlock;
        isBlocked = conversationBlock.isBlocked;
    }

    if (conversation && conversation._id) {
        const members = conversation.members;
        let membersName = '';
        let membersPicture = '';
        let member = {};
        for (let i = 0; i < members.length; i++) {
            if (members[i]._id !== userId) {
                let { userType } = members[i];
                member = members[i];
                let { firstName, lastName, profilePicture, name } = members[i].basicInfo;
                membersName += userType === 'individual' ? `${firstName ? firstName : ''} ${lastName ? lastName : ''}` : name;
                membersPicture = profilePicture
                    ? profilePicture
                    : userType === 'individual'
                    ? defaultIndividualProfilePicture
                    : defaultOrganizationProfilePicture;
            }
        }
        return (
            <Container>
                <Row className="conversation-info-box" style={{ backgroundColor: '#e8e8e8' }}>
                    <Col
                        sm={1}
                        onClick={() => {
                            gotoUserDetailsPage(member.userType, member._id);
                        }}
                    >
                        <Avatar round="5px" size="32" src={membersPicture} />
                    </Col>
                    <Col
                        sm={10}
                        onClick={() => {
                            gotoUserDetailsPage(member.userType, member._id);
                        }}
                    >
                        <b> {membersName}</b>
                    </Col>
                    <Col sm={1}>
                        <OptionButtonRender
                            handleBlockUser={handleBlockUser}
                            handleUnBlockUser={handleUnBlockUser}
                            isBlocked={isBlocked}
                            handleDeleteConversation={handleDeleteConversation}
                            conversationId={conversation._id}
                        />
                    </Col>
                </Row>
            </Container>
        );
    } else return <></>;
};
export default ConversationInfoRender;
const OptionButtonRender = ({ isBlocked, handleBlockUser, handleUnBlockUser, handleDeleteConversation, conversationId }) => {
    return (
        <DropdownButton size="sm" className="add-friend-btn dropdown-remove-arrow" title={<BiDotsVertical />} variant="primary">
            {isBlocked ? (
                <Dropdown.Item
                    size="sm"
                    eventKey="2"
                    onClick={() => {
                        handleUnBlockUser();
                    }}
                >
                    Unblock User
                </Dropdown.Item>
            ) : (
                <Dropdown.Item
                    size="sm"
                    eventKey="1"
                    onClick={() => {
                        handleBlockUser();
                    }}
                >
                    Block User
                </Dropdown.Item>
            )}
            <Dropdown.Item
                size="sm"
                eventKey="1"
                onClick={() => {
                    handleDeleteConversation(conversationId);
                }}
            >
                Delete Conversation
            </Dropdown.Item>
        </DropdownButton>
    );
};
