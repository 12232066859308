import React from 'react';
import { Card, CardColumns, Badge } from 'react-bootstrap';
import { getDefaultPP } from '@Constants/default-images';
const getUserTypeName = {
    individual: 'INDIVIDUAL',
    organization: 'ORGANIZATION',
};
const UserCardList = (props) => {
    const { users, title, handleGotoUserDetails } = props;
    const individuals = users.filter((user) => user.userType === 'individual');
    const organizations = users.filter((user) => user.userType === 'organization');
    const usersRender = (users) => {
        return (
            <>
                {users.map((user, i) => {
                    let { firstName, lastName, name, profilePicture, userType, _id } = user;
                    return (
                        <Card className="special-btn">
                            <div className="home-post-image-dark">
                                <Card.Img
                                    className="special-btn"
                                    variant="top"
                                    src={profilePicture ? profilePicture : getDefaultPP(userType)}
                                    alt="Following"
                                    onClick={() => {
                                        handleGotoUserDetails(userType, _id);
                                    }}
                                />
                                <Badge variant="primary" className="image-text-top-left-small">
                                    {getUserTypeName[userType]}
                                </Badge>
                            </div>
                            <Card.Body
                                onClick={() => {
                                    handleGotoUserDetails(userType, _id);
                                }}
                            >
                                <Card.Title>{userType === 'individual' ? firstName + ' ' + lastName : name}</Card.Title>
                            </Card.Body>
                        </Card>
                    );
                })}
            </>
        );
    };
    if (users && users.length > 0) {
        return (
            <>
                {individuals && individuals.length > 0 ? (
                    <>
                        <h6>INDIVIDUALS</h6>
                        <CardColumns className="four-columns">{usersRender(individuals)}</CardColumns>
                        <hr />
                    </>
                ) : (
                    <></>
                )}

                {organizations && organizations.length > 0 ? (
                    <>
                        <h6>ORGANIZATIONS</h6>
                        <CardColumns className="four-columns">{usersRender(organizations)}</CardColumns>
                    </>
                ) : (
                    <></>
                )}
            </>
        );
    } else return <h4>No {title} Found</h4>;
};
export default UserCardList;
