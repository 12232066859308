import ShareDA from '../data_accesses/share-da';
import * as Types from '../constants/reducer-types';

export const createOneShare = (share) => {
    return {
        type: Types.SET_SHARE,
        payload: ShareDA.create_one_share(share),
    };
};

export const updateOneShare = (shareId, share) => {
    return {
        type: Types.SET_SHARE,
        payload: ShareDA.update_one_share(shareId, share),
    };
};

export const getOneShare = (shareId) => {
    return {
        type: Types.GET_SHARE,
        payload: ShareDA.get_one_share(shareId),
    };
};

export const deleteOneShare = (shareId) => {
    return {
        type: Types.DELETE_SHARE,
        payload: ShareDA.delete_one_share(shareId),
    };
};
