import React from 'react';
import { Calendar, dateFnsLocalizer, Views } from 'react-big-calendar';

import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import { getColorByPostType, getAltColorByPostType } from '@Constants/post-types';

const locales = {
    'en-US': require('date-fns/locale/en-US'),
};

const allViews = ['month', 'week', 'day', 'agenda'];
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});
const add1Minute = (time) => {
    const added = new Date(time);
    added.setMinutes(added.getMinutes() + 100);
    return added;
};
const Event = ({ event }) => {
    return (
        <span>
            <strong>{event.title}</strong>
        </span>
    );
};
const EventAgenda = ({ event }) => {
    return (
        <span>
            <em style={{ color: 'white' }}>{event.title}</em>
        </span>
    );
};
const ColoredDateCellWrapper = ({ children }) =>
    React.cloneElement(React.Children.only(children), {
        style: {
            backgroundColor: 'white',
        },
    });

const CalendarView = (props) => {
    const posts = props.posts;
    const setPost = props.setPost;
    const setPostModal = props.setPostModal;
    const eventStyleGetter = (post, start, end, isSelected) => {
        var style = {
            background: post.isActive
                ? ''
                : `repeating-linear-gradient(45deg,${getAltColorByPostType(post.postType)},${getAltColorByPostType(post.postType)} 10px,${getColorByPostType(
                      post.postType,
                  )} 10px,${getColorByPostType(post.postType)} 20px)`,
            backgroundColor: getColorByPostType(post.postType),
            opacity: 0.8,
            color: 'white',
            borderRadius: '3px',
        };
        return {
            style: style,
        };
    };
    return (
        <>
            <Calendar
                onShowMore={(events, date) => {}} //console.log(date)}
                localizer={localizer}
                events={posts}
                startAccessor="scheduledPublishDateTime"
                endAccessor="scheduledPostEndTime"
                // startAccessor="startDateTime"
                // endAccessor="endDateTime"
                style={{ height: 700 }}
                views={allViews}
                components={{
                    event: Event,
                    agenda: {
                        event: EventAgenda,
                    },
                    timeSlotWrapper: ColoredDateCellWrapper,
                }}
                onSelectEvent={(e) => {
                    setPost(e);
                    setPostModal(true);
                }}
                eventPropGetter={eventStyleGetter}
            />
        </>
    );
};
export default CalendarView;
