import React, { useState } from 'react';
import { Modal, Container, Row, Col, Button, Table } from 'react-bootstrap';
import { DeleteButtonRender } from '../form_template/buttons-render';
import AsyncSelect from 'react-select/async';
const PostInviteModal = (props) => {
    const [invitationMessage, setInvitationMessage] = useState('Hey, You are invited to view this post!');
    //console.log('🚀 ~ file: post-invite-modal.js ~ line 7 ~ PostInviteModal ~ invitationMessage', invitationMessage);
    const { postInviteModal, setPostInviteModal, promiseOptions } = props;
    const { toBeInvitedUsers, setToBeInvitedUsers, handleAddUserToInvitationList, handleDeleteUserFromInvitationList, handleInviteUsers } = props;

    return (
        <Modal
            show={postInviteModal}
            onHide={() => {
                setPostInviteModal(false);
            }}
            size="lg"
        >
            <Modal.Header closeButton>Invite Users To View This Post</Modal.Header>
            <Modal.Body>
                <Container>
                    {toBeInvitedUsers && toBeInvitedUsers.length > 0 ? (
                        <Row>
                            <Col className="to-be-invited-user-list">
                                <Table striped bordered hover responsive size="sm">
                                    <thead>
                                        <tr>
                                            <td>User</td>
                                            <td>Actions</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {toBeInvitedUsers.map((user, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{user.label}</td>
                                                    <td>
                                                        <DeleteButtonRender
                                                            onClick={() => {
                                                                handleDeleteUserFromInvitationList(i);
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    ) : (
                        <></>
                    )}
                    <hr />
                    <Row>
                        <Col>
                            <label>User Name (type at least 3 letters)</label>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ zIndex: 400 }}>
                            <AsyncSelect
                                placeholder="Type at least 3 letter"
                                // cacheOptions
                                value={{}}
                                loadOptions={promiseOptions}
                                onChange={(value) => {
                                    handleAddUserToInvitationList(value);
                                }}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <label>Invitation Message</label>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <textarea
                                type="text"
                                className="form-control"
                                rows="3"
                                value={invitationMessage}
                                onChange={(e) => {
                                    setInvitationMessage(e.target.value);
                                }}
                            />
                        </Col>
                    </Row>
                    <br />
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    size="sm"
                    variant="outline-danger"
                    onClick={() => {
                        setPostInviteModal(false);
                    }}
                >
                    Cancel
                </Button>
                &nbsp;
                <Button
                    size="sm"
                    variant="success"
                    onClick={() => {
                        handleInviteUsers(toBeInvitedUsers, invitationMessage);
                        setPostInviteModal(false);
                    }}
                >
                    Invite
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default PostInviteModal;
