import axios from 'axios';
import * as APIPaths from '@Constants/api-paths';
class SocialMediaDA {
    get_social_media_connection_info = (userId) => {
        return axios
            .get(APIPaths.getSocialMediaConnectionInfo(userId), APIPaths.apiConfig())
            .then((response) => response.data)
            .catch((err) => err.response.data);
    };
    get_social_media_template = (socialMedia, userId) => {
        return axios
            .get(APIPaths.getSocialMediaTemplate(socialMedia, userId), APIPaths.apiConfig())
            .then((response) => response.data)
            .catch((err) => err.response.data);
    };
    set_social_media_template = (socialMedia, userId, template) => {
        console.log('🚀 ~ file: social-media-da.js ~ line 17 ~ SocialMediaDA ~ socialMedia, userId, template', socialMedia, userId, template);
        return axios
            .put(APIPaths.setSocialMediaTemplate(socialMedia, userId), { template }, APIPaths.apiConfig())
            .then((response) => response.data)
            .catch((err) => err.response.data);
    };
    // FACEBOOK
    get_facebook_account = () => {
        return axios
            .get(APIPaths.getFacebookAccount(), APIPaths.apiConfig())
            .then((response) => response.data)
            .catch((err) => err.response.data);
    };
    get_facebook_by_user = (userId) => {
        return axios
            .get(APIPaths.getFacebookByUser(userId), APIPaths.apiConfig())
            .then((response) => response.data)
            .catch((err) => err.response.data);
    };
    connect_facebook = (facebookInfo) => {
        return axios
            .post(APIPaths.connectFacebook(), facebookInfo, APIPaths.apiConfig())
            .then((response) => {
                console.log('R', response);
                return response.data;
            })
            .catch((err) => err.response.data);
    };
    disconnect_facebook = () => {
        console.log(APIPaths.disconnectFacebook(), APIPaths.apiConfig());
        return axios
            .post(APIPaths.disconnectFacebook(), {}, APIPaths.apiConfig())
            .then((response) => response.data)
            .catch((err) => {
                console.log(err.response);
                return err.response.data;
            });
    };
    get_all_facebook_pages_by_user = (userId) => {
        return axios
            .get(APIPaths.getAllFacebookPagesByUser(userId), APIPaths.apiConfig())
            .then((response) => response.data)
            .catch((err) => err.response.data);
    };

    get_selected_facebook_page_by_user = (userId) => {
        return axios
            .get(APIPaths.getSelectedFacebookPageByUser(userId), APIPaths.apiConfig())
            .then((response) => response.data)
            .catch((err) => err.response.data);
    };
    set_selected_facebook_page_by_user = (userId, page) => {
        console.log('🚀 ~ file: social-media-da.js ~ line 40 ~ SocialMediaDA ~ userId', userId);
        return axios
            .put(APIPaths.setSelectedFacebookPageByUser(userId), page, APIPaths.apiConfig())
            .then((response) => response.data)
            .catch((err) => err.response.data);
    };

    // LINKED IN
    get_linked_in_account = () => {
        console.log('APIPaths.getLinkedInAccount()', APIPaths.getLinkedInAccount());
        return axios
            .get(APIPaths.getLinkedInAccount(), APIPaths.apiConfig())
            .then((response) => response.data)
            .catch((err) => err.response.data);
    };
    connect_linked_in = (linkedInInfo) => {
        return axios
            .post(APIPaths.connectLinkedIn(), linkedInInfo, APIPaths.apiConfig())
            .then((response) => response.data)
            .catch((err) => err.response.data);
    };
    disconnect_linked_in = () => {
        return axios
            .post(APIPaths.disconnectLinkedIn(), {}, APIPaths.apiConfig())
            .then((response) => response.data)
            .catch((err) => err.response.data);
    };

    // TWITTER
    get_twitter_account = () => {
        return axios
            .get(APIPaths.getTwitterAccount(), APIPaths.apiConfig())
            .then((response) => response.data)
            .catch((err) => err.response.data);
    };
    connect_twitter = (twitterData) => {
        return axios
            .post(APIPaths.connectTwitter(), twitterData, APIPaths.apiConfig())
            .then((response) => response.data)
            .catch((err) => err.response.data);
    };
    disconnect_twitter = () => {
        return axios
            .post(APIPaths.disconnectTwitter(), {}, APIPaths.apiConfig())
            .then((response) => response.data)
            .catch((err) => err.response.data);
    };
    //requestTwitterToken
    request_twitter_token = (callBackURL) => {
        console.log(APIPaths.requestTwitterToken(callBackURL));
        return axios
            .get(APIPaths.requestTwitterToken(callBackURL), APIPaths.apiConfig())
            .then((response) => response.data)
            .catch((err) => err.response.data);
    };
    set_twitter_access_token = (twitterData) => {
        return axios
            .post(APIPaths.setTwitterAccessToken(), twitterData, APIPaths.apiConfig())
            .then((response) => response.data)
            .catch((err) => err.response.data);
    };
}
export default new SocialMediaDA();
