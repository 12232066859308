import React from 'react';
import { Container, Row, Col, Table, Button, Modal } from 'react-bootstrap';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { FacebookIconRender, SocialMediaAccountRender } from '../form_template/icon-render';
import { ConnectButtonRender, DisconnectButtonRender, SettingsButtonRender, TemplateButtonRender } from '../form_template/buttons-render';
const FacebookForm = ({
    getFacebookAccountResponse,
    handleFacebookConnectResponse,
    handleFacebookDisconnectResponse,
    allPages,
    selectedFacebookPage,
    handleFacebookSettingModalOpen,
    handleSocialMediaTemplate,
}) => {
    let facebookAccount = {};
    if (getFacebookAccountResponse?.success) {
        facebookAccount = getFacebookAccountResponse.facebookAccount;
    }
    return (
        <Row>
            <Col sm="3">
                <FacebookIconRender />
            </Col>
            {facebookAccount.id ? (
                <>
                    <Col sm="4">
                        <SocialMediaAccountRender name={facebookAccount.name} profilePicture={facebookAccount.profilePicture} link={facebookAccount.link} />
                    </Col>
                    <Col sm="4">
                        <DisconnectButtonRender
                            hover_title="Disconnect"
                            onClick={() => {
                                handleFacebookDisconnectResponse();
                            }}
                        />
                        &nbsp;
                        <SettingsButtonRender
                            hover_title="Settings"
                            onClick={() => {
                                handleFacebookSettingModalOpen();
                            }}
                        />
                        &nbsp;
                        {/* </Col>
                    <Col sm="2"> */}
                        <TemplateButtonRender
                            hover_title="Template"
                            onClick={() => {
                                handleSocialMediaTemplate('facebook', true);
                            }}
                        />
                    </Col>
                </>
            ) : (
                <Col sm="2">
                    <FacebookLogin
                        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                        fields="name,email,picture"
                        scope="user_posts,user_photos,pages_manage_posts,pages_manage_engagement,user_link"
                        callback={handleFacebookConnectResponse}
                        render={(renderProps) => {
                            return (
                                <Button size="sm" variant="outline-primary" onClick={renderProps.onClick}>
                                    CONNECT
                                </Button>
                            );
                        }}
                    />
                </Col>
            )}
        </Row>
    );
};
export default FacebookForm;
