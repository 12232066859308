import React from 'react';
import { Field } from 'redux-form';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { InputRender } from '../form_template/input-render';
import { required, email, stringLengthRange } from '@Actions/validate';

const stringRange6To256 = stringLengthRange(6, 256);

const ChangePasswordForm = (props) => {
    return (
        <Container className="saLoginForm">
            <Row>
                <Col></Col>
                <Col md="6" className="sign-ing-form">
                    <form onSubmit={props.handleOnSubmit}>
                        <h4>Recover Password</h4>
                        <br />
                        <Field name="email" type="text" component={InputRender} label="Email" validate={[required, email, stringRange6To256]} />
                        <Row>
                            <Col sm={4}></Col>
                            <Col sm={8}>
                                <Button type="submit" size="sm" variant="primary">
                                    Recover Password
                                </Button>
                            </Col>
                        </Row>
                        <div style={{ height: 25 }} />
                    </form>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    );
};
export default ChangePasswordForm;
