import React from 'react';
import { Button, Modal, Table } from 'react-bootstrap';

const CultivationListModal = (props) => {
    const { handleCopyCultivation, cultivation, cultivationFormModal, handleShowCultivationFormModal, setCultivationFormModal, handleSetCultivationValue } =
        props;
    //console.log('🚀 ~ file: cultivation-form-modal.js ~ line 6 ~ CultivationListModal ~ cultivation', cultivation);
    return (
        <Modal
            style={{ zIndex: 10000 }}
            show={cultivationFormModal}
            onHide={() => {
                setCultivationFormModal(false);
            }}
        >
            <Modal.Header closeButton>Cultivate List</Modal.Header>
            <Modal.Body>
                <label>Title</label>
                <input
                    type="text"
                    value={cultivation.title}
                    onChange={(e) => {
                        handleSetCultivationValue('title', e.target.value);
                    }}
                    className="form-control"
                />
                <br />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    size="sm"
                    variant="outline-danger"
                    onClick={() => {
                        setCultivationFormModal(false);
                    }}
                >
                    Cancel
                </Button>
                &nbsp;
                <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={() => {
                        handleCopyCultivation();
                    }}
                >
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default CultivationListModal;
