import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import ConversationList from '@Components/conversation/conversation-list';
import { connect } from 'react-redux';
import * as RoutePaths from '@Constants/route-paths';
import ConversationDetails from '@Components/conversation/conversation-details';
import ConversationInfoRender from '@Components/conversation/conversation-info';
import { FileMaxSizeErrorModal } from '@Components/conversation/conversation-warnings';
import {
    getAllConversationsByUser,
    getConversation,
    setMessage,
    getMessageTextFromLS,
    setMessageTextOnLS,
    printMessageTextOnLS,
    removeAllMessagesOnLS,
    getUserConnectivity,
    deleteOneConversation,
    clearDeleteOneConversation,
} from '@Actions/conversation-action';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { serverAddress } from '@Constants/api-paths';
import MessageForm from '@Components/conversation/message-form';
import useSound from 'use-sound';
import openSocket from 'socket.io-client';
import { userDetailsPage } from '../../constants/route-paths';
import { NotificationManager } from 'react-notifications';
import { getOneConnection } from '../../actions/connection-action';
import { createOneConversationBlock, removeOneConversationBlock, getOneConversationBlock } from '../../actions/conversation-block-action';
const socket = openSocket(serverAddress, { transports: ['websocket', 'polling', 'flashsocket'] });

const Conversations = (props) => {
    const [messageText, setMessageText] = useState('');
    const [images, setImages] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [userId, setUserId] = useState('');
    const [fileSizeErrorModal, setFileSizeErrorModal] = useState(false);
    const [socketConversationId, setSocketConversationId] = useState(1);
    const [receiverId, setReceiverId] = useState('');
    const [connection, setConnection] = useState({});
    const [messageForm, setMessageForm] = useState(true);
    const activeSocketFunction = (userId, conversationId) => {
        socket.on('Message_' + userId.toString(), (success) => {
            if (success === 'NewMessage') {
                props.dispatch(getAllConversationsByUser(userId));
                const audioEl = document.getElementsByClassName('audio-element-message')[0];
                audioEl.play();
                //console.log('Calling ', conversationId);
                setSocketConversationId(Math.random());
                //console.log('socketConversationId', socketConversationId);
            }
        });
    };
    useEffect(() => {
        // alert(socketConversationId);
        props.dispatch(getConversation(props.match.params.conversationId));
    }, [socketConversationId]);
    const DeActiveSocketFunction = (userId, conversationId) => {
        socket.off('Message_' + userId.toString(), (success) => {
            if (success === 'NewMessage') {
                //console.log('Closing Socket ', conversationId);
            }
        });
    };
    useEffect(() => {
        const user = props.auth.user;
        if (user && user._id) {
            setUserId(user._id);
            DeActiveSocketFunction(userId, props.match.params.conversationId);
            activeSocketFunction(user._id, props.match.params.conversationId);
            props.dispatch(getAllConversationsByUser(user._id));
        }
    }, []);
    useEffect(() => {
        // removeAllMessagesOnLS();
        const conversationId = props.match.params.conversationId;
        if (conversationId !== 'all') {
            props.dispatch(getConversation(conversationId));
            setMessageText(getMessageTextFromLS(conversationId));
            DeActiveSocketFunction(userId, props.match.params.conversationId);
            activeSocketFunction(userId, conversationId);
        }
        return () => {
            DeActiveSocketFunction(userId, props.match.params.conversationId);
        };
    }, [props.match.params.conversationId]);
    const submitMessage = (text) => {
        let imagesSize = 0;
        let attachmentsSize = 0;
        for (let i = 0; i < images.length; i++) {
            imagesSize += images[i].length;
        }
        imagesSize = imagesSize * 0.75;

        for (let i = 0; i < attachments.length; i++) {
            attachmentsSize += attachments[i].length;
        }
        attachmentsSize = attachmentsSize * 0.75;
        const totalSize = imagesSize + attachmentsSize;
        if (totalSize > 25 * 1024 * 1024) {
            // setFileSizeErrorModal(true);
            alert('Maximum 25 MB File can be send at a time.');
        } else {
            props.dispatch(
                setMessage({
                    senderId: userId,
                    conversationId: props.match.params.conversationId,
                    text,
                    images,
                    attachments,
                }),
            );
            setMessageText('');
            setImages([]);
            setAttachments([]);
            setMessageTextOnLS(props.match.params.conversationId, '');
        }
    };
    const onMessageSubmit = (text) => {
        const isConnected = connection.isConnected;
        if (isConnected) {
            submitMessage(text);
        } else {
            confirmAlert({
                title: 'Warning',
                message: `You are not currently connected to receiver. This will cost you a coin.`,
                buttons: [
                    {
                        label: 'YES',
                        onClick: () => {
                            submitMessage(text);
                        },
                    },
                    {
                        label: 'No',
                        onClick: () => {},
                    },
                ],
            });
        }
    };
    const handleGoToConversationDetails = (conversationId) => {
        setMessageTextOnLS(props.match.params.conversationId, messageText);
        setMessageText('');
        props.history.push(RoutePaths.ConversationPage(conversationId));
    };
    const gotoUserDetailsPage = (userType, userId) => {
        props.history.push(userDetailsPage(userType, userId));
    };
    // CONNECTION SYSTEM
    useEffect(() => {
        const { success } = props.getConversationResponse;
        if (success) {
            const { members } = props.getConversationResponse.conversation;
            if (members.length === 2) {
                const receiverId = members[0]._id === userId ? members[1]._id : members[0]._id;
                setReceiverId(receiverId);
                props.dispatch(getOneConnection(userId, receiverId));
                props.dispatch(getOneConversationBlock(userId, receiverId));
            }
        }
    }, [props.getConversationResponse]);

    useEffect(() => {
        const { success } = props.getOneConnection;
        if (success) {
            setConnection(props.getOneConnection.connection);
        }
    }, [props.getOneConnection]);

    const handleBlockUser = () => {
        confirmAlert({
            title: 'Warning',
            message: `If you block this user, they will not be able to send you messages.`,
            buttons: [
                {
                    label: 'YES',
                    onClick: () => {
                        props.dispatch(
                            createOneConversationBlock({
                                userId,
                                blockedUserId: receiverId,
                            }),
                        );
                    },
                },
                {
                    label: 'No',
                    onClick: () => {},
                },
            ],
        });
    };
    const handleUnBlockUser = () => {
        confirmAlert({
            title: 'Warning',
            message: `If you unblock this user, they will be able to send you messages.`,
            buttons: [
                {
                    label: 'YES',
                    onClick: () => {
                        props.dispatch(removeOneConversationBlock(userId, receiverId));
                    },
                },
                {
                    label: 'No',
                    onClick: () => {},
                },
            ],
        });
    };
    useEffect(() => {
        const { success } = props.getOneConversationBlockResponse;
        if (success) {
            const { conversationBlock, oppositeConversationBlock } = props.getOneConversationBlockResponse;
            console.log(
                '🚀 ~ file: manage-conversations.js ~ line 219 ~ useEffect ~ conversationBlock, oppositeConversationBlock',
                conversationBlock,
                oppositeConversationBlock,
            );
            if (!conversationBlock.isBlocked && !oppositeConversationBlock.isBlocked) {
                setMessageForm(true);
            } else {
                setMessageForm(false);
            }
        }
    }, [props.getOneConversationBlockResponse]);
    // FAILURE FOR NO COIN
    useEffect(() => {
        const { success, message } = props.setMessageResponse;

        if (success === false) {
            NotificationManager.error(message, 'Failed');
        }
    }, [props.setMessageResponse]);
    useEffect(() => {
        props.dispatch(getOneConversationBlock(userId, receiverId));
    }, [props.createOneConversationBlockResponse, props.removeOneConversationBlockResponse]);
    // CONNECTIVITY
    const handleGetConnectivity = (userId) => {
        props.dispatch(getUserConnectivity(userId));
    };
    // DELETE CONVERSATION
    const handleDeleteConversation = (conversationId) => {
        confirmAlert({
            title: 'Warning',
            message: `You can not retrieve the conversation once deleted.`,
            buttons: [
                {
                    label: 'YES',
                    onClick: () => {
                        props.dispatch(deleteOneConversation(conversationId));
                    },
                },
                {
                    label: 'No',
                    onClick: () => {},
                },
            ],
        });
    };
    useEffect(() => {
        const { success } = props.deleteOneConversationResponse;
        if (success) {
            NotificationManager.success('Conversation deleted successfully.', 'success');
            props.dispatch(clearDeleteOneConversation());
            props.dispatch(getConversation(props.match.params.conversationId));
            props.dispatch(getAllConversationsByUser(userId));
        } else if (success === false) {
            NotificationManager.error('Conversation not deleted successfully', 'Failed');
        }
    }, [props.deleteOneConversationResponse]);
    return (
        <Container fluid>
            <FileMaxSizeErrorModal show={fileSizeErrorModal} setShow={setFileSizeErrorModal} />
            <Row>
                <Col className="parent-page-manage-conversation">
                    <Row>
                        <Col sm={4}>
                            <ConversationList
                                allConversations={props.getAllConversationsResponse.success ? props.getAllConversationsResponse.conversations : []}
                                userId={userId}
                                handleGoToConversationDetails={handleGoToConversationDetails}
                                conversationId={props.match.params.conversationId}
                                handleGetConnectivity={handleGetConnectivity}
                                handleDeleteConversation={handleDeleteConversation}
                            />
                        </Col>
                        {props.match.params.conversationId !== 'all' && (
                            <Col sm={8}>
                                <ConversationInfoRender
                                    conversation={props.getConversationResponse.success ? props.getConversationResponse.conversation : {}}
                                    userId={userId}
                                    gotoUserDetailsPage={gotoUserDetailsPage}
                                    connection={connection}
                                    handleBlockUser={handleBlockUser}
                                    handleUnBlockUser={handleUnBlockUser}
                                    getOneConversationBlockResponse={props.getOneConversationBlockResponse}
                                    handleDeleteConversation={handleDeleteConversation}
                                />
                                <ConversationDetails
                                    conversation={props.getConversationResponse.success ? props.getConversationResponse.conversation : {}}
                                    messageUserEntity={props.getConversationResponse.success ? props.getConversationResponse.messageUserEntity : {}}
                                    userId={userId}
                                />
                                {messageForm ? (
                                    <MessageForm
                                        onMessageSubmit={onMessageSubmit}
                                        messageText={messageText}
                                        setMessageText={setMessageText}
                                        attachments={attachments}
                                        setAttachments={setAttachments}
                                        images={images}
                                        setImages={setImages}
                                        // messageText={messageText} setMessageText={setMessageText}
                                    />
                                ) : (
                                    <Container className="conversation-send-message">
                                        <Row>
                                            <Col className="center-text">YOU CAN NOT SEND ANY MESSAGE TO THIS CONVERSATION</Col>
                                        </Row>
                                    </Container>
                                )}
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};
const mapStateToProps = (state) => {
    const getAllConversationsResponse = state.Conversation.getAllConversationsByUser;
    const getConversationResponse = state.Conversation.getConversation;
    const getOneConnection = state.Connection.getOneConnection;
    const setMessageResponse = state.Conversation.setMessage;

    const getOneConversationBlockResponse = state.ConversationBlock.getOneConversationBlock;
    const createOneConversationBlockResponse = state.ConversationBlock.createConversationBlock;

    const removeOneConversationBlockResponse = state.ConversationBlock.removeConversationBlock;
    const deleteOneConversationResponse = state.Conversation.deleteConversation;
    return {
        getAllConversationsResponse,
        getConversationResponse,
        getOneConnection,
        setMessageResponse,
        getOneConversationBlockResponse,
        createOneConversationBlockResponse,
        removeOneConversationBlockResponse,
        deleteOneConversationResponse,
    };
};
export default connect(mapStateToProps, null)(Conversations);
