import * as Types from '@Constants/reducer-types';
const initialState = {
    getAllGlobalMapAreas: { success: false },
    generateMapAreaFile: { success: false },
};

const MapAreaReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.GET_ALL_GLOBAL_MAP_AREAS:
            return { ...state, getAllGlobalMapAreas: action.payload };
        case Types.GENERATE_MAP_AREA_FILE:
            return { ...state, generateMapAreaFile: action.payload };
        default:
            return { ...state };
    }
};
export default MapAreaReducer;
