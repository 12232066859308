import * as Types from '../constants/reducer-types';
import ConversationBlockDA from '../data_accesses/conversation-block-da';

export const createOneConversationBlock = (blockInfo) => {
    return {
        type: Types.CREATE_CONVERSATION_BLOCK,
        payload: ConversationBlockDA.create_conversation_block(blockInfo),
    };
};
export const removeOneConversationBlock = (userId, blockedUserId) => {
    return {
        type: Types.REMOVE_CONVERSATION_BLOCK,
        payload: ConversationBlockDA.remove_conversation_block(userId, blockedUserId),
    };
};
export const getOneConversationBlock = (userId, blockedUserId) => {
    return {
        type: Types.GET_ONE_CONVERSATION_BLOCK,
        payload: ConversationBlockDA.get_one_conversation_block(userId, blockedUserId),
    };
};
export const getAllConversationBlock = (userId) => {
    return {
        type: Types.GET_ALL_CONVERSATION_BLOCKS,
        payload: ConversationBlockDA.get_all_conversation_blocks(userId),
    };
};
