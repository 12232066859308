import axios from 'axios';
import * as APIPaths from '@Constants/api-paths';
class FeedbackDA {
    create_one_feedback = (feedback) => {
        return axios
            .post(APIPaths.createOneFeedback, feedback, APIPaths.apiConfig())
            .then((response) => {
                return response.data;
            })
            .catch((err) => err.response.data);
    };
}
export default new FeedbackDA();
