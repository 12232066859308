import React, { useEffect, useState } from 'react';
import { Button, Modal, Container, Row, Col } from 'react-bootstrap';
import { UpArrowButtonRender, DownArrowButtonRender } from '../form_template/buttons-render';
import { templatePostFields } from '../../constants/post-types';

const OrganizationSocialMediaTemplate = (props) => {
    const { templateModal, setTemplateModal, templateType, setTemplateType, handleSubmitTemplate } = props;
    const [template, setTemplate] = useState(templatePostFields);
    useEffect(() => {
        const { success } = props.getSocialMediaTemplateResponse;
        if (success) {
            console.log(
                '🚀 ~ file: organization-social-media-template.js ~ line 11 ~ useEffect ~ props.getSocialMediaTemplateResponse.socialMediaTemplate',
                props.getSocialMediaTemplateResponse.socialMediaTemplate.template,
            );
            setTemplate(props.getSocialMediaTemplateResponse.socialMediaTemplate.template);
        }
    }, [props.getSocialMediaTemplateResponse]);
    const handleItemPosition = (idx, movement) => {
        if (movement === 'up' && idx > 0) {
            let template_ = template;
            [template_[idx], template_[idx - 1]] = [template_[idx - 1], template_[idx]];
            setTemplate([...template_]);
        } else if (movement === 'down' && idx < templatePostFields.length - 1) {
            let template_ = template;
            [template_[idx], template_[idx + 1]] = [template_[idx + 1], template_[idx]];
            setTemplate([...template_]);
        }
    };
    const handleCheckField = (idx, name) => {
        console.log('🚀 ~ file: organization-social-media-template.js ~ line 19 ~ handleCheckField ~ idx, name', idx, name);
        let template_ = template;
        if (template_[idx].name === name) {
            template_[idx].selected = !template_[idx].selected;
            console.log('🚀 ~ file: organization-social-media-template.js ~ line 19 ~ handleCheckField ~ idx, template_', template_);
            setTemplate([...template_]);
        }
    };
    return (
        <Modal
            show={templateModal}
            onHide={() => {
                setTemplateModal(false);
            }}
        >
            <Modal.Header>Manage {templateType} template</Modal.Header>
            <Modal.Body>
                <Container>
                    {template &&
                        template.length > 0 &&
                        template.map((field, i) => {
                            return (
                                <Row key={i} style={{ borderBottom: '1px dotted gray', padding: 3 }}>
                                    <Col>
                                        {i !== 0 && (
                                            <UpArrowButtonRender
                                                onClick={() => {
                                                    handleItemPosition(i, 'up');
                                                }}
                                            />
                                        )}
                                        &nbsp;
                                        {i < template.length - 1 && (
                                            <DownArrowButtonRender
                                                onClick={() => {
                                                    handleItemPosition(i, 'down');
                                                }}
                                            />
                                        )}
                                    </Col>
                                    <Col>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id={'social-media-template_' + field.name}
                                            checked={field.selected}
                                            onChange={() => {
                                                handleCheckField(i, field.name);
                                            }}
                                        />
                                        <label className="form-check-label" htmlFor={'social-media-template_' + field.name}>
                                            {field.label}
                                        </label>
                                    </Col>
                                </Row>
                            );
                        })}
                </Container>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    size="sm"
                    variant="outline-success"
                    onClick={() => {
                        handleSubmitTemplate(template);
                    }}
                >
                    SAVE
                </Button>
                <Button
                    size="sm"
                    variant="outline-warning"
                    onClick={() => {
                        setTemplate(templatePostFields);
                    }}
                >
                    RESET
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default OrganizationSocialMediaTemplate;
