// AUTHENTICATION
export const USER_SIGN_IN = 'USER_SIGN_IN';
export const USER_SIGN_UP = 'USER_SIGN_UP';
export const USER_SIGN_OUT = 'USER_SIGN_OUT';
export const USER_AUTH = 'USER_AUTH';

// PASSWORD
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';

// IMPACT AREA
export const GET_ALL_GLOBAL_IMPACT_AREAS = 'GET_ALL_GLOBAL_IMPACT_AREAS';
export const GET_ALL_IMPACT_AREAS_BY_USER = 'GET_ALL_IMPACT_AREAS_BY_USER';

// SKILL
export const GET_ALL_GLOBAL_SKILLS = 'GET_ALL_GLOBAL_SKILLS';
export const GET_ALL_SKILLS_BY_USER = 'GET_ALL_SKILLS_BY_USER';

// LANGUAGE
export const GET_ALL_GLOBAL_LANGUAGES = 'GET_ALL_GLOBAL_LANGUAGES';
export const GET_ALL_LANGUAGES_BY_USER = 'GET_ALL_LANGUAGES_BY_USER';

export const GET_ALL_ORGANIZATION_TYPES = 'GET_ALL_ORGANIZATION_TYPES';

// USER
export const GET_BASIC_INFO = 'GET_BASIC_INFO';
export const GET_INVOLVEMENT = 'GET_INVOLVEMENT';
export const GET_PRIVACY = 'GET_PRIVACY';
export const GET_SERVICE_INFO = 'GET_SERVICE_INFO';
export const GET_INTERNAL_LINK = 'GET_INTERNAL_LINK';

export const SET_BASIC_INFO = 'SET_BASIC_INFO';
export const SET_INVOLVEMENT = 'SET_INVOLVEMENT';
export const SET_PRIVACY = 'SET_PRIVACY';
export const SET_SERVICE_INFO = 'SET_SERVICE_INFO';
export const SET_INTERNAL_LINK = 'SET_INTERNAL_LINK';

export const CLEAR_BASIC_INFO = 'CLEAR_BASIC_INFO';
export const CLEAR_INVOLVEMENT = 'CLEAR_INVOLVEMENT';
export const CLEAR_PRIVACY = 'CLEAR_PRIVACY';
export const CLEAR_SERVICE_INFO = 'CLEAR_SERVICE_INFO';
export const CLEAR_INTERNAL_LINK = 'CLEAR_INTERNAL_LINK';

export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_ALL_INDIVIDUALS = 'GET_ALL_INDIVIDUALS';
export const GET_USER_PUBLIC_INFO = 'GET_USER_PUBLIC_INFO';
export const GET_ALL_SUGGESTED_USERS = 'GET_ALL_SUGGESTED_USERS';

export const CLEAR_ALL_USERS = 'CLEAR_ALL_USERS';
export const CLEAR_USER_PUBLIC_INFO = 'CLEAR_USER_PUBLIC_INFO';
export const CLEAR_ALL_SUGGESTED_USERS = 'CLEAR_ALL_SUGGESTED_USERS';
export const GET_ALL_USERS_NAME = 'GET_ALL_USERS_NAME';

export const GET_ALL_CONNECTED_USERS_ID = 'GET_ALL_CONNECTED_USERS_ID';
export const CHECK_IF_CONNECTED = 'CHECK_IF_CONNECTED';

// FOLLOW
export const FOLLOW_USER = 'FOLLOW_USER';
export const UNFOLLOW_USER = 'UNFOLLOW_USER';
export const GET_ALL_FOLLOWERS = 'GET_ALL_FOLLOWERS';
export const GET_ALL_FOLLOWINGS = 'GET_ALL_FOLLOWINGS';
export const CHECK_IF_FOLLOWER = 'CHECK_IF_FOLLOWER';
export const GET_FOLLOWER_SUMMARY = 'GET_FOLLOWER_SUMMARY';

// POSTS
export const GET_POST = 'GET_POST';
export const SET_POST = 'SET_POST';
export const GET_ALL_POSTS = 'GET_ALL_POSTS';
export const CLEAR_POST = 'CLEAR_POST';
export const DELETE_POST = 'DELETE_POST';
export const HOME_FEED = 'HOME_FEED';
export const GET_ALL_SUGGESTED_POSTS = 'GET_ALL_SUGGESTED_POSTS';

export const LIKE_POST = 'LIKE_POST';
export const CANCEL_LIKE_POST = 'CANCEL_LIKE_POST';

export const INTERESTED_POST = 'INTERESTED_POST';
export const CANCEL_INTERESTED_POST = 'CANCEL_INTERESTED_POST';

export const GOING_POST = 'GOING_POST';
export const CANCEL_GOING_POST = 'CANCEL_GOING_POST';

export const CHANGE_POST_INTEREST = 'CHANGE_POST_INTEREST';
export const GET_GALLERY = 'GET_GALLERY';

export const GET_ALL_CALENDAR_POSTS = 'GET_ALL_CALENDAR_POSTS';
export const CLEAR_ALL_CALENDAR_POSTS = 'CLEAR_ALL_CALENDAR_POSTS';

export const GET_ALL_VIEWERS = 'GET_ALL_VIEWERS';
export const GET_ALL_VIEWERS_BY_POST = 'GET_ALL_VIEWERS_BY_POST';
export const GET_ALL_FOLLOWERS_BY_POST = 'GET_ALL_FOLLOWERS_BY_POST';
export const GET_VIEWER_SUMMARY = 'GET_VIEWER_SUMMARY';
export const GET_POST_STATISTICS = 'GET_POST_STATISTICS';
export const GET_ALL_UPCOMING_POSTS = 'GET_ALL_UPCOMING_POSTS';
export const INVITE_USERS_TO_VIEW_POST = 'INVITE_USERS_TO_VIEW_POST';
export const GET_USER_WALLET = 'GET_USER_WALLET';

export const GET_ALL_RECENTLY_ENDED_POSTS = 'GET_ALL_RECENTLY_ENDED_POSTS';

// NOTIFICATION
export const GET_TOP_NOTIFICATIONS = 'GET_TOP_NOTIFICATIONS';
export const GET_ALL_NOTIFICATIONS = 'GET_ALL_NOTIFICATIONS';
export const GET_NOTIFICATIONS_COUNT = 'GET_NOTIFICATIONS_COUNT';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const SET_NOTIFICATION_LOCAL = 'SET_NOTIFICATION_LOCAL';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const UPDATE_ALL_NOTIFICATIONS = 'UPDATE_ALL_NOTIFICATIONS';
export const READ_ALL_NOTIFICATIONS = 'READ_ALL_NOTIFICATIONS';

// MEMBERSHIP
export const GET_MEMBERSHIP = 'GET_MEMBERSHIP';
export const SET_MEMBERSHIP = 'SET_MEMBERSHIP';
export const GET_ALL_MEMBERSHIPS = 'GET_ALL_MEMBERSHIPS';

export const DELETE_MEMBERSHIP = 'DELETE_MEMBERSHIP';
export const ACCEPT_MEMBERSHIP = 'ACCEPT_MEMBERSHIP';
export const REJECT_MEMBERSHIP = 'REJECT_MEMBERSHIP';

// FRIENDSHIP
export const GET_FRIENDSHIP = 'GET_FRIENDSHIP';
export const SET_FRIENDSHIP = 'SET_FRIENDSHIP';
export const GET_ALL_FRIENDSHIPS = 'GET_ALL_FRIENDSHIPS';

export const DELETE_FRIENDSHIP = 'DELETE_FRIENDSHIP';
export const ACCEPT_FRIENDSHIP = 'ACCEPT_FRIENDSHIP';
export const REJECT_FRIENDSHIP = 'REJECT_FRIENDSHIP';
export const CHECK_IF_FRIENDS = 'CHECK_IF_FRIENDS';

// ENDORSEMENT
export const ENDORSE_USER = 'ENDORSE_USER';
export const CANCEL_ENDORSE_USER = 'CANCEL_ENDORSE_USER';
export const GET_ALL_ENDORSERS = 'GET_ALL_ENDORSERS';
export const GET_ALL_ENDORSEES = 'GET_ALL_ENDORSEES';
export const CHECK_IF_ENDORSES = 'CHECK_IF_ENDORSES';
export const GET_ENDORSER_SUMMARY = 'GET_ENDORSER_SUMMARY';

// CONVERSATION
export const SET_CONVERSATION = 'SET_CONVERSATION';
export const GET_ALL_CONVERSATIONS_BY_USER = 'GET_ALL_CONVERSATIONS_BY_USER';
export const GET_CONVERSATION = 'GET_CONVERSATION';
export const SET_MESSAGE = 'SET_MESSAGE';
export const GET_CONVERSATIONS_COUNT = 'GET_CONVERSATIONS_COUNT';
export const SEND_MESSAGES_TO_ALL_CONNECTED = 'SEND_MESSAGES_TO_ALL_CONNECTED';
export const SEND_MESSAGES_TO_MULTIPLE_USERS = 'SEND_MESSAGES_TO_MULTIPLE_USERS';
export const CLEAR_MESSAGES_TO_MULTIPLE_USERS = 'CLEAR_MESSAGES_TO_MULTIPLE_USERS';
export const DELETE_CONVERSATION = 'DELETE_CONVERSATION';
export const CLEAR_DELETE_CONVERSATION = 'CLEAR_DELETE_CONVERSATION';

// CULTIVATION
export const GET_CULTIVATION = 'GET_CULTIVATION';
export const GET_ALL_CULTIVATIONS = 'GET_ALL_CULTIVATIONS';
export const SET_CULTIVATION = 'SET_CULTIVATION';
export const COPY_CULTIVATION = 'COPY_CULTIVATION';
export const ADD_USERS_TO_CULTIVATION = 'ADD_USERS_TO_CULTIVATION';
export const REMOVE_USERS_FROM_CULTIVATION = 'REMOVE_USERS_FROM_CULTIVATION';
export const CLEAR_CULTIVATION = 'CLEAR_CULTIVATION';
export const CLEAR_ADD_USERS_TO_CULTIVATION = 'CLEAR_ADD_USERS_TO_CULTIVATION';
export const CLEAR_REMOVE_USERS_FROM_CULTIVATION = 'CLEAR_REMOVE_USERS_FROM_CULTIVATION';
export const DELETE_CULTIVATION = 'DELETE_CULTIVATION';

// SCHEDULER
export const CHECK_IF_POST_ADDED_TO_SCHEDULER = 'CHECK_IF_POST_ADDED_TO_SCHEDULER';
export const ADD_POST_TO_SCHEDULER = 'ADD_POST_TO_SCHEDULER';
export const REMOVE_POST_FROM_SCHEDULER = 'REMOVE_POST_FROM_SCHEDULER';
export const GET_ALL_SCHEDULER_POSTS = 'GET_ALL_SCHEDULER_POSTS';
// SHARE
export const SET_SHARE = 'SET_SHARE';
export const GET_SHARE = 'GET_SHARE';
export const GET_ALL_SHARE = 'GET_ALL_SHARE';
export const DELETE_SHARE = 'DELETE_SHARE';
export const CLEAR_SHARE = 'CLEAR_SHARE';

// FEEDBACKS
export const SET_FEEDBACK = 'SET_FEEDBACK';
export const GET_ALL_FEEDBACKS = 'GET_ALL_FEEDBACKS';
export const CLEAR_FEEDBACK = 'CLEAR_FEEDBACK';

// WALLET
export const GET_COIN_SPENDING_SUMMARY = 'GET_COIN_SPENDING_SUMMARY';

// INVITATIONS
export const INVITE_USERS_TO_FOLLOW_ORGANIZATION = 'INVITE_USERS_TO_FOLLOW_ORGANIZATION';

// MAP AREAS
export const GET_ALL_GLOBAL_MAP_AREAS = 'GET_ALL_GLOBAL_MAP_AREAS';
export const GENERATE_MAP_AREA_FILE = 'GENERATE_MAP_AREA_FILE';

// SOCIAL MEDIA
export const GET_SOCIAL_MEDIA_CONNECTION_INFO = 'GET_SOCIAL_MEDIA_CONNECTION_INFO';
export const CLEAR_CONNECT_SOCIAL_MEDIA = 'CLEAR_CONNECT_SOCIAL_MEDIA';
export const CLEAR_DISCONNECT_SOCIAL_MEDIA = 'CLEAR_DISCONNECT_SOCIAL_MEDIA';
export const GET_SOCIAL_MEDIA_TEMPLATE = 'GET_SOCIAL_MEDIA_TEMPLATE';
export const SET_SOCIAL_MEDIA_TEMPLATE = 'SET_SOCIAL_MEDIA_TEMPLATE';
export const CLEAR_SOCIAL_MEDIA_TEMPLATE = 'CLEAR_SOCIAL_MEDIA_TEMPLATE';
export const GET_FACEBOOK_TEMPLATE = 'GET_FACEBOOK_TEMPLATE';
export const GET_LINKED_IN_TEMPLATE = 'GET_LINKED_IN_TEMPLATE';
export const GET_TWITTER_TEMPLATE = 'GET_TWITTER_TEMPLATE';
// FACEBOOK
export const GET_FACEBOOK_BY_USER = 'GET_FACEBOOK_BY_USER';
export const GET_FACEBOOK_ACCOUNT = 'GET_FACEBOOK_ACCOUNT';
export const CONNECT_FACEBOOK = 'CONNECT_FACEBOOK';
export const DISCONNECT_FACEBOOK = 'DISCONNECT_FACEBOOK';
export const GET_ALL_FACEBOOK_PAGES_BY_USER = 'GET_ALL_FACEBOOK_PAGES_BY_USER';
export const GET_SELECTED_FACEBOOK_PAGE_BY_USER = 'GET_SELECTED_FACEBOOK_PAGE_BY_USER';
export const SET_SELECTED_FACEBOOK_PAGE_BY_USER = 'SET_SELECTED_FACEBOOK_PAGE_BY_USER';

//LINKEDIN
export const GET_LINKED_IN_ACCOUNT = 'GET_LINKED_IN_ACCOUNT';
export const CONNECT_LINKED_IN = 'CONNECT_LINKED_IN';
export const DISCONNECT_LINKED_IN = 'DISCONNECT_LINKED_IN';
//TWITTER
export const GET_TWITTER_ACCOUNT = 'GET_TWITTER_ACCOUNT';
export const CONNECT_TWITTER = 'CONNECT_TWITTER';
export const DISCONNECT_TWITTER = 'DISCONNECT_TWITTER';
export const SET_TWITTER_ACCESS_TOKEN = 'SET_TWITTER_ACCESS_TOKEN';

// CONNECTIONS
export const GET_ONE_CONNECTION = 'GET_ONE_CONNECTION_BY_USER';
export const GET_ONE_OPPOSITE_CONNECTION = 'GET_ONE_OPPOSITE_CONNECTION_BY_USER';
export const UPDATE_ONE_CONNECTION = 'UPDATE_ONE_CONNECTION';
export const UPDATE_ONE_OPPOSITE_CONNECTION = 'UPDATE_ONE_OPPOSITE_CONNECTION';
export const GET_ALL_CONNECTIONS = 'GET_ALL_CONNECTIONS';

// CONVERSATION BLOCK
export const CREATE_CONVERSATION_BLOCK = 'CREATE_CONVERSATION_BLOCK';
export const REMOVE_CONVERSATION_BLOCK = 'REMOVE_CONVERSATION_BLOCK';
export const GET_ONE_CONVERSATION_BLOCK = 'GET_ONE_CONVERSATION_BLOCK';
export const GET_ALL_CONVERSATION_BLOCKS = 'GET_ALL_CONVERSATION_BLOCKS';
