import React from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';

const BulkMessageModal = (props) => {
    const { messageText, setMessageText } = props;
    const { bulkMessageModal, setBulkMessageModal, handleSubmitBulkMessage, totalUsersToBeSent } = props;

    return (
        <Modal
            show={bulkMessageModal}
            onHide={() => {
                setBulkMessageModal(false);
            }}
            size="lg"
        >
            <Modal.Header closeButton>Send message to {totalUsersToBeSent} users </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            <label>Message</label>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <textarea
                                type="text"
                                className="form-control"
                                rows="3"
                                value={messageText}
                                onChange={(e) => {
                                    setMessageText(e.target.value);
                                }}
                            />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    size="sm"
                    variant="outline-danger"
                    onClick={() => {
                        setBulkMessageModal(false);
                    }}
                >
                    Cancel
                </Button>
                &nbsp;
                <Button
                    size="sm"
                    variant="success"
                    onClick={() => {
                        handleSubmitBulkMessage(false);
                    }}
                >
                    SEND
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default BulkMessageModal;
