/* eslint-disable */

import { followUser, getAllFollowings, unfollowUser } from '@Actions/follow-action';
import { getAllGlobalImpactAreas } from '@Actions/impact-area-action';
import { defaultIndividualProfilePicture } from '@Constants/default-images';
import {
    cancelGoingPost,
    cancelInterestedPost,
    cancelLikePost,
    changePostInterest,
    getAllPostsByFilter,
    goingPost,
    interestedPost,
    likePost,
} from '@Actions/post-action';
import { searchUsersByName } from '@Actions';
import { createOneShare } from '@Actions/share-action';
import LoadingAnim from '@Components/form_template/loading-anim';
import { NotificationManager } from 'react-notifications';
import PostsCardView from '@Components/post/post-card-view';
import PostsMapView from '@Components/post/post-map-view';
import PostFilter from '@Components/search/post-filters';
import SearchMenu from '@Components/search/search-menu';
import { defaultCurrentLocation } from '@Constants/default-user-information';
import { allSearchablePostTypes } from '@Constants/post-types';
import { postDetailsPage } from '@Constants/route-paths';
import React, { useEffect, useState } from 'react';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import { getAllSchedulerPosts, addPostToScheduler, removePostFromScheduler } from '@Actions/scheduler-action';

// INVITATION IMPORT
import PostInviteModal from '@Components/invitation/post-invite-modal';
import { getAllConnectedUsersId, getCoinSpendingSummary, inviteUsersToViewPost } from '@Actions/user-action';
import { countConnectedAndUnConnectedUsers, filterOnlyConnectedUsers } from '@Actions/wallet-action';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const SearchCommunityActivity = (props) => {
    const [currentLocation, setCurrentLocation] = useState(defaultCurrentLocation);
    const [activePage, setActivePage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [viewType, setViewType] = useState('list');
    const [userId, setUserId] = useState('');
    const [followingObject, setFollowingObject] = useState({});
    const [schedulerPostsObject, setSchedulerPostsObject] = useState({});
    const [filter, setFilter] = useState({
        title: '',
        impactAreas: [],
        postTypes: allSearchablePostTypes,
        startDate: '',
        endDate: '',
        fullAddress: '',
        keyword: '',
        topNeed: false,
        isActive: true,
    });
    //INVITATION
    const [postInviteModal, setPostInviteModal] = useState(false);
    const [userSearchText, setUserSearchText] = useState('');
    const [toBeInvitedUsers, setToBeInvitedUsers] = useState([]);
    const [invitedPost, setInvitedPost] = useState({ postId: '', postType: '' });
    const resetFilter = () => {
        setFilter({
            title: '',
            impactAreas: [],
            postTypes: allSearchablePostTypes,
            // startDate: new Date(),
            // endDate: new Date(),
            startDate: '',
            endDate: '',
            fullAddress: '',
            keyword: '',
            topNeed: false,
            isActive: true,
        });
    };
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(function (position) {
            //console.log(position);
            if (position) {
                const coords = position.coords;
                setCurrentLocation({
                    latitude: coords.latitude,
                    longitude: coords.longitude,
                });
            }
        });
    }, []);
    const changeFilter = (key, value) => {
        let filter_ = filter;
        filter[key] = value;

        setFilter({ ...filter_ });
    };
    const handleOnApplyFilter = () => {
        setLoading(true);
        props.dispatch(getAllPostsByFilter(filter));
        setLoading(false);
        setActivePage(1);
    };
    const gotoPostDetails = (postType, postId) => {
        props.history.push(postDetailsPage(postType, postId));
    };
    useEffect(() => {
        const getInitialInfo = () => {
            setLoading(true);
            const user = props.auth.user;
            setUserId(user._id);
            props.dispatch(getAllGlobalImpactAreas());
            props.dispatch(getAllFollowings(user._id));
            props.dispatch(getAllSchedulerPosts(user._id));
            props.dispatch(getCoinSpendingSummary());
            props.dispatch(getAllConnectedUsersId(user._id));
            setLoading(false);
        };
        getInitialInfo();
    }, []);
    useEffect(() => {
        const { success } = props.getAllFollowingsResponse;
        if (success) {
            const cards = {};

            const followings = props.getAllFollowingsResponse.followings;
            for (let i = 0; i < followings.length; i++) {
                cards[followings[i].followingId] = true;
            }
            setFollowingObject(cards);
            //console.log('🚀 ~ file: search-community-activity.js ~ line 95 ~ useEffect ~ cards', cards);
        }
    }, [props.getAllFollowingsResponse]);

    useEffect(() => {
        const { success } = props.getAllSchedulerPostsResponse;
        if (success) {
            const cards = {};
            const posts = props.getAllSchedulerPostsResponse.allSchedulerPosts;
            for (let i = 0; i < posts.length; i++) {
                cards[posts[i].postId] = true;
            }
            setSchedulerPostsObject(cards);
        }
    }, [props.getAllSchedulerPostsResponse]);

    const handleLikePost = (postId) => {
        props.dispatch(likePost(postId));
        props.dispatch(changePostInterest(postId, userId, 'like'));
    };
    const handleCancelLikePost = (postId) => {
        props.dispatch(cancelLikePost(postId));
        props.dispatch(changePostInterest(postId, userId, 'unlike'));
    };

    const handleInterestedPost = (postId) => {
        props.dispatch(interestedPost(postId));
        props.dispatch(changePostInterest(postId, userId, 'interested'));
    };
    const handleCancelInterestedPost = (postId) => {
        //console.log('🚀 ~ file: home.js ~ line 46 ~ handleCancelInterestedPost ~ postId', postId);
        props.dispatch(cancelInterestedPost(postId));
        props.dispatch(changePostInterest(postId, userId, 'uninterested'));
    };

    const handleGoingPost = (postId) => {
        props.dispatch(goingPost(postId));
        props.dispatch(changePostInterest(postId, userId, 'going'));
    };
    const handleCancelGoingPost = (postId) => {
        props.dispatch(cancelGoingPost(postId));
        props.dispatch(changePostInterest(postId, userId, 'ungoing'));
    };
    const handleFollowClick = (followingId) => {
        setLoading(true);
        props.dispatch(followUser({ followerId: userId, followingId }));
        const followings_ = followingObject;
        followings_[followingId] = true;
        setFollowingObject({ ...followings_ });
        setLoading(false);
    };
    const handleUnfollowClick = (followingId) => {
        setLoading(true);
        props.dispatch(unfollowUser({ followerId: userId, followingId }));
        const followings_ = followingObject;
        followings_[followingId] = false;
        setFollowingObject({ ...followings_ });
        setLoading(false);
    };

    const handleAddPostToScheduler = (postId) => {
        setLoading(true);
        props.dispatch(addPostToScheduler(userId, postId));
        const schedulerPostsObject_ = schedulerPostsObject;
        schedulerPostsObject_[postId] = true;
        setSchedulerPostsObject({ ...schedulerPostsObject_ });
        setLoading(false);
    };
    const handleRemovePostFromScheduler = (postId) => {
        setLoading(true);
        props.dispatch(removePostFromScheduler(userId, postId));
        const schedulerPostsObject_ = schedulerPostsObject;
        schedulerPostsObject_[postId] = false;
        setSchedulerPostsObject({ ...schedulerPostsObject_ });
        setLoading(false);
    };
    const handleCreateOneShare = (postId) => {
        props.dispatch(
            createOneShare({
                userId: userId,
                postId,
            }),
        );
        NotificationManager.success('Post is shared successfully', 'success');
    };
    // INVITATION
    const getUsers = async (searchText) => {
        let connectedUsersId = {};
        let { success } = props.getAllConnectedUsersIdResponse;
        if (success) {
            connectedUsersId = props.getAllConnectedUsersIdResponse.connectedUsersId;
        }
        //console.log('🚀 ~ file: display-post.js ~ line 124 ~ getUsers ~ connectedUsersId', connectedUsersId);
        if (searchText.length > 2) {
            const users = await searchUsersByName(searchText).then((response) => {
                if (response.success) {
                    return response.users;
                } else return [];
            });
            if (users && users.length > 0) {
                let allUsers = [];

                if (users[0]) {
                    allUsers.push(...users[0].users.slice(0, 20));
                }
                if (users[1]) {
                    allUsers.push(...users[1].users.slice(0, 20));
                }
                //console.log('223', connectedUsersId);
                //console.log('🚀 ~ file: individual-membership.js ~ line 43 ~ newPromise ~ organizations', allUsers);
                allUsers = allUsers.map((user) => {
                    let connected = false;
                    if (connectedUsersId.includes(user._id)) connected = true;
                    return {
                        value: user._id,
                        label: (
                            <Row>
                                <Col sm={2}>
                                    <img src={user.profilePicture ? user.profilePicture : defaultIndividualProfilePicture} height="30px" width="30px" />
                                </Col>
                                <Col sm={10}>
                                    {user.userType === 'individual' ? user.concatNameWithSpace : user.name}&nbsp;
                                    <small>{connected ? 'Connected' : 'Not Connected'}</small>
                                </Col>
                            </Row>
                        ),
                    };
                });
                //console.log('🚀 ~ file: individual-membership.js ~ line 252 ~ newPromise ~ organizations', allUsers);
                return allUsers;
            } else return [];
        } else return [];
    };
    const promiseOptions = (inputValue) =>
        new Promise((resolve) => {
            setTimeout(() => {
                resolve(getUsers(inputValue));
            }, 1000);
        });
    const handleChangeSearchText = async (text) => {
        //console.log(text);
        setUserSearchText(text);
    };
    const handleAddUserToInvitationList = (user) => {
        const toBeInvitedUsers_ = toBeInvitedUsers;
        toBeInvitedUsers_.push(user);
        setToBeInvitedUsers([...toBeInvitedUsers_]);
    };
    const handleDeleteUserFromInvitationList = (idx) => {
        const toBeInvitedUsers_ = toBeInvitedUsers;
        toBeInvitedUsers_.splice(idx, 1);
        setToBeInvitedUsers([...toBeInvitedUsers_]);
    };
    const handleInviteUsers = (users, message) => {
        //console.log('🚀 ~ file: display-post.js ~ line 168 ~ DisplayPost ~ users, message', users, message);
        if (!users || (users && users.length === 0)) {
            alert('No user was selected to send invitation');
        } else {
            const userList = users.map((user) => user.value);
            let { success } = props.getAllConnectedUsersIdResponse;
            if (success) {
                const { connectedUsersId } = props.getAllConnectedUsersIdResponse;
                const { totalConnected, totalNotConnected } = countConnectedAndUnConnectedUsers(userList, connectedUsersId);
                success = props.getCoinSpendingSummaryResponse;
                if (success) {
                    const { coinPerInvitation } = props.getCoinSpendingSummaryResponse.coinSpendingSummary;
                    const totalCoins = parseInt(totalNotConnected * coinPerInvitation);
                    if (totalCoins > 0) {
                        confirmAlert({
                            title: 'Warning',
                            message: `You are inviting total ${totalNotConnected} users. This will cost you ${parseInt(
                                totalNotConnected * coinPerInvitation,
                            )} Coins. Are you sure?`,
                            buttons: [
                                {
                                    label: 'YES',
                                    onClick: () => {
                                        props.dispatch(
                                            inviteUsersToViewPost(userId, {
                                                usersToBeInvited: userList,
                                                postLink: `/post/${invitedPost.postType}/details/${invitedPost.postId}`,
                                                invitationMessage: message,
                                            }),
                                        );
                                    },
                                },
                                {
                                    label: 'No',
                                    onClick: () => {
                                        setPostInviteModal(true);
                                    },
                                },
                                {
                                    label: 'Only Send to Connected Users',
                                    onClick: () => {
                                        props.dispatch(
                                            inviteUsersToViewPost(userId, {
                                                usersToBeInvited: filterOnlyConnectedUsers(userList, connectedUsersId),
                                                postLink: `/post/${invitedPost.postType}/details/${invitedPost.postId}`,
                                                invitationMessage: message,
                                            }),
                                        );
                                    },
                                },
                            ],
                        });
                    } else {
                        props.dispatch(
                            inviteUsersToViewPost(userId, {
                                usersToBeInvited: userList,
                                postLink: `/post/${invitedPost.postType}/details/${invitedPost.postId}`,
                                invitationMessage: message,
                            }),
                        );
                    }
                }
            }
        }
    };
    useEffect(() => {
        const { success, message } = props.inviteUsersToViewPostResponse;
        if (success) {
            NotificationManager.success(message, 'success');
        } else if (success === false) {
            NotificationManager.error(message, 'Failed');
        }
    }, [props.inviteUsersToViewPostResponse]);
    if (loading) return <LoadingAnim />;
    return (
        <Container>
            <Row className="parent-page">
                <Col lg={4}>
                    <SearchMenu selected="community-activity" />
                    <PostInviteModal
                        postInviteModal={postInviteModal}
                        setPostInviteModal={setPostInviteModal}
                        promiseOptions={promiseOptions}
                        handleChangeSearchText={handleChangeSearchText}
                        userSearchText={userSearchText}
                        toBeInvitedUsers={toBeInvitedUsers}
                        setToBeInvitedUsers={setToBeInvitedUsers}
                        handleAddUserToInvitationList={handleAddUserToInvitationList}
                        handleDeleteUserFromInvitationList={handleDeleteUserFromInvitationList}
                        handleInviteUsers={handleInviteUsers}
                    />
                    <hr />
                    <PostFilter
                        changeFilter={changeFilter}
                        resetFilter={resetFilter}
                        handleOnApplyFilter={handleOnApplyFilter}
                        filter={filter}
                        organizationTypes={props.getOrganizationTypeResponse?.success ? props.getOrganizationTypeResponse.organizationTypes : []}
                        impactAreas={props.getImpactAreaResponse?.success ? props.getImpactAreaResponse.impactAreas : []}
                    />
                </Col>
                <Col lg={8}>
                    <Nav
                        variant="pills"
                        activeKey={viewType}
                        onSelect={(eventKey) => {
                            setViewType(eventKey);
                        }}
                        size="sm"
                    >
                        <Nav.Item sz="sm">
                            <Nav.Link eventKey="list" title="list">
                                List
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item sz="sm">
                            <Nav.Link eventKey="map" title="map">
                                Map
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <hr />
                    {viewType === 'list' && (
                        <>
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={activePage}
                                itemsCountPerPage={30}
                                totalItemsCount={props.getAllPostsResponse.success ? props.getAllPostsResponse.allPosts.length : 0}
                                pageRangeDisplayed={5}
                                onChange={(page) => {
                                    setActivePage(page);
                                }}
                            />
                            <PostsCardView
                                allPosts={
                                    props.getAllPostsResponse.success
                                        ? props.getAllPostsResponse.allPosts.slice((activePage - 1) * 30, activePage * 30 - 1)
                                        : []
                                }
                                gotoPostDetails={gotoPostDetails}
                                userId={userId}
                                handleLikePost={handleLikePost}
                                handleCancelLikePost={handleCancelLikePost}
                                handleInterestedPost={handleInterestedPost}
                                handleCancelInterestedPost={handleCancelInterestedPost}
                                handleGoingPost={handleGoingPost}
                                handleCancelGoingPost={handleCancelGoingPost}
                                followingObject={followingObject}
                                handleFollowClick={handleFollowClick}
                                handleUnfollowClick={handleUnfollowClick}
                                followOrganizationButton={true}
                                schedulerPostsObject={schedulerPostsObject}
                                handleAddPostToScheduler={handleAddPostToScheduler}
                                handleRemovePostFromScheduler={handleRemovePostFromScheduler}
                                handleCreateOneShare={handleCreateOneShare}
                                setPostInviteModal={setPostInviteModal}
                                setInvitedPost={setInvitedPost}
                            />
                        </>
                    )}
                    {viewType === 'map' && (
                        <PostsMapView
                            allPosts={props.getAllPostsResponse.success ? props.getAllPostsResponse.allPosts : []}
                            zoom={6}
                            currentLocation={currentLocation}
                            gotoPostDetails={gotoPostDetails}
                            userId={userId}
                            handleLikePost={handleLikePost}
                            handleCancelLikePost={handleCancelLikePost}
                            handleInterestedPost={handleInterestedPost}
                            handleCancelInterestedPost={handleCancelInterestedPost}
                            handleGoingPost={handleGoingPost}
                            handleCancelGoingPost={handleCancelGoingPost}
                            followingObject={followingObject}
                            handleFollowClick={handleFollowClick}
                            handleUnfollowClick={handleUnfollowClick}
                            handleCreateOneShare={handleCreateOneShare}
                            setPostInviteModal={setPostInviteModal}
                        />
                    )}
                </Col>
            </Row>
        </Container>
    );
};
const mapStateToProps = (state) => {
    const getImpactAreaResponse = state.ImpactArea.getGlobalImpactAreas;
    const getAllPostsResponse = state.Post.getAllPosts;
    const getAllFollowingsResponse = state.Follow.getAllFollowings;
    const getFollowResponse = state.Follow.followUser;
    const getUnfollowResponse = state.Follow.unfollowUser;
    const getAllSchedulerPostsResponse = state.Scheduler.getAllSchedulerPosts;
    const setPostShareResponse = state.Share.setShare;
    const getAllConnectedUsersIdResponse = state.User.getAllConnectedUsersId;
    const getCoinSpendingSummaryResponse = state.User.getCoinSpendingSummary;
    const inviteUsersToViewPostResponse = state.User.inviteUsersToViewPost;
    return {
        getImpactAreaResponse,
        getAllPostsResponse,
        getAllFollowingsResponse,
        getFollowResponse,
        getUnfollowResponse,
        getAllSchedulerPostsResponse,
        setPostShareResponse,
        getAllConnectedUsersIdResponse,
        getCoinSpendingSummaryResponse,
        inviteUsersToViewPostResponse,
        getCoinSpendingSummaryResponse,
        inviteUsersToViewPostResponse,
    };
};
export default connect(mapStateToProps, null)(SearchCommunityActivity);
