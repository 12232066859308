import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
const OptionMenu = (props) => {
    const userName = props.userName;
    const executeScroll = props.executeScroll;
    const summaryRef = props.summaryRef;
    const statisticsRef = props.statisticsRef;
    const notificationRef = props.notificationRef;
    const upcomingRef = props.upcomingRef;
    const conversationRef = props.conversationRef;
    const recentlyEndedRef = props.recentlyEndedRef;
    const ButtonRender = (ref, label) => {
        return (
            <Button
                style={{ marginBottom: '5px' }}
                variant="dark"
                size="sm"
                onClick={() => {
                    executeScroll(ref);
                }}
            >
                {label}
            </Button>
        );
    };
    return (
        <Row>
            <Col md={12}>
                <h4>{userName}</h4>
            </Col>
            <Col md={12}>
                <Row>
                    <Col>
                        {ButtonRender(summaryRef, 'SUMMARY')}&nbsp;
                        {ButtonRender(statisticsRef, 'STATISTICS')}&nbsp;
                        {ButtonRender(notificationRef, 'TOP NOTIFICATIONS')}&nbsp;
                        {ButtonRender(conversationRef, 'TOP CONVERSATIONS')}&nbsp;
                        {ButtonRender(upcomingRef, 'UPCOMING')}&nbsp;
                        {ButtonRender(recentlyEndedRef, 'RECENTLY ENDED')}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
export default OptionMenu;
