export const countConnectedAndUnConnectedUsers = (userList, connectedUsersId) => {
    //console.log('🚀 ~ file: wallet-action.js ~ line 2 ~ countConnectedAndUnConnectedUsers ~ userList, connectedUsersId', userList, connectedUsersId);
    let totalConnected = 0;
    let totalNotConnected = 0;
    const connectedUserObject = {};
    for (let i = 0; i < connectedUsersId.length; i++) {
        connectedUserObject[connectedUsersId[i]] = true;
    }
    for (let i = 0; i < userList.length; i++) {
        if (connectedUserObject[userList[i]]) totalConnected++;
        else totalNotConnected++;
    }
    return { totalConnected, totalNotConnected };
};

export const filterOnlyConnectedUsers = (userList, connectedUsersId) => {
    const connectedUsers = [];
    const connectedUserObject = {};
    for (let i = 0; i < connectedUsersId.length; i++) {
        connectedUserObject[connectedUsersId[i]] = true;
    }
    for (let i = 0; i < userList.length; i++) {
        if (connectedUserObject[userList[i]]) connectedUsers.push(userList[i]);
    }
    //console.log('🚀 ~ file: wallet-action.js ~ line 27 ~ filterOnlyConnectedUsers ~ connectedUsers', connectedUsers);
    return connectedUsers;
};
