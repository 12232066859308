import React, { useEffect, useState } from 'react';
import CultivationList from '@Components/cultivation/cultivation-list';
import * as RoutePaths from '@Constants/route-paths';
import { connect } from 'react-redux';
import {
    getAllCultivationsByUser,
    deleteCultivation,
    clearCultivation,
    copyCultivation,
    addUsersToCultivation,
    clearAddUsersToCultivation,
} from '@Actions/cultivation-action';
import { NotificationManager } from 'react-notifications';
import CultivationFormModal from '@Components/cultivation/cultivation-form-modal';
import CultivationListModal from '@Components/cultivation/cultivation-list-modal';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
const ManageCultivation = (props) => {
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [cultivationFormModal, setCultivationFormModal] = useState(false);
    const [cultivation, setCultivation] = useState({});
    const [cultivationListModal, setCultivationListModal] = useState(false);
    const [selectedCultivation, setSelectedCultivation] = useState({});
    const getInitialInfo = (userId) => {
        setLoading(true);
        props.dispatch(getAllCultivationsByUser(userId));
        setLoading(false);
    };
    useEffect(() => {
        const user = props.auth.user;
        if (user && user._id) {
            setUserId(user._id);
            getInitialInfo(user._id);
        }
    }, [props.auth]);

    const handleGoToCreateCultivationPage = () => {
        props.history.push(RoutePaths.cultivationCreatePage);
    };
    const handleGoToEditCultivationPage = (cultivationId) => {
        props.history.push(RoutePaths.cultivationEditPage(cultivationId));
    };
    const handleGoToDisplayCultivationPage = (cultivationId) => {
        props.history.push(RoutePaths.cultivationDetailsPage(cultivationId));
    };
    const handleDeleteCultivation = (cultivationId) => {
        confirmAlert({
            title: 'Warning',
            message: 'Are you sure to delete this cultivation? This is a permanent action.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        props.dispatch(deleteCultivation(cultivationId));
                    },
                },
                {
                    label: 'No',
                },
            ],
        });
    };
    const handleShowCultivationFormModal = (cultivation) => {
        setCultivationFormModal(true);
        setCultivation(cultivation);
    };
    const handleCopyCultivation = () => {
        props.dispatch(copyCultivation(cultivation));
        setCultivationFormModal(false);
    };
    const handleSetCultivationValue = (key, value) => {
        const cultivation_ = cultivation;
        cultivation_[key] = value;
        setCultivation({ ...cultivation_ });
    };
    useEffect(() => {
        const { success } = props.deleteCultivationResponse;
        if (success) {
            NotificationManager.success('Cultivation deleted.', 'success');
            props.dispatch(clearCultivation());
            props.dispatch(getAllCultivationsByUser(userId));
        } else if (success === false) {
            NotificationManager.error('Cultivation not deleted', 'Failed');
            props.dispatch(clearCultivation());
        }
    }, [props.deleteCultivationResponse]);

    useEffect(() => {
        const { success } = props.copyCultivationResponse;
        if (success) {
            const { cultivation } = props.copyCultivationResponse;
            NotificationManager.success('Cultivation copied.', 'success');
            props.dispatch(getAllCultivationsByUser(userId));
            props.dispatch(clearCultivation());
        } else if (success === false) {
            NotificationManager.error('Cultivation not copied', 'Failed');
            props.dispatch(clearCultivation());
        }
    }, [props.copyCultivationResponse]);

    // COPY USER
    const handleAddUserToCultivationList = (cultivationId) => {
        const usersList = selectedCultivation.users;
        //console.log('🚀 ~ file: manage-cultivation.js ~ line 99 ~ handleAddUserToCultivationList ~ usersList', usersList);

        props.dispatch(addUsersToCultivation(cultivationId, usersList));
    };
    useEffect(() => {
        const { success } = props.addUserToCultivationResponse;
        if (success) {
            NotificationManager.success('Users are added successfully.', 'success');
            // setCultivationModal(false);
            props.dispatch(clearAddUsersToCultivation());
            getInitialInfo(userId);
        } else if (success === false) {
            NotificationManager.error('Users are not added', 'Failed');
            props.dispatch(clearAddUsersToCultivation());
        }
    }, [props.addUserToCultivationResponse]);
    return (
        <>
            <CultivationFormModal
                handleCopyCultivation={handleCopyCultivation}
                cultivation={cultivation}
                cultivationFormModal={cultivationFormModal}
                handleShowCultivationFormModal={handleShowCultivationFormModal}
                handleSetCultivationValue={handleSetCultivationValue}
                setCultivationFormModal={setCultivationFormModal}
            />
            <CultivationListModal
                cultivationModal={cultivationListModal}
                setCultivationModal={setCultivationListModal}
                allCultivations={props.getAllCultivationsResponse.success ? props.getAllCultivationsResponse.allCultivations : []}
                handleAddUserToCultivationList={handleAddUserToCultivationList}
                createCultivationButton={false}
                // createNewCultivation={createNewCultivation}
                // setCreateNewCultivation={setCreateNewCultivation}
                // newCultivation={newCultivation}
                // handleSetNewCultivation={handleSetNewCultivation}
                // handleCreateNewCultivation={handleCreateNewCultivation}
            />
            <CultivationList
                handleGoToCreateCultivationPage={handleGoToCreateCultivationPage}
                handleGoToEditCultivationPage={handleGoToEditCultivationPage}
                handleGoToDisplayCultivationPage={handleGoToDisplayCultivationPage}
                allCultivations={props.getAllCultivationsResponse.success ? props.getAllCultivationsResponse.allCultivations : []}
                handleDeleteCultivation={handleDeleteCultivation}
                handleShowCultivationFormModal={handleShowCultivationFormModal}
                setCultivationListModal={setCultivationListModal}
                setSelectedCultivation={setSelectedCultivation}
            />
        </>
    );
};
const mapStateToProps = (state) => {
    //console.log('🚀 ~ file: manage-cultivation.js ~ line 35 ~ mapStateToProps ~ state', state);

    const getAllCultivationsResponse = state.Cultivation.getAllCultivations;
    const deleteCultivationResponse = state.Cultivation.deleteCultivation;
    const copyCultivationResponse = state.Cultivation.copyCultivation;
    const addUserToCultivationResponse = state.Cultivation.addUserToCultivation;
    return { getAllCultivationsResponse, deleteCultivationResponse, copyCultivationResponse, addUserToCultivationResponse };
};
export default connect(mapStateToProps, null)(ManageCultivation);
