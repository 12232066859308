import { postManagePage, postManageSchedulerPage } from '@Constants/route-paths';
import React from 'react';
import { Row, Col, Nav } from 'react-bootstrap';

const SearchMenu = (props) => {
    const selected = props.selected;
    return (
        <Row>
            <Col>
                <Nav variant="pills" activeKey={selected}>
                    <Nav.Item sz="sm">
                        <Nav.Link eventKey="list" href={postManagePage}>
                            List View
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item sz="sm">
                        <Nav.Link eventKey="scheduler" href={postManageSchedulerPage}>
                            Scheduler View
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                <hr />
            </Col>
        </Row>
    );
};
export default SearchMenu;
