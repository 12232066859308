import React, { Component } from 'react';
import Header from '@Components/layout/header';
import Footer from '@Components/layout/footer';
import 'react-notifications/lib/notifications.css';
import { connect } from 'react-redux';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import { withRouter } from 'react-router-dom';
import { FaSearch, FaConnectdevelop, FaCalendarAlt, FaUserCog, FaEnvelope, FaNewspaper } from 'react-icons/fa';
import { RiDashboardLine } from 'react-icons/ri';
import { MdRssFeed } from 'react-icons/md';
import { CgUserList } from 'react-icons/cg';
import { FiDisc } from 'react-icons/fi';
import * as RoutePath from '@Constants/route-paths';
import styled from 'styled-components';

class DashboardLayout extends Component {
    state = {
        expanded: false,
    };
    onToggle = (expanded) => {
        this.setState({ expanded: expanded });
    };
    individualSideNav = () => {
        return (
            <SideNav
                onSelect={(selected) => {
                    this.props.history.push(selected);
                }}
                onToggle={this.onToggle}
            >
                <SideNav.Toggle />
                <SideNav.Nav defaultSelected="home">
                    <NavItem eventKey="/">
                        <NavIcon>
                            <MdRssFeed size={24} />
                        </NavIcon>
                        <NavText>Feed</NavText>
                    </NavItem>
                    <NavItem eventKey="/search/organization">
                        <NavIcon>
                            <FaSearch size={24} />
                        </NavIcon>
                        <NavText>Discover</NavText>
                    </NavItem>
                    <NavItem eventKey={RoutePath.communityFriendListPage}>
                        <NavIcon>
                            <FaConnectdevelop size={24} />
                        </NavIcon>
                        <NavText>Community</NavText>
                    </NavItem>
                    <NavItem eventKey="/calender">
                        <NavIcon>
                            <FaCalendarAlt size={24} />
                        </NavIcon>
                        <NavText>Calender</NavText>
                    </NavItem>

                    <NavItem eventKey={RoutePath.ManageConversationPage}>
                        <NavIcon>
                            <FaEnvelope size={24} />
                        </NavIcon>
                        <NavText>Mails</NavText>
                    </NavItem>
                    <NavItem eventKey={RoutePath.individualEditBasicInfoPage}>
                        <NavIcon>
                            <FaUserCog size={24} />
                        </NavIcon>
                        <NavText>Profile Settings</NavText>

                        <NavItem eventKey={RoutePath.individualEditBasicInfoPage}>
                            <NavText>Basic Information</NavText>
                        </NavItem>
                        <NavItem eventKey={RoutePath.individualEditInvolvementPage}>
                            <NavText>Involvement</NavText>
                        </NavItem>
                        <NavItem eventKey={RoutePath.individualEditPrivacyPage}>
                            <NavText>Privacy</NavText>
                        </NavItem>
                        <NavItem eventKey={RoutePath.individualEditMembershipPage}>
                            <NavText>Membership</NavText>
                        </NavItem>
                        <NavItem eventKey={RoutePath.changePasswordPage}>
                            <NavText>Change Password</NavText>
                        </NavItem>
                        {/* <NavItem eventKey={RoutePath.userCommonSettingsPage}>
                            <NavText>Account Settings</NavText>
                        </NavItem> */}
                    </NavItem>
                </SideNav.Nav>
            </SideNav>
        );
    };
    organizationSideNav = () => {
        return (
            <SideNav
                onSelect={(selected) => {
                    this.props.history.push(selected);
                }}
                onToggle={this.onToggle}
            >
                <SideNav.Toggle />
                <SideNav.Nav defaultSelected="home">
                    <NavItem eventKey={RoutePath.dashboardPage}>
                        <NavIcon>
                            <RiDashboardLine size={24} />
                        </NavIcon>
                        <NavText>Dashboard</NavText>
                    </NavItem>
                    <NavItem eventKey="/">
                        <NavIcon>
                            <MdRssFeed size={24} />
                        </NavIcon>
                        <NavText>Feed</NavText>
                    </NavItem>
                    <NavItem eventKey="/search/organization">
                        <NavIcon>
                            <FaSearch size={24} />
                        </NavIcon>
                        <NavText>Discover</NavText>
                    </NavItem>
                    <NavItem eventKey={RoutePath.individualSearchPage}>
                        <NavIcon>
                            <FiDisc size={24} />
                        </NavIcon>
                        <NavText>Prospect</NavText>
                    </NavItem>
                    <NavItem eventKey={RoutePath.cultivationManagePage}>
                        <NavIcon>
                            <CgUserList size={24} />
                        </NavIcon>
                        <NavText>Cultivate List</NavText>
                    </NavItem>

                    <NavItem eventKey={RoutePath.communityFollowerListPage}>
                        <NavIcon>
                            <FaConnectdevelop size={24} />
                        </NavIcon>
                        <NavText>Community</NavText>
                    </NavItem>
                    <NavItem eventKey="/calender">
                        <NavIcon>
                            <FaCalendarAlt size={24} />
                        </NavIcon>
                        <NavText>Calender</NavText>
                    </NavItem>
                    <NavItem eventKey={RoutePath.postManagePage}>
                        <NavIcon>
                            {/* <BsFilePost size={24} /> */}
                            <FaNewspaper size={24} />
                        </NavIcon>
                        <NavText>Posts</NavText>
                    </NavItem>
                    <NavItem eventKey={RoutePath.ManageConversationPage}>
                        <NavIcon>
                            <FaEnvelope size={24} />
                        </NavIcon>
                        <NavText>Mails</NavText>
                    </NavItem>
                    <NavItem eventKey={RoutePath.organizationEditBasicInfoPage}>
                        <NavIcon>
                            <FaUserCog size={24} />
                        </NavIcon>
                        <NavText>Profile Settings</NavText>

                        <NavItem eventKey={RoutePath.organizationEditBasicInfoPage}>
                            <NavText>Basic Information</NavText>
                        </NavItem>
                        <NavItem eventKey={RoutePath.organizationEditServiceInfoPage}>
                            <NavText>Service Information</NavText>
                        </NavItem>
                        <NavItem eventKey={RoutePath.organizationEditMembershipPage}>
                            <NavText>Membership</NavText>
                        </NavItem>
                        <NavItem eventKey={RoutePath.organizationEditSocialMediaPage}>
                            <NavText>Social Media</NavText>
                        </NavItem>
                        <NavItem eventKey={RoutePath.changePasswordPage}>
                            <NavText>Change Password</NavText>
                        </NavItem>
                        {/* <NavItem eventKey={RoutePath.userCommonSettingsPage}>
                            <NavText>Account Settings</NavText>
                        </NavItem> */}
                    </NavItem>
                </SideNav.Nav>
            </SideNav>
        );
    };
    adminSideNav = () => {
        return <></>;
    };

    render() {
        const isAuth = this.props.auth.isAuth;
        const user = this.props.auth.user;
        const basicInfo = this.props.auth.basicInfo;
        const gotoPage = (page) => {
            this.props.history.push(page);
        };
        const { expanded } = this.state;
        return (
            <div>
                <Header isAuth={isAuth || false} user={user || {}} basicInfo={basicInfo || {}} />
                <div className="full-page-div">
                    <div>
                        {user && user.userType === 'individual' && this.individualSideNav()}
                        {user && user.userType === 'organization' && this.organizationSideNav()}
                        {user && user.userType === 'admin' && this.adminSideNav()}
                        {/* </SideNav.Nav>
                        </SideNav> */}
                        <Main className="main-div-v2" expanded={expanded}>
                            {this.props.children}
                        </Main>
                    </div>
                </div>
                <Footer gotoPage={gotoPage} />
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        auth: state.Auth.auth,
    };
};
export default connect(mapStateToProps, null)(withRouter(DashboardLayout));
const Main = styled.main`
    position: relative;
    overflow: hidden;
    transition: all 0.15s;
    padding: 0 20px;
    margin-left: ${(props) => (props.expanded ? 240 : 64)}px;
`;
