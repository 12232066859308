import React from 'react';
import { Container, Row, Col, Table, Button, Modal } from 'react-bootstrap';
import { LinkedInIconRender, SocialMediaAccountRender } from '../form_template/icon-render';
import { ConnectButtonRender, DisconnectButtonRender, SettingsButtonRender, TemplateButtonRender } from '../form_template/buttons-render';
import LinkedIn from 'linkedin-login-for-react';
const LinkedInForm = ({ handleLinkedInConnectResponse, handleLinkedInDisconnect, getLinkedInAccountResponse, handleSocialMediaTemplate }) => {
    let linkedInAccount = {};
    if (getLinkedInAccountResponse?.success) {
        linkedInAccount = getLinkedInAccountResponse.linkedInAccount;
    }
    return (
        <Row>
            <Col sm="3">
                <LinkedInIconRender />
            </Col>
            {linkedInAccount.firstName ? (
                <>
                    <Col sm="4">
                        <SocialMediaAccountRender
                            name={linkedInAccount.firstName + ' ' + linkedInAccount.lastName}
                            profilePicture={linkedInAccount.profilePicture}
                        />
                    </Col>
                    <Col sm="4">
                        <DisconnectButtonRender
                            onClick={() => {
                                handleLinkedInDisconnect();
                            }}
                            hover_title="Disconnect"
                        />
                        &nbsp;
                        <TemplateButtonRender
                            onClick={() => {
                                handleSocialMediaTemplate('linked-in', true);
                            }}
                            hover_title="Template"
                        />
                    </Col>
                </>
            ) : (
                <>
                    <Col sm="2">
                        <LinkedIn
                            clientId={process.env.REACT_APP_LINKED_IN_CLIENT_ID}
                            callback={handleLinkedInConnectResponse}
                            scope={['r_liteprofile', 'r_emailaddress', 'w_member_social']}
                            className="btn btn-outline-primary btn-sm"
                            text="CONNECT"
                        />
                    </Col>
                    <Col sm="4"></Col>
                </>
            )}
        </Row>
    );
};
export default LinkedInForm;
