import React, { useState } from 'react';
import { Col, Container, Image, Modal, Row } from 'react-bootstrap';
import { SendMessageButtonRender } from '../form_template/buttons-render';

const BulkMessageModal = (props) => {
    const [messageText, setMessageText] = useState('');
    const { messageModal, setMessageModal, messageModalType, handleSubmitBulkMessage } = props;
    const { connection, connectionType } = messageModalType;
    return (
        <Modal
            style={{ zIndex: 10000 }}
            show={messageModal}
            onHide={() => {
                setMessageModal(false);
            }}
        >
            <Modal.Header closeButton>
                Send message to all {connectionType === 'new' ? 'new' : ''} {connection}
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            <label>Message</label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <textarea
                                type="text"
                                className="form-control"
                                rows="3"
                                value={messageText}
                                onChange={(e) => {
                                    setMessageText(e.target.value);
                                }}
                            />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <SendMessageButtonRender
                    onClick={() => {
                        handleSubmitBulkMessage(messageText);
                    }}
                />
            </Modal.Footer>
        </Modal>
    );
};

export default BulkMessageModal;
