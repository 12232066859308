/* eslint-disable */

import { getGallery } from '@Actions/post-action';
import { getUserPublicInfo } from '@Actions/user-action';
import { PostImageModalRender } from '@Components/form_template/image-modal-render';
import * as RoutePaths from '@Constants/route-paths';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import OrganizationSideMenu from './organization-side-menu';
import OrganizationInfoHeader from '@Components/organization/organization-info-header';
import OrganizationMessageView from '@Components/organization/organization-message-view';
import { checkIfConnected } from '@Actions/user-action';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { getCoinSpendingSummary } from '@Actions/user-action';
import { sendMessageToMultipleUsers, clearMessageToMultipleUsers } from '@Actions/conversation-action';
const OrganizationGallery = (props) => {
    const [loading, setLoading] = useState(false);

    const [userId, setUserId] = useState('');
    const [organizationId, setOrganizationId] = useState('');

    const [messageText, setMessageText] = useState('');
    useEffect(() => {
        const getInitialInfo = (userId) => {
            setLoading(true);
            const organizationId = props.match.params.userId;
            setOrganizationId(organizationId);
            props.dispatch(getCoinSpendingSummary());
            props.dispatch(checkIfConnected(userId, organizationId));
            props.dispatch(getUserPublicInfo(organizationId));
            setLoading(false);
        };
        const user = props.auth.user;

        if (user && user._id) {
            setUserId(user._id);
            getInitialInfo(user._id);
        }
    }, []);

    const handleMessageSubmit = () => {
        if (!messageText || messageText.length < 1) {
            alert('Message can not be blank');
        } else {
            const { success } = props.getCoinSpendingSummaryResponse;
            const { connected } = props.checkIfConnectedResponse;
            if (success) {
                const { coinPerInvitation } = props.getCoinSpendingSummaryResponse.coinSpendingSummary;
                const totalSpendingCoins = parseInt(coinPerInvitation * connected ? 0 : 1);
                if (totalSpendingCoins > 0) {
                    confirmAlert({
                        title: 'Warning',
                        message: `Your message will cost you ${totalSpendingCoins} Coins. Are you sure?`,
                        buttons: [
                            {
                                label: 'YES',
                                onClick: () => {
                                    props.dispatch(
                                        sendMessageToMultipleUsers(userId, {
                                            receiverIds: [organizationId],
                                            messageText,
                                        }),
                                    );
                                },
                            },
                            {
                                label: 'No',
                                onClick: () => {},
                            },
                        ],
                    });
                } else {
                    props.dispatch(
                        sendMessageToMultipleUsers(userId, {
                            receiverIds: [organizationId],
                            messageText,
                        }),
                    );
                }
            } else {
                NotificationManager.error('Server connection error', 'Failed');
            }
        }
    };
    useEffect(() => {
        const { success, message } = props.sendMessageToMultipleUsersResponse;
        if (success) {
            NotificationManager.success(message, 'success');
            setMessageText('');
            props.dispatch(clearMessageToMultipleUsers());
        } else if (success === false) {
            NotificationManager.error(message, 'Failed');
        }
    }, [props.sendMessageToMultipleUsersResponse]);
    return (
        <Container>
            <Row>
                <Col className="parent-page">
                    <Row>
                        <Col className="right-align" sm="2">
                            <OrganizationSideMenu activePage="Message" {...props} />
                        </Col>
                        <Col sm="9" className="left-border">
                            <OrganizationInfoHeader
                                props={props}
                                organization={props.getPublicInfoResponse && props.getPublicInfoResponse.success ? props.getPublicInfoResponse.user : {}}
                            />
                            <OrganizationMessageView
                                messageText={messageText}
                                setMessageText={setMessageText}
                                connected={props.checkIfConnectedResponse.success ? props.checkIfConnectedResponse.connected : false}
                                handleMessageSubmit={handleMessageSubmit}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};
const mapStateToProps = (state) => {
    const checkIfConnectedResponse = state.User.checkIfConnected;
    const getPublicInfoResponse = state.User.getUserPublicInfo;
    const getCoinSpendingSummaryResponse = state.User.getCoinSpendingSummary;
    const sendMessageToMultipleUsersResponse = state.Conversation.sendMessageToMultipleUsers;
    return { checkIfConnectedResponse, getPublicInfoResponse, getCoinSpendingSummaryResponse, sendMessageToMultipleUsersResponse };
};
export default connect(mapStateToProps, null)(OrganizationGallery);
