import * as Types from '../constants/reducer-types';
import ConnectionDA from '../data_accesses/connection-da';

export const getOneConnection = (senderId, receiverId) => {
    return {
        type: Types.GET_ONE_CONNECTION,
        payload: ConnectionDA.get_one_connection_by_user(senderId, receiverId),
    };
};

export const getOneOppositeConnection = (senderId, receiverId) => {
    return {
        type: Types.GET_ONE_OPPOSITE_CONNECTION,
        payload: ConnectionDA.get_one_connection_by_user(receiverId, senderId),
    };
};

export const updateOneConnection = (senderId, receiverId, update) => {
    return {
        type: Types.UPDATE_ONE_CONNECTION,
        payload: ConnectionDA.update_one_connection_by_user(senderId, receiverId, update),
    };
};

export const updateOneOppositeConnection = (senderId, receiverId, update) => {
    console.log('🚀 ~ file: connection-action.js ~ line 26 ~ updateOneOppositeConnection ~ senderId, receiverId, update', senderId, receiverId, update);
    return {
        type: Types.UPDATE_ONE_OPPOSITE_CONNECTION,
        payload: ConnectionDA.update_one_connection_by_user(receiverId, senderId, update),
    };
};

export const getAllConnections = (senderId) => {
    return {
        type: Types.GET_ALL_CONNECTIONS,
        payload: ConnectionDA.get_all_connections(senderId),
    };
};
