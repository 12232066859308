import React from 'react';
import { Container, Image, Row, Col, Badge, ListGroup, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { defaultIndividualProfilePicture } from '@Constants/default-images';
import Collapsible from 'react-collapsible';
import { RiUserFollowFill, RiUserUnfollowFill } from 'react-icons/ri';
import * as RoutePath from '@Constants/route-paths';
import { EndorseUserButtonRender, CancelEndorseUserButtonRender } from '../form_template/buttons-render.js';
import {
    AcceptFriendshipButtonRender,
    AddFriendshipButtonRender,
    DeleteFriendshipButtonRender,
    FriendDropdownRender,
    RejectFriendshipButtonRender,
} from '../form_template/buttons-render';
const SideMenu = (props) => {
    const individual = props.individual;
    const activePage = props.activePage;
    const follows = props.follows;
    const endorses = props.endorses;
    const friendship = props.friendship;
    const userId = props.userId;
    const individualUserId = props.individualUserId;
    const RenderListButtonItem = (label, path) => {
        return (
            <Button
                variant={label === activePage ? 'primary' : 'outline-primary'}
                size="sm"
                className="list-button"
                onClick={() => {
                    props.gotoPage(path);
                }}
            >
                {label}
            </Button>
        );
    };
    if (individual._id) {
        const basicInfo = individual.basicInfo;
        return (
            <>
                <Image
                    className="left-image"
                    src={basicInfo.profilePicture ? basicInfo.profilePicture : defaultIndividualProfilePicture}
                    width="100%"
                    thumbnail
                />
                <div style={{ height: 10 }} />
                {/* <Row> */}
                {/* <Col md={9}>
                        <h3>{basicInfo.firstName + ' ' + basicInfo.lastName}</h3>
                    </Col> */}

                {/* <Col md={3}> */}

                {props.friendAndFollowFlag && (
                    <>
                        {!friendship._id && (
                            <AddFriendshipButtonRender
                                onClick={() => {
                                    props.handleCreateFriendship();
                                }}
                            />
                        )}
                        {friendship.status === 'pending' ? (
                            <>
                                {friendship.senderId === userId ? (
                                    <DeleteFriendshipButtonRender
                                        onClick={() => {
                                            props.handleDeleteFriendship(friendship._id);
                                        }}
                                    />
                                ) : (
                                    <>
                                        <AcceptFriendshipButtonRender
                                            onClick={() => {
                                                props.handleAcceptFriendship(friendship._id);
                                            }}
                                        />
                                        <RejectFriendshipButtonRender
                                            onClick={() => {
                                                props.handleRejectFriendship(friendship._id);
                                            }}
                                        />
                                    </>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                        {friendship.status === 'accepted' && (
                            <FriendDropdownRender
                                handleUnfriendButton={() => {
                                    props.handleDeleteFriendship(friendship._id);
                                }}
                                handleUnfollowButton={() => {
                                    props.handleUnfollowUser();
                                }}
                                handleFollowButton={() => {
                                    props.handleFollowUser();
                                }}
                                follows={follows}
                            />
                        )}
                    </>
                )}
                {/* </Col>
                </Row> */}
                <hr />
                {/* <Collapsible trigger="MENU" className="special-btn"> */}
                <Row>
                    <Col>
                        {RenderListButtonItem('About', RoutePath.userDetailsPage('individual', individual._id))}
                        {RenderListButtonItem('Message', RoutePath.userMessagePage('individual', individual._id))}
                        {RenderListButtonItem('Friends', RoutePath.individualFriendsPage(individual._id))}
                        {RenderListButtonItem('Followings', RoutePath.individualFollowingsPage(individual._id))}
                        {RenderListButtonItem('Endorsees', RoutePath.individualEndorseesPage(individual._id))}
                    </Col>
                </Row>

                {/* </Collapsible> */}
            </>
        );
    } else return <></>;
};
export default SideMenu;
