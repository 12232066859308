import React from 'react';
import moment from 'moment';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import * as RoutePath from '@Constants/route-paths';
const Footer = (props) => {
    const dateString = '2021-12-26T12:49:05.446Z';
    const lastBuild = new Date(dateString);

    const navItems = [
        { title: 'ABOUT US', page: RoutePath.aboutUsPage },
        { title: 'PRIVACY POLICY', page: RoutePath.privacyPolicyPage },
        { title: 'TERMS AND CONDITIONS', page: RoutePath.termsAndConditionsPage },
        { title: 'CONTACT US', page: RoutePath.contactUsPage },
    ];
    return (
        <div className="footer">
            <Container>
                <Row>
                    <Col>
                        <h5>Thank you for staying with us.</h5>
                        <h6>This is now BETA version. Please wait till we release the real version.</h6>

                        <b>Server Last Updated At: </b>
                        {moment(lastBuild).format('LLLL')}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Nav className="justify-content-center mr-auto" activeKey="/home">
                            {navItems.map((item, i) => {
                                return (
                                    <Nav.Item className={i > 0 ? 'verticalLine' : ''} key={i}>
                                        <Nav.Link className="special-btn hover-btn nav-btn" href={item.page}>
                                            {item.title}
                                        </Nav.Link>
                                    </Nav.Item>
                                );
                            })}
                        </Nav>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
export default Footer;
