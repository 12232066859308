import React, { useRef, useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import Summary from '@Components/dashboard/summary-view';
import { countConnectedAndUnConnectedUsers } from '@Actions/wallet-action';
import Statistics from '@Components/dashboard/statistics-view';
import TopNotifications from '@Components/dashboard/top-notifications';
import TopConversations from '@Components/dashboard/top-conversations';
import UpcomingActivities from '@Components/dashboard/upcoming-activity-list';
import RecentlyEndedPosts from '@Components/dashboard/recently-ended-post-list';
import * as RoutePaths from '@Constants/route-paths';
import SectionMenu from '@Components/dashboard/section-menu';
import { getUserWallet, getAllConnectedUsersId, getCoinSpendingSummary } from '@Actions/user-action';
import { getFollowerSummary, getAllFollowers } from '@Actions/follow-action';
import { getEndorserSummary, getAllEndorsers } from '@Actions/endorsement-action';
import {
    getViewerSummary,
    getPostStatistics,
    getAllViewers,
    getAllUpcomingPosts,
    getAllRecentlyEndedPosts,
    getAllCommittedPersons,
} from '@Actions/post-action';
import UserListModal from '@Components/dashboard/user-list-modal';
import MessageModal from '@Components/dashboard/message-modal';
import { getNotificationsCount, getTitleByType, getTopNotifications, updateNotification, updateNotificationLocal } from '@Actions/notification-action';

import { getAllConversationsByUser, sendMessageToAllConnected, sendMessageToMultipleUsers } from '@Actions/conversation-action';
import BulkMessageModal from '@Components/dashboard/bulk-message-modal';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import FloatingToTopButton from '@Components/form_template/floating-to-top-button';
const Dashboard = (props) => {
    const [userId, setUserId] = useState('');
    const [userName, setUserName] = useState('');
    const summaryRef = useRef(null);
    const statisticsRef = useRef(null);
    const notificationRef = useRef(null);
    const conversationRef = useRef(null);
    const upcomingRef = useRef(null);
    const recentlyEndedRef = useRef(null);
    const [userListModal, setUserListModal] = useState(false);
    const [users, setUsers] = useState([]);
    const [viewersId, setViewersId] = useState([]);
    const [modalType, setModalType] = useState('');
    const [receiverIds, setReceiverIds] = useState([]);
    const [messageModal, setMessageModal] = useState(false);
    const [messageModalType, setMessageModalType] = useState({
        connection: '',
        connectionType: '',
    });
    const [messageOptionInModal, setMessageOptionInModal] = useState(false);

    // GET INITIAL ORGANIZATIOn INFORMATION
    useEffect(() => {
        document.title = 'Dashboard';
    }, []);
    const getInitialInfo = (userId) => {
        props.dispatch(getCoinSpendingSummary());
        props.dispatch(getAllConnectedUsersId(userId));
        props.dispatch(getUserWallet(userId));
        props.dispatch(getFollowerSummary(userId));
        props.dispatch(getEndorserSummary(userId));
        props.dispatch(getViewerSummary(userId));
        props.dispatch(getPostStatistics(userId));
        props.dispatch(getAllUpcomingPosts({ creatorId: userId }));
        props.dispatch(getAllViewers(userId));
        props.dispatch(getAllConversationsByUser(userId));
        props.dispatch(getAllRecentlyEndedPosts());
    };
    useEffect(() => {
        const user = props.auth.user;
        if (user && user._id) {
            setUserId(user._id);
            const basicInfo = props.auth.basicInfo;
            getInitialInfo(user._id);
            setUserName(basicInfo.name ? basicInfo.name : '');
        }
    }, [props.auth]);

    const executeScroll = (event) => {
        if (event.current) {
            event.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    };
    // HANDLE VIEW ALL BUTTON
    const handleViewAllButton = (modalType) => {
        setMessageOptionInModal(false);
        if (modalType === 'followers') {
            setUserListModal(true);
            setModalType(modalType);
            props.dispatch(getAllFollowers(userId));
        } else if (modalType === 'endorsers') {
            setUserListModal(true);
            setModalType(modalType);
            props.dispatch(getAllEndorsers(userId));
        } else if (modalType === 'viewers') {
            setUserListModal(true);
            setModalType(modalType);
            props.dispatch(getAllViewers(userId));
        }
    };

    // HANDLE GET ALL FOLLOWERS
    useEffect(() => {
        const { success } = props.getAllFollowersResponse;
        if (success) {
            const { followers } = props.getAllFollowersResponse;
            setUsers(
                followers.map((user) => {
                    return {
                        userId: user.followerId,
                        name: user.followerName,
                        firstName: user.followerFirstName,
                        lastName: user.followerLastName,
                        profilePicture: user.followerProfilePicture,
                        userType: user.followerUserType,
                    };
                }),
            );
        }
    }, [props.getAllFollowersResponse]);
    // HANDLE GET ALL ENDORSERS
    useEffect(() => {
        const { success } = props.getAllEndorsersResponse;
        if (success) {
            const { endorsers } = props.getAllEndorsersResponse;
            setUsers(
                endorsers.map((user) => {
                    return {
                        userId: user.endorserId,
                        name: user.endorserName,
                        firstName: user.endorserFirstName,
                        lastName: user.endorserLastName,
                        profilePicture: user.endorserProfilePicture,
                        userType: user.endorserUserType,
                    };
                }),
            );
        }
    }, [props.getAllEndorsersResponse]);
    // HANDLE GET ALL VIEWERS
    useEffect(() => {
        const { success } = props.getAllViewersResponse;
        if (success) {
            const { viewers } = props.getAllViewersResponse;
            //console.log('🚀 ~ file: dashboard.js ~ line 124 ~ useEffect ~ viewers', viewers);
            setUsers(
                viewers.map((user) => {
                    return {
                        userId: user.viewerId,
                        name: user.viewerName,
                        firstName: user.viewerFirstName,
                        lastName: user.viewerLastName,
                        profilePicture: user.viewerProfilePicture,
                        userType: user.viewerUserType,
                    };
                }),
            );
            setViewersId(viewers.map((user) => user.viewerId));
        }
    }, [props.getAllViewersResponse]);

    // NOTIFICATION
    const handleGoToNotificationDetails = (notification) => {
        const type = notification.type;
        if (['like', 'interest', 'going'].includes(type)) {
            props.history.push(RoutePaths.postDetailsPage(notification.postId.postType, notification.postId._id));
        } else if (type === 'friend-request') {
            props.history.push(RoutePaths.communityRequestListPage('received'));
        } else if (type === 'friend-accept') {
            props.history.push(RoutePaths.communityFriendListPage);
        } else if (type === 'follow') {
            props.history.push(RoutePaths.communityFollowerListPage);
        } else if (type === 'endorsement') {
            props.history.push(RoutePaths.communityEndorserListPage);
        } else if (type === 'membership-request') {
        } else if (type === 'membership-accept') {
        } else {
            props.history.push(RoutePaths.userDetailsPage(notification.userType, notification.senderId));
        }
        const notificationId = notification._id;
        props.dispatch(updateNotification(notificationId, { isRead: true }));
        props.dispatch(updateNotificationLocal(notificationId, true));
    };

    const handleNotificationRead = (notificationId) => {
        props.dispatch(updateNotification(notificationId, { isRead: true }));
        props.dispatch(updateNotificationLocal(notificationId, true));
    };
    const handleNotificationUnread = (notificationId) => {
        props.dispatch(updateNotification(notificationId, { isRead: false }));
        props.dispatch(updateNotificationLocal(notificationId, false));
    };

    // UPCOMING POSTS
    const handleGoToPostEdit = (postType, postId) => {
        props.history.push(RoutePaths.postEditPage(postType, postId));
    };
    const handleGoToPostDetails = (postType, postId) => {
        props.history.push(RoutePaths.postDetailsPage(postType, postId));
    };

    // RECENTLY ENDED POSTS
    const handleViewInteractedUsers = (postId, interactionType) => {
        getAllCommittedPersons(postId, interactionType).then((response) => {
            setUserListModal(true);
            setModalType('interacted');
            setMessageModalType({
                connection: 'interacted',
                connectionType: '',
            });
            setMessageOptionInModal(true);
            // setCommittedLoading(false);
            if (response.success) {
                console.log(response.users);
                setUsers(
                    response.users.map((user) => {
                        return {
                            _id: user.userId,
                            userType: user.userType,
                            firstName: user.userFirstName,
                            lastName: user.userLastName,
                            profilePicture: user.userProfilePicture,
                            name: user.userName,
                        };
                    }),
                );
            } else {
                alert('User List Not Found');
            }
        });
    };
    const handleSubmitBulkMessageToInteractedUsers = (userIds, messageText) => {
        console.log('🚀 ~ file: dashboard.js ~ line 232 ~ Dashboard ~ userIds, messageText', userIds, messageText);
    };

    // HANDLE BULK MESSAGING
    const handleSubmitBulkMessage = (messageText) => {
        if (!messageText || messageText.length === 0) {
            alert('Can not send blank message!');
        } else {
            console.log(messageModalType.connection);
            if (messageModalType.connection === 'viewers' || messageModalType.connection === 'interacted') {
                let { success } = props.getAllConnectedUsersIdResponse;
                if (success) {
                    const { connectedUsersId } = props.getAllConnectedUsersIdResponse;
                    console.log('viewersId', connectedUsersId, users);
                    const receiversId = messageModalType.connection === 'viewers' ? viewersId : users.map((user) => user._id);
                    console.log('🚀 ~ file: dashboard.js ~ line 253 ~ handleSubmitBulkMessage ~ receiversId', receiversId);
                    const { totalConnected, totalNotConnected } = countConnectedAndUnConnectedUsers(receiversId, connectedUsersId);
                    success = props.getCoinSpendingSummaryResponse;
                    if (success) {
                        setMessageModal(false);
                        setUserListModal(false);
                        const { coinPerInvitation } = props.getCoinSpendingSummaryResponse.coinSpendingSummary;
                        const totalCoins = parseInt(totalNotConnected * coinPerInvitation);
                        console.log('🚀 ~ file: dashboard.js ~ line 260 ~ handleSubmitBulkMessage ~ totalCoins', totalCoins);
                        if (totalCoins > 0) {
                            confirmAlert({
                                title: 'Warning',
                                message: `You are inviting total ${totalNotConnected} non connected users. This will cost you ${parseInt(
                                    totalNotConnected * coinPerInvitation,
                                )} Coins. Are you sure?`,
                                buttons: [
                                    {
                                        label: 'Yes',
                                        onClick: () => {
                                            if (messageModalType.connection === 'interacted') {
                                                props.dispatch(
                                                    sendMessageToMultipleUsers(userId, {
                                                        receiverIds: receiversId,
                                                        messageText,
                                                    }),
                                                );
                                            } else {
                                                props.dispatch(
                                                    sendMessageToAllConnected(
                                                        userId,
                                                        messageModalType.connection,
                                                        messageModalType.connectionType,
                                                        messageText,
                                                    ),
                                                );
                                            }
                                        },
                                    },
                                    {
                                        label: 'No',
                                        onClick: () => {
                                            setMessageModal(true);
                                        },
                                    },
                                    {
                                        label: 'Only Send to Connected Users',
                                        onClick: () => {
                                            if (messageModalType.connection === 'interacted') {
                                                props.dispatch(
                                                    sendMessageToMultipleUsers(userId, {
                                                        receiverIds: receiversId,
                                                        messageText,
                                                    }),
                                                );
                                            } else {
                                                props.dispatch(
                                                    sendMessageToAllConnected(
                                                        userId,
                                                        messageModalType.connection,
                                                        messageModalType.connectionType,
                                                        messageText,
                                                        true,
                                                    ),
                                                );
                                            }
                                        },
                                    },
                                ],
                            });
                        } else {
                            if (messageModalType.connection === 'interacted') {
                                props.dispatch(
                                    sendMessageToMultipleUsers(userId, {
                                        receiverIds: receiversId,
                                        messageText,
                                    }),
                                );
                            } else {
                                props.dispatch(sendMessageToAllConnected(userId, messageModalType.connection, messageModalType.connectionType, messageText));
                            }
                        }
                    } else {
                    }
                }
            } else {
                props.dispatch(sendMessageToAllConnected(userId, messageModalType.connection, messageModalType.connectionType, messageText));
                setMessageModal(false);
            }
        }
    };
    useEffect(() => {
        const { success, message } = props.sendMessageToAllConnectedResponse;
        //console.log('🚀 ~ file: dashboard.js ~ line 236 ~ useEffect ~ success, message', success, message);
        if (success) {
            NotificationManager.success(message, 'success');
            props.dispatch(getUserWallet(userId));
        } else if (success === false) {
            NotificationManager.error(message, 'Failed');
        }
    }, [props.sendMessageToAllConnectedResponse]);
    const handleGoToConversationDetails = (conversationsId) => {
        props.history.push(RoutePaths.ConversationPage(conversationsId));
    };
    return (
        <Container>
            <UserListModal
                userListModal={userListModal}
                users={users}
                setUserListModal={setUserListModal}
                modalType={modalType}
                messageOptionInModal={messageOptionInModal}
                handleSubmitBulkMessageToInteractedUsers={handleSubmitBulkMessageToInteractedUsers}
                handleSubmitBulkMessage={handleSubmitBulkMessage}
            />

            <BulkMessageModal
                messageModal={messageModal}
                setMessageModal={setMessageModal}
                messageModalType={messageModalType}
                setMessageModalType={setMessageModalType}
                handleSubmitBulkMessage={handleSubmitBulkMessage}
                // messageText={messageText}
                // setMessageText={setMessageText}
            />

            <Row>
                <Col className="parent-page">
                    <SectionMenu
                        userName={userName}
                        executeScroll={executeScroll}
                        summaryRef={summaryRef}
                        statisticsRef={statisticsRef}
                        notificationRef={notificationRef}
                        conversationRef={conversationRef}
                        upcomingRef={upcomingRef}
                        recentlyEndedRef={recentlyEndedRef}
                    />
                    <hr />
                    <div ref={summaryRef}>
                        <Summary
                            getFollowerSummaryResponse={props.getFollowerSummaryResponse}
                            getEndorserSummaryResponse={props.getEndorserSummaryResponse}
                            getViewerSummaryResponse={props.getViewerSummaryResponse}
                            getUserWalletResponse={props.getUserWalletResponse}
                            handleViewAllButton={handleViewAllButton}
                            setMessageModal={setMessageModal}
                            setMessageModalType={setMessageModalType}
                        />
                        <hr />
                    </div>
                    <div ref={statisticsRef}>
                        <Statistics getPostStatisticsResponse={props.getPostStatisticsResponse} />
                        <hr />
                    </div>
                    <div ref={notificationRef}>
                        <TopNotifications
                            allNotifications={props.getNotificationResponse.success ? props.getNotificationResponse.notifications : []}
                            handleGoToNotificationDetails={handleGoToNotificationDetails}
                            handleNotificationRead={handleNotificationRead}
                            handleNotificationUnread={handleNotificationUnread}
                            history={props.history}
                            dispatch={props.dispatch}
                        />
                        <hr />
                    </div>
                    <div ref={conversationRef}>
                        <TopConversations
                            allConversations={props.getAllConversationsByUserResponse.success ? props.getAllConversationsByUserResponse.conversations : []}
                            handleGoToConversationDetails={handleGoToConversationDetails}
                            userId={userId}
                        />
                        <hr />
                    </div>
                    <div ref={upcomingRef}>
                        <UpcomingActivities
                            allPosts={props.getAllUpcomingPostsResponse.success ? props.getAllUpcomingPostsResponse.allPosts : []}
                            handleGoToPostEdit={handleGoToPostEdit}
                            handleGoToPostDetails={handleGoToPostDetails}
                        />
                        <hr />
                    </div>
                    <div ref={recentlyEndedRef}>
                        <RecentlyEndedPosts
                            allRecentlyEndedPosts={
                                props.getAllRecentlyEndedPostsResponse.success ? props.getAllRecentlyEndedPostsResponse.allRecentlyEndedPosts : []
                            }
                            handleGoToPostEdit={handleGoToPostEdit}
                            handleGoToPostDetails={handleGoToPostDetails}
                            handleViewInteractedUsers={handleViewInteractedUsers}
                        />
                        <hr />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="right-align">
                    <FloatingToTopButton />
                </Col>
            </Row>
        </Container>
    );
};
const mapStateToProps = (state) => {
    //console.log('🚀 ~ file: dashboard.js ~ line 80 ~ mapStateToProps ~ state', state);
    const getFollowerSummaryResponse = state.Follow.getFollowerSummary;
    const getEndorserSummaryResponse = state.Endorsement.getEndorserSummary;
    const getViewerSummaryResponse = state.Post.getViewerSummary;
    const getPostStatisticsResponse = state.Post.getPostStatistics;
    const getAllFollowersResponse = state.Follow.getAllFollowers;
    const getAllEndorsersResponse = state.Endorsement.getAllEndorsers;
    const getNotificationResponse = state.Notification.getTopNotifications;
    const getAllUpcomingPostsResponse = state.Post.getAllUpComingPosts;
    const getUserWalletResponse = state.User.getUserWallet;
    const sendMessageToAllConnectedResponse = state.Conversation.sendMessageToAllConnected;
    const getAllViewersResponse = state.Post.getAllViewers;
    const getAllConnectedUsersIdResponse = state.User.getAllConnectedUsersId;
    const getCoinSpendingSummaryResponse = state.User.getCoinSpendingSummary;
    const getAllConversationsByUserResponse = state.Conversation.getAllConversationsByUser;
    const getAllRecentlyEndedPostsResponse = state.Post.getAllRecentlyEndedPosts;
    return {
        getFollowerSummaryResponse,
        getEndorserSummaryResponse,
        getViewerSummaryResponse,
        getPostStatisticsResponse,
        getAllFollowersResponse,
        getAllEndorsersResponse,
        getNotificationResponse,
        getAllUpcomingPostsResponse,
        getUserWalletResponse,
        sendMessageToAllConnectedResponse,
        getAllViewersResponse,
        getAllConnectedUsersIdResponse,
        getCoinSpendingSummaryResponse,
        getAllConversationsByUserResponse,
        getAllRecentlyEndedPostsResponse,
    };
};

export default connect(mapStateToProps, null)(Dashboard);
