import { defaultOrganizationProfilePicture } from '@Constants/default-images';
import { interestTypes } from '@Constants/interest-types';
import moment from 'moment';
import React, { useState } from 'react';
import Avatar from 'react-avatar';
import { Badge, Button, Col, Image, Row } from 'react-bootstrap';
import {
    GoingButtonRender,
    InterestedButtonRender,
    LikeButtonRender,
    AddToSchedulerButtonRender,
    RemoveFromSchedulerButtonRender,
    ShareButtonRender,
    InviteButtonRender,
} from '../form_template/buttons-render';
const convertDescription = (description) => {
    if (description && description.length > 0) {
        let newComment = description;
        newComment = newComment.split(`@@@__`).join('<a href="/user/');
        newComment = newComment.split(`^^^__`).join('">');
        newComment = newComment.split(`@@@^^^`).join('</a>');
        return <div dangerouslySetInnerHTML={{ __html: newComment }} />;
    }
    return '';
};
const HomePostFeed = (props) => {
    const userId = props.userId;
    // const [show, setShow] = useState(false);
    const { schedulerPostsObject, handleAddPostToScheduler, handleRemovePostFromScheduler } = props;
    const followingObject = props.followingObject;
    const posts = props.allPosts;
    let interest = {};
    const { handleCreateOneShare } = props;
    const setPostInviteModal = props.setPostInviteModal;
    const setInvitedPost = props.setInvitedPost;
    if (posts && posts.length > 0) {
        return (
            <>
                {posts.map((post, i) => {
                    //console.log('🚀 ~ file: home-post-feeds.js ~ line 40 ~ {posts.map ~ post', post);
                    interest = {};
                    let filter = post.interests.filter((interest) => interest.userId === userId);
                    if (filter && filter.length > 0) interest = filter[0];
                    let liked = post.interests.filter((interest) => interest.liked).length;
                    let interested = post.interests.filter((interest) => interest.interested).length;
                    let going = post.interests.filter((interest) => interest.going).length;

                    return (
                        <div key={i} className="justify-text post-box">
                            {post.postType && <h6 style={{ color: 'cadetblue' }}>{post.postType === 'general' ? '' : post.postType.toUpperCase()}</h6>}
                            <Row>
                                <Col sm={8}>
                                    {/* <p>{post.sharedBy ? ` ${post.sharedBy} shared this post` : ''}</p> */}
                                    <p>{post.sharedBy ? <a href={`/user/${post.sharedBy}`}>{post.sharedByName} shared this post</a> : ''}</p>
                                </Col>
                                <Col sm={4} className="right-align">
                                    <p>{post.sharedAt ? `@ ${moment(post.sharedAt).format('LLL')}` : ''}</p>
                                </Col>
                            </Row>

                            <h5
                                className="link-name"
                                onClick={() => {
                                    props.handleGotoPostDetails(post.postType, post._id);
                                }}
                            >
                                {post.title}
                            </h5>

                            <Row>
                                <Col>
                                    <Row>
                                        <Col sm="1">
                                            <Avatar
                                                src={post.organizationProfilePicture ? post.organizationProfilePicture : defaultOrganizationProfilePicture}
                                                round="5px"
                                                size="50"
                                            />
                                        </Col>
                                        <Col sm="8">
                                            <h6
                                                className="link-name"
                                                onClick={() => {
                                                    props.handleGotoOrganizationDetails(post.creatorId);
                                                }}
                                            >
                                                {post.organizationName}
                                            </h6>

                                            <small>Created At {moment(post.createdAt).format('LLLL')}</small>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {post.impactAreas.map((area, i) => {
                                        return (
                                            <Badge variant="light" className="badge-single-small impact-area-badge" key={i}>
                                                {area.label}
                                            </Badge>
                                        );
                                    })}
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    {post.skills &&
                                        post.skills.map((skill, i) => {
                                            return (
                                                <Badge variant="light" className="badge-single-small skill-badge" key={i}>
                                                    {skill.label}
                                                </Badge>
                                            );
                                        })}
                                </Col>
                            </Row>

                            <Row>
                                {post.startDateTime && (
                                    <Col sm={6}>
                                        From <b>{moment(post.startDateTime).format('LLLL')}</b>
                                    </Col>
                                )}
                                {post.endDateTime && (
                                    <Col sm={6} className="right-align">
                                        To <b>{moment(post.endDateTime).format('LLLL')}</b>
                                    </Col>
                                )}
                            </Row>
                            <br />
                            <Row>
                                {post.images && post.images.length > 0 && (
                                    <Col
                                        sm="4"
                                        className="home-post-image"
                                        onClick={() => {
                                            props.handleGotoPostDetails(post.postType, post._id);
                                        }}
                                    >
                                        <Image src={post.images[0].path} thumbnail style={{ width: '100%', height: 'auto' }} />
                                        {post.images.length > 1 && <div className="home-post-image-text">{post.images.length - 1} More Images</div>}
                                    </Col>
                                )}
                                <Col className="justify-text">
                                    {post.description && post.description.length > 250 ? (
                                        <>
                                            {convertDescription(post.description.substr(0, 250) + '...')}
                                            <Button
                                                type="button"
                                                size="sm"
                                                variant="link"
                                                onClick={() => {
                                                    props.handleGotoPostDetails(post.postType, post._id);
                                                }}
                                            >
                                                See More
                                            </Button>
                                        </>
                                    ) : (
                                        convertDescription(post.description)
                                    )}
                                </Col>
                            </Row>
                            <hr />

                            <Row>
                                <Col>
                                    {interestTypes[post.postType].like ? (
                                        interest.liked ? (
                                            <LikeButtonRender
                                                complete={true}
                                                onClick={() => {
                                                    props.handleCancelLikePost(post._id);
                                                }}
                                            />
                                        ) : (
                                            <LikeButtonRender
                                                complete={false}
                                                onClick={() => {
                                                    props.handleLikePost(post._id);
                                                }}
                                            />
                                        )
                                    ) : (
                                        <></>
                                    )}
                                    {interestTypes[post.postType].interested ? (
                                        <>
                                            &nbsp;
                                            {interest.interested ? (
                                                <InterestedButtonRender
                                                    complete={true}
                                                    onClick={() => {
                                                        props.handleCancelInterestedPost(post._id);
                                                    }}
                                                />
                                            ) : (
                                                <InterestedButtonRender
                                                    complete={false}
                                                    onClick={() => {
                                                        props.handleInterestedPost(post._id);
                                                    }}
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    {interestTypes[post.postType].going ? (
                                        <>
                                            &nbsp;
                                            {interest.going ? (
                                                <GoingButtonRender
                                                    complete={true}
                                                    onClick={() => {
                                                        props.handleCancelGoingPost(post._id);
                                                    }}
                                                />
                                            ) : (
                                                <GoingButtonRender
                                                    complete={false}
                                                    onClick={() => {
                                                        props.handleGoingPost(post._id);
                                                    }}
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    {interestTypes[post.postType].addable && !followingObject[post.creatorId] ? (
                                        <>
                                            &nbsp;
                                            {schedulerPostsObject[post._id] ? (
                                                <RemoveFromSchedulerButtonRender
                                                    hover_title="Remove From My Calendar"
                                                    onClick={() => {
                                                        handleRemovePostFromScheduler(post._id);
                                                    }}
                                                />
                                            ) : (
                                                <AddToSchedulerButtonRender
                                                    hover_title="Add To My Calendar"
                                                    onClick={() => {
                                                        handleAddPostToScheduler(post._id);
                                                    }}
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    &nbsp;
                                    <ShareButtonRender
                                        hover_title="Share This Post"
                                        onClick={() => {
                                            handleCreateOneShare(post._id);
                                        }}
                                    />
                                    &nbsp;
                                    <InviteButtonRender
                                        hover_title="Invite People To View This Post"
                                        onClick={() => {
                                            setInvitedPost({ postId: post._id, postType: post.postType });
                                            setPostInviteModal(true);
                                        }}
                                    />
                                </Col>
                                <Col className="right-align">
                                    {interestTypes[post.postType].like && liked > 0 && (
                                        <Button
                                            variant="outline-primary"
                                            size="sm"
                                            onClick={() => {
                                                props.handleClickCommittedButtons(post._id, 'liked');
                                            }}
                                        >
                                            {liked} Liked
                                        </Button>
                                    )}
                                    &nbsp;
                                    {interestTypes[post.postType].interested && interested > 0 && (
                                        <Button
                                            variant="outline-secondary"
                                            size="sm"
                                            onClick={() => {
                                                props.handleClickCommittedButtons(post._id, 'interested');
                                            }}
                                        >
                                            {interested} Interested
                                        </Button>
                                    )}
                                    &nbsp;
                                    {interestTypes[post.postType].going && going > 0 && (
                                        <Button
                                            variant="outline-info"
                                            size="sm"
                                            onClick={() => {
                                                props.handleClickCommittedButtons(post._id, 'going');
                                            }}
                                        >
                                            {going} Going
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    );
                })}
            </>
        );
    } else return <h4>No Posts Found</h4>;
};
export default HomePostFeed;
