import { getCountryByCode, getStateByCountryAndCode } from '@Constants/country-and-state';
import { defaultIndividualProfilePicture } from '@Constants/default-images';
import { getMembershipByValue } from '@Constants/membership-types';
import React from 'react';
import { Badge, Col, Container, Image, Row, Table } from 'react-bootstrap';
import { ImpactAreasRender, OrganizationTypesRender, TagWithLabelRender, LinkRender, MapAreasRender } from '../form_template/details-render';
import KMLViewer from '@Components/map/kml-viewer';
import { serverAddress } from '@Constants/api-paths';
const TimeRender = (time) => {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return `${months[time.month]}-${time.year}`;
};

const DetailsView = (props) => {
    const organization = props.organization;
    const memberships = props.memberships;
    //console.log('🚀 ~ file: organization-details-view.js ~ line 9 ~ DetailsView ~ memberships', memberships);
    const follows = props.follows;
    const infoRender = (label, value) => {
        if (value) {
            return (
                <Row>
                    <Col md="3">
                        <b>{label}</b>
                    </Col>
                    <Col md="9" className="justify-text">
                        {/* {value.split('\n').map((para, i) => (
                            <p key={i} className="justify-text">
                                {para}
                            </p>
                        ))} */}
                        {value}
                    </Col>
                </Row>
            );
        } else return <></>;
    };

    const keywordsRender = (label, keywords) => {
        return (
            <Row>
                <Col md="3">
                    <b>{label}</b>
                </Col>
                <Col md="9">
                    {keywords.map((key, i) => {
                        return (
                            <Badge variant="primary" key={i} className="badge-single badge-single-small">
                                {key}
                            </Badge>
                        );
                    })}
                </Col>
            </Row>
        );
    };
    const tagsRender = (label, tags) => {
        if (tags && tags.length > 0) {
            return (
                <Row>
                    <Col md="3">
                        <b>{label}</b>
                    </Col>
                    <Col md="9">
                        {tags.map((tag, i) => {
                            if (tag)
                                return (
                                    <Badge variant="primary" key={i} className="badge-single">
                                        {tag.label}
                                    </Badge>
                                );
                        })}
                    </Col>
                </Row>
            );
        } else return <></>;
    };

    const addressMaker = (address) => {
        console.log('🚀 ~ file: organization-details-view.js ~ line 91 ~ addressMaker ~ address', address);
        let fullAddress = '';
        if (address) {
            if (address.street1) fullAddress += address.street1 + ', ';
            if (address.street2) fullAddress += address.street2 + ', ';
            if (address.city) fullAddress += address.city + ', ';
            if (address.code) fullAddress += address.code + ', ';
            if (address.state) fullAddress += getStateByCountryAndCode(address.country, address.state) + ', ';
            if (address.country) fullAddress += getCountryByCode(address.country);
        }
        return fullAddress;
    };

    if (organization._id && organization.userType === 'organization') {
        const basicInfo = organization.basicInfo;
        const serviceInfo = organization.serviceInfo;
        const impactAreas = serviceInfo.impactAreas;
        const serviceAreas = serviceInfo.serviceAreas;
        console.log('🚀 ~ file: organization-details-view.js ~ line 96 ~ DetailsView ~ serviceAreas', serviceAreas);
        const col1Length = serviceAreas && serviceAreas.length > 0 ? 6 : 12;
        const col2Length = 12 - col1Length;
        return (
            <>
                <Row>
                    <Col md={col1Length}>
                        {infoRender('Contact Email', basicInfo.contactEmail?.toLowerCase())}
                        {LinkRender('Website', basicInfo.website, true)}
                        {infoRender('Phone', basicInfo.phone)}
                        {infoRender('EIN', basicInfo.ein)}
                        {infoRender('Address', addressMaker(basicInfo.address))}
                        <hr />
                        {ImpactAreasRender('Impact Area', impactAreas)}
                        {keywordsRender('Keywords', serviceInfo.keywords)}
                        <hr />
                        {infoRender('Mission', basicInfo.mission)}
                    </Col>
                    {serviceAreas && serviceAreas.length > 0 && (
                        <Col md={col2Length}>
                            {MapAreasRender('Service Areas', serviceAreas)}
                            <KMLViewer kmlURL={`${serverAddress}/kml-files/user-service-area-${organization._id}.kml`} />
                        </Col>
                    )}
                </Row>

                <hr />
                {infoRender('Description', basicInfo.description)}
                {memberships && memberships.length > 0 && (
                    <>
                        <hr />
                        <b>Memberships</b>
                        <Table striped bordered hover responsive size="sm">
                            <thead></thead>
                            <tbody>
                                {memberships.map((membership, i) => {
                                    const name = membership.individualFirstName + ' ' + membership.individualLastName;
                                    const profilePicture = membership.individualProfilePicture
                                        ? membership.individualProfilePicture
                                        : defaultIndividualProfilePicture;
                                    return (
                                        <tr key={i}>
                                            <td>
                                                <Image src={profilePicture} style={{ height: 32, width: 32 }} thumbnail />
                                            </td>
                                            <td>{name}</td>
                                            <td>{getMembershipByValue(membership.membershipType)}</td>
                                            <td>
                                                <b>FROM:</b> {TimeRender(membership.startTime)}
                                            </td>
                                            {/* <td>{membership.isCurrent}</td> */}
                                            {membership.isCurrent ? (
                                                <td>
                                                    <Badge variant="primary">Current Member</Badge>
                                                </td>
                                            ) : (
                                                <td>{TimeRender(membership.endTime)}</td>
                                            )}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </>
                )}
                <div style={{ height: 25 }} />
            </>
        );
    } else
        return (
            <Container>
                <h3>No User Found</h3>
            </Container>
        );
};

export default DetailsView;
