import React, { useEffect, useState } from 'react';
import { reduxForm, formValueSelector, change } from 'redux-form';
import ContactUsForm from '@Components/home/contact-us-form';
import { connect } from 'react-redux';
import { createOneFeedback, clearFeedback } from '@Actions/feedback-action';
import { NotificationManager } from 'react-notifications';

const ContactUs = (props) => {
    const [loading, setLoading] = useState(false);
    const onSubmit = (values) => {
        //console.log('🚀 ~ file: contact-us.js ~ line 9 ~ onSubmit ~ values', values);
        const feedback = {
            ...values,
        };
        props.dispatch(createOneFeedback(feedback));
    };
    useEffect(() => {
        const { success } = props.setFeedbackResponse;
        if (success) {
            NotificationManager.success('Feedback saved.', 'success');
            props.dispatch(clearFeedback());
        } else if (success === false) {
            NotificationManager.error('Feedback not saved', 'Failed');
        }
    }, [props.setFeedbackResponse]);
    return (
        <ContactUsForm
            handleOnSubmit={props.handleSubmit((feedback) => {
                onSubmit(feedback);
            })}
        />
    );
};
const mapStateToProps = (state) => {
    const setFeedbackResponse = state.Feedback.setFeedback;

    return { setFeedbackResponse };
};
export default connect(
    mapStateToProps,
    null,
)(
    reduxForm({
        form: 'ContactUs',
        enableReinitialize: true,
    })(ContactUs),
);
