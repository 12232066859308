/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import IndividualInfoHeader from '@Components/individual/individual-info-header';
import UserCardList from '@Components/user/user-card-list';
import LoadingAnim from '@Components/form_template/loading-anim';
import * as RoutePaths from '@Constants/route-paths';
import { getAllFollowings } from '../../actions/follow-action';
import IndividualSideMenu from './individual-side-menu';

const IndividualFollowers = (props) => {
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [individualId, setIndividualId] = useState('');
    const [userType, setUserType] = useState('');
    const [userList, setUserList] = useState([]);

    useEffect(() => {
        const getInitialInfo = (individualId) => {
            setLoading(true);
            props.dispatch(getAllFollowings(individualId));
        };
        const individualId = props.match.params.userId;
        setIndividualId(individualId);
        const user = props.auth.user;
        if (user && user._id) {
            setUserId(user._id);
            setUserType(user.userType);
            getInitialInfo(individualId);
        }
    }, [props.match.params.userId]);
    useEffect(() => {
        const { success } = props.getAllFollowingsResponse;
        if (success) {
            let userList = [];
            const followings = props.getAllFollowingsResponse.followings;
            for (let i = 0; i < followings.length; i++) {
                userList.push({
                    _id: followings[i].followingId,
                    userType: followings[i].followingUserType,
                    firstName: followings[i].followingFirstName,
                    lastName: followings[i].followingLastName,
                    name: followings[i].followingName,
                    profilePicture: followings[i].followingProfilePicture,
                });
            }
            setUserList([...userList]);
            setLoading(false);
        }
    }, [props.getAllFollowingsResponse]);

    const handleGotoUserDetails = (userType, userId) => {
        props.history.push(RoutePaths.userDetailsPage(userType, userId));
    };

    if (loading) return <LoadingAnim />;
    else {
        return (
            <Container>
                <Row>
                    <Col className="parent-page">
                        <Row>
                            <Col className="right-align" sm="2">
                                <IndividualSideMenu activePage="Followings" {...props} />
                            </Col>
                            <Col sm="10" className="left-border">
                                <IndividualInfoHeader
                                    individual={props.getPublicInfoResponse.success ? props.getPublicInfoResponse.user : {}}
                                    pageTitle="Followings"
                                />
                                <UserCardList users={userList} userId={userId} handleGotoUserDetails={handleGotoUserDetails} title="FOLLOWINGS" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
};
const mapStateToProps = (state) => {
    const getAllFollowingsResponse = state.Follow.getAllFollowings;
    const getPublicInfoResponse = state.User.getUserPublicInfo;
    return { getAllFollowingsResponse, getPublicInfoResponse };
};
export default connect(mapStateToProps, null)(IndividualFollowers);
