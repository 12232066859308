/* eslint-disable */

import React from 'react';
import { Container, Row, Col, Card, Button, CardGroup, CardColumns } from 'react-bootstrap';
const titles = [
    { id: 1, name: 'title', label: 'Title', isMandatory: true },
    { id: 2, name: 'description', label: 'Description', isMandatory: true },
    { id: 3, name: 'image', label: 'Images', isMandatory: false },
    { id: 4, name: 'skill', label: 'Skills', isMandatory: false },
    { id: 5, name: 'impact-areas', label: 'Impact Areas', isMandatory: false },
    { id: 6, name: 'startTime', label: 'Start Time', isMandatory: false },
    { id: 7, name: 'endTime', label: 'End Time', isMandatory: false },
];
const Soc = (props) => {
    return (
        <Container>
            <Row>
                <Col className="parent-page">
                    <Row>
                        <Col>
                            <h4>SOCIAL MEDIA ROUGH</h4>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <CardColumns className="four-columns">
                                <Card>
                                    <Card.Header>FACEBOOK</Card.Header>
                                    <Card.Body></Card.Body>
                                    <Card.Footer>
                                        <Button size="sm">SUBMIT</Button>
                                    </Card.Footer>
                                </Card>
                                <Card>
                                    <Card.Header>LINKED IN</Card.Header>
                                    <Card.Body></Card.Body>
                                    <Card.Footer>
                                        <Button size="sm">SUBMIT</Button>
                                    </Card.Footer>
                                </Card>
                                <Card>
                                    <Card.Header>INSTAGRAM</Card.Header>
                                    <Card.Body></Card.Body>
                                    <Card.Footer>
                                        <Button size="sm">SUBMIT</Button>
                                    </Card.Footer>
                                </Card>
                                <Card>
                                    <Card.Header>TWITTER</Card.Header>
                                    <Card.Body></Card.Body>
                                    <Card.Footer>
                                        <Button size="sm">SUBMIT</Button>
                                    </Card.Footer>
                                </Card>
                            </CardColumns>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};
export default Soc;
