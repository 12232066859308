import React, { useEffect } from 'react';
import { serverAddress } from '@Constants/api-paths';
import openSocket from 'socket.io-client';

const socket = openSocket(serverAddress, { transports: ['websocket', 'polling', 'flashsocket'] });
const GlobalUserActivity = (props) => {
    useEffect(() => {
        const user = props.user;
        console.log('Emitting');
        socket.emit('connect_user', user._id);
    }, [props.user]);
    return <></>;
};
export default GlobalUserActivity;
