import * as Types from '@Constants/reducer-types';
import FeedbackDA from '@DA/feedback-da';

export const createOneFeedback = (feedback) => {
    return {
        type: Types.SET_FEEDBACK,
        payload: FeedbackDA.create_one_feedback(feedback),
    };
};
export const clearFeedback = () => {
    return {
        type: Types.CLEAR_FEEDBACK,
        payload: {},
    };
};
