import React, { useEffect, useState } from 'react';
import CultivationForm from '@Components/cultivation/cultivation-form';
import * as RoutePaths from '@Constants/route-paths';
import { reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import LoadingAnim from '@Components/form_template/loading-anim';
import { NotificationManager } from 'react-notifications';
import {
    createCultivation,
    getCultivationById,
    removeUsersFromCultivation,
    clearRemoveUsersToCultivation,
    updateCultivation,
    clearCultivation,
} from '@Actions/cultivation-action';
import { postDetailsPage } from '@Constants/route-paths';
import { getCoinSpendingSummary } from '@Actions/user-action';
import OrganizationInviteModal from '../../components/invitation/organization-invite-modal';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { inviteUsersToFollowOrganization } from '@Actions/invitation-action';
import BulkMessageModal from '../../components/conversation/bulk-message-modal';
import { sendMessageToMultipleUsers } from '@Actions/conversation-action';
import { serverAddress } from '@Constants/api-paths';
const CreateCultivation = (props) => {
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [invitationModal, setInvitationModal] = useState(false);
    const [invitationText, setInvitationText] = useState('You are Invited To Follow me.');
    const [usersToBeInvited, setUsersToBeInvited] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [bulkMessageModal, setBulkMessageModal] = useState(false);
    const [messageText, setMessageText] = useState('');
    const [usersToBeSent, setUsersToBeSent] = useState([]);
    const getInitialInfo = (cultivationId) => {
        setLoading(true);
        props.dispatch(getCultivationById(cultivationId));
        props.dispatch(getCoinSpendingSummary());
        setLoading(false);
    };
    useEffect(() => {
        const user = props.auth.user;
        if (user && user._id) {
            //console.log(user);
            setUserId(user._id);
            const url = window.location.pathname;
            //console.log('🚀 ~ file: create-cultivation.js ~ line 23 ~ useEffect ~ url', url);
            if (url.split('/')[3] === 'edit') {
                setEditMode(true);
                const cultivationId = props.match.params.cultivationId;

                getInitialInfo(cultivationId);
            }
        }
    }, [props.auth]);
    useEffect(() => {
        return () => {
            props.dispatch(clearCultivation());
        };
    }, []);
    const handleRemoveUsersFromCultivation = (userId) => {
        props.dispatch(removeUsersFromCultivation(props.match.params.cultivationId, [userId]));
        props.dispatch(getCultivationById(props.match.params.cultivationId));
    };
    const handleGoToUserDetailsPage = (userType, userId) => {
        props.history.push(RoutePaths.userDetailsPage(userType, userId));
    };

    useEffect(() => {
        const { success } = props.getUserRemoveResponse;
        if (success) {
            NotificationManager.success('1 User removed successfully.', 'success');
            props.dispatch(clearRemoveUsersToCultivation());
        } else if (success === false) {
            NotificationManager.error('User not removed', 'Failed');
            props.dispatch(clearRemoveUsersToCultivation());
        }
    }, [props.getUserRemoveResponse]);
    useEffect(() => {
        const { success } = props.setCultivationResponse;
        if (success) {
            const { cultivation } = props.setCultivationResponse;
            NotificationManager.success('Cultivation saved.', 'success');
            props.dispatch(clearCultivation());
            // props.history.push(RoutePaths.cultivationDetailsPage(cultivation._id));
            props.history.push(RoutePaths.cultivationManagePage);
        } else if (success === false) {
            NotificationManager.error('Cultivation not saved', 'Failed');
            props.dispatch(clearCultivation());
        }
    }, [props.setCultivationResponse]);
    const onSubmit = (values) => {
        if (editMode) {
            const cultivation = {
                ...values,
                creatorId: userId,
            };
            props.dispatch(updateCultivation(props.match.params.cultivationId, cultivation));
        } else {
            const cultivation = {
                ...values,
                creatorId: userId,
            };
            props.dispatch(createCultivation(cultivation));
        }
    };
    const handleGoToManageCultivations = () => {
        props.history.push(RoutePaths.cultivationManagePage);
    };
    const handleGoToPostDetailsPage = (postType, PostId) => {
        props.history.push(RoutePaths.postDetailsPage(postType, PostId));
    };
    // INVITATION
    const handleSubmitInvitation = () => {
        // S1 CHECK IF ALL DATA IS GIVEN
        if (!usersToBeInvited || usersToBeInvited.length < 1) {
            alert('No Users To Be Invited');
        } else if (!invitationText || invitationText.length < 1) {
            alert('Invitation message can not be blank');
        } else {
            // S2 CHECK/CONFIRM FOR COINS
            setInvitationModal(false);
            const { success } = props.getCoinSpendingSummaryResponse;
            if (success) {
                const { coinPerInvitation } = props.getCoinSpendingSummaryResponse.coinSpendingSummary;
                const totalSpendingCoins = parseInt(coinPerInvitation * totalUsers);
                if (totalSpendingCoins > 0) {
                    confirmAlert({
                        title: 'Warning',
                        message: `You are inviting total ${usersToBeInvited.length} users. This will cost you ${totalSpendingCoins} Coins. Are you sure?`,
                        buttons: [
                            {
                                label: 'YES',
                                onClick: () => {
                                    props.dispatch(
                                        inviteUsersToFollowOrganization(userId, {
                                            usersToBeInvited,
                                            invitationText,
                                            organizationLink: `${serverAddress}/user/${userId}`,
                                        }),
                                    );
                                },
                            },
                            {
                                label: 'No',
                                onClick: () => {
                                    setInvitationModal(true);
                                },
                            },
                        ],
                    });
                } else {
                    props.dispatch(
                        inviteUsersToFollowOrganization(userId, {
                            usersToBeInvited,
                            invitationText,
                            organizationLink: `${serverAddress}/user/${userId}`,
                        }),
                    );
                }
            } else {
                NotificationManager.error('Server connection error', 'Failed');
            }
        }
        // S3 SEND INVITATIONS
        // DONE & HIDE ALL
    };

    // BULK MESSAGE SUBMIT
    const handleSubmitBulkMessage = () => {
        if (!usersToBeSent || usersToBeSent.length < 1) {
            alert('No Users To Be Sent');
        } else if (!messageText || messageText.length < 1) {
            alert('Message can not be blank');
        } else {
            // S2 CHECK/CONFIRM FOR COINS
            setBulkMessageModal(false);
            const { success } = props.getCoinSpendingSummaryResponse;
            if (success) {
                const { coinPerInvitation } = props.getCoinSpendingSummaryResponse.coinSpendingSummary;
                const totalNonConnectedUsers = usersToBeSent.filter((user) => !user.connected).length;
                const totalSpendingCoins = parseInt(coinPerInvitation * totalNonConnectedUsers);
                if (totalSpendingCoins > 0) {
                    confirmAlert({
                        title: 'Warning',
                        message: `You are inviting total ${usersToBeSent.length} users. This will cost you ${totalSpendingCoins} Coins. Are you sure?`,
                        buttons: [
                            {
                                label: 'YES',
                                onClick: () => {
                                    props.dispatch(
                                        sendMessageToMultipleUsers(userId, {
                                            receiverIds: usersToBeSent.map((user) => user._id),
                                            messageText,
                                        }),
                                    );
                                },
                            },
                            {
                                label: 'No',
                                onClick: () => {
                                    setBulkMessageModal(true);
                                },
                            },
                            {
                                label: 'Only Send to Connected Users',
                                onClick: () => {
                                    props.dispatch(
                                        sendMessageToMultipleUsers(userId, {
                                            receiverIds: usersToBeSent.filter((user) => user.connected).map((user) => user._id),
                                            messageText,
                                        }),
                                    );
                                },
                            },
                        ],
                    });
                } else {
                    props.dispatch(
                        sendMessageToMultipleUsers(userId, {
                            receiverIds: usersToBeSent.map((user) => user._id),
                            messageText,
                        }),
                    );
                }
            } else {
                NotificationManager.error('Server connection error', 'Failed');
            }
        }
        // S1 CHECK IF ALL DATA IS GIVEN
        // S2 CHECK/CONFIRM FOR COINS
        // S3 SEND INVITATIONS
        // DONE & HIDE ALL
    };
    useEffect(() => {
        const { success, message } = props.inviteUsersToFollowOrganizationResponse;
        if (success) {
            NotificationManager.success(message, 'success');
            const cultivationId = props.match.params.cultivationId;
            getInitialInfo(cultivationId);
        } else if (success === false) {
            NotificationManager.error(message, 'Failed');
        }
    }, [props.inviteUsersToFollowOrganizationResponse]);
    useEffect(() => {
        const { success, message } = props.sendMessageToMultipleUsersResponse;
        if (success) {
            NotificationManager.success(message, 'success');
            const cultivationId = props.match.params.cultivationId;
            getInitialInfo(cultivationId);
        } else if (success === false) {
            NotificationManager.error(message, 'Failed');
        }
    }, [props.sendMessageToMultipleUsersResponse]);
    if (loading) return <LoadingAnim />;
    else
        return (
            <>
                <BulkMessageModal
                    bulkMessageModal={bulkMessageModal}
                    setBulkMessageModal={setBulkMessageModal}
                    messageText={messageText}
                    setMessageText={setMessageText}
                    handleSubmitBulkMessage={handleSubmitBulkMessage}
                    totalUsersToBeSent={usersToBeSent ? usersToBeSent.length : 0}
                />
                <OrganizationInviteModal
                    invitationModal={invitationModal}
                    setInvitationModal={setInvitationModal}
                    invitationText={invitationText}
                    setInvitationText={setInvitationText}
                    handleSubmitInvitation={handleSubmitInvitation}
                    totalUsersToBeInvited={usersToBeInvited ? usersToBeInvited.length : 0}
                />
                <CultivationForm
                    cultivation={props.getCultivationResponse.success ? props.getCultivationResponse.cultivation : {}}
                    editMode={editMode}
                    handleOnSubmit={props.handleSubmit((post) => {
                        onSubmit(post);
                    })}
                    handleGoToUserDetailsPage={handleGoToUserDetailsPage}
                    handleRemoveUsersFromCultivation={handleRemoveUsersFromCultivation}
                    handleGoToManageCultivations={handleGoToManageCultivations}
                    handleGoToPostDetailsPage={handleGoToPostDetailsPage}
                    invitationModal={invitationModal}
                    setInvitationModal={setInvitationModal}
                    setUsersToBeInvited={setUsersToBeInvited}
                    setTotalUsers={setTotalUsers}
                    setBulkMessageModal={setBulkMessageModal}
                    setUsersToBeSent={setUsersToBeSent}
                />
            </>
        );
};
const mapStateToProps = (state) => {
    let initialValues = {};
    const getCultivationResponse = state.Cultivation.getCultivation;
    const setCultivationResponse = state.Cultivation.setCultivation;
    const getUserRemoveResponse = state.Cultivation.removeUserFromCultivation;
    const getCoinSpendingSummaryResponse = state.User.getCoinSpendingSummary;
    if (getCultivationResponse && getCultivationResponse.success) {
        initialValues = getCultivationResponse.cultivation;
    }
    const inviteUsersToFollowOrganizationResponse = state.Invitation.inviteUsersToFollowOrganization;
    const sendMessageToMultipleUsersResponse = state.Conversation.sendMessageToMultipleUsers;
    //console.log(
    //     '🚀 ~ file: create-cultivation.js ~ line 195 ~ mapStateToProps ~ inviteUsersToFollowOrganizationResponse',
    //     inviteUsersToFollowOrganizationResponse,
    // );
    return {
        initialValues,
        getCultivationResponse,
        getUserRemoveResponse,
        setCultivationResponse,
        getCoinSpendingSummaryResponse,
        inviteUsersToFollowOrganizationResponse,
        sendMessageToMultipleUsersResponse,
    };
};
// export default connect(mapStateToProps, null)(CreateCultivation);
export default connect(
    mapStateToProps,
    null,
)(
    reduxForm({
        form: 'CreateCultivation',
        enableReinitialize: true,
    })(CreateCultivation),
);
