import * as Types from '@Constants/reducer-types';

const initialState = {
    inviteUsersToFollowOrganization: {},
};
const InvitationReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.INVITE_USERS_TO_FOLLOW_ORGANIZATION:
            return { ...state, inviteUsersToFollowOrganization: action.payload };

        default:
            return { ...state };
    }
};
export default InvitationReducer;
