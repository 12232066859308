import { defaultIndividualProfilePicture, defaultOrganizationProfilePicture } from '@Constants/default-images';
import React, { useState } from 'react';
import Avatar from 'react-avatar';
import { Col, Container, ListGroup, Row, Dropdown, DropdownButton } from 'react-bootstrap';
import moment from 'moment';
import { serverAddress } from '@Constants/api-paths';
import { ConnectedIconRender, DisconnectedIconRender } from '../form_template/icon-render';
import openSocket from 'socket.io-client';
import { BiDotsVertical } from 'react-icons/bi';
const socket = openSocket(serverAddress, { transports: ['websocket', 'polling', 'flashsocket'] });

const PersonRender = ({ person }) => {
    const [connectivity, setConnectivity] = useState(false);
    const { userType, name, firstName, lastName, profilePicture } = person;
    let fullName = userType === 'individual' ? `${firstName ? firstName : ''} ${lastName ? lastName : ''}` : name;

    socket.on('Connectivity_' + person._id.toString(), (response) => {
        console.log('🚀 ~ file: conversation-list.js ~ line 17 ~ socket.on ~ response', response);
        if (response === 'connected') {
            setConnectivity(true);
        }
        if (response === 'disconnected') {
            setConnectivity(false);
        }
    });
    return (
        <Row>
            <Col md={2}>
                <Avatar
                    round="5px"
                    size="40"
                    src={profilePicture ? profilePicture : userType === 'individual' ? defaultIndividualProfilePicture : defaultOrganizationProfilePicture}
                />
            </Col>
            <Col md={9} className="conversation-user-name">
                <div className={`connectivity ${connectivity ? 'connected-dot' : 'disconnected-dot'}`} />
                {UserNameRender(fullName)}
            </Col>
            <br />
        </Row>
    );
};
const UserNameRender = (name) => {
    if (name && name.length > 30) name = name.substr(0, 27) + '...';
    return name;
};
const LastMessageRender = ({ lastMessageEntity, userId }) => {
    if (lastMessageEntity._id) {
        const { message } = lastMessageEntity;
        const { senderId, text, images, attachments, createdAt } = message;

        return (
            <Row>
                <Col>{messageTextRender(senderId === userId, text, createdAt)}</Col>
            </Row>
        );
    } else return <></>;
};
const messageTextRender = (user, text, createdAt) => {
    if (text && text.length > 50) text = text.substr(0, 47) + '...';
    return (
        <small>
            {user ? 'You: ' : ''}
            {text}
            <br />
            <b>{moment(createdAt).fromNow()}</b>
        </small>
    );
};
const ConversationList = (props) => {
    const allConversations = props.allConversations;
    const userId = props.userId;
    const selectedConversationId = props.conversationId;
    const handleGetConnectivity = props.handleGetConnectivity;
    const handleDeleteConversation = props.handleDeleteConversation;
    return (
        <div className="thread-list">
            <ListGroup as="ul">
                <Container>
                    <Row>
                        <Col>
                            {/* <input type="text" className="form-control" placeholder="Search Name" /> */}
                            <h4>All Messages</h4>
                        </Col>
                    </Row>
                </Container>

                {allConversations.map((conversation, i) => {
                    const members = conversation.members;
                    if (members && members.length > 1) {
                        let memberObject = {};
                        for (let i = 0; i < members.length; i++) {
                            memberObject[members[i]._id] = members[i];
                        }
                        let person = {};
                        if (members[0]._id === userId) person = members[1];
                        else if (members[1]._id === userId) person = members[0];
                        handleGetConnectivity(person._id);
                        let className = 'special-btn conversation-item ';
                        if (selectedConversationId === conversation._id) {
                            className += 'selected-conversation ';
                        }
                        let lastRead = true;
                        if (conversation.lastMessageEntity._id) lastRead = conversation.lastMessageEntity.isRead;

                        if (lastRead) className += 'read-conversation';
                        else className += 'unread-conversation';

                        return (
                            <ListGroup.Item key={i} className={className}>
                                <Row>
                                    <Col
                                        onClick={() => {
                                            props.handleGoToConversationDetails(conversation._id);
                                        }}
                                    >
                                        {person && person._id && <PersonRender person={person} />}
                                    </Col>
                                    <Col sm="2">
                                        <OptionButtonRender handleDeleteConversation={handleDeleteConversation} conversationId={conversation._id} />
                                    </Col>
                                </Row>

                                <div
                                    onClick={() => {
                                        props.handleGoToConversationDetails(conversation._id);
                                    }}
                                >
                                    <LastMessageRender lastMessageEntity={conversation.lastMessageEntity} userId={userId} />
                                </div>
                            </ListGroup.Item>
                        );
                    } else return <div key={i}></div>;
                })}
            </ListGroup>
        </div>
    );
};

export default ConversationList;
const OptionButtonRender = ({ handleDeleteConversation, conversationId }) => {
    return (
        <DropdownButton size="sm" className="add-friend-btn dropdown-remove-arrow" title={<BiDotsVertical />} variant="primary">
            <Dropdown.Item
                size="sm"
                eventKey="1"
                onClick={() => {
                    handleDeleteConversation(conversationId);
                }}
            >
                Delete Conversation
            </Dropdown.Item>
        </DropdownButton>
    );
};
