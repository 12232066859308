import React from 'react';
import { Button, Card, CardColumns, Col, Row } from 'react-bootstrap';
import { RiMailSendFill, RiFileList2Line } from 'react-icons/ri';
const CardRender = (props) => {
    const { setMessageModal, setMessageModalType, messageToNew, messageToAll } = props;
    const cardHeader = props.cardHeader;
    const cardType = props.cardType;
    const data = props.data;
    const handleViewAllButton = props.handleViewAllButton;
    const footer = props.footer;
    return (
        <Card border="primary">
            <Card.Header>{cardHeader}</Card.Header>
            <Card.Body>
                {data &&
                    data.length > 0 &&
                    data.map((d, i) => {
                        return (
                            <Row key={i}>
                                <Col sm={8}>
                                    <b>{d.label}</b>
                                </Col>
                                <Col sm={4}>{d.value}</Col>
                            </Row>
                        );
                    })}
            </Card.Body>
            {footer && (
                <Card.Footer>
                    <Button
                        variant="outline-primary"
                        size="sm"
                        className="dashboard-btn"
                        onClick={() => {
                            handleViewAllButton(cardType);
                        }}
                    >
                        <RiFileList2Line /> View all
                    </Button>
                    {messageToNew && (
                        <Button
                            variant="outline-primary"
                            size="sm"
                            className="dashboard-btn"
                            onClick={() => {
                                setMessageModal(true);
                                setMessageModalType({ connection: cardType, connectionType: 'new' });
                            }}
                        >
                            <RiMailSendFill /> New {cardType}
                        </Button>
                    )}
                    {messageToAll && (
                        <Button
                            variant="outline-primary"
                            size="sm"
                            className="dashboard-btn"
                            onClick={() => {
                                setMessageModal(true);
                                setMessageModalType({ connection: cardType, connectionType: 'all' });
                            }}
                        >
                            <RiMailSendFill /> All {cardType}
                        </Button>
                    )}
                </Card.Footer>
            )}
        </Card>
    );
};
const SummaryView = (props) => {
    const getFollowerSummaryResponse = props.getFollowerSummaryResponse;
    const getEndorserSummaryResponse = props.getEndorserSummaryResponse;
    const getViewerSummaryResponse = props.getViewerSummaryResponse;
    const getUserWalletResponse = props.getUserWalletResponse;
    const handleViewAllButton = props.handleViewAllButton;
    const { setMessageModal, setMessageModalType } = props;
    let totalFollowers = 0;
    let totalNewFollowers = 0;
    let totalEndorsers = 0;
    let totalNewEndorsers = 0;
    let totalViewers = 0;
    let totalNewViewers = 0;
    let walletCoin = 0;
    if (getFollowerSummaryResponse && getFollowerSummaryResponse.success) {
        totalFollowers = getFollowerSummaryResponse.totalFollowers;
        totalNewFollowers = getFollowerSummaryResponse.totalNewFollowers;
    }
    if (getEndorserSummaryResponse && getEndorserSummaryResponse.success) {
        totalEndorsers = getEndorserSummaryResponse.totalEndorsers;
        totalNewEndorsers = getEndorserSummaryResponse.totalNewEndorsers;
    }
    if (getViewerSummaryResponse && getViewerSummaryResponse.success) {
        totalViewers = getViewerSummaryResponse.totalViewers;
        totalNewViewers = getViewerSummaryResponse.totalNewViewers;
    }
    if (getUserWalletResponse && getUserWalletResponse.success) {
        walletCoin = getUserWalletResponse.wallet.walletCoin;
        //console.log('🚀 ~ file: summary-view.js ~ line 76 ~ SummaryView ~ walletCoin', walletCoin);
    }
    return (
        <>
            <Row>
                <Col>
                    <h5>SUMMARY</h5>
                    <CardColumns className="four-columns">
                        <CardRender
                            cardHeader="Followers"
                            cardType="followers"
                            data={[
                                { label: 'Total Followers', value: totalFollowers },
                                { label: 'Last 7 Days', value: totalNewFollowers },
                            ]}
                            handleViewAllButton={handleViewAllButton}
                            footer={true}
                            setMessageModal={setMessageModal}
                            setMessageModalType={setMessageModalType}
                            messageToNew={totalNewFollowers && totalNewFollowers > 0 ? true : false}
                            messageToAll={totalFollowers && totalFollowers > 0 ? true : false}
                        />
                        <CardRender
                            cardHeader="Endorsers"
                            cardType="endorsers"
                            data={[
                                { label: 'Total Endorsers', value: totalEndorsers },
                                { label: 'Last 7 Days', value: totalNewEndorsers },
                            ]}
                            handleViewAllButton={handleViewAllButton}
                            footer={true}
                            setMessageModal={setMessageModal}
                            setMessageModalType={setMessageModalType}
                            messageToNew={totalNewEndorsers && totalNewEndorsers > 0 ? true : false}
                            messageToAll={totalEndorsers && totalEndorsers > 0 ? true : false}
                        />
                        <CardRender
                            cardHeader="Views"
                            cardType="viewers"
                            data={[
                                { label: 'Total Views', value: totalViewers },
                                { label: 'Last 7 Days', value: totalNewViewers },
                            ]}
                            handleViewAllButton={handleViewAllButton}
                            footer={true}
                            setMessageModal={setMessageModal}
                            setMessageModalType={setMessageModalType}
                            messageToNew={totalNewViewers && totalNewViewers > 0 ? true : false}
                            messageToAll={totalViewers && totalViewers > 0 ? true : false}
                        />
                        <CardRender
                            cardHeader="Wallet"
                            cardType="wallet"
                            data={[{ label: 'Total Coin Remaining', value: walletCoin }]}
                            footer={false}
                            messageToNew={false}
                            messageToAll={false}
                        />
                    </CardColumns>
                </Col>
            </Row>
        </>
    );
};
export default SummaryView;
