/* eslint-disable */

import React, { useEffect, useState } from 'react';
import PostForm from '@Components/post/post-form';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, change, getFormValues } from 'redux-form';
import LoadingAnim from '@Components/form_template/loading-anim';
import { getPostById, createPost, updatePostById, clearPost } from '@Actions/post-action';
import { getAllImpactAreasByUser } from '@Actions/impact-area-action';
import { getAllSkillsByUser } from '@Actions/skill-action';
import { getServiceInfo, getAllUsersNames } from '@Actions/user-action';
import { NotificationManager } from 'react-notifications';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import * as RoutePaths from '@Constants/route-paths';
import { getSocialMediaConnectionInfo, getFacebookTemplate, getTwitterTemplate, getLinkedInTemplate } from '../../actions/social-media-action';
import SocialMediaTemplateModal from '../../components/post/social-media-template-modal';
import { templatePostFields } from '../../constants/post-types';
import SocialMediaDescriptionModal from '../../components/post/social-media-description-modal';
const CreatePost = (props) => {
    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([]);
    const [requiredItems, setRequiredItems] = useState([]);
    const [editMode, setEditMode] = useState(false);

    const [description, setDescription] = useState('');
    const [usersName, setUsersName] = useState([]);
    const [location, setLocation] = useState({
        latitude: 0,
        longitude: 0,
    });
    const [userId, setUserId] = useState('');
    const [template, setTemplate] = useState({
        facebook: {},
        linkedIn: {},
        twitter: {},
    });
    const [templateModal, setTemplateModal] = useState(false);
    const [templatePreviewModal, setTemplatePreviewModal] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState('facebook');

    const [descriptionModal, setDescriptionModal] = useState(false);
    useEffect(() => {
        if (editMode && props.getPostResponse.success) {
            //console.log(props.getPostResponse.post);

            setDescription(props.getPostResponse.post.description);
            setImages(props.getPostResponse.post.images);
            setRequiredItems(props.getPostResponse.post.requiredItems);
            if (props.getPostResponse.post.address) {
                setLocation({
                    latitude: props.getPostResponse.post.address.latitude,
                    longitude: props.getPostResponse.post.address.longitude,
                });
            }
        }
    }, [props.getPostResponse]);
    const handlePictureUpload = (post) => {
        var file = post.target.files[0];
        if (file) {
        }
        let reader = new FileReader();
        if (post.target.files[0]) {
            reader.readAsDataURL(file);
            reader.onload = () => {
                let images_ = images;
                images_.push({
                    path: reader.result,
                    description: '',
                });
                setImages([...images_]);
            };
            reader.onerror = function (error) {};
        }
    };
    const handleGoToManagePosts = () => {
        props.history.push(RoutePaths.postManagePage);
    };

    const onSubmit = (values) => {
        console.log('🚀 ~ file: create-post.js ~ line 82 ~ onSubmit ~ values', values);

        let items = [];
        if (requiredItems && requiredItems.length > 0) {
            items = requiredItems.filter((item) => item.name && item.name.length > 0 && item.requirement && item.requirement.length > 0);
        }
        let post = {
            ...values,
            description,
            postType: props.match.params.postType,
            creatorId: props.auth.user._id,
            images: images,
            requiredItems: items,
            address: {
                ...values.address,
                latitude: location.latitude,
                longitude: location.longitude,
            },

            socialMediaSharing: {
                facebook: {
                    ...values.socialMediaSharing.facebook,
                    template: template['facebook'],
                },
                twitter: {
                    ...values.socialMediaSharing.twitter,
                    template: template['twitter'],
                },
                linkedIn: {
                    ...values.socialMediaSharing.linkedIn,
                    template: template['linkedIn'],
                },
            },
        };
        //console.log(post);
        setLoading(true);
        setDescription('');
        if (editMode) {
            props.dispatch(updatePostById(props.match.params.postId, post));
        } else {
            props.dispatch(createPost(post));
        }
    };
    const handleImageDescriptionEdit = (idx, e) => {
        let images_ = images;
        images[idx].description = e.target.value;
        setImages([...images_]);
    };
    const handleImageDelete = (idx) => {
        confirmAlert({
            title: 'Warning',
            message: 'Are you sure to delete this image?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let images_ = images;
                        images_.splice(idx, 1);
                        setImages([...images_]);
                    },
                },
                {
                    label: 'No',
                },
            ],
        });
    };
    const handleItemEdit = (idx, name, value) => {
        //console.log('🚀 ~ file: create-post.js ~ line 103 ~ handleItemEdit ~ idx, name, value', idx, name, value);
        let requiredItems_ = requiredItems;
        let item = requiredItems_[idx];
        item[name] = value;
        //console.log('T', item);
        requiredItems_[idx] = item;
        setRequiredItems([...requiredItems_]);
    };
    const handleItemDelete = (idx) => {
        confirmAlert({
            title: 'Warning',
            message: 'Are you sure to delete this item?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        const requiredItems_ = requiredItems;
                        requiredItems_.splice(idx, 1);
                        setRequiredItems([...requiredItems_]);
                    },
                },
                {
                    label: 'No',
                },
            ],
        });
    };
    const handleItemPosition = (idx, movement) => {
        if (movement === 'up' && idx > 0) {
            let requiredItems_ = requiredItems;
            [requiredItems_[idx], requiredItems_[idx - 1]] = [requiredItems_[idx - 1], requiredItems_[idx]];
            setRequiredItems([...requiredItems_]);
        } else if (movement === 'down' && idx < requiredItems.length - 1) {
            let requiredItems_ = requiredItems;
            [requiredItems_[idx], requiredItems_[idx + 1]] = [requiredItems_[idx + 1], requiredItems_[idx]];
            setRequiredItems([...requiredItems_]);
        }
    };
    const handleImagePosition = (idx, movement) => {
        if (movement === 'up' && idx > 0) {
            let images_ = images;
            [images_[idx], images_[idx - 1]] = [images_[idx - 1], images_[idx]];
            setImages([...images_]);
        } else if (movement === 'down' && idx < images.length - 1) {
            let images_ = images;
            [images_[idx], images_[idx + 1]] = [images_[idx + 1], images_[idx]];
            setImages([...images_]);
        }
    };
    const handleAddMineImpactAreas = () => {
        //console.log(props.getServiceInfoResponse);
        if (props.getServiceInfoResponse.success && props.getServiceInfoResponse.serviceInfo.impactAreas) {
            props.change('impactAreas', props.getServiceInfoResponse.serviceInfo.impactAreas);
        } else props.change('impactAreas', []);
    };
    const handleAddItem = () => {
        const requiredItems_ = requiredItems;
        requiredItems_.push({
            name: '',
            requirement: '',
            neededBy: new Date(),
        });
        setRequiredItems([...requiredItems_]);
    };
    useEffect(() => {
        const getInitialInfo = (postId) => {
            setLoading(true);
            props.dispatch(getPostById(postId));
            setLoading(false);
            props.dispatch(getAllUsersNames());
        };
        const user = props.auth.user;
        if (user && user._id) {
            //console.log(user);
            props.dispatch(getAllImpactAreasByUser(user._id));
            props.dispatch(getAllSkillsByUser(user._id));
            props.dispatch(getServiceInfo(user._id));
            props.dispatch(getSocialMediaConnectionInfo(user._id));
            getAllTemplate(user._id);
            const url = window.location.pathname;
            if (url.split('/')[3] === 'edit') {
                setEditMode(true);
                getInitialInfo(props.match.params.postId);
            } else {
                props.dispatch(clearPost());
                setImages([]);
                setRequiredItems([]);
            }
        }
    }, [props.auth]);
    useEffect(() => {
        return () => {
            setImages({});
            props.dispatch(clearPost());
        };
    }, []);
    useEffect(() => {
        setLoading(false);
        const { success, message } = props.setPostResponse;
        if (success) {
            NotificationManager.success(message, 'success');
            setImages({});
            props.dispatch(clearPost());
            if (editMode) props.history.push(RoutePaths.postDetailsPage(props.match.params.postType, props.match.params.postId));
            else {
                props.history.push(RoutePaths.postDetailsPage(props.match.params.postType, props.setPostResponse.post._id));
            }
        } else if (success === false) NotificationManager.error(message, 'Failed');
    }, [props.setPostResponse]);
    useEffect(() => {
        const { success } = props.getAllUsersNameResponse;
        if (success) {
            const users = props.getAllUsersNameResponse.users;
            const usersName_ = users.map((u) => (u.type === 'individual' ? { id: u._id, display: u.concatNameWithSpace } : { id: u._id, display: u.name }));

            setUsersName([...usersName_]);
        }
    }, [props.getAllUsersNameResponse]);
    const handleOnStartDateTimeChange = (time) => {
        const startDateTime = new Date(time);
        startDateTime.setHours(startDateTime.getHours() + 1);
        //console.log('🚀 ~ file: create-post.js ~ line 255 ~ handleOnStartDateTimeChange ~ startDateTime', startDateTime);
        props.change('endDateTime', startDateTime);
    };
    // TEMPLATE
    const getAllTemplate = (userId) => {
        props.dispatch(getFacebookTemplate(userId));
        props.dispatch(getTwitterTemplate(userId));
        props.dispatch(getLinkedInTemplate(userId));
    };
    useEffect(() => {
        const { success } = props.getFacebookTemplateResponse;
        let template_ = template;
        if (success) {
            template_['facebook'] = props.getFacebookTemplateResponse.socialMediaTemplate.template;
        } else if (success === false) {
            template_['facebook'] = templatePostFields;
        }
        setTemplate({ ...template_ });
    }, [props.getFacebookTemplateResponse]);
    useEffect(() => {
        const { success } = props.getTwitterTemplateResponse;
        let template_ = template;
        if (success) {
            template_['twitter'] = props.getTwitterTemplateResponse.socialMediaTemplate.template;
        } else if (success === false) {
            template_['twitter'] = templatePostFields;
        }
        setTemplate({ ...template_ });
    }, [props.getTwitterTemplateResponse]);
    useEffect(() => {
        const { success } = props.getLinkedInTemplateResponse;
        let template_ = template;
        console.log('🚀 ~ file: create-post.js ~ line 293 ~ useEffect ~ props.getLinkedInTemplateResponse', props.getLinkedInTemplateResponse);
        if (success) {
            template_['linkedIn'] = props.getLinkedInTemplateResponse.socialMediaTemplate.template;
        } else if (success === false) {
            template_['linkedIn'] = templatePostFields;
        }
        setTemplate({ ...template_ });
    }, [props.getLinkedInTemplateResponse]);
    const handleMoveItemPosition = (socialMedia, idx, movement) => {
        if (movement === 'up' && idx > 0) {
            let template_ = template;
            [template_[socialMedia][idx], template_[socialMedia][idx - 1]] = [template_[socialMedia][idx - 1], template_[socialMedia][idx]];
            setTemplate({ ...template_ });
        } else if (movement === 'down' && idx < 15) {
            let template_ = template;
            [template_[socialMedia][idx], template_[socialMedia][idx + 1]] = [template_[socialMedia][idx + 1], template_[socialMedia][idx]];
            setTemplate({ ...template_ });
        }
    };
    const handleToggleItem = (socialMedia, idx, name) => {
        let template_ = template;
        console.log('template_[socialMedia][idx]', template_[socialMedia][idx]);
        if (template_[socialMedia][idx].name === name) {
            template_[socialMedia][idx].selected = !template_[socialMedia][idx].selected;
            setTemplate({ ...template_ });
        }
    };
    const handleChangeReduxField = (key, value) => {
        console.log('🚀 ~ file: create-post.js ~ line 331 ~ handleChangeReduxField ~ key, value', key, value);
        props.change(key, value);
    };
    if (loading) return <LoadingAnim />;
    else
        return (
            <>
                <SocialMediaTemplateModal
                    template={template}
                    templateModal={templateModal}
                    selectedTemplate={selectedTemplate}
                    setTemplateModal={setTemplateModal}
                    handleMoveItemPosition={handleMoveItemPosition}
                    handleToggleItem={handleToggleItem}
                    formState={props.formState}
                    description={description}
                    postType={props.match.params.postType}
                />
                <SocialMediaDescriptionModal
                    selectedTemplate={selectedTemplate}
                    descriptionModal={descriptionModal}
                    setDescriptionModal={setDescriptionModal}
                />

                <PostForm
                    usersName={usersName}
                    description={description}
                    setDescription={setDescription}
                    postType={props.match.params.postType}
                    handleGoToManagePosts={handleGoToManagePosts}
                    handleImagePosition={handleImagePosition}
                    handleImageDelete={handleImageDelete}
                    handlePictureUpload={handlePictureUpload}
                    handleImageDescriptionEdit={handleImageDescriptionEdit}
                    allImpactAreas={props.getImpactAreaResponse.success ? props.getImpactAreaResponse.impactAreas : []}
                    allSkills={props.getSkillResponse.success ? props.getSkillResponse.skills : []}
                    images={images}
                    editMode={editMode}
                    handleOnSubmit={props.handleSubmit((post) => {
                        onSubmit(post);
                    })}
                    location={location}
                    setLocation={setLocation}
                    handleAddMineImpactAreas={handleAddMineImpactAreas}
                    handleAddItem={handleAddItem}
                    handleItemEdit={handleItemEdit}
                    handleItemDelete={handleItemDelete}
                    handleItemPosition={handleItemPosition}
                    requiredItems={requiredItems}
                    handleOnStartDateTimeChange={handleOnStartDateTimeChange}
                    socialMediaConnectionInfo={
                        props.getSocialMediaConnectionInfoResponse.success ? props.getSocialMediaConnectionInfoResponse.socialMediaConnectionInfo : {}
                    }
                    setSelectedTemplate={setSelectedTemplate}
                    setTemplateModal={setTemplateModal}
                    setTemplatePreviewModal={setTemplatePreviewModal}
                    setDescriptionModal={setDescriptionModal}
                    handleChangeReduxField={handleChangeReduxField}
                />
            </>
        );
};
const selector = formValueSelector('CreatePost');
const mapStateToProps = (state) => {
    //console.log(state);
    const formState = getFormValues('CreatePost')(state);

    const getImpactAreaResponse = state.ImpactArea.getImpactAreasByUser;
    const getSkillResponse = state.Skill.getSkillsByUser;
    const getPostResponse = state.Post.getPost;
    const setPostResponse = state.Post.setPost;
    const getServiceInfoResponse = state.User.getServiceInfo;
    let initialValues = {
        socialMediaSharing: {
            facebook: { sharePost: true, description: '' },
            twitter: { sharePost: true, description: '' },
            linkedIn: { sharePost: true, description: '' },
            instagram: { sharePost: true, description: '' },
        },
    };

    if (getPostResponse.success) {
        initialValues = getPostResponse.post;
        if (initialValues.keywords && initialValues.keywords.length > 0 && typeof initialValues.keywords[0] === 'string') {
            initialValues.keywords = initialValues.keywords.map((word) => {
                //console.log(word);
                return { value: word, label: word };
            });
        }
    }

    const getAllUsersNameResponse = state.User.getAllUsersName;
    const getSocialMediaConnectionInfoResponse = state.SocialMedia.getSocialMediaConnectionInfo;
    const getFacebookTemplateResponse = state.SocialMedia.getFacebookTemplate;
    const getTwitterTemplateResponse = state.SocialMedia.getTwitterTemplate;
    const getLinkedInTemplateResponse = state.SocialMedia.getLinkedInTemplate;
    console.log('initialValues', initialValues);
    return {
        formState,
        getImpactAreaResponse,
        initialValues,
        getPostResponse,
        setPostResponse,
        getSkillResponse,
        getServiceInfoResponse,
        getAllUsersNameResponse,
        getSocialMediaConnectionInfoResponse,
        getFacebookTemplateResponse,
        getTwitterTemplateResponse,
        getLinkedInTemplateResponse,
    };
};

export default connect(
    mapStateToProps,
    null,
)(
    reduxForm({
        form: 'CreatePost',
        enableReinitialize: true,
    })(CreatePost),
);
