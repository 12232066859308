import * as Types from '../constants/reducer-types';

const initialState = {
    createConversationBlock: {},
    removeConversationBlock: {},
    getOneConversationBlock: {},
    getAllConversationBlock: {},
};

const ConversationBlockReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.CREATE_CONVERSATION_BLOCK:
            return { ...state, createConversationBlock: action.payload };
        case Types.REMOVE_CONVERSATION_BLOCK:
            return { ...state, removeConversationBlock: action.payload };
        case Types.GET_ONE_CONVERSATION_BLOCK:
            return { ...state, getOneConversationBlock: action.payload };
        case Types.GET_ALL_CONVERSATION_BLOCKS:
            return { ...state, getAllConversationBlock: action.payload };

        default:
            return { ...state };
    }
};
export default ConversationBlockReducer;
