import * as Types from '@Constants/reducer-types';
const initialState = {
    facebookAccount: {},
    linkedInAccount: {},
    twitterAccount: {},
    instagramAccount: {},
    connectFacebook: {},
    disconnectFacebook: {},
    connectTwitter: {},
    disconnectTwitter: {},
    connectLinkedIn: {},
    disconnectLinkedIn: {},
    connectInstagram: {},
    getAllFacebookPagesByUser: {},
    getSelectedFacebookPageByUser: {},
    setSelectedFacebookPageByUser: {},
    setTwitterAccessToken: {},
    getSocialMediaConnectionInfo: {},
    getSocialMediaTemplate: {},
    setSocialMediaTemplate: {},
    getFacebookTemplate: {},
    getTwitterTemplate: {},
    getLinkedInTemplate: {},
};

const SocialMediaReducer = (state = initialState, action) => {
    switch (action.type) {
        // ACCOUNT
        case Types.GET_FACEBOOK_ACCOUNT:
            return { ...state, facebookAccount: action.payload };
        case Types.GET_TWITTER_ACCOUNT:
            return { ...state, twitterAccount: action.payload };
        case Types.GET_LINKED_IN_ACCOUNT:
            return { ...state, linkedInAccount: action.payload };

        // CONNECT
        case Types.GET_ALL_FACEBOOK_PAGES_BY_USER:
            return { ...state, getAllFacebookPagesByUser: action.payload };
        case Types.CONNECT_FACEBOOK:
            return { ...state, connectFacebook: action.payload };
        case Types.DISCONNECT_FACEBOOK:
            return { ...state, disconnectFacebook: action.payload };
        case Types.CONNECT_TWITTER:
            return { ...state, connectTwitter: action.payload };
        case Types.DISCONNECT_TWITTER:
            return { ...state, disconnectTwitter: action.payload };
        case Types.CONNECT_LINKED_IN:
            return { ...state, connectLinkedIn: action.payload };
        case Types.DISCONNECT_LINKED_IN:
            return { ...state, disconnectLinkedIn: action.payload };
        case Types.GET_SELECTED_FACEBOOK_PAGE_BY_USER:
            return { ...state, getSelectedFacebookPageByUser: action.payload };
        case Types.SET_SELECTED_FACEBOOK_PAGE_BY_USER:
            return { ...state, setSelectedFacebookPageByUser: action.payload };
        case Types.SET_TWITTER_ACCESS_TOKEN:
            return { ...state, setTwitterAccessToken: action.payload };
        case Types.CLEAR_CONNECT_SOCIAL_MEDIA:
            return {
                ...state,
                connectFacebook: {},
                connectTwitter: {},
                connectLinkedIn: {},
                connectInstagram: {},
                setTwitterAccessToken: {},
                disconnectFacebook: {},
                disconnectTwitter: {},
                disconnectLinkedIn: {},
            };
        case Types.CLEAR_DISCONNECT_SOCIAL_MEDIA:
            return { ...state };
        case Types.GET_SOCIAL_MEDIA_CONNECTION_INFO:
            return { ...state, getSocialMediaConnectionInfo: action.payload };
        case Types.GET_SOCIAL_MEDIA_TEMPLATE:
            return { ...state, getSocialMediaTemplate: action.payload };
        case Types.SET_SOCIAL_MEDIA_TEMPLATE:
            return { ...state, setSocialMediaTemplate: action.payload };
        case Types.CLEAR_SOCIAL_MEDIA_TEMPLATE:
            return { ...state, getSocialMediaTemplate: {}, setSocialMediaTemplate: {} };
        case Types.GET_FACEBOOK_TEMPLATE:
            return { ...state, getFacebookTemplate: action.payload };
        case Types.GET_TWITTER_TEMPLATE:
            return { ...state, getTwitterTemplate: action.payload };
        case Types.GET_LINKED_IN_TEMPLATE:
            return { ...state, getLinkedInTemplate: action.payload };
        default:
            return state;
    }
};
export default SocialMediaReducer;
