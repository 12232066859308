import React from 'react';
import { Container, Row, Col, Image, Card, Badge } from 'react-bootstrap';
import { FollowUserButtonRender, UnfollowUserButtonRender, EndorseUserButtonRender, CancelEndorseUserButtonRender } from '../form_template/buttons-render';
import { defaultOrganizationProfilePicture } from '@Constants/default-images';
const OrganizationPopOverCard = (props) => {
    const { organization, follows, endorses, handleUnfollowClick, handleFollowClick, handleCancelEndorseClick, handleEndorseClick } = props;
    //console.log('🚀 ~ file: organization-popover-card.js ~ line 7 ~ OrganizationPopOverCard ~ organization', organization);
    const descriptionRender = (description) => {
        if (description) {
            if (description.length < 101) return description;
            else return description.substr(0, 100) + '...';
        } else return 'No description available';
    };
    if (organization && organization._id) {
        const basicInfo = organization.basicInfo;
        const serviceInfo = organization.serviceInfo;
        return (
            <Card className="special-btn special-card">
                <Card.Img
                    variant="top"
                    src={basicInfo.profilePicture ? basicInfo.profilePicture : defaultOrganizationProfilePicture}
                    alt="No Image Found"
                    className="organization-list-image"
                />
                <Card.Body>
                    <Card.Text className="left-text bold-text">{basicInfo.name}</Card.Text>
                    {basicInfo.organizationTypes &&
                        basicInfo.organizationTypes.length > 0 &&
                        basicInfo.organizationTypes.map((type, i) => {
                            return (
                                <Badge variant="info" className="badge-single-small" key={i}>
                                    {type.label}
                                </Badge>
                            );
                        })}
                    {serviceInfo.impactAreas &&
                        serviceInfo.impactAreas.length > 0 &&
                        serviceInfo.impactAreas.map((area, i) => {
                            return (
                                <Badge variant="light" className="badge-single-small impact-area-badge" key={i}>
                                    {area.label}
                                </Badge>
                            );
                        })}
                    {basicInfo.address ? (
                        <Row>
                            <Col>
                                <small className="gray-text">
                                    {basicInfo.address.street1 +
                                        ', ' +
                                        basicInfo.address.street2 +
                                        ', ' +
                                        basicInfo.address.city +
                                        ', ' +
                                        basicInfo.address.code}
                                </small>
                            </Col>
                        </Row>
                    ) : (
                        <></>
                    )}
                    <Card.Text className="justify-text">
                        <small>{descriptionRender(basicInfo.description)}</small>
                    </Card.Text>
                </Card.Body>
                <Card.Footer>
                    <Row>
                        <Col>
                            {follows ? (
                                <UnfollowUserButtonRender
                                    onClick={() => {
                                        handleUnfollowClick(organization._id);
                                    }}
                                />
                            ) : (
                                <FollowUserButtonRender
                                    onClick={() => {
                                        handleFollowClick(organization._id);
                                    }}
                                />
                            )}
                            &nbsp;
                            {endorses ? (
                                <CancelEndorseUserButtonRender
                                    onClick={() => {
                                        handleCancelEndorseClick(organization._id);
                                    }}
                                />
                            ) : (
                                <EndorseUserButtonRender
                                    onClick={() => {
                                        handleEndorseClick(organization._id);
                                    }}
                                />
                            )}
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        );
    } else {
        return (
            <Container>
                <Row>
                    <Col>Loading</Col>
                </Row>
            </Container>
        );
    }
};
export default OrganizationPopOverCard;
