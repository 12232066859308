/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getUserPublicInfo } from '@Actions/user-action';
import { userDetailsPage } from '@Constants/route-paths';
const RedirectUserDetails = (props) => {
    useEffect(() => {
        const userId = props.match.params.userId;
        if (userId) {
            props.dispatch(getUserPublicInfo(userId));
        }
    }, [props.match.params.userId]);
    useEffect(() => {
        const { success } = props.getPublicInfoResponse;
        if (success) {
            const userType = props.getPublicInfoResponse.user.userType;
            props.history.push(userDetailsPage(userType, props.match.params.userId));
        } else if (success === false) {
            props.history.pop();
        }
    }, [props.getPublicInfoResponse]);
    return (
        <Container>
            <Row>
                <Col className="parent-page">Redirecting...</Col>
            </Row>
        </Container>
    );
};
const mapStateToProps = (state) => {
    const getPublicInfoResponse = state.User.getUserPublicInfo;
    return { getPublicInfoResponse };
};
export default connect(mapStateToProps, null)(RedirectUserDetails);
