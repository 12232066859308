import { getLocalStorage } from '@Actions';

export const facebookAppId = '808303879820603';

// export const serverAddress = `http://localhost:3001`; // LOCAL SERVER
// export const serverAddress = `http://cascaid.sky4242.com`; // SUBDOMAIN SERVER
// export const serverAddress = `http://64.225.18.151`; // MAIN SERVER
export const serverAddress = 'https://impacttapestry.com'; // MAIN SERVER WITH NAME // USE THIS ONE

// API CONFIG
export const apiConfig = () => ({ headers: { Authorization: `Bearer ${getLocalStorage(`token`)}` } });

// DEBUGGING
export const responseLog = (apiPath, response) => {
    //console.log(`RESPONSE FROM ` + apiPath + `: ` + response);
};
export const errorLog = (apiPath, err) => {
    //console.log(`ERROR FROM`, apiPath, err);
};

// AUTHENTICATION
export const checkEmailExist = serverAddress + `/api/users/email/`;
export const userSignUp = serverAddress + `/api/auth/sign-up`;
export const userSignIn = serverAddress + `/api/auth/sign-in`;
export const userSignOut = serverAddress + `/api/auth/sign-out`;
export const userAuth = serverAddress + `/api/auth`;

// PASSWORD
export const changePassword = serverAddress + `/api/auth/change-password`;
export const recoverPassword = serverAddress + `/api/auth/recover-password`;
export const resetPassword = serverAddress + `/api/auth/reset-password`;

// USER
export const getBasicInfo = (userId) => serverAddress + `/api/users/${userId}/basic-info`;
export const getInvolvement = (userId) => serverAddress + `/api/users/${userId}/involvement`;
export const getPrivacy = (userId) => serverAddress + `/api/users/${userId}/privacy`;
export const getServiceInfo = (userId) => serverAddress + `/api/users/${userId}/service-info`;
export const getInternalLink = (userId) => serverAddress + `/api/users/${userId}/internal-link`;
export const getUserPublicInfo = (userId) => serverAddress + `/api/users/${userId}/public-info`;
export const getAllUsers = serverAddress + `/api/users/`;

export const setBasicInfo = (userId) => serverAddress + `/api/users/${userId}/basic-info`;
export const setInvolvement = (userId) => serverAddress + `/api/users/${userId}/involvement`;
export const setPrivacy = (userId) => serverAddress + `/api/users/${userId}/privacy`;
export const setServiceInfo = (userId) => serverAddress + `/api/users/${userId}/service-info`;
export const setInternalLink = (userId) => serverAddress + `/api/users/${userId}/internal-link`;
export const getAllUsersName = serverAddress + `/api/users/name/all`;
export const getAllIndividuals = serverAddress + `/api/users/individual`;
export const getAllConnectedUsersIdByUser = (userId) => serverAddress + `/api/users/${userId}/connected-users`;
export const inviteUsersToViewPost = (userId) => serverAddress + `/api/users/${userId}/invite`;
export const getUserWallet = (userId) => serverAddress + `/api/users/${userId}/wallet`;
export const checkIfConnected = (userId, connectedUserId) => serverAddress + `/api/users/check-if-connected/${userId}/${connectedUserId}`;

// Impact Area
export const getAllGlobalImpactAreas = serverAddress + `/api/impact-areas/global`;
export const getAllImpactAreasByUser = (userId) => serverAddress + `/api/users/${userId}/impact-areas`;

// Skill
export const getAllGlobalSkills = serverAddress + `/api/skills/global`;
export const getAllSkillsByUser = (userId) => serverAddress + `/api/users/${userId}/skills`;

// Languages
export const getAllGlobalLanguages = serverAddress + `/api/languages/global`;
export const getAllLanguagesByUser = (userId) => serverAddress + `/api/users/${userId}/languages`;

// Map Area
export const getAllGlobalMapAreas = serverAddress + `/api/map-areas/name`;
export const generateMapAreaFile = (userId, areaType) => serverAddress + `/api/map-areas/generate-file/user/${userId}/area-type/${areaType}`;

// SEARCH
export const searchByName = serverAddress + `/api/users/search/`;

// Organization Type
export const getAllOrganizationTypes = serverAddress + `/api/organization-types/`;

// POSTS
export const getPostById = (postId) => serverAddress + `/api/posts/${postId}`;
export const createPost = serverAddress + `/api/posts/`;
export const updatePostById = (postId) => serverAddress + `/api/posts/${postId}`;
export const deletePostById = (postId) => serverAddress + `/api/posts/${postId}`;
export const getAllPosts = serverAddress + `/api/posts`;
export const getAllUpcomingPosts = serverAddress + `/api/posts/upcoming`;
export const getAllRecentlyEndedPosts = serverAddress + `/api/posts/recently-ended`;
export const getAllPostsByUser = (userId) => serverAddress + `/api/users/${userId}/posts`;
export const getAllImages = (userId) => serverAddress + `/api/posts/gallery/user/${userId}`;
export const getAllCalendarPosts = (userId) => serverAddress + `/api/posts/calendar/user/${userId}`;
export const getViewerSummary = (userId) => serverAddress + `/api/posts/viewers/${userId}/summary`;
export const getPostStatistics = (userId) => serverAddress + `/api/posts/${userId}/statistics`;

// FOLLOW
export const followUser = serverAddress + `/api/follows/follow`;
export const unfollowUser = serverAddress + `/api/follows/unfollow`;
export const getAllFollower = (userId, onlyFriends) => serverAddress + `/api/follows/followers/${userId}?onlyFriends=${onlyFriends}`;
export const getAllFollowing = (userId) => serverAddress + `/api/follows/followings/${userId}`;
export const checkIfFollower = (followerId, followingId) => serverAddress + `/api/follows/${followerId}/${followingId}`;
export const getFollowerSummary = (userId) => serverAddress + `/api/follows/followers/${userId}/summary`;
export const getAllFollowersByPost = (postId) => serverAddress + `/api/posts/${postId}/followers`;
// HOME
export const getAllFeedPosts = serverAddress + `/api/posts/feed`;
export const getAllSuggestedUsers = (userId, userType, limit) =>
    serverAddress + `/api/users/${userId}/suggestions${userType ? `?userType=${userType}` : ''}${limit ? `&limit=${limit}` : ``}`;

// export const getAllOrganizationSuggestions = serverAddress + '/api/users/suggestions';
// export const getAllSuggestedPosts = (userId) => serverAddress + '/api/posts/suggestions';

// INTEREST
export const likePost = (postId) => serverAddress + `/api/posts/${postId}/like`;
export const cancelLikePost = (postId) => serverAddress + `/api/posts/${postId}/cancel-like`;
export const interestPost = (postId) => serverAddress + `/api/posts/${postId}/interested`;
export const cancelInterestPost = (postId) => serverAddress + `/api/posts/${postId}/cancel-interested`;
export const goingPost = (postId) => serverAddress + `/api/posts/${postId}/going`;
export const cancelGoingPost = (postId) => serverAddress + `/api/posts/${postId}/cancel-going`;
export const getAllCommittedPersons = (postId, type) => serverAddress + `/api/posts/${postId}/committed/?interestType=${type}`;

// NOTIFICATION
export const getNotificationsCount = (onlyNew) => serverAddress + `/api/notifications/count${onlyNew ? '?onlyNew=true' : ''}`;
export const getAllNotifications = (topNotifications) => serverAddress + `/api/notifications/${topNotifications ? '?topNotifications=true' : ''}`;
export const updateNotification = (notificationId) => serverAddress + `/api/notifications/${notificationId}`;
export const deleteNotification = (notificationId) => serverAddress + `/api/notifications/${notificationId}`;
export const updateAllNotifications = () => serverAddress + `/api/notifications/`;
// MEMBERSHIP
export const createMembership = serverAddress + `/api/memberships/`;
export const updateMembership = (membershipId) => serverAddress + `/api/memberships/`;
export const getAllMemberships = serverAddress + `/api/memberships/`;
export const deleteMembership = (membershipId) => serverAddress + `/api/memberships/${membershipId}`;
export const acceptMembership = (membershipId) => serverAddress + `/api/memberships/${membershipId}/accept`;
export const rejectMembership = (membershipId) => serverAddress + `/api/memberships/${membershipId}/reject`;

// FRIENDSHIP
export const createFriendship = serverAddress + `/api/friendships/`;
export const acceptFriendship = (friendshipId) => serverAddress + `/api/friendships/${friendshipId}/accept`;
export const rejectFriendship = (friendshipId) => serverAddress + `/api/friendships/${friendshipId}/reject`;
export const deleteFriendship = (friendshipId) => serverAddress + `/api/friendships/${friendshipId}`;
export const checkIfFriends = (userId, friendId) => serverAddress + `/api/friendships/${userId}/${friendId}`;
export const getAllFriendships = (userId, status) => serverAddress + `/api/friendships/${userId}${status ? `?status=${status}` : ''}`;

// ENDORSEMENT
export const endorseUser = serverAddress + `/api/endorsements/endorse`;
export const cancelEndorseUser = serverAddress + `/api/endorsements/cancel-endorse`;
export const getAllEndorsers = (userId, onlyFriends) => serverAddress + `/api/endorsements/endorsers/${userId}?onlyFriends=${onlyFriends}`;
export const getAllEndorsees = (userId) => serverAddress + `/api/endorsements/endorsees/${userId}`;
export const CheckIfEndorses = (endorserId, endorseeId) => serverAddress + `/api/endorsements/${endorserId}/${endorseeId}`;
export const getEndorserSummary = (userId) => serverAddress + `/api/endorsements/endorsers/${userId}/summary`;

// VIEWER
export const getAllViewers = (userId) => serverAddress + `/api/posts/viewers/user/${userId}`;
export const getAllViewersByPost = (postId) => serverAddress + `/api/posts/${postId}/viewers`;

// CONVERSATION
export const createConversation = serverAddress + `/api/conversations/`;
export const getAllConversationsByUser = (userId) => serverAddress + `/api/conversations/user/${userId}`;
export const getOneConversation = (conversationId) => serverAddress + `/api/conversations/${conversationId}`;
export const createOneMessage = serverAddress + `/api/conversations/message`;
export const getConversationCountByUser = (userId) => serverAddress + `/api/conversations/count/new/${userId}`;
export const sendMessageToAllConnected = (userId, connection, connectionType, onlyConnected = false) =>
    serverAddress + `/api/conversations/bulk/user/${userId}/${connection}/${connectionType}${onlyConnected ? `?onlyConnected=true` : ``}`;
export const sendMessageToMultipleUsers = (userId) => serverAddress + `/api/conversations/bulk/user/${userId}/multiple-users`;
export const deleteOneConversation = (conversationId) => serverAddress + `/api/conversations/${conversationId}`;
// CULTIVATION
export const createOneCultivation = serverAddress + '/api/cultivations/';
export const updateOneCultivation = (cultivationId) => serverAddress + `/api/cultivations/${cultivationId}`;
export const getAllCultivationsByUser = (userId) => serverAddress + `/api/cultivations/user/${userId}`;
export const getOneCultivation = (cultivationId) => serverAddress + `/api/cultivations/${cultivationId}`;
export const addUsersToCultivation = (cultivationId) => serverAddress + `/api/cultivations/${cultivationId}/add/user`;
export const removeUsersFromCultivation = (cultivationId) => serverAddress + `/api/cultivations/${cultivationId}/remove/user`;
export const deleteOneCultivation = (cultivationId) => serverAddress + `/api/cultivations/${cultivationId}`;

// SCHEDULER
export const checkIfPostAddedToScheduler = (userId, postId) => serverAddress + `/api/schedulers/check-if-added/${userId}/${postId}`;
export const addPostToScheduler = (userId) => serverAddress + `/api/schedulers/user/${userId}/add-post`;
export const removePostFromScheduler = (userId) => serverAddress + `/api/schedulers/user/${userId}/remove-post`;
export const getAllSchedulerPosts = (userId) => serverAddress + `/api/schedulers/user/${userId}`;

// SHARE
export const createOneShare = serverAddress + `/api/shares/`;
export const getOneShare = (shareId) => serverAddress + `/api/shares/${shareId}`;
export const updateOneShare = (shareId) => serverAddress + `/api/shares/${shareId}`;
export const deleteOneShare = (shareId) => serverAddress + `/api/shares/${shareId}`;

// FEEDBACK
export const createOneFeedback = serverAddress + `/api/feedbacks/`;
export const getAllFeedbacks = serverAddress + `/api/feedbacks/`;

// WALLET
export const getCoinSpendingSummary = serverAddress + `/api/wallets/spending-summary`;

// INVITATION
export const inviteUsersToFollowOrganization = (userId) => serverAddress + `/api/invitations/${userId}/`;

// SOCIAL MEDIA
export const getSocialMediaTemplate = (socialMediaType, userId) => serverAddress + `/api/social-media/${socialMediaType}/template/${userId}`; // TEMPLATE
export const setSocialMediaTemplate = (socialMediaType, userId) => serverAddress + `/api/social-media/${socialMediaType}/template/${userId}`; // TEMPLATE
export const getSocialMediaConnectionInfo = (userId) => serverAddress + `/api/social-media/${userId}/connection-info`;
export const getFacebookByUser = (userId) => serverAddress + `/api/${userId}/social-media/facebook`;
export const getFacebookAccount = () => serverAddress + `/api/social-media/facebook/account`;
export const connectFacebook = () => serverAddress + `/api/social-media/facebook/connect`;
export const disconnectFacebook = () => serverAddress + `/api/social-media/facebook/disconnect`;
export const getAllFacebookPagesByUser = (userId) => serverAddress + `/api/social-media/${userId}/facebook/pages`;
export const getSelectedFacebookPageByUser = (userId) => serverAddress + `/api/social-media/${userId}/facebook/pages/selected`;
export const setSelectedFacebookPageByUser = (userId) => serverAddress + `/api/social-media/${userId}/facebook/pages/selected`;
// export const setFacebookPageByUser = (userId) => serverAddress + `/api/social-media/${userId}/facebook/pages`;

export const getLinkedInAccount = () => serverAddress + `/api/social-media/linked-in/account`;
export const connectLinkedIn = () => serverAddress + `/api/social-media/linked-in/connect`;
export const disconnectLinkedIn = () => serverAddress + `/api/social-media/linked-in/disconnect`;

export const getTwitterAccount = () => serverAddress + `/api/social-media/twitter/account`;
export const connectTwitter = () => serverAddress + `/api/social-media/twitter/connect`;
export const disconnectTwitter = () => serverAddress + `/api/social-media/twitter/disconnect`;
export const requestTwitterToken = (callBackURL) => serverAddress + `/api/social-media/twitter/request-token?callBackURL=${callBackURL}`;
export const setTwitterAccessToken = () => serverAddress + `/api/social-media/twitter/access-token`;

// CONNECTION
export const getOneConnectionByUser = (senderId, receiverId) => serverAddress + `/api/connections/sender/${senderId}/receiver/${receiverId}`;
export const updateOneConnectionByUser = (senderId, receiverId) => serverAddress + `/api/connections/sender/${senderId}/receiver/${receiverId}`;
export const getAllConnections = (userId) => serverAddress + `/api/connections/sender/${userId}`;

// CONVERSATION BLOCK
export const createConversationBlock = () => serverAddress + `/api/conversation-block`;
export const removeConversationBlock = (userId, blockedUserId) => serverAddress + `/api/conversation-block/userId/${userId}/blocked-userId/${blockedUserId}`;
export const getOneConversationBlock = (userId, blockedUserId) => serverAddress + `/api/conversation-block/userId/${userId}/blocked-userId/${blockedUserId}`;
export const getAllConversationBlock = (userId) => serverAddress + `/api/conversation-block/userId/${userId}`;

// CONNECTIVITIES
export const getUserConnectivities = (userId) => serverAddress + `/api/connectivities/userId/${userId}`;
