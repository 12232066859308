import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Overlay, Popover } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getUserPublicInfo } from '@Actions/user-action';
import { followUser, unfollowUser, checkIfFollower } from '@Actions/follow-action';
import { endorseUser, cancelEndorseUser, checkIfEndorses } from '@Actions/endorsement-action';
import OrganizationPopOverCard from './organization-popover-card';
const PopoverStickOnHover = (props) => {
    const { delay, onMouseEnter, children, placement } = props;
    const { postId, postTitle } = props;
    const [showPopover, setShowPopover] = useState(false);
    const [loading, setLoading] = useState(false);
    const [follows, setFollows] = useState(false);
    const [endorses, setEndorses] = useState(false);
    const [getInfo, setGetInfo] = useState(true);
    const childNode = useRef(null);
    let setTimeoutConst = null;
    useEffect(() => {
        const { success } = props.getCheckIfFollowerResponse;
        if (success) {
            setFollows(props.getCheckIfFollowerResponse.follows);
        }
    }, [props.getCheckIfFollowerResponse]);
    useEffect(() => {
        const { success } = props.getCheckIfEndorsesResponse;
        if (success) {
            setEndorses(props.getCheckIfEndorsesResponse.endorses);
        }
    }, [props.getCheckIfEndorsesResponse]);
    const getInitialInfo = () => {
        const { organizationId, organizationName, userId } = props;
        //console.log(
        //     '🚀 ~ file: organization-sticky-popup.js ~ line 22 ~ getInitialInfo ~ organizationId, organizationName, userId',
        //     organizationId,
        //     organizationName,
        //     userId,
        // );
        props.dispatch(getUserPublicInfo(organizationId));
        props.dispatch(checkIfFollower(userId, organizationId));
        props.dispatch(checkIfEndorses(userId, organizationId));
    };
    // useEffect(() => {}, [props.organizationId]);

    useEffect(() => {
        return () => {
            if (setTimeoutConst) {
                clearTimeout(setTimeoutConst);
            }
        };
    });

    const handleMouseEnter = () => {
        setTimeoutConst = setTimeout(() => {
            setShowPopover(true);
            onMouseEnter();
        }, delay);
    };

    const handleMouseLeave = () => {
        clearTimeout(setTimeoutConst);
        setShowPopover(false);
        setGetInfo(true);
    };

    const displayChild = React.Children.map(children, (child) =>
        React.cloneElement(child, {
            onMouseEnter: handleMouseEnter,
            onMouseLeave: handleMouseLeave,
            ref: (node) => {
                childNode.current = node;
                const { ref } = child;
                if (typeof ref === 'function') {
                    ref(node);
                }
            },
        }),
    )[0];
    const handleFollowClick = () => {
        const { userId, organizationId } = props;
        props.dispatch(followUser({ followerId: userId, followingId: organizationId, postId }));
        setLoading(false);
    };
    const handleUnfollowClick = () => {
        setLoading(true);
        const { userId, organizationId } = props;
        props.dispatch(unfollowUser({ followerId: userId, followingId: organizationId }));
        setLoading(false);
    };
    useEffect(() => {
        const { success } = props.getFollowResponse;
        if (success) {
            setFollows(true);
        } else if (success === false) {
        }
    }, [props.getFollowResponse]);
    useEffect(() => {
        const { success } = props.getUnfollowResponse;
        if (success) {
            setFollows(false);
        } else if (success === false) {
        }
    }, [props.getUnfollowResponse]);

    const handleEndorseClick = () => {
        const { userId, organizationId } = props;
        props.dispatch(endorseUser({ endorserId: userId, endorseeId: organizationId }));
        setLoading(false);
    };
    const handleCancelEndorseClick = () => {
        const { userId, organizationId } = props;
        props.dispatch(cancelEndorseUser({ endorserId: userId, endorseeId: organizationId }));
        setLoading(false);
    };
    useEffect(() => {
        const { success } = props.getEndorseResponse;
        if (success) {
            setEndorses(true);
        } else if (success === false) {
        }
    }, [props.getEndorseResponse]);
    useEffect(() => {
        const { success } = props.getCancelEndorseResponse;
        if (success) {
            setEndorses(false);
        } else if (success === false) {
        }
    }, [props.getCancelEndorseResponse]);
    if (getInfo && showPopover) {
        getInitialInfo();
        setGetInfo(false);
    }
    return (
        <>
            {displayChild}
            <Overlay show={showPopover} placement={placement} target={childNode} shouldUpdatePosition>
                <Popover
                    onMouseEnter={() => {
                        setShowPopover(true);
                    }}
                    onMouseLeave={handleMouseLeave}
                    id="popover"
                >
                    {/* {organizationName}
                    <br />
                    {organizationId} */}

                    <OrganizationPopOverCard
                        organization={props.getPublicInfoResponse && props.getPublicInfoResponse.success ? props.getPublicInfoResponse.user : {}}
                        handleFollowClick={handleFollowClick}
                        handleUnfollowClick={handleUnfollowClick}
                        follows={follows}
                        handleEndorseClick={handleEndorseClick}
                        handleCancelEndorseClick={handleCancelEndorseClick}
                        endorses={endorses}
                    />
                </Popover>
            </Overlay>
        </>
    );
};

PopoverStickOnHover.defaultProps = {
    delay: 0,
};

PopoverStickOnHover.propTypes = {
    delay: PropTypes.number,
    onMouseEnter: PropTypes.func,
    children: PropTypes.element.isRequired,
};
const mapStateToProps = (state) => {
    const getPublicInfoResponse = state.User.getUserPublicInfo;
    const getCheckIfFollowerResponse = state.Follow.checkIfFollower;
    const getFollowResponse = state.Follow.followUser;
    const getUnfollowResponse = state.Follow.unfollowUser;

    const getCheckIfEndorsesResponse = state.Endorsement.checkIfEndorses;
    const getEndorseResponse = state.Endorsement.endorseUser;
    const getCancelEndorseResponse = state.Endorsement.cancelEndorseUser;
    return {
        getPublicInfoResponse,
        getCheckIfFollowerResponse,
        getFollowResponse,
        getUnfollowResponse,
        getCheckIfEndorsesResponse,
        getEndorseResponse,
        getCancelEndorseResponse,
    };
};
export default connect(mapStateToProps, null)(PopoverStickOnHover);
