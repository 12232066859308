import * as Types from '@Constants/reducer-types';
import SocialMediaDA from '@DA/social-media-da';

export const getFacebookAccount = () => {
    return {
        type: Types.GET_FACEBOOK_ACCOUNT,
        payload: SocialMediaDA.get_facebook_account(),
    };
};
export const getFacebookByUser = (userId) => {
    return {
        type: Types.GET_FACEBOOK_BY_USER,
        payload: SocialMediaDA.get_facebook_by_user(userId),
    };
};
export const connectFacebook = (facebookInfo) => {
    return {
        type: Types.CONNECT_FACEBOOK,
        payload: SocialMediaDA.connect_facebook(facebookInfo),
    };
};
export const disconnectFacebook = () => {
    return {
        type: Types.DISCONNECT_FACEBOOK,
        payload: SocialMediaDA.disconnect_facebook(),
    };
};
export const getAllFacebookPagesByUser = (userId) => {
    return {
        type: Types.GET_ALL_FACEBOOK_PAGES_BY_USER,
        payload: SocialMediaDA.get_all_facebook_pages_by_user(userId),
    };
};
export const getSelectedFacebookPageByUser = (userId) => {
    return {
        type: Types.GET_SELECTED_FACEBOOK_PAGE_BY_USER,
        payload: SocialMediaDA.get_selected_facebook_page_by_user(userId),
    };
};
export const setSelectedFacebookPageByUser = (userId, page) => {
    return {
        type: Types.SET_SELECTED_FACEBOOK_PAGE_BY_USER,
        payload: SocialMediaDA.set_selected_facebook_page_by_user(userId, page),
    };
};

// LINKED IN
export const getLinkedInAccount = () => {
    return {
        type: Types.GET_LINKED_IN_ACCOUNT,
        payload: SocialMediaDA.get_linked_in_account(),
    };
};
export const connectLinkedIn = (linkedInInfo) => {
    return {
        type: Types.CONNECT_LINKED_IN,
        payload: SocialMediaDA.connect_linked_in(linkedInInfo),
    };
};
export const disconnectLinkedIn = () => {
    return {
        type: Types.DISCONNECT_LINKED_IN,
        payload: SocialMediaDA.disconnect_linked_in(),
    };
};
// TWITTER
export const getTwitterAccount = () => {
    return {
        type: Types.GET_TWITTER_ACCOUNT,
        payload: SocialMediaDA.get_twitter_account(),
    };
};
export const connectTwitter = (twitterData) => {
    return {
        type: Types.CONNECT_TWITTER,
        payload: SocialMediaDA.connect_twitter(twitterData),
    };
};
export const disconnectTwitter = () => {
    return {
        type: Types.DISCONNECT_TWITTER,
        payload: SocialMediaDA.disconnect_twitter(),
    };
};
export const setTwitterAccessToken = (twitterData) => {
    return {
        type: Types.SET_TWITTER_ACCESS_TOKEN,
        payload: SocialMediaDA.set_twitter_access_token(twitterData),
    };
};
// COMMON
export const clearConnectSocialMedia = () => {
    return {
        type: Types.CLEAR_CONNECT_SOCIAL_MEDIA,
        payload: {},
    };
};
export const clearDisconnectSocialMedia = () => {
    return {
        type: Types.CLEAR_DISCONNECT_SOCIAL_MEDIA,
        payload: {},
    };
};
export const getSocialMediaConnectionInfo = (userId) => {
    return {
        type: Types.GET_SOCIAL_MEDIA_CONNECTION_INFO,
        payload: SocialMediaDA.get_social_media_connection_info(userId),
    };
};
//
export const getSocialMediaTemplate = (socialMedia, userId) => {
    return {
        type: Types.GET_SOCIAL_MEDIA_TEMPLATE,
        payload: SocialMediaDA.get_social_media_template(socialMedia, userId),
    };
};
export const setSocialMediaTemplate = (socialMedia, userId, template) => {
    return {
        type: Types.SET_SOCIAL_MEDIA_TEMPLATE,
        payload: SocialMediaDA.set_social_media_template(socialMedia, userId, template),
    };
};
export const clearSocialMediaTemplate = () => {
    return {
        type: Types.CLEAR_SOCIAL_MEDIA_TEMPLATE,
        payload: {},
    };
};
export const getFacebookTemplate = (userId) => {
    return {
        type: Types.GET_FACEBOOK_TEMPLATE,
        payload: SocialMediaDA.get_social_media_template('facebook', userId),
    };
};
export const getTwitterTemplate = (userId) => {
    return {
        type: Types.GET_TWITTER_TEMPLATE,
        payload: SocialMediaDA.get_social_media_template('twitter', userId),
    };
};
export const getLinkedInTemplate = (userId) => {
    return {
        type: Types.GET_LINKED_IN_TEMPLATE,
        payload: SocialMediaDA.get_social_media_template('linked-in', userId),
    };
};
