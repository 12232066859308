// /* eslint-disable */

// import React, { useEffect } from 'react';
// import { connect } from 'react-redux';
// import { userSignOut, clearUserAuth } from '@Actions';
// import * as RoutePath from '@Constants/route-paths';

// const SignOut = (props) => {
//     props.dispatch(userSignOut());

//     useEffect(() => {
//         const { success } = props.userSignOutResponse;
//         if (success) {
//             clearUserAuth();
//             props.history.push(RoutePath.signInPage);
//         }
//     }, [props.userSignOutResponse]);
//     return null;
// };

// const mapStateToProps = (state) => {
//     const userSignOutResponse = state.Auth.userSignOut;
//     console.log('🚀 ~ file: sign-out.js ~ line 21 ~ mapStateToProps ~ userSignOutResponse', userSignOutResponse);
//     return { userSignOutResponse };
// };
// export default connect(mapStateToProps, null)(SignOut);

import { Component } from 'react';
import { connect } from 'react-redux';
import { userSignOut, clearUserAuth } from '../../actions';
import * as RoutePath from '../../constants/route-paths';

class SignOut extends Component {
    componentDidMount() {
        this.props.dispatch(userSignOut());
        clearUserAuth();
        this.props.history.push(RoutePath.signInPage);
    }

    render() {
        return null;
    }
}

export default connect()(SignOut);
