import { defaultIndividualProfilePicture, defaultOrganizationProfilePicture } from '@Constants/default-images';
import React from 'react';
import { Button, Col, Container, Image, Row, Table } from 'react-bootstrap';
import { Field } from 'redux-form';
import { DetailsButtonRender, RemoveUserFromCultivation } from '../form_template/buttons-render';
import { InputRender, TextRender } from '../form_template/input-render';
import { CheckIconRender, CrossIconRender } from '@Components/form_template/icon-render';
import { interestTypes } from '@Constants/interest-types';
import { CSVLink, CSVDownload } from 'react-csv';
import moment from 'moment';
const CultivationCSVHeaders = [
    { label: 'List Title', key: 'title' },
    { label: 'List Description', key: 'description' },
    { label: 'User Name', key: 'user' },
    { label: 'Total Interactions', key: 'totalInteractions' },
    { label: 'Last Liked', key: 'lastLiked' },
    { label: 'Last Interested', key: 'lastInterested' },
    { label: 'Last Going', key: 'lastGoing' },
    { label: 'Follows', key: 'follows' },
    { label: 'Endorses', key: 'endorses' },
];
const interactionTextRender = (interaction, post) => {
    if (interaction && post) {
        return `Last ${interaction.label} ${post.title} at ${moment(interaction.date).format('LLL')}`;
    } else return '';
};
const CultivationForm = (props) => {
    const handleGoToPostDetailsPage = props.handleGoToPostDetailsPage;
    const editMode = props.editMode;
    const handleGoToUserDetailsPage = props.handleGoToUserDetailsPage;
    const handleRemoveUsersFromCultivation = props.handleRemoveUsersFromCultivation;
    const cultivation = props.cultivation;
    const CultivationCSVData = [];
    CultivationCSVData.push({
        title: cultivation.title,
        description: cultivation.description,
    });
    const { invitationModal, setInvitationModal, setUsersToBeInvited, setTotalUsers } = props;
    const { bulkMessageModal, setBulkMessageModal, setUsersToBeSent } = props;
    const interactionRender = (interaction, post) => {
        if (interaction && post) {
            return (
                <p>
                    Last <label style={{ color: 'cadetBlue', fontWeight: 'bold' }}>{interaction.label}</label>{' '}
                    <b
                        className="special-btn link-name"
                        onClick={() => {
                            handleGoToPostDetailsPage(post.postType, post._id);
                        }}
                    >
                        {post.title}
                    </b>
                    <small>at {moment(interaction.date).format('LLL')}</small>
                </p>
            );
        } else return <></>;
    };
    return (
        <Container>
            <Row>
                <Col className="parent-page">
                    <Row>
                        <Col sm={4}>{editMode ? <h4>Edit Cultivation</h4> : <h4>Create Cultivation</h4>}</Col>
                        <Col sm={8} className="right-align">
                            <Button
                                size="sm"
                                type="button"
                                variant="outline-primary"
                                onClick={() => {
                                    props.handleGoToManageCultivations();
                                }}
                            >
                                Manage Cultivations
                            </Button>

                            {editMode && (
                                <>
                                    &nbsp;
                                    <CSVLink
                                        className="btn btn-outline-primary btn-sm"
                                        data={CultivationCSVData}
                                        headers={CultivationCSVHeaders}
                                        filename={'cultivation.csv'}
                                    >
                                        Export To CSV
                                    </CSVLink>
                                </>
                            )}
                            {editMode && (
                                <>
                                    &nbsp;
                                    <Button
                                        variant="outline-secondary"
                                        size="sm"
                                        onClick={() => {
                                            if (cultivation.users && cultivation.users.length > 0) {
                                                const usersToBeInvited = cultivation.users
                                                    .filter((user) => !user.invited && !user.follows)
                                                    .map((user) => user._id);
                                                setUsersToBeInvited([...usersToBeInvited]);

                                                const totalUsers = cultivation.users.filter((user) => !user.invited && !user.follows && !user.endorses).length;

                                                setTotalUsers(totalUsers);
                                            } else {
                                                setUsersToBeInvited([]);
                                            }
                                            setInvitationModal(true);
                                        }}
                                    >
                                        Send Invitation Message
                                    </Button>
                                </>
                            )}
                            {editMode && (
                                <>
                                    &nbsp;
                                    <Button
                                        variant="outline-secondary"
                                        size="sm"
                                        onClick={() => {
                                            if (cultivation.users && cultivation.users.length > 0) {
                                                const usersToBeSent = [];
                                                for (let i = 0; i < cultivation.users.length; i++) {
                                                    if (cultivation.users[i].follows || cultivation.users[i].endorses) {
                                                        usersToBeSent.push({
                                                            _id: cultivation.users[i]._id,
                                                            connected: true,
                                                        });
                                                    } else {
                                                        usersToBeSent.push({
                                                            _id: cultivation.users[i]._id,
                                                            connected: false,
                                                        });
                                                    }
                                                }
                                                setUsersToBeSent([...usersToBeSent]);
                                            } else {
                                                setUsersToBeSent([]);
                                            }
                                            setBulkMessageModal(true);
                                        }}
                                    >
                                        Send Bulk Message
                                    </Button>
                                </>
                            )}
                        </Col>
                    </Row>
                    <hr />
                    <form onSubmit={props.handleOnSubmit}>
                        <Field name="title" type="text" component={InputRender} label="Title" placeholder="Title..." />
                        <Field name="description" type="text" component={TextRender} label="Description" placeholder="Description..." col1={4} col2={8} />

                        <Row>
                            <Col sm={4}></Col>
                            <Col sm={8}>
                                <Button variant="primary" size="sm" type="submit">
                                    {editMode ? 'Update' : 'Save'}
                                </Button>
                                &nbsp;
                                <Button
                                    size="sm"
                                    type="button"
                                    variant="outline-primary"
                                    onClick={() => {
                                        props.handleGoToManageCultivations();
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </form>
                    <hr />
                    <Row>
                        <Col>
                            <h6>USERS</h6>
                        </Col>
                    </Row>
                    {editMode && cultivation.users && cultivation.users.length > 0 ? (
                        <Row>
                            <Col>
                                <Table striped bordered hover responsive size="sm">
                                    <thead>
                                        <tr>
                                            <td>#</td>
                                            <td>Name</td>
                                            <td>Total Interactions</td>
                                            <td>Last Interactions</td>
                                            <td>Follows</td>
                                            <td>Invited To Follow</td>
                                            <td>Endorses</td>
                                            <td>Actions</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cultivation.users.map((user, i) => {
                                            let name = '';
                                            let profilePicture = '';
                                            if (user.userType === 'individual') {
                                                name = user.basicInfo.firstName + ' ' + user.basicInfo.lastName;
                                                profilePicture = user.basicInfo.profilePicture
                                                    ? user.basicInfo.profilePicture
                                                    : defaultIndividualProfilePicture;
                                            } else if (user.userType === 'organization') {
                                                name = user.basicInfo.name;
                                                profilePicture = user.basicInfo.profilePicture
                                                    ? user.basicInfo.profilePicture
                                                    : defaultOrganizationProfilePicture;
                                            }
                                            CultivationCSVData.push({
                                                user: name,
                                                totalInteractions: user.totalInteractions,
                                                lastLiked:
                                                    user.lastLiked && user.lastLiked._id
                                                        ? interactionTextRender({ label: 'LIKED', date: user.lastLiked.likedAt }, user.lastLikedPost)
                                                        : '',
                                                lastInterested:
                                                    user.lastInterested && user.lastInterested._id
                                                        ? interactionTextRender(
                                                              { label: 'INTERESTED', date: user.lastInterested.interestedAt },
                                                              user.lastInterestedPost,
                                                          )
                                                        : '',
                                                lastGoing:
                                                    user.lastGoing && user.lastGoing._id
                                                        ? interactionTextRender({ label: 'GOING', date: user.lastGoing.goingAt }, user.lastGoingPost)
                                                        : '',
                                                follows: user.follows,
                                                endorses: user.endorses,
                                            });
                                            return (
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>
                                                        <Image src={profilePicture} width="40" rounded />
                                                        &nbsp;
                                                        {name}
                                                    </td>
                                                    <td>{user.totalInteractions}</td>
                                                    <td>
                                                        {user.lastLiked &&
                                                            interactionRender({ label: 'LIKED', date: user.lastLiked.likedAt }, user.lastLikedPost)}

                                                        {user.lastInterested &&
                                                            interactionRender(
                                                                { label: 'INTERESTED', date: user.lastInterested.interestedAt },
                                                                user.lastInterestedPost,
                                                            )}

                                                        {user.lastGoing &&
                                                            interactionRender({ label: 'GOING', date: user.lastGoing.goingAt }, user.lastGoingPost)}
                                                    </td>

                                                    <td>{user.follows ? <CheckIconRender /> : <CrossIconRender />}</td>
                                                    <td>{user.invited ? <CheckIconRender /> : <CrossIconRender />}</td>
                                                    <td>{user.endorses ? <CheckIconRender /> : <CrossIconRender />}</td>
                                                    <td>
                                                        <DetailsButtonRender
                                                            onClick={() => {
                                                                handleGoToUserDetailsPage(user.userType, user._id);
                                                            }}
                                                        />{' '}
                                                        &nbsp;
                                                        <RemoveUserFromCultivation
                                                            onClick={() => {
                                                                handleRemoveUsersFromCultivation(user._id);
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col>No User Added To The List</Col>
                        </Row>
                    )}
                </Col>
            </Row>
        </Container>
    );
};
export default CultivationForm;
