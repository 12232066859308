import React, { Component } from 'react';
import { compose, withProps } from 'recompose';
const { withScriptjs, withGoogleMap, GoogleMap, KmlLayer } = require('react-google-maps');
const KMLViewer = compose(
    withProps({
        googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDRr3kcYxjU4O2l1P16mkD3M4tRz_7riq4',
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `80vh` }} />,
        mapElement: <div style={{ height: `80%` }} />,
    }),
    withScriptjs,
    withGoogleMap,
)((props) => {
    const kmlURL = props.kmlURL;
    return (
        <GoogleMap
            defaultZoom={12}
            defaultCenter={{ lat: 39.953194, lng: -75.163345 }}
            // mapTypeId={"terrain"}
        >
            <KmlLayer
                url={kmlURL}
                // url={'https://impacttapestry.com/kml-files/user-service-area-60a12531518f0ca06d9e0ac5.kml'}
                options={{ preserveViewport: false }}
                onClick={(e) => {
                    console.log(e);
                }}
            />
        </GoogleMap>
    );
});

export default KMLViewer;
