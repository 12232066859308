import * as Types from '@Constants/reducer-types';

const initialState = {
    setFeedback: {},
};
const FeedbackReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.SET_FEEDBACK:
            return { ...state, setFeedback: action.payload };
        case Types.CLEAR_FEEDBACK:
            return { ...state, setFeedback: {} };
        default:
            return { ...state };
    }
};
export default FeedbackReducer;
