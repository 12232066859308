import axios from 'axios';
import * as APIPaths from '../constants/api-paths';
class ConversationBlockDA {
    create_conversation_block = (blockInfo) => {
        return axios
            .post(APIPaths.createConversationBlock(), blockInfo, APIPaths.apiConfig())
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err.response.data;
            });
    };
    remove_conversation_block = (userId, blockedUserId) => {
        return axios
            .delete(APIPaths.removeConversationBlock(userId, blockedUserId), APIPaths.apiConfig())
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err.response.data;
            });
    };
    get_one_conversation_block = (userId, blockedUserId) => {
        return axios
            .get(APIPaths.getOneConversationBlock(userId, blockedUserId), APIPaths.apiConfig())
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err.response.data;
            });
    };
    get_all_conversation_blocks = (userId) => {
        return axios
            .get(APIPaths.getAllConversationBlock(userId), APIPaths.apiConfig())
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err.response.data;
            });
    };
}
export default new ConversationBlockDA();
