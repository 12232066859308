import axios from 'axios';
import * as APIPaths from '../constants/api-paths';

class ConnectionDA {
    get_one_connection_by_user = (senderId, receiverId) => {
        return axios
            .get(APIPaths.getOneConnectionByUser(senderId, receiverId), APIPaths.apiConfig())
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err.response.data;
            });
    };

    update_one_connection_by_user = (senderId, receiverId, update) => {
        return axios
            .put(APIPaths.updateOneConnectionByUser(senderId, receiverId), update, APIPaths.apiConfig())
            .then((response) => {
                console.log('🚀 ~ file: connection-da.js ~ line 20 ~ ConnectionDA ~ .then ~ response', response);
                return response.data;
            })
            .catch((err) => {
                console.log('🚀 ~ file: connection-da.js ~ line 24 ~ ConnectionDA ~ err', err);
                return err.response.data;
            });
    };
    get_all_connections = (senderId) => {
        return axios
            .get(APIPaths.getAllConnections(senderId), APIPaths.apiConfig())
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err.response.data;
            });
    };
}
export default new ConnectionDA();
