export const allPostTypes = [
    { id: 1, value: 'event', label: 'Event' },
    { id: 2, value: 'project', label: 'Project' },
    { id: 3, value: 'general', label: 'General Post' },
    { id: 4, value: 'volunteering', label: 'Volunteering' },
    { id: 5, value: 'in-kind', label: 'In-Kind' },
    { id: 6, value: 'advocacy', label: 'Advocacy' },
    { id: 7, value: '', label: '' },
];
export const allSearchablePostTypes = [
    { id: 1, value: 'event', label: 'Event' },
    { id: 2, value: 'project', label: 'Project' },
    { id: 3, value: 'general', label: 'General Post' },
    { id: 4, value: 'volunteering', label: 'Volunteering' },
    { id: 5, value: 'advocacy', label: 'Advocacy' },
];
export const getPostTypeByValue = (value) => {
    return allPostTypes.filter((type) => type.value === value);
};
const fields = {
    title: 'title',
    description: 'description',
    images: 'images',
    impactAreas: 'impactAreas',
    skills: 'skills',
    startDateTime: 'startDateTime',
    endDateTime: 'endDateTime',
    address: 'address',
    expectedRequiredHours: 'expectedRequiredHours',
    topNeed: 'topNeed',
    petitionLink: 'petitionLink',
    isActive: 'isActive',
    requiredItems: 'requiredItems',
    keywords: 'keywords',
};
export const postTypeFields = {
    event: {
        title: 1,
        description: 1,
        images: 1,
        impactAreas: 1,
        startDateTime: 1,
        endDateTime: 1,
        address: 1,
        isActive: 1,
        keywords: 1,
        postURL: 1,
        contact: 1,
        addable: 1,
    },
    project: {
        title: 1,
        description: 1,
        images: 1,
        impactAreas: 1,
        skills: 1,
        expectedRequiredHours: 1,
        startDateTime: 1,
        endDateTime: 1,
        address: 1,
        isActive: 1,
        topNeed: 1,
        keywords: 1,
        postURL: 1,
        contact: 1,
        addable: 1,
    },
    general: { title: 1, description: 1, images: 1, impactAreas: 1, isActive: 1, keywords: 1 },
    volunteering: {
        title: 1,
        description: 1,
        images: 1,
        impactAreas: 1,
        skills: 1,
        startDateTime: 1,
        endDateTime: 1,
        address: 1,
        isActive: 1,
        topNeed: 1,
        keywords: 1,
        postURL: 1,
        contact: 1,
        addable: 1,
    },
    'in-kind': { title: 1, description: 1, images: 1, impactAreas: 1, requiredItems: 1, isActive: 1, topNeed: 1, keywords: 1, postURL: 1, contact: 1 },
    advocacy: {
        title: 1,
        description: 1,
        images: 1,
        petitionLink: 1,
        impactAreas: 1,
        topNeed: 1,
        startDateTime: 1,
        endDateTime: 1,
        address: 1,
        isActive: 1,
        keywords: 1,
        postURL: 1,
        contact: 1,
        addable: 1,
    },
};
export const allPostTypeValues = ['event', 'project', 'general', 'volunteering', 'in-kind', 'advocacy'];
export const allPostTypeLabels = ['Event', 'Project', 'General', 'Volunteering', 'In-Kind', 'Advocacy'];
export const getColorByPostType = (postType) => {
    const colors = {
        event: '#274e13',
        project: '#20124d',
        general: '#1c4587',
        volunteering: '#073763',
        'in-kind': '#0c343d',
        advocacy: '#4c1130',
    };
    return colors[postType] ? colors[postType] : 'gray';
};
export const getAltColorByPostType = (postType) => {
    const colors = {
        event: '#83d658',
        project: '#7556d7',
        general: '#6f9ae1',
        volunteering: '#429df1',
        'in-kind': '#48c0db',
        advocacy: '#d8549a',
    };
    return colors[postType] ? colors[postType] : 'gray';
};
export const getPostTypeName = (value) => {
    const postTypes = {
        event: 'Event',
        project: 'Project',
        general: 'General Post',
        volunteering: 'Volunteering',
        'in-kind': 'In-Kind',
        advocacy: 'Advocacy',
    };
    const name = postTypes[value] ? postTypes[value] : '';
    return name;
};
export const allCalenderPostTypes = [
    { value: 'event', label: 'EVENT' },
    { value: 'project', label: 'PROJECT' },
    { value: 'volunteering', label: 'VOLUNTEERING' },
    { value: 'advocacy', label: 'ADVOCACY' },
];
export const templatePostFields = [
    { id: 1, name: 'postType', label: 'Post Type', serial: 1, selected: true },
    { id: 2, name: 'title', label: 'Title', serial: 2, selected: true },
    { id: 3, name: 'description', label: 'Description', serial: 3, selected: true },
    { id: 4, name: 'images', label: 'Images', serial: 4, selected: true },
    { id: 5, name: 'impactAreas', label: 'Impact Areas (As Hashtag)', serial: 5, selected: true },
    { id: 6, name: 'skills', label: 'Skills (As Hashtag)', serial: 6, selected: true },
    { id: 7, name: 'dateTime', label: 'Start And End Date Time', serial: 7, selected: true },
    { id: 8, name: 'address', label: 'Address', serial: 8, selected: true },
    { id: 9, name: 'expectedRequiredHours', label: 'Expected Required Hours', serial: 9, selected: true },
    { id: 10, name: 'requiredItems', label: 'Required Items', serial: 10, selected: true },
    { id: 11, name: 'topNeed', label: 'Top Need', serial: 11, selected: true },
    { id: 12, name: 'petitionLink', label: 'Petition Link', serial: 12, selected: true },
    { id: 13, name: 'postURL', label: 'Post URL', serial: 13, selected: true },
    { id: 14, name: 'contact', label: 'Contact', serial: 14, selected: true },
    { id: 15, name: 'keywords', label: 'Keywords (As Hashtag)', serial: 15, selected: true },
];
