import axios from 'axios';
import * as APIPaths from '@Constants/api-paths';

class InvitationDA {
    invite_users_to_follow_organization = (userId, values) => {
        return axios
            .post(APIPaths.inviteUsersToFollowOrganization(userId), values, APIPaths.apiConfig())
            .then((response) => response.data)
            .catch((err) => {
                return err.response.data;
            });
    };
}
export default new InvitationDA();
