import React from 'react';
import { Alert } from 'react-bootstrap';

export const FileMaxSizeErrorModal = (props) => {
    const show = props.show;
    const setShow = props.setShow;
    if (show) {
        return (
            <Alert variant="danger" onClose={() => setShow(false)} dismissible>
                <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                <p>
                    Change this and that and try again. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Cras
                    mattis consectetur purus sit amet fermentum.
                </p>
            </Alert>
        );
    } else return <></>;
};
