/* eslint-disable */

import React, { useEffect, useState } from 'react';
import NotificationList from '@Components/notification/notification-list';
import LoadingAnim from '@Components/form_template/loading-anim';
import { connect } from 'react-redux';
import {
    getAllNotifications,
    updateNotification,
    updateNotificationLocal,
    deleteNotification,
    updateAllNotifications,
    readAllNotifications,
} from '@Actions/notification-action';
import * as RoutePaths from '@Constants/route-paths';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { NotificationManager } from 'react-notifications';
const ManageNotifications = (props) => {
    const [userId, setUserId] = useState('');
    const [loading, setLoading] = useState(false);
    const [deletedNotifications, setDeletedNotifications] = useState({});
    const [deleteTimeOut, setDeleteTimeOut] = useState({});
    const getInitialInfo = (userId) => {
        setLoading(true);
        props.dispatch(getAllNotifications(userId));
        setLoading(false);
    };
    useEffect(() => {
        const user = props.auth.user;
        if (user && user._id);
        setUserId(user._id);
        getInitialInfo(user._id);
    }, [props.auth]);

    const handleGoToNotificationDetails = (notification) => {
        const type = notification.type;
        if (['like', 'interest', 'going'].includes(type)) {
            props.history.push(RoutePaths.postDetailsPage(notification.postId.postType, notification.postId._id));
        } else if (type === 'new-post') {
            props.history.push(RoutePaths.postDetailsPage(notification.postId.postType, notification.postId._id));
        } else if (type === 'friend-request') {
            props.history.push(RoutePaths.communityRequestListPage('received'));
        } else if (type === 'friend-accept') {
            props.history.push(RoutePaths.communityFriendListPage);
        } else if (type === 'follow') {
            props.history.push(RoutePaths.communityFollowerListPage);
        } else if (type === 'endorsement') {
            props.history.push(RoutePaths.communityEndorserListPage);
        } else if (type === 'membership-request') {
        } else if (type === 'membership-accept') {
        } else {
            props.history.push(RoutePaths.userDetailsPage(notification.userType, notification.senderId));
        }
        const notificationId = notification._id;
        props.dispatch(updateNotification(notificationId, { isRead: true }));
        props.dispatch(updateNotificationLocal(notificationId, true));
    };

    const handleNotificationRead = (notificationId) => {
        props.dispatch(updateNotification(notificationId, { isRead: true }));
        props.dispatch(updateNotificationLocal(notificationId, true));
    };
    const handleNotificationUnread = (notificationId) => {
        props.dispatch(updateNotification(notificationId, { isRead: false }));
        props.dispatch(updateNotificationLocal(notificationId, false));
    };
    const handleNotificationDelete = (notificationId) => {
        let deletedNotifications_ = deletedNotifications;
        deletedNotifications_[notificationId] = true;
        setDeletedNotifications({ ...deletedNotifications_ });

        const timeout = setTimeout(() => {
            if (deletedNotifications[notificationId]) {
                props.dispatch(deleteNotification(notificationId));
            }
        }, 10000);
        let deleteTimeOut_ = deleteTimeOut;
        deleteTimeOut_[notificationId] = timeout;
        console.log('🚀 ~ file: manage-notifications.js ~ line 81 ~ handleNotificationDelete ~ deleteTimeOut_', deleteTimeOut_);
        setDeleteTimeOut({ ...deleteTimeOut_ });
    };
    useEffect(() => {
        const { success, message } = props.deleteNotificationResponse;
        if (success) {
            NotificationManager.success(message, 'success');
            getInitialInfo(userId);
        } else if (success === false) {
            NotificationManager.error(message, 'Failed');
        }
    }, [props.deleteNotificationResponse]);
    const handleReadAllNotifications = () => {
        props.dispatch(updateAllNotifications({ isRead: true }));
        props.dispatch(readAllNotifications());
    };
    const handleUndoNotificationDelete = (notificationId) => {
        let deletedNotifications_ = deletedNotifications;
        deletedNotifications_[notificationId] = false;
        setDeletedNotifications({ ...deletedNotifications_ });
        clearTimeout(deleteTimeOut[notificationId]);
    };
    if (loading) return <LoadingAnim />;
    else {
        return (
            <NotificationList
                allNotifications={props.getAllNotificationsResponse.success ? props.getAllNotificationsResponse.notifications : []}
                handleReadAllNotifications={handleReadAllNotifications}
                handleGoToNotificationDetails={handleGoToNotificationDetails}
                handleNotificationRead={handleNotificationRead}
                handleNotificationUnread={handleNotificationUnread}
                handleNotificationDelete={handleNotificationDelete}
                deletedNotifications={deletedNotifications}
                handleUndoNotificationDelete={handleUndoNotificationDelete}
            />
        );
    }
};
const mapStateToProps = (state) => {
    const getAllNotificationsResponse = state.Notification.getAllNotifications;
    const deleteNotificationResponse = state.Notification.deleteNotification;
    return {
        getAllNotificationsResponse,
        deleteNotificationResponse,
    };
};
export default connect(mapStateToProps, null)(ManageNotifications);
