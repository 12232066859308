import React, { useState } from 'react';
import { Container, Image, Row, Col, Button, Table, Dropdown, Modal } from 'react-bootstrap';
import { CSVLink, CSVDownload } from 'react-csv';
const PostCSVHeaders = [
    { label: 'Post Type', key: 'postType' },
    { label: 'Post Title', key: 'title' },
    { label: 'Created At ', key: 'createdAt' },
    { label: 'Published ', key: 'isPublished' },
];

const ManagePostHeader = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const allPosts = props.allPosts;
    let postCSVData = [];
    if (allPosts && allPosts.length > 0) {
        postCSVData = allPosts.map((post) => {
            return {
                postType: post.postType,
                title: post.title,
                createdAt: post.createdAt,
                isPublished: post.isActive,
            };
        });
    }
    const ButtonRender = (variant, type, label) => {
        return (
            <Button
                style={{ marginTop: 5, width: '100%' }}
                variant={variant}
                size="sm"
                onClick={() => {
                    props.handleGoToPostCreate(type);
                }}
            >
                {label}
            </Button>
        );
    };
    return (
        <Row>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Post Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm="6">{ButtonRender('info', 'event', 'Event')}</Col>
                        <Col sm="6">{ButtonRender('info', 'project', 'Project')}</Col>
                        <Col sm="6">{ButtonRender('info', 'general', 'General Post')}</Col>
                        <Col sm="6">{ButtonRender('info', 'volunteering', 'Volunteering')}</Col>
                        <Col sm="6">{ButtonRender('info', 'in-kind', 'In-Kind')}</Col>
                        <Col sm="6">{ButtonRender('info', 'advocacy', 'Advocacy')}</Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} size="sm">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <Col sm="6">{allPosts && allPosts.length > 0 ? <h4>{allPosts.length} Posts Found</h4> : <h4>No Post Found</h4>}</Col>
            <Col sm="6" className="right-align">
                <Button variant="primary" onClick={handleShow} size="sm">
                    Create Post
                </Button>
                &nbsp;
                <CSVLink className="btn btn-outline-primary btn-sm" data={postCSVData} headers={PostCSVHeaders} filename={'all-posts.csv'}>
                    Export To CSV
                </CSVLink>
            </Col>
        </Row>
    );
};
export default ManagePostHeader;
