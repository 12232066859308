import axios from 'axios';
import * as APIPaths from '@Constants/api-paths';

class ConversationDA {
    create_new_conversation = (conversation) => {
        return axios
            .post(APIPaths.createConversation, conversation, APIPaths.apiConfig())
            .then((response) => response.data)
            .catch((err) => {
                return err.response.data;
            });
    };
    create_new_message = (message) => {
        return axios
            .post(APIPaths.createOneMessage, message, APIPaths.apiConfig())
            .then((response) => response.data)
            .catch((err) => {
                return err.response.data;
            });
    };
    get_all_conversations_by_user = (userId) => {
        return axios
            .get(APIPaths.getAllConversationsByUser(userId), APIPaths.apiConfig())
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err.response.data;
            });
    };
    get_conversation = (conversationId) => {
        return axios
            .get(APIPaths.getOneConversation(conversationId), APIPaths.apiConfig())
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err.response.data;
            });
    };
    get_conversations_count_by_user = (userId) => {
        return axios
            .get(APIPaths.getConversationCountByUser(userId), APIPaths.apiConfig())
            .then((response) => response.data)
            .catch((err) => {
                return err.response.data;
            });
    };
    send_messages_to_all_connected = (userId, connection, connectionType, messageText, onlyConnected = false) => {
        return axios
            .post(APIPaths.sendMessageToAllConnected(userId, connection, connectionType, onlyConnected), { text: messageText }, APIPaths.apiConfig())
            .then((response) => response.data)
            .catch((err) => {
                return err.response.data;
            });
    };
    send_message_to_multiple_users = (userId, values) => {
        return axios
            .post(APIPaths.sendMessageToMultipleUsers(userId), values, APIPaths.apiConfig())
            .then((response) => response.data)
            .catch((err) => {
                return err.response.data;
            });
    };
    get_user_connectivity = (userId) => {
        return axios
            .get(APIPaths.getUserConnectivities(userId), APIPaths.apiConfig())
            .then((response) => response.data)
            .catch((err) => {
                return err.response.data;
            });
    };
    delete_one_conversation = (conversationId) => {
        return axios
            .delete(APIPaths.deleteOneConversation(conversationId), APIPaths.apiConfig())
            .then((response) => response.data)
            .catch((err) => {
                return err.response.data;
            });
    };
}

export default new ConversationDA();
