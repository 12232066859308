import React, { useEffect, useState } from 'react';
import { Button, Modal, Container, Row, Col } from 'react-bootstrap';
import { UpArrowButtonRender, DownArrowButtonRender } from '../form_template/buttons-render';
import { getPostTypeName } from '../../constants/post-types';
import moment from 'moment';
const socialMedias = {
    facebook: 'FACEBOOK',
    twitter: 'TWITTER',
    instagram: 'INSTAGRAM',
    linkedIn: 'LINKED IN',
};

const SocialMediaTemplateModal = ({
    template,
    templateModal,
    selectedTemplate,
    setTemplateModal,
    handleMoveItemPosition,
    handleToggleItem,
    formState,
    postType,
    description,
}) => {
    let socialMediaTemplate = template[selectedTemplate];
    console.log(socialMediaTemplate);
    const [previewBody, setPreviewBody] = useState('');

    useEffect(() => {
        socialMediaTemplate = template[selectedTemplate];
        console.log(socialMediaTemplate);
        let fullBody = '';
        if (formState && socialMediaTemplate && socialMediaTemplate.length > 0) {
            for (let i = 0; i < socialMediaTemplate.length; i++) {
                let field = socialMediaTemplate[i];

                if (field.selected && formState[field.name] && field.name !== 'description') {
                    switch (field.name) {
                        case 'postType':
                            fullBody += getPostTypeName(postType);
                            fullBody += '<br/>';
                            break;
                        case 'impactAreas':
                        case 'skills':
                        case 'keywords':
                            if (formState[field.name].length > 0) {
                                fullBody += fieldProperLabel[field.name] + ': ';
                                fullBody += badgesToHashtagString(formState[field.name]);
                                fullBody += '<br/>';
                            } else {
                            }
                            break;

                        case 'address':
                            if (formState['address'] && formState['address'].fullAddress) {
                                fullBody += 'Address: ' + formState['address'].fullAddress;
                                fullBody += '<br/>';
                            }
                            break;
                        case 'title':
                            fullBody += formState[field.name];
                            fullBody += '<br/>';
                            break;
                        case 'expectedRequiredHours':
                        case 'petitionLink':
                        case 'postURL':
                        case 'contact':
                            fullBody += field.label + ': ' + formState[field.name];
                            fullBody += '<br/>';
                            break;
                        case 'topNeed':
                            fullBody += `${field.label}: ${formState[field.name] ? 'YES' : 'NO'}`;
                            fullBody += '<br/>';
                            break;

                        default:
                            fullBody += '';
                    }
                } else if (field.selected && field.name === 'description') {
                    console.log('Came here');
                    let description_ = description;
                    if (formState?.socialMediaSharing && formState?.socialMediaSharing[selectedTemplate]?.description?.length > 0) {
                        description_ = formState.socialMediaSharing[selectedTemplate].description;
                    }
                    fullBody += description_;
                    fullBody += '<br/>';
                } else if (field.selected && field.name === 'postType') {
                    fullBody += getPostTypeName(postType);
                    fullBody += '<br/>';
                } else if (field.selected && field.name === 'dateTime') {
                    if (formState['startDateTime']) {
                        fullBody += 'Start Time: ' + moment(formState['startDateTime']).format('LLL');
                    }
                    if (formState['endDateTime']) {
                        fullBody += ' End Time: ' + moment(formState['endDateTime']).format('LLL');
                    }
                    fullBody += '<br/>';
                } else fullBody += '';
            }
        }
        setPreviewBody(fullBody);
    }, [socialMediaTemplate, template, formState, description]);
    const [characterLimit, setCharacterLimit] = useState(10000000);
    let totalCharacter = 0;
    useEffect(() => {
        if (selectedTemplate === 'twitter') {
            setCharacterLimit(230);
        } else {
            setCharacterLimit(10000000);
        }
    }, [selectedTemplate]);
    return (
        <Modal
            size="xl"
            style={{ zIndex: 100000 }}
            show={templateModal}
            onHide={() => {
                setTemplateModal(false);
            }}
        >
            <Modal.Header closeButton>MANAGE {socialMedias[selectedTemplate]} TEMPLATE & PREVIEW</Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col md="4">
                            {socialMediaTemplate &&
                                socialMediaTemplate.length > 0 &&
                                socialMediaTemplate.map((field, i) => {
                                    return (
                                        <Row key={i} style={{ borderBottom: '1px dotted gray', padding: 3 }}>
                                            <Col md="4">
                                                {i !== 0 && (
                                                    <UpArrowButtonRender
                                                        onClick={() => {
                                                            handleMoveItemPosition(selectedTemplate, i, 'up');
                                                        }}
                                                    />
                                                )}
                                                &nbsp;
                                                {i < socialMediaTemplate.length - 1 && (
                                                    <DownArrowButtonRender
                                                        onClick={() => {
                                                            handleMoveItemPosition(selectedTemplate, i, 'down');
                                                        }}
                                                    />
                                                )}
                                            </Col>
                                            <Col md="8">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value=""
                                                    id={'social-media-template_' + field.name}
                                                    checked={field.selected}
                                                    onChange={() => {
                                                        handleToggleItem(selectedTemplate, i, field.name);
                                                    }}
                                                />
                                                <label className="form-check-label" htmlFor={'social-media-template_' + field.name}>
                                                    {field.label}
                                                </label>
                                            </Col>
                                        </Row>
                                    );
                                })}
                        </Col>
                        <Col md="8">
                            <h4>PREVIEW</h4>
                            {formState ? (
                                <div className="social-media-preview-box">
                                    {previewBody.split('<br/>').map((body, i) => {
                                        return (
                                            <React.Fragment key={i}>
                                                {body.split(' ').map((word, j) => {
                                                    if (totalCharacter > characterLimit - 25 && totalCharacter < characterLimit) {
                                                        totalCharacter = 0;
                                                    }
                                                    totalCharacter += word.length + 1;

                                                    if (word[0] === '#') {
                                                        return (
                                                            <mark key={j} className="hashtag">
                                                                {word.trim()}{' '}
                                                                {totalCharacter > characterLimit - 25 && totalCharacter < characterLimit ? (
                                                                    <>
                                                                        <hr />
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </mark>
                                                        );
                                                    } else {
                                                        return (
                                                            <React.Fragment key={j}>
                                                                {word.trim()}{' '}
                                                                {totalCharacter > characterLimit - 25 && totalCharacter < characterLimit ? (
                                                                    <>
                                                                        <hr />
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </React.Fragment>
                                                        );
                                                    }
                                                })}

                                                <br />
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                            ) : (
                                <></>
                            )}

                            <br />
                            {/* <div className="debug-area">
                                DEBUG AREA(will be removed later)
                                <br />
                                
                            </div> */}
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
};
export default SocialMediaTemplateModal;
const badgesToHashtagString = (badges) => {
    let labelString = '';
    for (let i = 0; i < badges.length; i++) {
        labelString += `#${badges[i].label.split(' ').join('_')}  `;
    }
    return labelString;
};
const fieldProperLabel = {
    impactAreas: 'Impact Areas',
    skills: 'Skills',
    keywords: 'Keywords',
};
