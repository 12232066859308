/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { defaultIndividualProfilePicture } from '@Constants/default-images';
import PostDetails from '@Components/post/post-details';
import { connect } from 'react-redux';
import { getAllCommittedPersons } from '@Actions/post-action';
import { NotificationManager } from 'react-notifications';
import CommittedPersonsModal from '@Components/post/committed-persons-list';
import { searchUsersByName } from '@Actions';
import {
    getPostById,
    likePost,
    cancelLikePost,
    interestedPost,
    cancelInterestedPost,
    goingPost,
    cancelGoingPost,
    changePostInterest,
} from '@Actions/post-action';
import { postManagePage } from '@Constants/route-paths';
import { checkIfPostAddedToScheduler, addPostToScheduler, removePostFromScheduler } from '@Actions/scheduler-action';
import { createOneShare } from '@Actions/share-action';
import PostInviteModal from '@Components/invitation/post-invite-modal';
import { getAllConnectedUsersId, getCoinSpendingSummary, inviteUsersToViewPost } from '@Actions/user-action';
import { countConnectedAndUnConnectedUsers } from '@Actions/wallet-action';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const DisplayPost = (props) => {
    const [loading, setLoading] = useState(false);
    const [url, setUrl] = useState('');
    const [userId, setUserId] = useState('');
    const [committedModal, setCommittedModal] = useState(false);
    const [committedLoading, setCommittedLoading] = useState(false);
    const [committedList, setCommittedList] = useState([]);
    const [postInviteModal, setPostInviteModal] = useState(false);
    const [userSearchText, setUserSearchText] = useState('');
    const [toBeInvitedUsers, setToBeInvitedUsers] = useState([]);
    useEffect(() => {
        const getInitialInfo = (postId) => {
            const user = props.auth.user;
            //console.log('🚀 ~ file: display-post.js ~ line 42 ~ getInitialInfo ~ user', user);
            if (user && user._id) {
                setUserId(user._id);
                props.dispatch(checkIfPostAddedToScheduler(user._id, postId));
                props.dispatch(getAllConnectedUsersId(user._id));
            }
            setLoading(true);
            props.dispatch(getPostById(postId));
            props.dispatch(getCoinSpendingSummary());
            setUrl(window.location.href);
            setLoading(false);
        };
        const postId = props.match.params.postId;
        if (postId) getInitialInfo(postId);
        else {
        }
    }, [props.auth]);
    const handleClickCommittedButtons = (postId, type) => {
        //console.log('🚀 ~ file: home.js ~ line 20 ~ handleClickCommittedButtons ~ postId, type', postId, type);
        setCommittedLoading(true);
        getAllCommittedPersons(postId, type).then((response) => {
            //console.log('🚀 ~ file: home.js ~ line 22 ~ getAllCommittedPersons ~ response', response);
            setCommittedModal(true);
            setCommittedLoading(false);
            if (response.success) {
                setCommittedList(response.users);
            } else {
                alert('User List Not Found');
            }
        });
    };
    const handleGotoManagePosts = () => {
        props.history.push(postManagePage);
    };
    const handleLikePost = (postId) => {
        props.dispatch(likePost(postId));
        props.dispatch(changePostInterest(postId, userId, 'like'));
    };
    const handleCancelLikePost = (postId) => {
        props.dispatch(cancelLikePost(postId));
        props.dispatch(changePostInterest(postId, userId, 'unlike'));
    };

    const handleInterestedPost = (postId) => {
        props.dispatch(interestedPost(postId));
        props.dispatch(changePostInterest(postId, userId, 'interested'));
    };
    const handleCancelInterestedPost = (postId) => {
        //console.log('🚀 ~ file: home.js ~ line 46 ~ handleCancelInterestedPost ~ postId', postId);
        props.dispatch(cancelInterestedPost(postId));
        props.dispatch(changePostInterest(postId, userId, 'uninterested'));
    };

    const handleGoingPost = (postId) => {
        props.dispatch(goingPost(postId));
        props.dispatch(changePostInterest(postId, userId, 'going'));
    };
    const handleCancelGoingPost = (postId) => {
        props.dispatch(cancelGoingPost(postId));
        props.dispatch(changePostInterest(postId, userId, 'ungoing'));
    };
    const handleAddPostToScheduler = () => {
        props.dispatch(addPostToScheduler(userId, props.match.params.postId));
    };
    const handleRemovePostFromScheduler = () => {
        props.dispatch(removePostFromScheduler(userId, props.match.params.postId));
    };
    const handleCreateOneShare = () => {
        props.dispatch(
            createOneShare({
                userId: userId,
                postId: props.match.params.postId,
            }),
        );
        NotificationManager.success('Post is shared successfully', 'success');
    };
    const getUsers = async (searchText) => {
        let connectedUsersId = {};
        let { success } = props.getAllConnectedUsersIdResponse;
        if (success) {
            connectedUsersId = props.getAllConnectedUsersIdResponse.connectedUsersId;
        }
        //console.log('🚀 ~ file: display-post.js ~ line 124 ~ getUsers ~ connectedUsersId', connectedUsersId);
        if (searchText.length > 2) {
            const users = await searchUsersByName(searchText).then((response) => {
                if (response.success) {
                    return response.users;
                } else return [];
            });
            if (users && users.length > 0) {
                let allUsers = [];

                if (users[0]) {
                    allUsers.push(...users[0].users.slice(0, 20));
                }
                if (users[1]) {
                    allUsers.push(...users[1].users.slice(0, 20));
                }
                //console.log('🚀 ~ file: individual-membership.js ~ line 43 ~ newPromise ~ organizations', allUsers);
                allUsers = allUsers.map((user) => {
                    let connected = false;
                    if (connectedUsersId.includes(user._id)) connected = true;
                    return {
                        value: user._id,
                        label: (
                            <Row>
                                <Col sm={2}>
                                    <img src={user.profilePicture ? user.profilePicture : defaultIndividualProfilePicture} height="30px" width="30px" />
                                </Col>
                                <Col sm={10}>
                                    {user.userType === 'individual' ? user.concatNameWithSpace : user.name}&nbsp;
                                    <small>{connected ? 'Connected' : 'Not Connected'}</small>
                                </Col>
                            </Row>
                        ),
                    };
                });
                //console.log('🚀 ~ file: individual-membership.js ~ line 43 ~ newPromise ~ organizations', allUsers);
                return allUsers;
            } else return [];
        } else return [];
    };
    const promiseOptions = (inputValue) =>
        new Promise((resolve) => {
            setTimeout(() => {
                resolve(getUsers(inputValue));
            }, 1000);
        });
    const handleChangeSearchText = async (text) => {
        //console.log(text);
        setUserSearchText(text);
    };
    const handleAddUserToInvitationList = (user) => {
        const toBeInvitedUsers_ = toBeInvitedUsers;
        toBeInvitedUsers_.push(user);
        setToBeInvitedUsers([...toBeInvitedUsers_]);
    };
    const handleDeleteUserFromInvitationList = (idx) => {
        const toBeInvitedUsers_ = toBeInvitedUsers;
        toBeInvitedUsers_.splice(idx, 1);
        setToBeInvitedUsers([...toBeInvitedUsers_]);
    };
    const handleInviteUsers = (users, message) => {
        //console.log('🚀 ~ file: display-post.js ~ line 168 ~ DisplayPost ~ users, message', users, message);
        if (!users || (users && users.length === 0)) {
            alert('No user was selected to send invitation');
        } else {
            const userList = users.map((user) => user.value);
            let { success } = props.getAllConnectedUsersIdResponse;
            if (success) {
                const { connectedUsersId } = props.getAllConnectedUsersIdResponse;
                const { totalConnected, totalNotConnected } = countConnectedAndUnConnectedUsers(userList, connectedUsersId);
                success = props.getCoinSpendingSummaryResponse;
                if (success) {
                    const { coinPerInvitation } = props.getCoinSpendingSummaryResponse.coinSpendingSummary;
                    const totalCoins = parseInt(totalNotConnected * coinPerInvitation);
                    if (totalCoins > 0) {
                        confirmAlert({
                            title: 'Warning',
                            message: `You are inviting total ${totalNotConnected} users. This will cost you ${parseInt(
                                totalNotConnected * coinPerInvitation,
                            )} Coins. Are you sure?`,
                            buttons: [
                                {
                                    label: 'Yes',
                                    onClick: () => {
                                        props.dispatch(
                                            inviteUsersToViewPost(userId, {
                                                usersToBeInvited: userList,
                                                postLink: window.location.href,
                                                invitationMessage: message,
                                            }),
                                        );
                                    },
                                },
                                {
                                    label: 'No',
                                    onClick: () => {
                                        setPostInviteModal(true);
                                    },
                                },
                            ],
                        });
                    } else {
                        props.dispatch(
                            inviteUsersToViewPost(userId, {
                                usersToBeInvited: userList,
                                postLink: window.location.href,
                                invitationMessage: message,
                            }),
                        );
                    }
                }
            }
        }
    };
    useEffect(() => {
        const { success, message } = props.inviteUsersToViewPostResponse;
        if (success) {
            NotificationManager.success(message, 'success');
        } else if (success === false) {
            NotificationManager.error(message, 'Failed');
        }
    }, [props.inviteUsersToViewPostResponse]);
    useEffect(() => {
        const { success, message } = props.getAddPostToSchedulerResponse;
        if (success) {
            NotificationManager.success(message, 'success');
            props.dispatch(checkIfPostAddedToScheduler(userId, props.match.params.postId));
        } else if (success === false) {
            NotificationManager.error(message, 'Failed');
        }
    }, [props.getAddPostToSchedulerResponse]);
    useEffect(() => {
        const { success, message } = props.getRemovePostFromSchedulerResponse;
        if (success) {
            NotificationManager.success(message, 'success');
            props.dispatch(checkIfPostAddedToScheduler(userId, props.match.params.postId));
        } else if (success === false) {
            NotificationManager.error(message, 'Failed');
        }
    }, [props.getRemovePostFromSchedulerResponse]);
    return (
        <>
            <CommittedPersonsModal
                committedModal={committedModal}
                committedLoading={committedLoading}
                committedList={committedList}
                setCommittedModal={setCommittedModal}
            />
            <PostInviteModal
                postInviteModal={postInviteModal}
                setPostInviteModal={setPostInviteModal}
                promiseOptions={promiseOptions}
                handleChangeSearchText={handleChangeSearchText}
                userSearchText={userSearchText}
                toBeInvitedUsers={toBeInvitedUsers}
                setToBeInvitedUsers={setToBeInvitedUsers}
                handleAddUserToInvitationList={handleAddUserToInvitationList}
                handleDeleteUserFromInvitationList={handleDeleteUserFromInvitationList}
                handleInviteUsers={handleInviteUsers}
            />
            <PostDetails
                post={props.getPostResponse.success ? props.getPostResponse.post : {}}
                userId={userId}
                handleGotoManagePosts={handleGotoManagePosts}
                handleLikePost={handleLikePost}
                handleCancelLikePost={handleCancelLikePost}
                handleInterestedPost={handleInterestedPost}
                handleCancelInterestedPost={handleCancelInterestedPost}
                handleGoingPost={handleGoingPost}
                handleCancelGoingPost={handleCancelGoingPost}
                handleClickCommittedButtons={handleClickCommittedButtons}
                getCheckIfPostAddedToSchedulerResponse={props.getCheckIfPostAddedToSchedulerResponse}
                handleAddPostToScheduler={handleAddPostToScheduler}
                handleRemovePostFromScheduler={handleRemovePostFromScheduler}
                url={url}
                handleCreateOneShare={handleCreateOneShare}
                setPostInviteModal={setPostInviteModal}
            />
        </>
    );
};
const mapStateToProps = (state) => {
    //console.log(state);
    const getPostResponse = state.Post.getPost ? state.Post.getPost : {};
    const getCheckIfPostAddedToSchedulerResponse = state.Scheduler.checkIfPostAddedToScheduler;
    const getAddPostToSchedulerResponse = state.Scheduler.addPostToScheduler;
    const getRemovePostFromSchedulerResponse = state.Scheduler.removePostFromScheduler;
    const setPostShareResponse = state.Share.setShare;
    const getAllConnectedUsersIdResponse = state.User.getAllConnectedUsersId;
    const getCoinSpendingSummaryResponse = state.User.getCoinSpendingSummary;
    const inviteUsersToViewPostResponse = state.User.inviteUsersToViewPost;
    //console.log('🚀 ~ file: display-post.js ~ line 281 ~ mapStateToProps ~ inviteUsersToViewPostResponse', inviteUsersToViewPostResponse);
    return {
        getPostResponse,
        getCheckIfPostAddedToSchedulerResponse,
        getAddPostToSchedulerResponse,
        getRemovePostFromSchedulerResponse,
        setPostShareResponse,
        getAllConnectedUsersIdResponse,
        getCoinSpendingSummaryResponse,
        inviteUsersToViewPostResponse,
    };
};
export default connect(mapStateToProps, null)(DisplayPost);
