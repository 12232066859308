/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import LoadingAnim from '@Components/form_template/loading-anim';
import OrganizationSocialMediaForm from '@Components/organization/organization-social-media-form';
import {
    connectFacebook,
    disconnectFacebook,
    getAllFacebookPagesByUser,
    connectLinkedIn,
    disconnectLinkedIn,
    connectTwitter,
    disconnectTwitter,
    getSelectedFacebookPageByUser,
    setSelectedFacebookPageByUser,
    setTwitterAccessToken,
    clearConnectSocialMedia,
    getSocialMediaTemplate,
    setSocialMediaTemplate,
    clearSocialMediaTemplate,
    getFacebookAccount,
    getTwitterAccount,
    getLinkedInAccount,
} from '../../actions/social-media-action';
import { NotificationManager } from 'react-notifications';
import FacebookPageSetupModal from '../../components/organization/organization-facebook-settings-modal';
import { Button } from 'react-bootstrap';
import SocialMediaDA from '../../data_accesses/social-media-da';
import queryString from 'query-string';
import SocialMediaTemplate from '../../components/organization/organization-social-media-template';
import FacebookForm from '../../components/social_media/facebook-form';
import TwitterForm from '../../components/social_media/twitter-form';
import LinkedInForm from '../../components/social_media/linked-in-form';
import InstagramForm from '../../components/social_media/instagram-form';

const OrganizationSocialMedia = (props) => {
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState();
    const [fbs, setfbs] = useState({});
    const [facebookSettingModal, setFacebookSettingModal] = useState(false);
    const [templateModal, setTemplateModal] = useState(false);
    const [templateType, setTemplateType] = useState('');

    const getInitialInfo = (userId) => {
        props.dispatch(getAllFacebookPagesByUser(userId));
        props.dispatch(getSelectedFacebookPageByUser(userId));
        props.dispatch(getFacebookAccount());
        props.dispatch(getTwitterAccount());
        props.dispatch(getLinkedInAccount());
    };
    useEffect(() => {
        const user = props.auth.user;
        setUserId(user._id);
        getInitialInfo(user._id);
    }, [props.auth]);

    // FACEBOOK
    const handleFacebookConnectResponse = (response) => {
        const { userID, accessToken } = response;
        console.log('🚀 ~ file: organization-social-media.js ~ line 57 ~ handleFacebookConnectResponse ~ userID, accessToken', response);
        props.dispatch(connectFacebook({ userID, accessToken }));
    };
    const handleFacebookRefresh = () => {
        window.FB.getLoginStatus(function (response) {
            if (response.status === 'connected') {
                var uid = response.authResponse.userID;
                var accessToken = response.authResponse.accessToken;
            } else if (response.status === 'not_authorized') {
            } else {
            }
        });
    };
    const handleFacebookDisconnectResponse = () => {
        // window.FB.init({
        //     appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        //     cookie: true,
        //     xfbml: true,
        //     version: 'v8.0',
        // });

        // window.FB.api('/me/permissions', 'delete', null, (response) => {
        //     window.FB.logout();
        // });

        props.dispatch(disconnectFacebook());
    };
    const handleSelectedFacebookPageSubmit = (selectedPage) => {
        let page = {
            pageName: selectedPage.label,
            pageId: selectedPage.value,
        };
        if (selectedPage.value === '000000000000000') {
            page.postFromPage = false;
        } else {
            page.postFromPage = true;
        }
        props.dispatch(setSelectedFacebookPageByUser(userId, page));
    };
    useEffect(() => {
        const { success } = props.connectFacebookResponse;
        if (success) {
            NotificationManager.success('Facebook connected successfully.', 'success');
            props.dispatch(clearConnectSocialMedia());
            props.dispatch(getFacebookAccount());
        } else if (success === false) {
            NotificationManager.error('Facebook not connected', 'Failed');
            props.dispatch(clearConnectSocialMedia());
        }
    }, [props.connectFacebookResponse]);
    useEffect(() => {
        const { success } = props.setSelectedFacebookPageByUserResponse;
        if (success) {
            NotificationManager.success('Facebook Info Saved.', 'success');
            props.dispatch(clearConnectSocialMedia());
        } else if (success === false) {
            NotificationManager.error('Facebook Info Not Saved.', 'Failed');
            props.dispatch(clearConnectSocialMedia());
        }
    }, [props.setSelectedFacebookPageByUserResponse]);
    const handleFacebookSettingModalOpen = () => {
        setFacebookSettingModal(true);
    };
    // LINKED IN
    const handleLinkedInConnectResponse = (error, code, redirectUri, a) => {
        props.dispatch(connectLinkedIn({ code, redirectURL: redirectUri }));
    };
    useEffect(() => {
        const { success } = props.connectLinkedInResponse;
        if (success) {
            NotificationManager.success('Linked In connected successfully.', 'success');
            props.dispatch(clearConnectSocialMedia());
            props.dispatch(getLinkedInAccount());
        } else if (success === false) {
            NotificationManager.error('Linked In not connected.', 'Failed');
            props.dispatch(clearConnectSocialMedia());
        }
    }, [props.connectLinkedInResponse]);
    const handleLinkedInDisconnect = () => {
        props.dispatch(disconnectLinkedIn());
    };
    useEffect(() => {
        const { success } = props.disconnectLinkedInResponse;
        if (success) {
            NotificationManager.success('Linked In disconnected successfully.', 'success');
            props.dispatch(clearConnectSocialMedia());
            props.dispatch(getLinkedInAccount());
        } else if (success === false) {
            NotificationManager.error('Linked In not disconnected', 'Failed');
            props.dispatch(clearConnectSocialMedia());
        }
    }, [props.disconnectLinkedInResponse]);
    // INSTAGRAM
    const responseInstagram = (response) => {};

    // TWITTER
    const responseTwitter = (err, data) => {
        if (err) {
            alert('UNKNOWN ERROR');
        } else {
            props.dispatch(connectTwitter(data));
        }
    };
    const handleTwitterLogin = () => {
        const response = SocialMediaDA.request_twitter_token(window.location.href)
            .then((response) => {
                const { oauth_token, oauth_token_secret } = response;
                window.location.href = `https://api.twitter.com/oauth/authenticate?oauth_token=${oauth_token}`;
            })
            .catch((err) => err.response.data);
    };
    useEffect(() => {
        const { oauth_token, oauth_verifier } = queryString.parse(window.location.search);
        if (oauth_token && oauth_verifier) {
            props.dispatch(setTwitterAccessToken({ oauth_token, oauth_verifier }));
        }
    }, []);
    useEffect(() => {
        const { success } = props.setTwitterAccessTokenResponse;
        if (success) {
            NotificationManager.success('Twitter connected successfully.', 'success');
            props.dispatch(clearConnectSocialMedia());
            props.dispatch(getTwitterAccount());
            props.history.push('/edit/organization/social-media');
        } else if (success === false) {
            NotificationManager.error('Twitter not connected', 'Failed');
            props.dispatch(clearConnectSocialMedia());
        }
    }, [props.setTwitterAccessTokenResponse]);
    const handleTwitterDisconnect = () => {
        props.dispatch(disconnectTwitter());
    };
    useEffect(() => {
        const { success } = props.disconnectTwitterResponse;
        if (success) {
            NotificationManager.success('Twitter disconnected successfully.', 'success');
            props.dispatch(clearConnectSocialMedia());
            props.dispatch(getTwitterAccount());
        } else if (success === false) {
            NotificationManager.error('Twitter not disconnected', 'Failed');
            props.dispatch(clearConnectSocialMedia());
        }
    }, [props.disconnectTwitterResponse]);
    useEffect(() => {
        console.log(props.disconnectFacebookResponse);
        const { success } = props.disconnectFacebookResponse;
        if (success) {
            NotificationManager.success('Facebook disconnected successfully.', 'success');
            props.dispatch(clearConnectSocialMedia());
            props.dispatch(getFacebookAccount());
        } else if (success === false) {
            NotificationManager.error('Facebook not disconnected', 'Failed');
            props.dispatch(clearConnectSocialMedia());
        }
    }, [props.disconnectFacebookResponse]);
    // TEMPLATE
    const handleSocialMediaTemplate = (templateType, templateModal) => {
        setTemplateType(templateType);
        setTemplateModal(templateModal);
        props.dispatch(getSocialMediaTemplate(templateType, userId));
    };
    const handleSubmitTemplate = (template) => {
        for (let i = 0; i < template.length; i++) {
            template[i].serial = i + 1;
        }
        template = template.map((field) => {
            return {
                name: field.name,
                label: field.label,
                selected: field.selected,
                serial: field.serial,
            };
        });

        props.dispatch(setSocialMediaTemplate(templateType, userId, template));
        setTemplateModal(false);
    };
    useEffect(() => {
        const { success } = props.setSocialMediaTemplateResponse;
        if (success) {
            NotificationManager.success('Template saved successfully.', 'success');
            props.dispatch(clearSocialMediaTemplate());
        } else if (success === false) {
            NotificationManager.error('Template not saved', 'Failed');
            props.dispatch(clearSocialMediaTemplate());
        }
    }, [props.setSocialMediaTemplateResponse]);
    if (loading) return <LoadingAnim />;
    else
        return (
            <Container>
                <FacebookPageSetupModal
                    facebookSettingModal={facebookSettingModal}
                    setFacebookSettingModal={setFacebookSettingModal}
                    allPages={props.getAllFacebookPagesByUserResponse.success ? props.getAllFacebookPagesByUserResponse.allPages : []}
                    selectedFacebookPage={props.getSelectedFacebookPageByUserResponse?.success ? props.getSelectedFacebookPageByUserResponse.selectedPage : {}}
                    handleSelectedFacebookPageSubmit={handleSelectedFacebookPageSubmit}
                />
                <SocialMediaTemplate
                    templateModal={templateModal}
                    setTemplateModal={setTemplateModal}
                    templateType={templateType}
                    setTemplateType={setTemplateType}
                    handleSubmitTemplate={handleSubmitTemplate}
                    getSocialMediaTemplateResponse={props.getSocialMediaTemplateResponse}
                />
                <Row>
                    <Col></Col>
                    <Col md="8" className="parent-page">
                        <Row>
                            <Col>
                                <h4>Connect Social Media</h4>
                            </Col>
                        </Row>
                        <hr />
                        <Row></Row>
                        <FacebookForm
                            handleFacebookConnectResponse={handleFacebookConnectResponse}
                            handleFacebookDisconnectResponse={handleFacebookDisconnectResponse}
                            handleSelectedFacebookPageSubmit={handleSelectedFacebookPageSubmit}
                            handleFacebookSettingModalOpen={handleFacebookSettingModalOpen}
                            getFacebookAccountResponse={props.getFacebookAccountResponse}
                            handleSocialMediaTemplate={handleSocialMediaTemplate}
                        />
                        <TwitterForm
                            responseTwitter={responseTwitter}
                            handleTwitterLogin={handleTwitterLogin}
                            handleTwitterDisconnect={handleTwitterDisconnect}
                            getTwitterAccountResponse={props.getTwitterAccountResponse}
                            handleSocialMediaTemplate={handleSocialMediaTemplate}
                        />
                        <LinkedInForm
                            handleLinkedInConnectResponse={handleLinkedInConnectResponse}
                            handleLinkedInDisconnect={handleLinkedInDisconnect}
                            getLinkedInAccountResponse={props.getLinkedInAccountResponse}
                            handleSocialMediaTemplate={handleSocialMediaTemplate}
                        />
                        {/* <InstagramForm responseInstagram={responseInstagram} handleSocialMediaTemplate={handleSocialMediaTemplate} /> */}
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
        );
};
const mapStateToProps = (state) => {
    const getAllFacebookPagesByUserResponse = state.SocialMedia.getAllFacebookPagesByUser;
    const connectFacebookResponse = state.SocialMedia.connectFacebook;
    const getSelectedFacebookPageByUserResponse = state.SocialMedia.getSelectedFacebookPageByUser;
    const setSelectedFacebookPageByUserResponse = state.SocialMedia.setSelectedFacebookPageByUser;
    const setTwitterAccessTokenResponse = state.SocialMedia.setTwitterAccessToken;
    const connectLinkedInResponse = state.SocialMedia.connectLinkedIn;
    const getSocialMediaTemplateResponse = state.SocialMedia.getSocialMediaTemplate;

    const setSocialMediaTemplateResponse = state.SocialMedia.setSocialMediaTemplate;
    const disconnectFacebookResponse = state.SocialMedia.disconnectFacebook;
    const disconnectTwitterResponse = state.SocialMedia.disconnectTwitter;
    const disconnectLinkedInResponse = state.SocialMedia.disconnectLinkedIn;
    const getFacebookAccountResponse = state.SocialMedia.facebookAccount;
    const getTwitterAccountResponse = state.SocialMedia.twitterAccount;
    const getLinkedInAccountResponse = state.SocialMedia.linkedInAccount;
    console.log('🚀 ~ file: organization-social-media.js ~ line 289 ~ mapStateToProps ~ getLinkedInAccountResponse', disconnectFacebookResponse);
    return {
        getAllFacebookPagesByUserResponse,
        connectFacebookResponse,
        getSelectedFacebookPageByUserResponse,
        setSelectedFacebookPageByUserResponse,
        setTwitterAccessTokenResponse,
        connectLinkedInResponse,
        getSocialMediaTemplateResponse,
        setSocialMediaTemplateResponse,
        disconnectFacebookResponse,
        disconnectTwitterResponse,
        disconnectLinkedInResponse,
        getFacebookAccountResponse,
        getTwitterAccountResponse,
        getLinkedInAccountResponse,
    };
};
export default connect(
    mapStateToProps,
    null,
)(
    reduxForm({
        form: 'OrganizationSocialMedia',
        enableReinitialize: true,
    })(OrganizationSocialMedia),
);
