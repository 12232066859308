import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
const PostFilter = (props) => {
    const filters = props.filters;
    const label = 'Show Only Top Need';
    const id = 'topNeedFilter';
    return (
        <Row>
            <Col>
                <div className="custom-control custom-switch">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        id={id}
                        checked={filters.topNeed}
                        onChange={() => {
                            props.handleSetFilter('topNeed', !filters.topNeed);
                        }}
                    />
                    <label className="custom-control-label" htmlFor={id}>
                        {label}
                    </label>
                </div>
                {/* <Form>
                    <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                            type="checkbox"
                            label="Show Only Top Need"
                            checked={filters.topNeed}
                            onChange={() => {
                                props.handleSetFilter('topNeed', !filters.topNeed);
                            }}
                        />
                    </Form.Group>
                </Form> */}

                {/* <hr /> */}
                <br />
            </Col>
        </Row>
    );
};
export default PostFilter;
