import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FaArrowAltCircleUp } from 'react-icons/fa';
const FloatingToTopButton = () => {
    const [visible, setVisible] = useState(false);
    const threshold = 200;

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > threshold) {
            setVisible(true);
        } else if (scrolled <= threshold) {
            setVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    window.addEventListener('scroll', toggleVisible);
    if (visible)
        return (
            <Button className="scroll-to-top-btn" size="sm" onClick={scrollToTop} variant="dark">
                <FaArrowAltCircleUp size={30} />
            </Button>
        );
    else return <></>;
};
export default FloatingToTopButton;
