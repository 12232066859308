import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
const FacebookPageSetupModal = (props) => {
    const { facebookSettingModal, setFacebookSettingModal, allPages, selectedFacebookPage, handleSelectedFacebookPageSubmit } = props;
    const [selectedPage, setSelectedPage] = useState({});
    useEffect(() => {
        setSelectedPage({
            value: props.selectedFacebookPage.pageId,
            label: props.selectedFacebookPage.pageName,
        });
    }, [props.selectedFacebookPage]);

    return (
        <Modal
            show={facebookSettingModal}
            onHide={() => {
                setFacebookSettingModal(false);
            }}
        >
            <Modal.Header>Manage Facebook Account</Modal.Header>
            <Modal.Body>
                <label>Select your preferred facebook sharing source</label>
                <Select
                    className="basic-single"
                    defaultValue={selectedPage}
                    name="page"
                    options={allPages}
                    onChange={(e) => {
                        setSelectedPage(e);
                    }}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={() => {
                        setFacebookSettingModal(false);
                    }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        handleSelectedFacebookPageSubmit(selectedPage);
                        setFacebookSettingModal(false);
                    }}
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default FacebookPageSetupModal;
