import { getDefaultPP } from '@Constants/default-images';
import React from 'react';
import { Col, Container, Image, Modal, Row } from 'react-bootstrap';

const FollowerListModal = (props) => {
    const userListModal = props.userListModal;
    const title = props.title;
    const followers = props.followers;
    const { handleGotoUserDetails } = props;
    return (
        <Modal
            style={{ zIndex: 10000 }}
            show={userListModal}
            onHide={() => {
                props.setUserListModal(false);
            }}
        >
            <Modal.Header closeButton>{title ? title : ''}</Modal.Header>
            <Modal.Body>
                {followers &&
                    followers.length > 0 &&
                    followers.map((follower, i) => {
                        let name = '';
                        if (follower.followerUserType === 'individual') {
                            name = follower.followerFirstName + ' ' + follower.followerLastName;
                        } else if (follower.followerUserType === 'organization') {
                            name = follower.followerName;
                        }
                        return (
                            <Container className="ppl-liked-list" key={i}>
                                <Row
                                    onClick={() => {
                                        handleGotoUserDetails(follower.followerUserType, follower.followerId);
                                    }}
                                >
                                    <Col sm={2}>
                                        <Image
                                            src={follower.followerProfilePicture ? follower.followerProfilePicture : getDefaultPP(follower.followerUserType)}
                                            thumbnail
                                            style={{ width: '100%', height: '100%' }}
                                        />
                                    </Col>
                                    <Col sm={6} className="v-middle">
                                        {name}
                                    </Col>
                                </Row>
                            </Container>
                        );
                    })}
            </Modal.Body>
        </Modal>
    );
};

export default FollowerListModal;
