/* eslint-disable */

import { checkIfFollower, followUser, unfollowUser } from '@Actions/follow-action';
import {
    cancelGoingPost,
    cancelInterestedPost,
    cancelLikePost,
    changePostInterest,
    getAllPostsByFilter,
    goingPost,
    interestedPost,
    likePost,
} from '@Actions/post-action';
import { getUserPublicInfo } from '@Actions/user-action';
import CalendarPostModal from '@Components/calendar/calendar-post-modal';
import CalendarView from '@Components/calendar/calendar-view';
import LoadingAnim from '@Components/form_template/loading-anim';
import SideSubMenu from '@Components/organization/organization-sub-menu';
import { postDetailsPage, userDetailsPage } from '@Constants/route-paths';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import OrganizationInfoHeader from '@Components/organization/organization-info-header';
const OrganizationScheduler = (props) => {
    const [loading, setLoading] = useState(false);
    const [follows, setFollows] = useState(false);
    const [userId, setUserId] = useState('');
    const [allPosts, setAllPosts] = useState([]);
    const [postModal, setPostModal] = useState(false);
    const [post, setPost] = useState({});

    const gotoPostDetails = (postType, postId) => {
        props.history.push(postDetailsPage(postType, postId));
    };
    useEffect(() => {
        const getInitialInfo = () => {
            setLoading(true);
            //console.log('Came here');
            const organizationId = props.match.params.userId;
            props.dispatch(getUserPublicInfo(organizationId));
            const user = props.auth.user;
            setUserId(user._id);
            props.dispatch(checkIfFollower(user._id, props.match.params.userId));
            setLoading(false);
        };
        getInitialInfo();
    }, []);
    useEffect(() => {
        setLoading(true);
        const userId = props.match.params.userId;

        props.dispatch(getAllPostsByFilter({ creatorId: userId }));
        setLoading(false);
    }, [props.match.params.userId]);
    useEffect(() => {
        const success = props.getCheckIfFollowerResponse;
        if (success) {
            setFollows(props.getCheckIfFollowerResponse.follows);
        }
    }, [props.getCheckIfFollowerResponse]);

    const handleFollowClick = () => {
        setLoading(true);
        const user = props.auth.user;
        props.dispatch(followUser({ followerId: user._id, followingId: props.match.params.userId }));
        setLoading(false);
    };
    const handleUnfollowClick = () => {
        setLoading(true);
        const user = props.auth.user;
        props.dispatch(unfollowUser({ followerId: user._id, followingId: props.match.params.userId }));
        setLoading(false);
    };
    useEffect(() => {
        const { success } = props.getFollowResponse;
        if (success) {
            setFollows(true);
        } else if (success === false) {
        }
    }, [props.getFollowResponse]);
    useEffect(() => {
        const { success } = props.getUnfollowResponse;

        if (success) {
            setFollows(false);
            // NotificationManager.success('You are unfollowing', 'success');
        } else if (success === false) {
            // NotificationManager.success('Server connection error', 'failed');
        }
    }, [props.getUnfollowResponse]);
    useEffect(() => {
        const { success } = props.getAllPostsResponse;
        if (success) {
            let allFilteredPosts = props.getAllPostsResponse.allPosts;
            setAllPosts([...allFilteredPosts]);
        } else {
            setAllPosts([]);
        }
    }, [props.getAllPostsResponse]);
    const gotoPage = (url) => {
        props.history.push(url);
    };
    const handleLikePost = (postId) => {
        props.dispatch(likePost(postId));
        props.dispatch(changePostInterest(postId, userId, 'like'));
    };
    const handleCancelLikePost = (postId) => {
        props.dispatch(cancelLikePost(postId));
        props.dispatch(changePostInterest(postId, userId, 'unlike'));
    };

    const handleInterestedPost = (postId) => {
        props.dispatch(interestedPost(postId));
        props.dispatch(changePostInterest(postId, userId, 'interested'));
    };
    const handleCancelInterestedPost = (postId) => {
        //console.log('🚀 ~ file: home.js ~ line 46 ~ handleCancelInterestedPost ~ postId', postId);
        props.dispatch(cancelInterestedPost(postId));
        props.dispatch(changePostInterest(postId, userId, 'uninterested'));
    };

    const handleGoingPost = (postId) => {
        props.dispatch(goingPost(postId));
        props.dispatch(changePostInterest(postId, userId, 'going'));
    };
    const handleCancelGoingPost = (postId) => {
        props.dispatch(cancelGoingPost(postId));
        props.dispatch(changePostInterest(postId, userId, 'ungoing'));
    };
    const handleGotoPostDetails = (postType, postId) => {
        props.history.push(postDetailsPage(postType, postId));
    };
    const handleGotoOrganizationDetails = (userId) => {
        props.history.push(userDetailsPage('organization', userId));
    };
    if (loading) return <LoadingAnim />;
    return (
        <Container>
            <Row>
                <Col className="parent-page">
                    <CalendarPostModal
                        postModal={postModal}
                        setPostModal={setPostModal}
                        post={post}
                        handleGotoPostDetails={handleGotoPostDetails}
                        handleGotoOrganizationDetails={handleGotoOrganizationDetails}
                        handleLikePost={handleLikePost}
                        handleCancelLikePost={handleCancelLikePost}
                        handleInterestedPost={handleInterestedPost}
                        handleCancelInterestedPost={handleCancelInterestedPost}
                        handleGoingPost={handleGoingPost}
                        handleCancelGoingPost={handleCancelGoingPost}
                    />
                    <Row>
                        <Col className="right-align" sm="2">
                            <SideSubMenu
                                activePage="Calendar"
                                organization={props.getPublicInfoResponse.success ? props.getPublicInfoResponse.user : {}}
                                handleFollowClick={handleFollowClick}
                                handleUnfollowClick={handleUnfollowClick}
                                follows={follows}
                                gotoPage={gotoPage}
                            />
                        </Col>
                        <Col sm="9" className="left-border">
                            <OrganizationInfoHeader
                                props={props}
                                organization={props.getPublicInfoResponse && props.getPublicInfoResponse.success ? props.getPublicInfoResponse.user : {}}
                            />
                            <CalendarView
                                posts={props.getAllPostsResponse.success ? props.getAllPostsResponse.allPosts : []}
                                setPost={setPost}
                                setPostModal={setPostModal}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};
const mapStateToProps = (state) => {
    const getAllPostsResponse = state.Post.getAllPosts;
    //console.log('🚀 ~ file: organization-post-list.js ~ line 95 ~ mapStateToProps ~ getAllPostsResponse', state);
    const getPublicInfoResponse = state.User.getUserPublicInfo;
    const getCheckIfFollowerResponse = state.Follow.checkIfFollower;
    const getFollowResponse = state.Follow.followUser;
    const getUnfollowResponse = state.Follow.unfollowUser;
    return {
        getPublicInfoResponse,
        getCheckIfFollowerResponse,
        getFollowResponse,
        getUnfollowResponse,
        getAllPostsResponse,
    };
};
export default connect(mapStateToProps, null)(OrganizationScheduler);
