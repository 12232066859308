export const defaultCurrentLocation = {
    latitude: 39.953194,
    longitude: -75.163345,
};

export const defaultInvolvement = {
    lookingForVolunteeringOpportunity: true,
    lookingForProject: true,
    lookingForMembership: true,
    lookingForCommittee: true,
};

export const defaultPrivacy = {
    isCalenderPublic: false,
    isEmailSearchable: true,
    isUserSearchable: true,
};
