export const countries = [
    { name: 'Australia', code: 'Australia' },
    { name: 'Canada', code: 'Canada' },
    { name: 'Japan', code: 'Japan' },
    { name: 'United States', code: 'UnitedStates' },
    { name: 'United Kingdom', code: 'UnitedKingdom' },
];
export const getCountryByCode = (code) => {
    const country = countries.filter((country) => country.code === code);
    if (country && country.length > 0) return country[0].name;
};
export const states = {
    Australia: [
        { code: 'AustralianCapitalTerritory', name: 'Australian Capital Territory' },
        { code: 'NewSouthWales', name: 'New South Wales' },
        { code: 'NorthernTerritory', name: 'Northern Territory' },
        { code: 'Queensland', name: 'Queensland' },
        { code: 'SouthAustralia', name: 'South Australia' },
        { code: 'Tasmania', name: 'Tasmania' },
        { code: 'Victoria', name: 'Victoria' },
        { code: 'WesternAustralia', name: 'Western Australia' },
    ],
    Canada: [
        { code: 'AB', name: 'Alberta' },
        { code: 'BC', name: 'British Columbia' },
        { code: 'MB', name: 'Manitoba' },
        { code: 'NB', name: 'New Brunswick' },
        { code: 'NL', name: 'Newfoundland and Labrador' },
        { code: 'NS', name: 'Nova Scotia' },
        { code: 'NT', name: 'Northwest Territories' },
        { code: 'NU', name: 'Nunavut' },
        { code: 'ON', name: 'Ontario' },
        { code: 'PE', name: 'Prince Edward Island' },
        { code: 'QC', name: 'Quebec' },
        { code: 'SK', name: 'Saskatchewan' },
        { code: 'YT', name: 'Yukon' },
    ],
    Japan: [
        { code: '01', name: 'Hokkaido' },
        { code: '02', name: 'Aomori' },
        { code: '03', name: 'Iwate' },
        { code: '04', name: 'Miyagi' },
        { code: '05', name: 'Akita' },
        { code: '06', name: 'Yamagata' },
        { code: '07', name: 'Fukushima' },
        { code: '08', name: 'Ibaraki' },
        { code: '09', name: 'Tochigi' },
        { code: '10', name: 'Gunma' },
        { code: '11', name: 'Saitama' },
        { code: '12', name: 'Chiba' },
        { code: '13', name: 'Tokyo' },
        { code: '14', name: 'Kanagawa' },
        { code: '15', name: 'Niigata' },
        { code: '16', name: 'Toyama' },
        { code: '17', name: 'Ishikawa' },
        { code: '18', name: 'Fukui' },
        { code: '19', name: 'Yamanashi' },
        { code: '20', name: 'Nagano' },
        { code: '21', name: 'Gifu' },
        { code: '22', name: 'Shizuoka' },
        { code: '23', name: 'Aichi' },
        { code: '24', name: 'Mie' },
        { code: '25', name: 'Shiga' },
        { code: '26', name: 'Kyoto' },
        { code: '27', name: 'Osaka' },
        { code: '28', name: 'Hyogo' },
        { code: '29', name: 'Nara' },
        { code: '30', name: 'Wakayama' },
        { code: '31', name: 'Tottori' },
        { code: '32', name: 'Shimane' },
        { code: '33', name: 'Okayama' },
        { code: '34', name: 'Hiroshima' },
        { code: '35', name: 'Yamaguchi' },
        { code: '36', name: 'Tokushima' },
        { code: '37', name: 'Kagawa' },
        { code: '38', name: 'Ehime' },
        { code: '39', name: 'Kochi' },
        { code: '40', name: 'Fukuoka' },
        { code: '41', name: 'Saga' },
        { code: '42', name: 'Nagasaki' },
        { code: '43', name: 'Kumamoto' },
        { code: '44', name: 'Oita' },
        { code: '45', name: 'Miyazaki' },
        { code: '46', name: 'Kagoshima' },
        { code: '47', name: 'Okinawa' },
    ],
    UnitedStates: [
        {
            name: 'Armed Forces Americas (except Canada)',
            code: 'ArmedForcesAmericas',
        },
        { name: 'Armed Forces', code: 'ArmedForces' },
        { name: 'Alaska', code: 'Alaska' },
        { name: 'Alabama', code: 'Alabama' },
        { name: 'Armed Forces Pacific', code: 'ArmedForcesPacific' },
        { name: 'Arkansas', code: 'Arkansas' },
        { name: 'American Samoa', code: 'AmericanSamoa' },
        { name: 'Arizona', code: 'Arizona' },
        { name: 'California', code: 'California' },
        { name: 'Colorado', code: 'Colorado' },
        { name: 'Connecticut', code: 'Connecticut' },
        { name: 'District of Columbia', code: 'DistrictOfColumbia' },
        { name: 'Delaware', code: 'Delaware' },
        { name: 'Florida', code: 'Florida' },
        { name: 'Georgia', code: 'Georgia' },
        { name: 'Guam', code: 'Guam' },
        { name: 'Hawaii', code: 'Hawaii' },
        { name: 'Iowa', code: 'Iowa' },
        { name: 'Idaho', code: 'Idaho' },
        { name: 'Illinois', code: 'Illinois' },
        { name: 'Indiana', code: 'Indiana' },
        { name: 'Kansas', code: 'Kansas' },
        { name: 'Kentucky', code: 'Kentucky' },
        { name: 'Louisiana', code: 'Louisiana' },
        { name: 'Massachusetts', code: 'Massachusetts' },
        { name: 'Maryland', code: 'Maryland' },
        { name: 'Maine', code: 'Maine' },
        { name: 'Marshall Islands', code: 'MarshallIslands' },
        { name: 'Michigan', code: 'Michigan' },
        { name: 'Minnesota', code: 'Minnesota' },
        { name: 'Missouri', code: 'Missouri' },
        { name: 'Northern Mariana Islands', code: 'NorthernMarianaIslands' },
        { name: 'Mississippi', code: 'Mississippi' },
        { name: 'Montana', code: 'Montana' },
        { name: 'North Carolina', code: 'NorthCarolina' },
        { name: 'North Dakota', code: 'NorthDakota' },
        { name: 'Nebraska', code: 'Nebraska' },
        { name: 'New Hampshire', code: 'NewHampshire' },
        { name: 'New Jersey', code: 'NewJersey' },
        { name: 'New Mexico', code: 'NewMexico' },
        { name: 'Nevada', code: 'Nevada' },
        { name: 'New York', code: 'NewYork' },
        { name: 'Ohio', code: 'Ohio' },
        { name: 'Oklahoma', code: 'Oklahoma' },
        { name: 'Oregon', code: 'Oregon' },
        { name: 'Pennsylvania', code: 'Pennsylvania' },
        { name: 'Puerto Rico', code: 'PuertoRico' },
        { name: 'Palau', code: 'Palau' },
        { name: 'Rhode Island', code: 'RhodeIsland' },
        { name: 'South Carolina', code: 'SouthCarolina' },
        { name: 'South Dakota', code: 'SouthDakota' },
        { name: 'Tennessee', code: 'Tennessee' },
        { name: 'Texas', code: 'Texas' },
        { name: 'Utah', code: 'Utah' },
        { name: 'Virginia', code: 'Virginia' },
        { name: 'Virgin Islands', code: 'VirginIslands' },
        { name: 'Vermont', code: 'Vermont' },
        { name: 'Washington', code: 'Washington' },
        { name: 'Wisconsin', code: 'Wisconsin' },
        { name: 'West Virginia', code: 'WestVirginia' },
        { name: 'Wyoming', code: 'Wyoming' },
    ],
};
export const getStateByCountryAndCode = (countyCode, code) => {
    let states_ = states[countyCode];
    if (states_ && states_.length > 0) {
        let state = states_.filter((state) => state.code === code);
        if (state && state.length > 0) return state[0].name;
    } else return '';
};
