/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import PostList from '@Components/post/post-list';
import LoadingAnim from '@Components/form_template/loading-anim';
import { connect } from 'react-redux';
import CommittedPersonsModal from '@Components/post/committed-persons-list';
import { getAllPostsByFilter, getAllViewersByPost, getAllCommittedPersons, getAllFollowersByPost } from '@Actions/post-action';
import * as RoutePaths from '@Constants/route-paths';
import ManagePostMenu from '@Components/post/manage-post-menu';
import ManagePostHeader from '@Components/post/post-list-header';
import FollowerListModal from '@Components/post/follower-list-modal';

const ManagePosts = (props) => {
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState({
        postType: '',
        title: '',
        isActive: 'both',
    });
    const [viewerModal, setViewerModal] = useState(false);
    const [committedModal, setCommittedModal] = useState(false);
    const [committedLoading, setCommittedLoading] = useState(false);
    const [committedList, setCommittedList] = useState([]);
    const [followerModal, setFollowerModal] = useState(false);
    useEffect(() => {
        document.title = 'Manage Posts';
    }, []);
    useEffect(() => {
        const getInitialInfo = (userId) => {
            setLoading(true);
            props.dispatch(getAllPostsByFilter({ creatorId: userId }));
            setLoading(false);
        };
        const user = props.auth.user;
        if (user && user._id);
        getInitialInfo(user._id);
    }, [props.auth]);
    const handleGoToPostEdit = (postType, postId) => {
        props.history.push(RoutePaths.postEditPage(postType, postId));
    };
    const handleGoToPostDetails = (postType, postId) => {
        props.history.push(RoutePaths.postDetailsPage(postType, postId));
    };
    const handleGoToPostCreate = (postType) => {
        props.history.push(RoutePaths.postCreatePage(postType));
    };
    const handleViewerListShow = (postId) => {
        setViewerModal(true);
        props.dispatch(getAllViewersByPost(postId));
    };
    const handleFollowerListShow = (postId) => {
        setFollowerModal(true);
        props.dispatch(getAllFollowersByPost(postId));
    };
    const handleClickCommittedButtons = (postId, type) => {
        //console.log('🚀 ~ file: home.js ~ line 20 ~ handleClickCommittedButtons ~ postId, type', postId, type);
        setCommittedLoading(true);
        getAllCommittedPersons(postId, type).then((response) => {
            //console.log('🚀 ~ file: home.js ~ line 22 ~ getAllCommittedPersons ~ response', response);
            setCommittedModal(true);
            setCommittedLoading(false);
            if (response.success) {
                setCommittedList(response.users);
            } else {
                alert('User List Not Found');
            }
        });
    };
    const handleGotoUserDetails = (userType, userId) => {
        props.history.push(RoutePaths.userDetailsPage(userType, userId));
    };
    if (loading) return <LoadingAnim />;
    else {
        return (
            <Container>
                <Row>
                    <Col className="parent-page">
                        <FollowerListModal
                            title="Followers from the post"
                            userListModal={followerModal}
                            setUserListModal={setFollowerModal}
                            followers={props.getAllFollowersResponse?.success ? props.getAllFollowersResponse.followers : []}
                            handleGotoUserDetails={handleGotoUserDetails}
                        />
                        <ManagePostHeader
                            allPosts={props.getAllPostsResponse.success ? props.getAllPostsResponse.allPosts : []}
                            handleGoToPostCreate={handleGoToPostCreate}
                        />
                        <hr />
                        <ManagePostMenu selected="list" />
                        <CommittedPersonsModal
                            committedModal={committedModal}
                            committedLoading={committedLoading}
                            committedList={committedList}
                            setCommittedModal={setCommittedModal}
                        />
                        <PostList
                            allPosts={props.getAllPostsResponse.success ? props.getAllPostsResponse.allPosts : []}
                            handleGoToPostEdit={handleGoToPostEdit}
                            handleGoToPostDetails={handleGoToPostDetails}
                            handleGoToPostCreate={handleGoToPostCreate}
                            filter={filter}
                            viewerModal={viewerModal}
                            setViewerModal={setViewerModal}
                            handleViewerListShow={handleViewerListShow}
                            viewers={props.getAllViewersResponse.success ? props.getAllViewersResponse.viewers : []}
                            handleClickCommittedButtons={handleClickCommittedButtons}
                            handleFollowerListShow={handleFollowerListShow}
                        />
                    </Col>
                </Row>
            </Container>
        );
    }
};
const mapStateToProps = (state) => {
    const getAllPostsResponse = state.Post.getAllPosts;
    const getAllViewersResponse = state.Post.getAllViewersByPost;
    const getAllFollowersResponse = state.Post.getAllFollowersByPost;
    console.log('🚀 ~ file: manage-posts.js ~ line 21 ~ mapStateToProps ~ getAllPostsResponse', getAllFollowersResponse);

    return {
        getAllPostsResponse,
        getAllViewersResponse,
        getAllFollowersResponse,
    };
};
export default connect(mapStateToProps, null)(ManagePosts);
