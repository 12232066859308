import React from 'react';
import { Button, Modal, Container, Row, Col } from 'react-bootstrap';
import { TextRender } from '../form_template/input-render';
import { Field } from 'redux-form';
const socialMedias = {
    facebook: 'FACEBOOK',
    twitter: 'TWITTER',
    instagram: 'INSTAGRAM',
    linkedIn: 'LINKED IN',
};
const SocialMediaDescriptionModal = ({ descriptionModal, setDescriptionModal, selectedTemplate }) => {
    return (
        <Modal
            size="xl"
            style={{ zIndex: 100000 }}
            show={descriptionModal}
            onHide={() => {
                setDescriptionModal(false);
            }}
        >
            <Modal.Header closeButton>MANAGE {socialMedias[selectedTemplate]} Description</Modal.Header>
            <Modal.Body>
                {selectedTemplate === 'facebook' && (
                    <Field
                        name="socialMediaSharing.facebook.description"
                        type="text"
                        component={TextRender}
                        label="Description"
                        placeholder="Description..."
                        col1={12}
                        col2={12}
                    />
                )}
                {selectedTemplate === 'twitter' && (
                    <Field
                        name="socialMediaSharing.twitter.description"
                        type="text"
                        component={TextRender}
                        label="Description"
                        placeholder="Description..."
                        col1={12}
                        col2={12}
                    />
                )}
                {selectedTemplate === 'linkedIn' && (
                    <Field
                        name="socialMediaSharing.linkedIn.description"
                        type="text"
                        component={TextRender}
                        label="Description"
                        placeholder="Description..."
                        col1={12}
                        col2={12}
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={() => {
                        setDescriptionModal(false);
                    }}
                >
                    Done
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default SocialMediaDescriptionModal;
