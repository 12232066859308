import React from 'react';
import { Card, CardColumns, Row, Col, Badge, Button } from 'react-bootstrap';
import { defaultPostPicture } from '@Constants/default-images';
import moment from 'moment';
import { interestTypes } from '@Constants/interest-types';
import { getPostTypeByValue } from '@Constants/post-types';
import { ImpactAreasRender, InfoRender } from '../form_template/details-render';
import OrganizationStickyPopUp from '@Components/organization/organization-sticky-popup';
import {
    LikeButtonRender,
    InterestedButtonRender,
    GoingButtonRender,
    FollowButtonRender,
    UnfollowUserButtonRender,
    AddToSchedulerButtonRender,
    RemoveFromSchedulerButtonRender,
    ShareButtonRender,
    InviteButtonRender,
} from '../form_template/buttons-render';
const convertDescription = (description) => {
    let newComment = description;
    newComment = newComment.split(`@@@__`).join('<a href="/user/');
    newComment = newComment.split(`^^^__`).join('">');
    newComment = newComment.split(`@@@^^^`).join('</a>');
    return <div dangerouslySetInnerHTML={{ __html: newComment }} />;
};
const descriptionRender = (description) => {
    if (description) {
        if (description.length < 101) return description;
        else return description.substr(0, 100) + '...';
    } else return '';
};
const PostCardView = (props) => {
    const userId = props.userId;
    const allPosts = props.allPosts;
    const followOrganizationButton = props.followOrganizationButton;
    const followingObject = props.followingObject;
    const { schedulerPostsObject, handleAddPostToScheduler, handleRemovePostFromScheduler } = props;
    let interest = {};
    const { handleCreateOneShare } = props;
    const setPostInviteModal = props.setPostInviteModal;
    const setInvitedPost = props.setInvitedPost;
    if (allPosts && allPosts.length > 0) {
        return (
            <CardColumns md="6">
                {allPosts.map((post, i) => {
                    if (post && post._id) {
                        interest = {};
                        let filter = post.interests.filter((interest) => interest.userId === userId);
                        if (filter && filter.length > 0) interest = filter[0];

                        return (
                            <Card className="special-btn special-card" key={i}>
                                <div
                                    className="home-post-image-dark"
                                    onClick={() => {
                                        props.gotoPostDetails(post.postType, post._id);
                                    }}
                                >
                                    <Card.Img
                                        variant="top"
                                        src={post.images && post.images.length > 0 ? post.images[0].path : defaultPostPicture}
                                        alt="No Image Found"
                                    />
                                    <Badge variant="primary" className="image-text-top-left">
                                        {getPostTypeByValue(post.postType)[0].label}
                                    </Badge>
                                </div>

                                <Card.Body>
                                    <Card.Title
                                        onClick={() => {
                                            props.gotoPostDetails(post.postType, post._id);
                                        }}
                                        className="center-aligned"
                                    >
                                        {descriptionRender(post.title)}
                                    </Card.Title>
                                    <OrganizationStickyPopUp
                                        placement="left"
                                        onMouseEnter={() => {}}
                                        // delay={200}
                                        organizationId={post.creatorId}
                                        organizationName={post.organizationName}
                                        userId={userId}
                                        postId={post._id}
                                        postTitle={post.title}
                                    >
                                        <h6>{post.organizationName}</h6>
                                    </OrganizationStickyPopUp>
                                    <Card.Text className="justify-text">
                                        <small>{convertDescription(descriptionRender(post.description))}</small>
                                    </Card.Text>
                                    {ImpactAreasRender('', post.impactAreas)}
                                    <br />
                                    {post.address && InfoRender('', post.address.fullAddress)}
                                    <br />
                                    <small>
                                        {post.startDateTime && (
                                            <Row style={{ marginBottom: 5 }}>
                                                <Col sm={3}>FROM</Col>
                                                <Col sm={9} className="right-align">
                                                    <b>{moment(post.startDateTime).format('MM/DD/y hh:mm:A')}</b>
                                                </Col>
                                            </Row>
                                        )}
                                        {post.endDateTime && (
                                            <Row>
                                                <Col sm={3}>TO</Col>
                                                <Col sm={9} className="right-align">
                                                    <b>{moment(post.endDateTime).format('MM/DD/yy hh:mm:A')}</b>
                                                </Col>
                                            </Row>
                                        )}
                                    </small>
                                </Card.Body>
                                <Card.Footer>
                                    <Row>
                                        <Col>
                                            {interestTypes[post.postType].like ? (
                                                interest.liked ? (
                                                    <LikeButtonRender
                                                        complete={true}
                                                        onClick={() => {
                                                            props.handleCancelLikePost(post._id);
                                                        }}
                                                    />
                                                ) : (
                                                    <LikeButtonRender
                                                        complete={false}
                                                        onClick={() => {
                                                            props.handleLikePost(post._id);
                                                        }}
                                                    />
                                                )
                                            ) : (
                                                <></>
                                            )}
                                            {interestTypes[post.postType].interested ? (
                                                interest.interested ? (
                                                    <InterestedButtonRender
                                                        complete={true}
                                                        onClick={() => {
                                                            props.handleCancelInterestedPost(post._id);
                                                        }}
                                                    />
                                                ) : (
                                                    <InterestedButtonRender
                                                        complete={false}
                                                        onClick={() => {
                                                            props.handleInterestedPost(post._id);
                                                        }}
                                                    />
                                                )
                                            ) : (
                                                <></>
                                            )}
                                            &nbsp;
                                            {interestTypes[post.postType].going ? (
                                                interest.going ? (
                                                    <GoingButtonRender
                                                        complete={true}
                                                        onClick={() => {
                                                            props.handleCancelGoingPost(post._id);
                                                        }}
                                                    />
                                                ) : (
                                                    <GoingButtonRender
                                                        complete={false}
                                                        onClick={() => {
                                                            props.handleGoingPost(post._id);
                                                        }}
                                                    />
                                                )
                                            ) : (
                                                <></>
                                            )}
                                            &nbsp;
                                            {interestTypes[post.postType].addable && !followingObject[post.creatorId] ? (
                                                schedulerPostsObject[post._id] ? (
                                                    <RemoveFromSchedulerButtonRender
                                                        hover_title="Remove From My Calendar"
                                                        onClick={() => {
                                                            handleRemovePostFromScheduler(post._id);
                                                        }}
                                                    />
                                                ) : (
                                                    <AddToSchedulerButtonRender
                                                        hover_title="Add To My Calendar"
                                                        onClick={() => {
                                                            handleAddPostToScheduler(post._id);
                                                        }}
                                                    />
                                                )
                                            ) : (
                                                <></>
                                            )}
                                        </Col>
                                        <ShareButtonRender
                                            hover_title="Share This Post"
                                            onClick={() => {
                                                handleCreateOneShare(post._id);
                                            }}
                                        />
                                        &nbsp;
                                        <InviteButtonRender
                                            hover_title="Invite People To View This Post"
                                            onClick={() => {
                                                setInvitedPost({ postId: post._id, postType: post.postType });
                                                setPostInviteModal(true);
                                            }}
                                        />
                                    </Row>
                                </Card.Footer>
                            </Card>
                        );
                    } else return <></>;
                })}
            </CardColumns>
        );
    } else return <h4>No Posts Found</h4>;
};
export default PostCardView;
