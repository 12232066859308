import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { Field } from 'redux-form';
import { DateTimePickerRender } from '../form_template/input-render';

const PostScheduleModal = ({ scheduleModal, setScheduleModal, handleChangeReduxField, onSubmit, setIsActive }) => {
    return (
        <Modal
            style={{ zIndex: 10000 }}
            show={scheduleModal}
            onHide={() => {
                setScheduleModal(false);
            }}
        >
            <Modal.Header closeButton>Set Schedule Time</Modal.Header>
            <Modal.Body>
                <Field name="scheduledPublishDateTime" component={DateTimePickerRender} label="Publish date time" col1={4} col2={8} zIndex={1001} />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    size="sm"
                    type="submit"
                    onClick={async () => {
                        await handleChangeReduxField('scheduledPublish', true);
                        await handleChangeReduxField('isActive', false);
                        onSubmit();
                    }}
                >
                    Publish(On Schedule Time)
                </Button>
                &nbsp;
                <Button
                    size="sm"
                    type="submit"
                    variant="warning"
                    onClick={async () => {
                        await handleChangeReduxField('scheduledPublish', false);
                        await handleChangeReduxField('isActive', false);
                        onSubmit();
                    }}
                >
                    Save As Draft
                </Button>
                &nbsp;
                <Button
                    size="sm"
                    variant="danger"
                    onClick={() => {
                        setScheduleModal(false);
                    }}
                >
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default PostScheduleModal;
