import React from 'react';
import { Container, Image, Row, Col, Badge, ListGroup, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { defaultOrganizationProfilePicture } from '@Constants/default-images';
import Collapsible from 'react-collapsible';
import { RiUserFollowFill, RiUserUnfollowFill } from 'react-icons/ri';
import * as RoutePath from '@Constants/route-paths';
import { EndorseUserButtonRender, CancelEndorseUserButtonRender } from '../form_template/buttons-render.js';
const SideMenu = (props) => {
    const organization = props.organization;
    const activePage = props.activePage;
    //console.log(organization);
    const follows = props.follows;
    const endorses = props.endorses;
    const actionButton = props.actionButton;
    const RenderListButtonItem = (label, path) => {
        return (
            <Button
                variant={label === activePage ? 'primary' : 'outline-primary'}
                size="sm"
                className="list-button"
                onClick={() => {
                    props.gotoPage(path);
                }}
            >
                {label}
            </Button>
        );
    };
    if (organization._id) {
        const basicInfo = organization.basicInfo;
        return (
            <>
                <Image
                    className="left-image"
                    src={basicInfo.profilePicture ? basicInfo.profilePicture : defaultOrganizationProfilePicture}
                    width="100%"
                    thumbnail
                />
                <div style={{ height: 10 }} />
                {actionButton && (
                    <>
                        {follows ? (
                            <OverlayTrigger placement="bottom" overlay={<Tooltip>Click to unfollow</Tooltip>}>
                                <Button
                                    size="sm"
                                    variant="outline-info"
                                    className="details-following-btn"
                                    onClick={() => {
                                        props.handleUnfollowClick();
                                    }}
                                >
                                    <RiUserFollowFill /> Following
                                </Button>
                            </OverlayTrigger>
                        ) : (
                            <OverlayTrigger placement="bottom" overlay={<Tooltip>Click to follow</Tooltip>}>
                                <Button
                                    size="sm"
                                    variant="outline-info"
                                    className="details-follow-btn"
                                    onClick={() => {
                                        props.handleFollowClick();
                                    }}
                                >
                                    <RiUserFollowFill /> Follow
                                </Button>
                            </OverlayTrigger>
                        )}
                        <div style={{ height: 10 }} />
                        {endorses ? (
                            <CancelEndorseUserButtonRender
                                onClick={() => {
                                    props.handleCancelEndorseClick();
                                }}
                            />
                        ) : (
                            <EndorseUserButtonRender
                                onClick={() => {
                                    props.handleEndorseClick();
                                }}
                            />
                        )}
                    </>
                )}
                <hr />
                {/* <Collapsible trigger="MENU" className="special-btn"> */}
                {RenderListButtonItem('About', RoutePath.userDetailsPage('organization', organization._id))}
                {RenderListButtonItem('Impact', RoutePath.postListPageByOrganizationAndPostType(organization._id, 'project'))}
                {RenderListButtonItem('Message', RoutePath.userMessagePage('organization', organization._id))}
                {RenderListButtonItem('Followers', RoutePath.organizationFollowersPage(organization._id))}
                {RenderListButtonItem('Feed', RoutePath.organizationFeedPage(organization._id))}
                {RenderListButtonItem('Calendar', RoutePath.organizationSchedulerPage(organization._id))}
                {RenderListButtonItem('Gallery', RoutePath.userGalleryPage('organization', organization._id))}
                {/* </Collapsible> */}
            </>
        );
    } else return <></>;
};
export default SideMenu;
