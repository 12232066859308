import React, { useEffect, useState } from 'react';
import { ImpactAreasRender, OrganizationTypesRender, TagWithLabelRender, LinkRender } from '../form_template/details-render';
import { Button, ButtonGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import UserListModal from '@Components/dashboard/user-list-modal';
import { getFollowerSummary, getAllFollowers } from '@Actions/follow-action';
import { getEndorserSummary, getAllEndorsers } from '@Actions/endorsement-action';
const OrganizationInfoHeader = (props) => {
    const [userListModal, setUserListModal] = useState(false);
    const [users, setUsers] = useState([]);
    const [modalType, setModalType] = useState('');
    const [organizationId, setOrganizationId] = useState('');
    const organization = props.organization;
    console.log('🚀 ~ file: organization-info-header.js ~ line 13 ~ OrganizationInfoHeader ~ organization', organization);
    const handleOpenUserModal = (modalType) => {
        setModalType(modalType);
        setUserListModal(true);
        if (modalType === 'follower') {
            props.dispatch(getAllFollowers(organizationId, false));
        } else if (modalType === 'follower(friends)') {
            props.dispatch(getAllFollowers(organizationId, true));
        } else if (modalType === 'endorser') {
            props.dispatch(getAllEndorsers(organizationId, false));
        } else if (modalType === 'endorser(friends)') {
            props.dispatch(getAllEndorsers(organizationId, true));
        }
    };
    useEffect(() => {
        const organizationId = props.organization._id;
        props.dispatch(getFollowerSummary(organizationId));
        props.dispatch(getEndorserSummary(organizationId));
        setOrganizationId(organizationId);
    }, [props.organization]);
    useEffect(() => {
        const { success } = props.getAllFollowersResponse;
        console.log('🚀 ~ file: organization-info-header.js ~ line 36 ~ useEffect ~ props.getAllFollowersResponse', props.getAllFollowersResponse);
        if (success) {
            const { followers } = props.getAllFollowersResponse;
            setUsers(
                followers.map((user) => {
                    return {
                        userId: user.followerId,
                        name: user.followerName,
                        firstName: user.followerFirstName,
                        lastName: user.followerLastName,
                        profilePicture: user.followerProfilePicture,
                        userType: user.followerUserType,
                    };
                }),
            );
        }
    }, [props.getAllFollowersResponse]);
    // HANDLE GET ALL ENDORSERS
    useEffect(() => {
        const { success } = props.getAllEndorsersResponse;
        console.log('🚀 ~ file: organization-info-header.js ~ line 56 ~ useEffect ~ props.getAllEndorsersResponse', props.getAllEndorsersResponse);
        if (success) {
            const { endorsers } = props.getAllEndorsersResponse;
            setUsers(
                endorsers.map((user) => {
                    return {
                        userId: user.endorserId,
                        name: user.endorserName,
                        firstName: user.endorserFirstName,
                        lastName: user.endorserLastName,
                        profilePicture: user.endorserProfilePicture,
                        userType: user.endorserUserType,
                    };
                }),
            );
        }
    }, [props.getAllEndorsersResponse]);
    if (organization._id && organization.userType === 'organization') {
        const basicInfo = organization.basicInfo;
        return (
            <>
                <UserListModal userListModal={userListModal} users={users} setUserListModal={setUserListModal} modalType={modalType} />
                <h3>{basicInfo.name}</h3>
                {OrganizationTypesRender('Organization Type', basicInfo.organizationTypes)}
                {props.getFollowerSummaryResponse.success ? (
                    <ButtonGroup aria-label="Basic example" size="sm">
                        <Button
                            variant="outline-primary"
                            onClick={() => {
                                if (props.getFollowerSummaryResponse.totalFollowers > 0) {
                                    handleOpenUserModal('follower');
                                }
                            }}
                        >
                            {props.getFollowerSummaryResponse.totalFollowers} Followers
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                if (props.getFollowerSummaryResponse.totalFriendsFollowers) {
                                    handleOpenUserModal('follower(friends)');
                                }
                            }}
                        >
                            {props.getFollowerSummaryResponse.totalFriendsFollowers} Friends
                        </Button>
                    </ButtonGroup>
                ) : (
                    <></>
                )}
                &nbsp;
                {props.getEndorserSummaryResponse.success ? (
                    <ButtonGroup aria-label="Basic example" size="sm">
                        <Button
                            variant="outline-success"
                            onClick={() => {
                                if (props.getEndorserSummaryResponse.totalEndorsers > 0) {
                                    handleOpenUserModal('endorser');
                                }
                            }}
                        >
                            {props.getEndorserSummaryResponse.totalEndorsers} Endorsers
                        </Button>
                        <Button
                            variant="success"
                            onClick={() => {
                                if (props.getEndorserSummaryResponse.totalFriendsEndorsers > 0) {
                                    handleOpenUserModal('endorser(friends)');
                                }
                            }}
                        >
                            {props.getEndorserSummaryResponse.totalFriendsEndorsers} Friends
                        </Button>
                    </ButtonGroup>
                ) : (
                    <></>
                )}
                <hr />
            </>
        );
    } else return <></>;
};

const mapStateToProps = (state) => {
    const getFollowerSummaryResponse = state.Follow.getFollowerSummary;
    const getEndorserSummaryResponse = state.Endorsement.getEndorserSummary;
    const getAllFollowersResponse = state.Follow.getAllFollowers;
    const getAllEndorsersResponse = state.Endorsement.getAllEndorsers;
    return { getFollowerSummaryResponse, getEndorserSummaryResponse, getAllFollowersResponse, getAllEndorsersResponse };
};
export default connect(mapStateToProps, null)(OrganizationInfoHeader);
