import * as Types from '../constants/reducer-types';

const initialState = {
    getShare: {},
    setShare: {},
    deleteShare: {},
};

const ShareReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.GET_SHARE:
            return { ...state, getShare: action.payload };
        case Types.SET_SHARE:
            return { ...state, setShare: action.payload };
        case Types.DELETE_SHARE:
            return { ...state, deleteShare: action.payload };
        case Types.CLEAR_SHARE:
            return { ...state, getShare: {}, setShare: {} };

        default:
            return state;
    }
};
export default ShareReducer;
