import React from 'react';
import { Modal, Container, Row, Col, Button, Table } from 'react-bootstrap';
import { DeleteButtonRender } from '../form_template/buttons-render';
import AsyncSelect from 'react-select/async';
const OrganizationInvitationModal = (props) => {
    const { invitationText, setInvitationText } = props;
    const { invitationModal, setInvitationModal, handleSubmitInvitation, totalUsersToBeInvited } = props;

    return (
        <Modal
            show={invitationModal}
            onHide={() => {
                setInvitationModal(false);
            }}
            size="lg"
        >
            <Modal.Header closeButton>Invite {totalUsersToBeInvited} Users To Follow</Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            <label>Invitation Message</label>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <textarea
                                type="text"
                                className="form-control"
                                rows="3"
                                value={invitationText}
                                onChange={(e) => {
                                    setInvitationText(e.target.value);
                                }}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <small>
                                <b>NOTE.</b> Invitations will be sent only to the users who are not following and was never invited before.
                            </small>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    size="sm"
                    variant="outline-danger"
                    onClick={() => {
                        setInvitationModal(false);
                    }}
                >
                    Cancel
                </Button>
                &nbsp;
                <Button
                    size="sm"
                    variant="success"
                    onClick={() => {
                        handleSubmitInvitation(false);
                    }}
                >
                    SEND
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default OrganizationInvitationModal;
