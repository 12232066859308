import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { searchUsersByName } from '@Actions';
import { connect } from 'react-redux';
import { followUser, getAllFollowings } from '@Actions/follow-action';
import { defaultIndividualProfilePicture } from '@Constants/default-images';
import { createOneShare } from '@Actions/share-action';
import {
    cancelGoingPost,
    cancelInterestedPost,
    cancelLikePost,
    changePostInterest,
    getAllCommittedPersons,
    getHomeFeed,
    goingPost,
    interestedPost,
    likePost,
} from '@Actions/post-action';
import { NotificationManager } from 'react-notifications';
import { getAllSuggestedUsers } from '@Actions/user-action';
import LoadingAnim from '@Components/form_template/loading-anim';
import HomePostFeeds from '@Components/home/home-post-feeds';
import HomeOrganizationSuggestions from '@Components/home/home-suggestions';
import CommittedPersonsModal from '@Components/post/committed-persons-list';
import { postDetailsPage, userDetailsPage } from '@Constants/route-paths';
import { getAllSchedulerPosts, addPostToScheduler, removePostFromScheduler } from '@Actions/scheduler-action';
// INVITATION IMPORT
import PostInviteModal from '@Components/invitation/post-invite-modal';
import { getAllConnectedUsersId, getCoinSpendingSummary, inviteUsersToViewPost } from '@Actions/user-action';
import { countConnectedAndUnConnectedUsers, filterOnlyConnectedUsers } from '@Actions/wallet-action';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const Home = (props) => {
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [committedModal, setCommittedModal] = useState(false);
    const [committedLoading, setCommittedLoading] = useState(false);
    const [committedList, setCommittedList] = useState([]);
    const [cards, setCards] = useState({});
    const [schedulerPostsObject, setSchedulerPostsObject] = useState({});
    const [followingObject, setFollowingObject] = useState({});
    //INVITATION
    const [postInviteModal, setPostInviteModal] = useState(false);
    const [userSearchText, setUserSearchText] = useState('');
    const [toBeInvitedUsers, setToBeInvitedUsers] = useState([]);
    const [invitedPost, setInvitedPost] = useState({ postId: '', postType: '' });
    const handleFollowOrganization = (organizationId) => {
        let cards_ = cards;
        cards_[organizationId] = false;
        setCards({ ...cards_ });

        props.dispatch(followUser({ followerId: userId, followingId: organizationId }));
    };
    const handleClickCommittedButtons = (postId, type) => {
        setCommittedLoading(true);
        getAllCommittedPersons(postId, type).then((response) => {
            setCommittedModal(true);
            setCommittedLoading(false);
            if (response.success) {
                setCommittedList(response.users);
            } else {
                alert('User List Not Found');
            }
        });
    };
    useEffect(() => {
        const getInitialInfo = () => {
            setLoading(true);
            const user = props.auth.user;
            setUserId(user._id);
            props.dispatch(getHomeFeed());
            props.dispatch(getCoinSpendingSummary());
            props.dispatch(getAllSchedulerPosts(user._id));
            props.dispatch(getAllFollowings(user._id));
            props.dispatch(getAllConnectedUsersId(user._id));
            props.dispatch(getAllSuggestedUsers(user._id, 'organization', 20));
            setLoading(false);
        };
        getInitialInfo();
    }, []);
    const handleAddPostToScheduler = (postId) => {
        setLoading(true);
        props.dispatch(addPostToScheduler(userId, postId));
        const schedulerPostsObject_ = schedulerPostsObject;
        schedulerPostsObject_[postId] = true;
        setSchedulerPostsObject({ ...schedulerPostsObject_ });
        setLoading(false);
    };
    const handleRemovePostFromScheduler = (postId) => {
        setLoading(true);
        props.dispatch(removePostFromScheduler(userId, postId));
        const schedulerPostsObject_ = schedulerPostsObject;
        schedulerPostsObject_[postId] = false;
        setSchedulerPostsObject({ ...schedulerPostsObject_ });
        setLoading(false);
    };
    useEffect(() => {
        const { success } = props.getAllFollowingsResponse;
        if (success) {
            const cards = {};

            const followings = props.getAllFollowingsResponse.followings;
            for (let i = 0; i < followings.length; i++) {
                cards[followings[i].followingId] = true;
            }
            setFollowingObject(cards);
        }
    }, [props.getAllFollowingsResponse]);
    useEffect(() => {
        const { success } = props.getAllSchedulerPostsResponse;
        if (success) {
            const cards = {};
            const posts = props.getAllSchedulerPostsResponse.allSchedulerPosts;
            for (let i = 0; i < posts.length; i++) {
                cards[posts[i].postId] = true;
            }
            setSchedulerPostsObject(cards);
        }
    }, [props.getAllSchedulerPostsResponse]);
    useEffect(() => {
        const { success } = props.OrganizationSuggestionResponse;
        if (success) {
            let cards = {};
            const users = props.OrganizationSuggestionResponse.users;
            for (let i = 0; i < users.length; i++) {
                cards[users[i]._id] = true;
            }
            setCards({ ...cards });
        }
    }, [props.OrganizationSuggestionResponse]);
    const handleGotoPostDetails = (postType, postId) => {
        props.history.push(postDetailsPage(postType, postId));
    };
    const handleGotoOrganizationDetails = (userId) => {
        props.history.push(userDetailsPage('organization', userId));
    };
    const handleLikePost = (postId) => {
        props.dispatch(likePost(postId));
        props.dispatch(changePostInterest(postId, userId, 'like'));
    };
    const handleCancelLikePost = (postId) => {
        props.dispatch(cancelLikePost(postId));
        props.dispatch(changePostInterest(postId, userId, 'unlike'));
    };

    const handleInterestedPost = (postId) => {
        props.dispatch(interestedPost(postId));
        props.dispatch(changePostInterest(postId, userId, 'interested'));
    };
    const handleCancelInterestedPost = (postId) => {
        props.dispatch(cancelInterestedPost(postId));
        props.dispatch(changePostInterest(postId, userId, 'uninterested'));
    };

    const handleGoingPost = (postId) => {
        props.dispatch(goingPost(postId));
        props.dispatch(changePostInterest(postId, userId, 'going'));
    };
    const handleCancelGoingPost = (postId) => {
        props.dispatch(cancelGoingPost(postId));
        props.dispatch(changePostInterest(postId, userId, 'ungoing'));
    };
    // SHARE
    const handleCreateOneShare = (postId) => {
        props.dispatch(
            createOneShare({
                userId: userId,
                postId,
            }),
        );
        NotificationManager.success('Post is shared successfully', 'success');
    };
    // INVITATION
    const getUsers = async (searchText) => {
        let connectedUsersId = {};
        let { success } = props.getAllConnectedUsersIdResponse;
        if (success) {
            connectedUsersId = props.getAllConnectedUsersIdResponse.connectedUsersId;
        }
        //console.log('🚀 ~ file: display-post.js ~ line 124 ~ getUsers ~ connectedUsersId', connectedUsersId);
        if (searchText.length > 2) {
            const users = await searchUsersByName(searchText).then((response) => {
                if (response.success) {
                    return response.users;
                } else return [];
            });
            if (users && users.length > 0) {
                let allUsers = [];

                if (users[0]) {
                    allUsers.push(...users[0].users.slice(0, 20));
                }
                if (users[1]) {
                    allUsers.push(...users[1].users.slice(0, 20));
                }
                //console.log('223', connectedUsersId);
                //console.log('🚀 ~ file: individual-membership.js ~ line 43 ~ newPromise ~ organizations', allUsers);
                allUsers = allUsers.map((user) => {
                    let connected = false;
                    if (connectedUsersId.includes(user._id)) connected = true;
                    return {
                        value: user._id,
                        label: (
                            <Row>
                                <Col sm={2}>
                                    <img src={user.profilePicture ? user.profilePicture : defaultIndividualProfilePicture} height="30px" width="30px" />
                                </Col>
                                <Col sm={10}>
                                    {user.userType === 'individual' ? user.concatNameWithSpace : user.name}&nbsp;
                                    <small>{connected ? 'Connected' : 'Not Connected'}</small>
                                </Col>
                            </Row>
                        ),
                    };
                });
                //console.log('🚀 ~ file: individual-membership.js ~ line 252 ~ newPromise ~ organizations', allUsers);
                return allUsers;
            } else return [];
        } else return [];
    };
    const promiseOptions = (inputValue) =>
        new Promise((resolve) => {
            setTimeout(() => {
                resolve(getUsers(inputValue));
            }, 1000);
        });
    const handleChangeSearchText = async (text) => {
        //console.log(text);
        setUserSearchText(text);
    };
    const handleAddUserToInvitationList = (user) => {
        const toBeInvitedUsers_ = toBeInvitedUsers;
        toBeInvitedUsers_.push(user);
        setToBeInvitedUsers([...toBeInvitedUsers_]);
    };
    const handleDeleteUserFromInvitationList = (idx) => {
        const toBeInvitedUsers_ = toBeInvitedUsers;
        toBeInvitedUsers_.splice(idx, 1);
        setToBeInvitedUsers([...toBeInvitedUsers_]);
    };
    const handleInviteUsers = (users, message) => {
        if (!users || (users && users.length === 0)) {
            alert('No user was selected to send invitation');
        } else {
            const userList = users.map((user) => user.value);
            let { success } = props.getAllConnectedUsersIdResponse;
            if (success) {
                const { connectedUsersId } = props.getAllConnectedUsersIdResponse;
                const { totalConnected, totalNotConnected } = countConnectedAndUnConnectedUsers(userList, connectedUsersId);
                success = props.getCoinSpendingSummaryResponse;
                if (success) {
                    const { coinPerInvitation } = props.getCoinSpendingSummaryResponse.coinSpendingSummary;
                    const totalCoins = parseInt(totalNotConnected * coinPerInvitation);
                    if (totalCoins > 0) {
                        confirmAlert({
                            title: 'Warning',
                            message: `You are inviting total ${totalNotConnected} users. This will cost you ${parseInt(
                                totalNotConnected * coinPerInvitation,
                            )} Coins. Are you sure?`,
                            buttons: [
                                {
                                    label: 'YES',
                                    onClick: () => {
                                        props.dispatch(
                                            inviteUsersToViewPost(userId, {
                                                usersToBeInvited: userList,
                                                postLink: `/post/${invitedPost.postType}/details/${invitedPost.postId}`,
                                                invitationMessage: message,
                                            }),
                                        );
                                    },
                                },
                                {
                                    label: 'No',
                                    onClick: () => {
                                        setPostInviteModal(true);
                                    },
                                },
                                {
                                    label: 'Only Send to Connected Users',
                                    onClick: () => {
                                        props.dispatch(
                                            inviteUsersToViewPost(userId, {
                                                usersToBeInvited: filterOnlyConnectedUsers(userList, connectedUsersId),
                                                postLink: `/post/${invitedPost.postType}/details/${invitedPost.postId}`,
                                                invitationMessage: message,
                                            }),
                                        );
                                    },
                                },
                            ],
                        });
                    } else {
                        props.dispatch(
                            inviteUsersToViewPost(userId, {
                                usersToBeInvited: userList,
                                postLink: `/post/${invitedPost.postType}/details/${invitedPost.postId}`,
                                invitationMessage: message,
                            }),
                        );
                    }
                }
            }
        }
    };
    useEffect(() => {
        const { success, message } = props.inviteUsersToViewPostResponse;
        if (success) {
            NotificationManager.success(message, 'success');
        } else if (success === false) {
            NotificationManager.error(message, 'Failed');
        }
    }, [props.inviteUsersToViewPostResponse]);
    if (loading) return <LoadingAnim />;
    else {
        return (
            <Container fluid>
                <CommittedPersonsModal
                    committedModal={committedModal}
                    committedLoading={committedLoading}
                    committedList={committedList}
                    setCommittedModal={setCommittedModal}
                />
                <PostInviteModal
                    postInviteModal={postInviteModal}
                    setPostInviteModal={setPostInviteModal}
                    promiseOptions={promiseOptions}
                    handleChangeSearchText={handleChangeSearchText}
                    userSearchText={userSearchText}
                    toBeInvitedUsers={toBeInvitedUsers}
                    setToBeInvitedUsers={setToBeInvitedUsers}
                    handleAddUserToInvitationList={handleAddUserToInvitationList}
                    handleDeleteUserFromInvitationList={handleDeleteUserFromInvitationList}
                    handleInviteUsers={handleInviteUsers}
                />
                <Row>
                    <Col className="parent-page-home">
                        <Row>
                            <Col md="8">
                                <HomePostFeeds
                                    handleGotoPostDetails={handleGotoPostDetails}
                                    handleGotoOrganizationDetails={handleGotoOrganizationDetails}
                                    allPosts={props.homeFeedResponse.success ? props.homeFeedResponse.allPosts : []}
                                    handleLikePost={handleLikePost}
                                    handleCancelLikePost={handleCancelLikePost}
                                    handleInterestedPost={handleInterestedPost}
                                    handleCancelInterestedPost={handleCancelInterestedPost}
                                    handleGoingPost={handleGoingPost}
                                    handleCancelGoingPost={handleCancelGoingPost}
                                    userId={userId}
                                    handleClickCommittedButtons={handleClickCommittedButtons}
                                    schedulerPostsObject={schedulerPostsObject}
                                    handleAddPostToScheduler={handleAddPostToScheduler}
                                    handleRemovePostFromScheduler={handleRemovePostFromScheduler}
                                    followingObject={followingObject}
                                    handleCreateOneShare={handleCreateOneShare}
                                    setPostInviteModal={setPostInviteModal}
                                    setInvitedPost={setInvitedPost}
                                />
                            </Col>
                            <Col md="4">
                                <HomeOrganizationSuggestions
                                    allOrganizations={
                                        props.OrganizationSuggestionResponse && props.OrganizationSuggestionResponse.success
                                            ? props.OrganizationSuggestionResponse.users
                                            : []
                                    }
                                    cards={cards}
                                    handleFollowOrganization={handleFollowOrganization}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
};
const mapStateToProps = (state) => {
    const getAllFollowingsResponse = state.Follow.getAllFollowings;
    const homeFeedResponse = state.Post.homeFeed;
    const PostSuggestionResponse = state.Post.getAllSuggestions;
    const OrganizationSuggestionResponse = state.User.getAllSuggestedUsers;
    const getAllSchedulerPostsResponse = state.Scheduler.getAllSchedulerPosts;
    const setPostShareResponse = state.Share.setShare;
    const inviteUsersToViewPostResponse = state.User.inviteUsersToViewPost;
    const getAllConnectedUsersIdResponse = state.User.getAllConnectedUsersId;
    const getCoinSpendingSummaryResponse = state.User.getCoinSpendingSummary;
    return {
        getAllFollowingsResponse,
        homeFeedResponse,
        PostSuggestionResponse,
        OrganizationSuggestionResponse,
        getAllSchedulerPostsResponse,
        setPostShareResponse,
        inviteUsersToViewPostResponse,
        getAllConnectedUsersIdResponse,
        getCoinSpendingSummaryResponse,
    };
};
export default connect(mapStateToProps, null)(Home);
