import axios from 'axios';
import * as APIPaths from '@Constants/api-paths';
class MapAreaDA {
    get_all_global_map_areas = () => {
        console.log(APIPaths.getAllGlobalMapAreas);
        return axios
            .get(APIPaths.getAllGlobalMapAreas, APIPaths.apiConfig())
            .then((response) => {
                console.log('🚀 ~ file: map-area-da.js ~ line 9 ~ MapAreaDA ~ .then ~ response', response);
                return response.data;
            })
            .catch((err) => err.response.data);
    };
    generate_map_area_file = (userId, areaType) => {
        return axios
            .post(APIPaths.generateMapAreaFile(userId, areaType), {}, APIPaths.apiConfig())
            .then((response) => {
                return response.data;
            })
            .catch((err) => err.response.data);
    };
}
export default new MapAreaDA();
