/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import PostList from '@Components/post/post-list';
import LoadingAnim from '@Components/form_template/loading-anim';
import CalendarView from '@Components/calendar/calendar-view';
import SchedulerView from '@Components/calendar/scheduler-view';
import { connect } from 'react-redux';
import CalendarPostModal from '@Components/calendar/calendar-post-modal';
import CommittedPersonsModal from '@Components/post/committed-persons-list';
import { getAllPostsByFilter, getAllViewersByPost, getAllCommittedPersons } from '@Actions/post-action';
import * as RoutePaths from '@Constants/route-paths';
import ManagePostMenu from '@Components/post/manage-post-menu';
import ManagePostHeader from '@Components/post/post-list-header';
import { postDetailsPage, userDetailsPage } from '@Constants/route-paths';
const add1Minute = (time) => {
    const added = new Date(time);
    added.setMinutes(added.getMinutes() + 100);
    return added;
};
const ManagePosts = (props) => {
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState({
        postType: '',
        title: '',
        isActive: 'both',
    });
    const [viewerModal, setViewerModal] = useState(false);
    const [committedModal, setCommittedModal] = useState(false);
    const [committedLoading, setCommittedLoading] = useState(false);
    const [committedList, setCommittedList] = useState([]);
    const [postModal, setPostModal] = useState(false);
    const [post, setPost] = useState({});
    const [posts, setPosts] = useState([]);
    useEffect(() => {
        document.title = 'Manage Posts';
    }, []);
    useEffect(() => {
        const getInitialInfo = (userId) => {
            setLoading(true);
            props.dispatch(getAllPostsByFilter({ creatorId: userId }));
            setLoading(false);
        };
        const user = props.auth.user;
        if (user && user._id);
        getInitialInfo(user._id);
    }, [props.auth]);
    const handleGoToPostEdit = (postType, postId) => {
        props.history.push(RoutePaths.postEditPage(postType, postId));
    };
    const handleGoToPostDetails = (postType, postId) => {
        props.history.push(RoutePaths.postDetailsPage(postType, postId));
    };
    const handleGoToPostCreate = (postType) => {
        props.history.push(RoutePaths.postCreatePage(postType));
    };
    const handleViewerListShow = (postId) => {
        setViewerModal(true);
        props.dispatch(getAllViewersByPost(postId));
    };
    const handleClickCommittedButtons = (postId, type) => {
        //console.log('🚀 ~ file: home.js ~ line 20 ~ handleClickCommittedButtons ~ postId, type', postId, type);
        setCommittedLoading(true);
        getAllCommittedPersons(postId, type).then((response) => {
            //console.log('🚀 ~ file: home.js ~ line 22 ~ getAllCommittedPersons ~ response', response);
            setCommittedModal(true);
            setCommittedLoading(false);
            if (response.success) {
                setCommittedList(response.users);
            } else {
                alert('User List Not Found');
            }
        });
    };
    const handleGotoPostDetails = (postType, postId) => {
        props.history.push(postDetailsPage(postType, postId));
    };
    const handleGotoOrganizationDetails = (userId) => {
        props.history.push(userDetailsPage('organization', userId));
    };
    useEffect(() => {
        const { success } = props.getAllPostsResponse;

        if (success) {
            const allPosts = props.getAllPostsResponse.allPosts;
            if (allPosts && allPosts.length > 0) {
                const allPosts_ = allPosts
                    .filter((post) => post.scheduledPublish)
                    .map((p) => {
                        return {
                            ...p,
                            id: p._id.toString(),
                            scheduledPublishDateTime: p.scheduledPublishDateTime ? new Date(p.scheduledPublishDateTime) : new Date(p.createdAt),
                            scheduledPostEndTime: p.scheduledPublishDateTime
                                ? new Date(add1Minute(p.scheduledPublishDateTime))
                                : new Date(add1Minute(p.createdAt)),
                            // startDateTime: new Date(p.startDateTime),
                            // endDateTime: new Date(p.endDateTime),
                        };
                    });
                //console.log('🚀 ~ file: post-calender-view.js ~ line 144 ~ useEffect ~ props.homeFeedResponse', allPosts_);
                setPosts(allPosts_);
            }
        }
    }, [props.getAllPostsResponse]);
    if (loading) return <LoadingAnim />;
    else {
        return (
            <Container>
                <Row>
                    <Col className="parent-page">
                        <CalendarPostModal
                            postModal={postModal}
                            setPostModal={setPostModal}
                            post={post}
                            handleGotoPostDetails={handleGotoPostDetails}
                            handleGotoOrganizationDetails={handleGotoOrganizationDetails}
                            hidePostInteraction={true}
                        />
                        <ManagePostHeader
                            allPosts={props.getAllPostsResponse.success ? props.getAllPostsResponse.allPosts : []}
                            handleGoToPostCreate={handleGoToPostCreate}
                        />
                        <hr />
                        <ManagePostMenu selected="scheduler" />
                        <CommittedPersonsModal
                            committedModal={committedModal}
                            committedLoading={committedLoading}
                            committedList={committedList}
                            setCommittedModal={setCommittedModal}
                        />
                        <SchedulerView
                            // posts={props.getAllPostsResponse.success ? props.getAllPostsResponse.allPosts : []}
                            posts={posts}
                            setPost={setPost}
                            setPostModal={setPostModal}
                        />
                    </Col>
                </Row>
            </Container>
        );
    }
};
const mapStateToProps = (state) => {
    const getAllPostsResponse = state.Post.getAllPosts;
    const getAllViewersResponse = state.Post.getAllViewersByPost;
    //console.log('🚀 ~ file: manage-posts.js ~ line 21 ~ mapStateToProps ~ getAllPostsResponse', getAllPostsResponse);

    return {
        getAllPostsResponse,
        getAllViewersResponse,
    };
};
export default connect(mapStateToProps, null)(ManagePosts);
