import React from 'react';
import { Row, Col } from 'react-bootstrap';
const IndividualInfoHeader = (props) => {
    const { individual, pageTitle } = props;
    if (individual._id && individual.userType === 'individual') {
        const basicInfo = individual.basicInfo;
        return (
            <>
                <Row>
                    <Col sm={9}>
                        <h3>{basicInfo.firstName + ' ' + basicInfo.lastName}</h3>
                    </Col>
                    <Col sm={3} className="right-align">
                        <h5>{pageTitle}</h5>
                    </Col>
                </Row>
                <hr />
            </>
        );
    } else return <></>;
};

export default IndividualInfoHeader;
