import { defaultIndividualProfilePicture, defaultOrganizationProfilePicture } from '@Constants/default-images';
import React, { useState } from 'react';
import { Col, Table, Container, Image, Modal, Row, Button } from 'react-bootstrap';
const UserListModal = ({ userListModal, users, modalType, setUserListModal, messageOptionInModal, handleSubmitBulkMessage }) => {
    const [messageText, setMessageText] = useState('');

    return (
        <Modal
            style={{ zIndex: 10000 }}
            show={userListModal}
            onHide={() => {
                setUserListModal(false);
            }}
        >
            <Modal.Header closeButton>
                {users.length} {modalType ? modalType : 'User'}
                {users.length > 1 ? 's' : ''} found
            </Modal.Header>
            <Modal.Body>
                <Table striped bordered hover responsive size="sm">
                    <tbody>
                        {users &&
                            users.length > 0 &&
                            users.map((user, i) => {
                                let name = '';
                                let profilePicture = '';
                                if (user.userType === 'individual') {
                                    name = user.firstName + ' ' + user.lastName;
                                    profilePicture = user.profilePicture ? user.profilePicture : defaultIndividualProfilePicture;
                                } else if (user.userType === 'organization') {
                                    name = user.name;
                                    profilePicture = user.profilePicture ? user.profilePicture : defaultOrganizationProfilePicture;
                                }
                                return (
                                    <tr key={i}>
                                        <td style={{ width: '10%' }}>
                                            <Image src={profilePicture} rounded width="40" />
                                        </td>
                                        <td style={{ width: '90%' }}>{name}</td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </Table>
                <hr />
                {messageOptionInModal && (
                    <>
                        <b>Send Message</b>
                        <br />
                        <textarea
                            type="text"
                            className="form-control"
                            rows="3"
                            value={messageText}
                            onChange={(e) => {
                                setMessageText(e.target.value);
                            }}
                        />
                        <br />
                        <Button
                            size="sm"
                            onClick={() => {
                                handleSubmitBulkMessage(messageText);
                            }}
                        >
                            SEND
                        </Button>
                    </>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default UserListModal;
