import React, { useState } from 'react';
import { Container, Row, Col, Card, Accordion, Button, Table, CardDeck } from 'react-bootstrap';
import { FacebookIconRender, TwitterIconRender, InstagramIconRender, LinkedInIconRender } from '../form_template/icon-render';
import { Field } from 'redux-form';
import { SwitchRenderWithoutColumn } from '../form_template/input-render';

const PostSocialMediaManagement = (props) => {
    const { socialMediaConnectionInfo } = props;
    const { setTemplatePreviewModal } = props;
    return (
        <Accordion defaultActiveKey="0">
            <Card>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                    Social Media Sharing
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                    <Card.Body className="long-horizontal">
                        <Row>
                            <Col sm="3">
                                <FacebookIconRender />
                            </Col>

                            {socialMediaConnectionInfo.facebook ? (
                                <>
                                    <Col sm="3">
                                        <Field
                                            name="socialMediaSharing.facebook.sharePost"
                                            id="sharePostOnFacebook"
                                            type="checkbox"
                                            component={SwitchRenderWithoutColumn}
                                            label="Publish"
                                        />
                                    </Col>
                                    <Col sm="6" className="right-align">
                                        <CustomizeButtonRender
                                            socialMedia={'facebook'}
                                            setSelectedTemplate={props.setSelectedTemplate}
                                            setTemplateModal={props.setTemplateModal}
                                            setDescriptionModal={props.setDescriptionModal}
                                        />
                                    </Col>
                                </>
                            ) : (
                                <>Not connected</>
                            )}
                        </Row>
                        <Row>
                            <Col sm="3">
                                <TwitterIconRender />
                            </Col>

                            {socialMediaConnectionInfo.twitter ? (
                                <>
                                    <Col sm="3">
                                        <Field
                                            name="socialMediaSharing.twitter.sharePost"
                                            id="sharePostOnTwitter"
                                            type="checkbox"
                                            component={SwitchRenderWithoutColumn}
                                            label="Publish"
                                        />
                                    </Col>
                                    <Col sm="6" className="right-align">
                                        <CustomizeButtonRender
                                            socialMedia={'twitter'}
                                            setSelectedTemplate={props.setSelectedTemplate}
                                            setTemplateModal={props.setTemplateModal}
                                            setDescriptionModal={props.setDescriptionModal}
                                        />
                                    </Col>
                                </>
                            ) : (
                                <>Not connected</>
                            )}
                        </Row>
                        <Row>
                            <Col sm="3">
                                <LinkedInIconRender />
                            </Col>
                            {socialMediaConnectionInfo.linkedIn ? (
                                <>
                                    <Col sm="3">
                                        <Field
                                            name="socialMediaSharing.linkedIn.sharePost"
                                            id="sharePostOnLinkedIn"
                                            type="checkbox"
                                            component={SwitchRenderWithoutColumn}
                                            label="Publish"
                                        />
                                    </Col>
                                    <Col sm="6" className="right-align">
                                        <CustomizeButtonRender
                                            socialMedia={'linkedIn'}
                                            setSelectedTemplate={props.setSelectedTemplate}
                                            setTemplateModal={props.setTemplateModal}
                                            setDescriptionModal={props.setDescriptionModal}
                                        />
                                    </Col>
                                </>
                            ) : (
                                <>Not connected</>
                            )}
                        </Row>
                        {/* <Row>
                            <Col sm="6">
                                <TwitterIconRender />
                            </Col>
                            <Col sm="6">
                                <Field
                                    name="socialMediaSharing.instagram.sharePost"
                                    id="sharePostOnInstagram"
                                    type="checkbox"
                                    component={SwitchRenderWithoutColumn}
                                    label="Publish On Instagram"
                                    col1={4}
                                    col2={8}
                                />
                            </Col>
                        </Row> */}
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
};

export default PostSocialMediaManagement;
const CustomizeButtonRender = ({ socialMedia, setSelectedTemplate, setTemplateModal, setDescriptionModal }) => {
    return (
        <>
            <Button
                size="sm"
                variant="outline-primary"
                onClick={() => {
                    setSelectedTemplate(socialMedia);
                    setDescriptionModal(true);
                }}
            >
                Description
            </Button>
            &nbsp;
            <Button
                size="sm"
                variant="outline-primary"
                onClick={() => {
                    setSelectedTemplate(socialMedia);
                    setTemplateModal(true);
                }}
            >
                CUSTOMIZE & PREVIEW
            </Button>
        </>
    );
};

const PreviewButtonRender = ({ socialMedia, setSelectedTemplate, setTemplatePreviewModal, setDescriptionModal }) => {
    return (
        <>
            <Button
                size="sm"
                variant="outline-primary"
                onClick={() => {
                    setSelectedTemplate(socialMedia);
                    setTemplatePreviewModal(true);
                }}
            >
                PREVIEW
            </Button>
        </>
    );
};
