import React from 'react';
import { Field } from 'redux-form';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { InputRender, TextRender } from '../form_template/input-render';
import { email, required } from '../../actions/validate';
const ContactUsForm = (props) => {
    return (
        <Container>
            <Row>
                <Col className="parent-page">
                    <h2>Contact Us</h2>
                    <small>last updated on June 06, 2021</small>
                    <hr />
                    <form onSubmit={props.handleOnSubmit}>
                        <Field name="fullName" type="text" component={InputRender} label="Full Name" placeholder="Your Full Name..." validate={[required]} />
                        <Field
                            name="contactEmail"
                            type="text"
                            component={InputRender}
                            label="Contact Email"
                            placeholder="Your Contact Email..."
                            validate={[required, email]}
                        />
                        <Field name="address" type="text" component={InputRender} label="Address" placeholder="Your address..." />
                        <Field
                            name="description"
                            type="text"
                            component={TextRender}
                            label="Concern/Question"
                            placeholder="Details..."
                            col1={4}
                            col2={8}
                            validate={[required]}
                        />
                        <Row>
                            <Col sm={4}></Col>
                            <Col sm={8}>
                                <Button variant="primary" size="sm" type="submit">
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </form>
                    <br />
                </Col>
            </Row>
        </Container>
    );
};
export default ContactUsForm;
