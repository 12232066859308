import * as Types from '@Constants/reducer-types';
import MapAreaDA from '@DA/map-area-da';

export const getAllGlobalMapAreas = () => {
    console.log('CAME');
    return {
        type: Types.GET_ALL_GLOBAL_MAP_AREAS,
        payload: MapAreaDA.get_all_global_map_areas(),
    };
};
export const generateMapAreaFile = (userId, areaType) => {
    return {
        type: Types.GENERATE_MAP_AREA_FILE,
        payload: MapAreaDA.generate_map_area_file(userId, areaType),
    };
};
