import { DeleteButtonRender, DetailsButtonRender, EditButtonRender, ListButtonRender } from '@Components/form_template/buttons-render';
import moment from 'moment';
import { getPostTypeByValue } from '@Constants/post-types';
import React from 'react';
import { Col, Row, Table, Button } from 'react-bootstrap';
import { CheckIconRender, CrossIconRender } from '@Components/form_template/icon-render';
import { interestTypes } from '@Constants/interest-types';
const RecentlyEndedPosts = (props) => {
    const allRecentlyEndedPosts = props.allRecentlyEndedPosts;
    const handleViewInteractedUsers = props.handleViewInteractedUsers;
    if (allRecentlyEndedPosts && allRecentlyEndedPosts.length > 0) {
        return (
            <>
                <Row>
                    <Col>
                        <h5>RECENTLY ENDED</h5>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table striped bordered hover responsive size="sm">
                            <thead>
                                <tr className="table-active">
                                    <th>#</th>
                                    <th>Post Type</th>
                                    <th>Title</th>
                                    <th>Created On</th>
                                    <th>End Date</th>
                                    <th>Action</th>
                                    <th>Liked/ Interested</th>
                                    <th>Going</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allRecentlyEndedPosts.map((post, i) => {
                                    let liked = post.interests.filter((interest) => interest.liked).length;
                                    let interested = post.interests.filter((interest) => interest.interested).length;
                                    let going = post.interests.filter((interest) => interest.going).length;
                                    return (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{getPostTypeByValue(post.postType)[0].label}</td>
                                            <td>{post.title}</td>
                                            <td>{moment(post.createdAt).format('LLL')}</td>
                                            <td>{post.endDateTime ? moment(post.endDateTime).format('LLL') : ''}</td>

                                            <td>
                                                <DetailsButtonRender
                                                    onClick={() => {
                                                        props.handleGoToPostDetails(post.postType, post._id);
                                                    }}
                                                />{' '}
                                                &nbsp;
                                                <EditButtonRender
                                                    onClick={() => {
                                                        props.handleGoToPostEdit(post.postType, post._id);
                                                    }}
                                                />{' '}
                                            </td>
                                            <td>
                                                {interestTypes[post.postType].like && liked > 0 && (
                                                    <Button
                                                        variant="outline-primary"
                                                        size="sm"
                                                        onClick={() => {
                                                            props.handleViewInteractedUsers(post._id, 'liked');
                                                        }}
                                                    >
                                                        {liked}
                                                    </Button>
                                                )}

                                                {interestTypes[post.postType].interested && interested > 0 && (
                                                    <Button
                                                        variant="outline-secondary"
                                                        size="sm"
                                                        onClick={() => {
                                                            props.handleViewInteractedUsers(post._id, 'interested');
                                                        }}
                                                    >
                                                        {interested}
                                                    </Button>
                                                )}
                                            </td>
                                            <td>
                                                {interestTypes[post.postType].going && going > 0 && (
                                                    <Button
                                                        variant="outline-info"
                                                        size="sm"
                                                        onClick={() => {
                                                            props.handleViewInteractedUsers(post._id, 'going');
                                                        }}
                                                    >
                                                        {going}
                                                    </Button>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </>
        );
    } else return <></>;
};
export default RecentlyEndedPosts;
