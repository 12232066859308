import React from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import Avatar from 'react-avatar';
import moment from 'moment';
import { defaultIndividualProfilePicture, defaultOrganizationProfilePicture } from '@Constants/default-images';
import { DetailsButtonRender } from '../form_template/buttons-render';
const UserNameRender = (name) => {
    if (name && name.length > 30) name = name.substr(0, 27) + '...';
    return name;
};
const LastMessageRender = ({ lastMessageEntity, userId }) => {
    const { message } = lastMessageEntity;
    const { senderId, text, images, attachments, createdAt } = message;
    return messageTextRender(senderId === userId, text, createdAt);
};
const messageTextRender = (user, text, createdAt) => {
    if (text && text.length > 150) text = text.substr(0, 150) + '...';
    return (
        <>
            <td>
                {user ? 'You: ' : ''}
                {text}
            </td>
            <td>{moment(createdAt).fromNow()}</td>
        </>
    );
};
const PersonRender = ({ person }) => {
    const { userType, name, firstName, lastName, profilePicture } = person;
    let fullName = userType === 'individual' ? `${firstName ? firstName : ''} ${lastName ? lastName : ''}` : name;

    return (
        <Row>
            <Col sm={2}>
                <Avatar
                    round="5px"
                    size="32"
                    src={profilePicture ? profilePicture : userType === 'individual' ? defaultIndividualProfilePicture : defaultOrganizationProfilePicture}
                />
            </Col>
            <Col sm={10}>
                <b> {UserNameRender(fullName)}</b>
            </Col>
            <br />
        </Row>
    );
};
const TopConversations = (props) => {
    const { handleGoToConversationDetails, userId, allConversations } = props;
    const newConversations = allConversations.filter((conversation) => !conversation?.lastMessageEntity?.isRead);
    if (newConversations && newConversations.length > 0) {
        return (
            <>
                <Row>
                    <Col>
                        <h5>CONVERSATIONS</h5>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table striped bordered hover responsive size="sm">
                            <tbody>
                                {newConversations.map((conversation, i) => {
                                    let members = conversation.members;
                                    if (members && members.length > 1) {
                                        let memberObject = {};
                                        for (let i = 0; i < members.length; i++) {
                                            memberObject[members[i]._id] = members[i];
                                        }
                                        let person = {};
                                        if (members[0]._id === userId) person = members[1];
                                        else if (members[1]._id === userId) person = members[0];
                                        return (
                                            <tr key={i}>
                                                <td>
                                                    <PersonRender person={person} />
                                                </td>

                                                <LastMessageRender lastMessageEntity={conversation.lastMessageEntity} userId={userId} />

                                                <td>
                                                    <DetailsButtonRender
                                                        onClick={() => {
                                                            handleGoToConversationDetails(conversation._id);
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    }
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </>
        );
    } else {
        return <h6>No New Conversation</h6>;
    }
};
export default TopConversations;
