import React from 'react';
import { Row, Col, Badge, Image, Table, Card } from 'react-bootstrap';
import { defaultPostPicture } from '@Constants/default-images';
import { getPostTypeByValue } from '@Constants/post-types';
import { FiExternalLink } from 'react-icons/fi';
import { CheckIconRender, CrossIconRender } from './icon-render';
import moment from 'moment';
import { interestTypes } from '@Constants/interest-types';
import { LikeButtonRender, InterestedButtonRender, GoingButtonRender, FollowButtonRender, UnfollowUserButtonRender } from '../form_template/buttons-render';
import { getCountryByCode, getStateByCountryAndCode } from '@Constants/country-and-state';
export const DetailsInfoRowRender = ({ label, value }) => {
    if (value) {
        return (
            <Row className="detailsRow">
                <Col sm={3} className="detailsLabel">
                    <label>{label}</label>
                </Col>

                <Col className="detailsValue">
                    <span>{value}</span>
                </Col>
            </Row>
        );
    } else return <Row />;
};

const BadgeRender = (badgeArray, variant, className) => {
    return badgeArray.map((badge, i) => {
        if (badge)
            return (
                <Badge variant={variant} key={i} className={'badge-single ' + className}>
                    {badge.label}
                </Badge>
            );
        else return <></>;
    });
};

export const TagWithLabelRender = (label, tags) => {
    if (label && tags && tags.length > 0) {
        return (
            <Row>
                {label && (
                    <Col md="3">
                        <b>{label}</b>
                    </Col>
                )}
                <Col md="9">{BadgeRender(tags, 'primary', '')}</Col>
            </Row>
        );
    }
    if (tags && tags.length > 0) {
        return (
            <Row>
                <Col md="12">{BadgeRender(tags, 'primary', '')}</Col>
            </Row>
        );
    } else return <></>;
};

export const ImpactAreasRender = (label, areas) => {
    if (label && areas && areas.length > 0) {
        return (
            <Row>
                {label && (
                    <Col md="3">
                        <b>{label}</b>
                    </Col>
                )}
                <Col md="9">{BadgeRender(areas, 'primary', 'impact-area-badge  badge-single-small')}</Col>
            </Row>
        );
    }
    if (areas && areas.length > 0) {
        return (
            <Row>
                <Col md="12">{BadgeRender(areas, 'primary', 'impact-area-badge  badge-single-small')}</Col>
            </Row>
        );
    } else return <></>;
};
export const MapAreasRender = (label, areas) => {
    if (label && areas && areas.length > 0) {
        return (
            <Row>
                {label && (
                    <Col md="3">
                        <b>{label}</b>
                    </Col>
                )}
                <Col md="9">{BadgeRender(areas, 'primary', 'map-area-badge  badge-single-small')}</Col>
            </Row>
        );
    }
    if (areas && areas.length > 0) {
        return (
            <Row>
                <Col md="12">{BadgeRender(areas, 'primary', 'map-area-badge  badge-single-small')}</Col>
            </Row>
        );
    } else return <></>;
};

export const DescriptionRender = (label, value) => {
    const valueRender = () => {
        return (
            <>
                {value.split('\n').map((para, i) => {
                    return (
                        <p className="justify-text" key={i}>
                            {para}
                        </p>
                    );
                })}
            </>
        );
    };
    if (label && value) {
        return (
            <Row>
                <Col sm="3">{label}</Col>
                <Col sm="9">{valueRender()}</Col>
            </Row>
        );
    } else if (value) {
        return (
            <Row>
                <Col sm="12">{valueRender()}</Col>
            </Row>
        );
    } else return <></>;
};
export const ImageAndDescriptionRender = (images) => {
    if (images && images.length > 0) {
        return (
            <>
                {images.map((image, i) => {
                    return (
                        <Row key={i} className="image-description-box">
                            <Col sm="3">
                                <Image width="100%" src={image.path} thumbnail />
                            </Col>
                            <Col sm="9">{DescriptionRender('', image.description)}</Col>
                        </Row>
                    );
                })}
            </>
        );
    } else return <></>;
};
export const InfoRender = (label, value) => {
    if (label && value) {
        return (
            <Row>
                <Col sm="3">
                    <b>{label}</b>
                </Col>
                <Col sm="9">{value}</Col>
            </Row>
        );
    } else if (value) {
        return (
            <Row>
                <Col sm="12">{value}</Col>
            </Row>
        );
    } else return <></>;
};
export const LinkRender = (label, link, showSiteName = false) => {
    if (label && link) {
        return (
            <Row>
                <Col sm="3">
                    <b>{label}</b>
                </Col>
                <Col sm="9">
                    <a href={link} className="btn-outline-primary btn btn-sm" target="_blank">
                        <FiExternalLink /> {showSiteName ? link : ''}
                    </a>
                </Col>
            </Row>
        );
    } else if (link) {
        return (
            <Row>
                <Col sm="12">
                    <a href={link} target="_blank">
                        <FiExternalLink /> {showSiteName ? link : ''}
                    </a>
                </Col>
            </Row>
        );
    } else return <></>;
};

export const BooleanRender = (label, value) => {
    if (label && value) {
        return (
            <Row>
                <Col sm="3">
                    <b>{label}</b>
                </Col>
                <Col sm="9">{value ? <CheckIconRender /> : <CrossIconRender />}</Col>
            </Row>
        );
    } else if (value) {
        return (
            <Row>
                <Col sm="12">{value ? <CheckIconRender /> : <CrossIconRender />}</Col>
            </Row>
        );
    } else return <></>;
};
export const RequiredItemsRender = (items) => {
    if (items && items.length > 0) {
        return (
            <>
                <h6>Required Items</h6>
                <Table striped bordered hover responsive size="sm">
                    <thead variant="dark">
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Requirement</th>
                            <th>Needed By</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, i) => {
                            return (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{item.name}</td>
                                    <td>{item.requirement}</td>
                                    <td>{moment(item.neededBy).format('LLLL')}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </>
        );
    } else return <label>No required items found!</label>;
};
export const KeywordsRender = (label, keywords) => {
    if (keywords && keywords.length > 0) {
        return (
            <Row>
                <Col md="3">
                    <b>
                        {label}({keywords.length})
                    </b>
                </Col>
                <Col md="9">
                    {keywords.map((key, i) => {
                        return (
                            <Badge variant="primary" key={i} className="badge-single">
                                {key}
                            </Badge>
                        );
                    })}
                </Col>
            </Row>
        );
    } else return <></>;
};

export const SkillsRender = (label, skills) => {
    if (label && skills && skills.length > 0) {
        return (
            <Row>
                {label && (
                    <Col md="3">
                        <b>{label}</b>
                    </Col>
                )}
                <Col md="9">{BadgeRender(skills, 'primary', 'skill-badge  badge-single-small')}</Col>
            </Row>
        );
    }
    if (skills && skills.length > 0) {
        return (
            <Row>
                <Col md="12">{BadgeRender(skills, 'light', 'skill-badge  badge-single-small')}</Col>
            </Row>
        );
    } else return <></>;
};

export const OrganizationTypesRender = (label, organizationTypes) => {
    if (organizationTypes && organizationTypes.length > 0) {
        return (
            <Row>
                <Col md="12">{BadgeRender(organizationTypes, 'info', 'organization-type-badge  badge-single-small')}</Col>
            </Row>
        );
    } else return <></>;
};
const convertDescription = (description) => {
    let newComment = description;
    newComment = newComment.split(`@@@__`).join('<a href="/user/');
    newComment = newComment.split(`^^^__`).join('">');
    newComment = newComment.split(`@@@^^^`).join('</a>');
    return <div dangerouslySetInnerHTML={{ __html: newComment }} />;
};
const descriptionRender = (description) => {
    if (description) {
        if (description.length < 101) return description;
        else return description.substr(0, 100) + '...';
    } else return 'No description available';
};
// POST CARD
// export const PostCardRender = (props) => {
//     const post = {
//         title: 'Homecoming program of the city cricket club, Dhaka, Bangladesh',
//     };
//     return (
//         <Card className="special-btn special-card">
//             <div
//                 className="home-post-image-dark"
//                 onClick={() => {
//                     props.gotoPostDetails(post.postType, post._id);
//                 }}
//             >
//                 <Card.Img variant="top" src={post.images && post.images.length > 0 ? post.images[0].path : defaultPostPicture} alt="No Image Found" />
//                 <Badge variant="primary" className="image-text-top-left">
//                     {getPostTypeByValue(post.postType)[0].label}
//                 </Badge>
//             </div>

//             <Card.Body
//                 onClick={() => {
//                     props.gotoPostDetails(post.postType, post._id);
//                 }}
//             >
//                 <Card.Title className="center-aligned">{post.title}</Card.Title>
//                 <h6>{post.organizationName}</h6>
//                 <Card.Text className="justify-text">
//                     <small>{convertDescription(descriptionRender(post.description))}</small>
//                 </Card.Text>

//                 {ImpactAreasRender('', post.impactAreas)}
//                 <br />
//                 {post.address && InfoRender('', post.address.fullAddress)}
//                 <br />
//                 <small>
//                     {post.startDateTime && (
//                         <Row style={{ marginBottom: 5 }}>
//                             <Col sm={3}>FROM</Col>
//                             <Col sm={9} className="right-align">
//                                 <b>{moment(post.startDateTime).format('MM/DD/y hh:mm:A')}</b>
//                             </Col>
//                         </Row>
//                     )}
//                     {post.endDateTime && (
//                         <Row>
//                             <Col sm={3}>TO</Col>
//                             <Col sm={9} className="right-align">
//                                 <b>{moment(post.endDateTime).format('MM/DD/yy hh:mm:A')}</b>
//                             </Col>
//                         </Row>
//                     )}
//                 </small>
//             </Card.Body>
//             <Card.Footer>
//                 <Row>
//                     <Col>
//                         {interestTypes[post.postType].like ? (
//                             interest.liked ? (
//                                 <LikeButtonRender
//                                     complete={true}
//                                     onClick={() => {
//                                         props.handleCancelLikePost(post._id);
//                                     }}
//                                 />
//                             ) : (
//                                 <LikeButtonRender
//                                     complete={false}
//                                     onClick={() => {
//                                         props.handleLikePost(post._id);
//                                     }}
//                                 />
//                             )
//                         ) : (
//                             <></>
//                         )}
//                         &nbsp;
//                         {interestTypes[post.postType].interested ? (
//                             interest.interested ? (
//                                 <InterestedButtonRender
//                                     complete={true}
//                                     onClick={() => {
//                                         props.handleCancelInterestedPost(post._id);
//                                     }}
//                                 />
//                             ) : (
//                                 <InterestedButtonRender
//                                     complete={false}
//                                     onClick={() => {
//                                         props.handleInterestedPost(post._id);
//                                     }}
//                                 />
//                             )
//                         ) : (
//                             <></>
//                         )}
//                         &nbsp;
//                         {interestTypes[post.postType].going ? (
//                             interest.going ? (
//                                 <GoingButtonRender
//                                     complete={true}
//                                     onClick={() => {
//                                         props.handleCancelGoingPost(post._id);
//                                     }}
//                                 />
//                             ) : (
//                                 <GoingButtonRender
//                                     complete={false}
//                                     onClick={() => {
//                                         props.handleGoingPost(post._id);
//                                     }}
//                                 />
//                             )
//                         ) : (
//                             <></>
//                         )}
//                         &nbsp;
//                     </Col>
//                     {followOrganizationButton && (
//                         <Col>
//                             {followingObject[post.creatorId] ? (
//                                 <UnfollowUserButtonRender
//                                     onClick={() => {
//                                         props.handleUnfollowClick(post.creatorId);
//                                     }}
//                                 />
//                             ) : (
//                                 <FollowButtonRender
//                                     onClick={() => {
//                                         props.handleFollowClick(post.creatorId);
//                                     }}
//                                 />
//                             )}
//                         </Col>
//                     )}
//                 </Row>
//             </Card.Footer>
//         </Card>
//     );
// };

// POST BOX (Home Page)
export const PostBoxRender = (props) => {};

export const PublicAddressRender = (label, address) => {
    const { code, city, state, country } = address;
    let col1 = 0,
        col2 = 12;
    if (label) {
        col1 = 3;
        col2 = 9;
    }
    if (label || address)
        return (
            <Row>
                {label && (
                    <Col sm={col1}>
                        <b>{label}</b>
                    </Col>
                )}
                <Col sm={col2}>
                    {
                        <p>
                            {code}
                            <br />
                            {city}, {getStateByCountryAndCode(country, state)}
                            <br />
                            {getCountryByCode(country)}
                        </p>
                    }
                </Col>
            </Row>
        );
    else return <></>;
};
