import { getTitleByType } from '@Actions/notification-action';
import moment from 'moment';
import React, { useState } from 'react';
import { Col, Container, Image, ListGroup, Row, Button } from 'react-bootstrap';
import { DeleteButtonRender, ListButtonRender, ReadButtonRender, UnreadButtonRender, UndoButtonRender } from '../form_template/buttons-render';
import UserListModal from './user-list-modal';
import { getDefaultPP } from '../../constants/default-images';
const NotificationList = (props) => {
    const allNotifications = props.allNotifications;
    const deletedNotifications = props.deletedNotifications;
    console.log('🚀 ~ file: notification-list.js ~ line 11 ~ NotificationList ~ deletedNotifications', deletedNotifications);
    const [userListModal, setUserListModal] = useState(false);
    const [users, setUsers] = useState([]);
    const handleListModal = (users, modal) => {
        setUsers([...users]);
        setUserListModal(modal);
    };
    return (
        <Container>
            <Row>
                <UserListModal userListModal={userListModal} users={users} setUserListModal={setUserListModal} />
                <Col className="parent-page">
                    <Row>
                        <Col sm="6">
                            {allNotifications && allNotifications.length > 0 ? (
                                <h4>{allNotifications.length} Notifications Found</h4>
                            ) : (
                                <h4>No Notification Found</h4>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                size="sm"
                                variant="outline-info"
                                onClick={() => {
                                    props.handleReadAllNotifications();
                                }}
                            >
                                Mark All As Read
                            </Button>
                        </Col>
                    </Row>
                    <br />
                    {allNotifications &&
                        allNotifications.length > 0 &&
                        allNotifications.map((notification, i) => {
                            const sender = notification.senders[0].userId;
                            const userType = sender.userType;
                            let name = '';
                            if (userType === 'individual') name = sender.basicInfo.firstName + ' ' + sender.basicInfo.lastName;
                            else if (userType === 'organization') name = sender.basicInfo.name;
                            const profilePicture = sender.basicInfo.profilePicture ? sender.basicInfo.profilePicture : getDefaultPP(userType);
                            const more = notification.senders.length - 1;
                            const postType = notification.postId && notification.postId.postType ? notification.postId.postType : '';
                            return (
                                <ListGroup horizontal="xl" className="my-1" key={i} style={{ width: '100%' }}>
                                    <ListGroup.Item
                                        // className="col-sm-8"
                                        action
                                        onClick={() => {
                                            props.handleGoToNotificationDetails(notification);
                                        }}
                                    >
                                        <Row>
                                            <Col md="2">
                                                <Image src={profilePicture} width="50%" thumbnail className="notification-image" />
                                            </Col>
                                            <Col md="10">
                                                <h6>
                                                    {notification.isRead ? (
                                                        getTitleByType(notification.type, name, more, postType)
                                                    ) : (
                                                        <b>{getTitleByType(notification.type, name, more, postType)}</b>
                                                    )}
                                                </h6>
                                                {notification.postId && notification.postId.title}
                                                <br />
                                                <small>{moment(notification.createdAt).format('LLLL')}</small>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item className="col-sm-2">
                                        <ListButtonRender
                                            onClick={() => {
                                                handleListModal(notification.senders, true);
                                            }}
                                            title="View List"
                                        />
                                        &nbsp;
                                        {notification.isRead ? (
                                            <UnreadButtonRender
                                                onClick={() => {
                                                    props.handleNotificationUnread(notification._id);
                                                }}
                                            />
                                        ) : (
                                            <ReadButtonRender
                                                onClick={() => {
                                                    props.handleNotificationRead(notification._id);
                                                }}
                                            />
                                        )}
                                        &nbsp;
                                        {deletedNotifications[notification._id] ? (
                                            <UndoButtonRender
                                                onClick={() => {
                                                    props.handleUndoNotificationDelete(notification._id);
                                                }}
                                                hover_title="Undo Delete"
                                            />
                                        ) : (
                                            <DeleteButtonRender
                                                onClick={() => {
                                                    props.handleNotificationDelete(notification._id);
                                                }}
                                            />
                                        )}
                                    </ListGroup.Item>
                                </ListGroup>
                            );
                        })}
                </Col>
            </Row>
        </Container>
    );
};
export default NotificationList;
