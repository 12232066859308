import { getPostTypeByValue, postTypeFields } from '@Constants/post-types';
import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Field } from 'redux-form';
import { MentionsInput, Mention } from 'react-mentions';
import {
    CheckBoxRender,
    SwitchRender,
    CreatableMultiSelectRender,
    DateTimePickerRender,
    InputRender,
    InputRenderWithLargeLabel,
    TextRender,
    ScheduleTimeRender,
} from '../form_template/input-render';
import PostGeoCoding from './post-geo-coding';
import PostImageList from './post-image-list';
import RequiredItemList from './required-item-list';
import { MentionStyle } from '@Constants/styles';
import SearchDA from '@DA/search-da';
import PostSocialMediaManagement from './post-social-media-management';
import PostScheduleModal from './post-schedule-modal';
import moment from 'moment';
function fetchUsers(query, callback) {
    if (!query) return;
    SearchDA.search_by_name(query)
        .then((response) => {
            //console.log('🚀 ~ file: facebook-login.js ~ line 9 ~ SearchDA.search_by_name ~ response', response);
            if (response.success) {
                const users = response.users;
                let merged = [];
                if (users[0] && users[0].users) merged = merged.concat(users[0].users);
                if (users[1] && users[1].users) merged = merged.concat(users[1].users);
                merged = merged.slice(0, 20);
                return merged.map((user) => {
                    return user.userType === 'individual' ? { display: user.concatNameWithSpace, id: user._id } : { display: user.name, id: user._id };
                });
            } else return [];
        })
        .then(callback);
}
let style = {
    input: {
        overflow: 'auto',
        height: 200,
        // zIndex: 5100,
    },
    highlighter: {
        boxSizing: 'border-box',
        overflow: 'hidden',
        height: 200,
        // zIndex: 5200,
    },
};

const PostForm = (props) => {
    //console.log(props);
    const usersName = props.usersName;
    const description = props.description;
    console.log('🚀 ~ file: post-form.js ~ line 60 ~ PostForm ~ description', { description });
    const setDescription = props.setDescription;
    const allImpactAreas = props.allImpactAreas;
    const allSkills = props.allSkills;
    const images = props.images;
    const requiredItems = props.requiredItems;
    const editMode = props.editMode;
    const postType = props.postType;
    const postTypeLabel = getPostTypeByValue(postType)[0].label;
    const fields = postTypeFields[postType];

    const { socialMediaConnectionInfo } = props;
    const [scheduleModal, setScheduleModal] = useState(false);
    return (
        <Container>
            <Row>
                <Col sm="1"></Col>
                <Col className="parent-page">
                    <form onSubmit={props.handleOnSubmit}>
                        <PostScheduleModal
                            scheduleModal={scheduleModal}
                            setScheduleModal={setScheduleModal}
                            handleChangeReduxField={props.handleChangeReduxField}
                            onSubmit={props.handleOnSubmit}
                        />
                        <Row>
                            <Col>{editMode ? <h4>Edit {postTypeLabel}</h4> : <h4>Create {postTypeLabel}</h4>}</Col>

                            <Col className="right-align">
                                <Button
                                    size="sm"
                                    variant="outline-primary"
                                    onClick={() => {
                                        props.handleGoToManagePosts();
                                    }}
                                >
                                    Manage Posts
                                </Button>
                            </Col>
                        </Row>
                        <br />
                        {/* <label>{description}</label> */}
                        {fields.title && <Field name="title" type="text" component={InputRender} label="Title" placeholder="Title..." />}
                        {fields.startDateTime && (
                            <Field
                                name="startDateTime"
                                component={DateTimePickerRender}
                                label="Start time"
                                col1={4}
                                col2={8}
                                zIndex={6001}
                                onChange={props.handleOnStartDateTimeChange}
                            />
                        )}
                        {fields.endDateTime && <Field name="endDateTime" component={DateTimePickerRender} label="End time" col1={4} col2={8} zIndex={6000} />}
                        {fields.description && (
                            // <Field name="description" type="text" component={TextRender} label="Description" placeholder="Description..." col1={4} col2={8} />
                            <Row>
                                {/* <Col sm={12}>
                                    <label>{convertDescription(description)}</label>
                                </Col>
                                <br /> */}
                                <Col sm={4}>Description</Col>
                                <Col sm={8} style={{ zIndex: 5000 }}>
                                    <MentionsInput
                                        value={description}
                                        onChange={(e) => {
                                            setDescription(e.target.value);
                                        }}
                                        style={MentionStyle}
                                        a11ySuggestionsListLabel={'Suggested users for mention'}
                                    >
                                        <Mention
                                            markup="@@@____id__^^^____display__@@@^^^"
                                            trigger="@"
                                            data={fetchUsers}
                                            style={{ backgroundColor: '#cee4e5' }}
                                        />
                                    </MentionsInput>
                                </Col>

                                <br />
                            </Row>
                        )}
                        <br />
                        {fields.postURL && <Field name="postURL" type="text" component={InputRender} label="Post URL" placeholder="URL..." />}
                        {fields.contact && <Field name="contact" type="text" component={InputRender} label="Contact Info" placeholder="Phone/email..." />}
                        {fields.skills && (
                            <Field
                                name="skills"
                                component={CreatableMultiSelectRender}
                                label="Skill"
                                col1={4}
                                col2={8}
                                options={allSkills}
                                zIndex={5000}
                                menuPlacement="top"
                            />
                        )}
                        {fields.keywords && (
                            <Field
                                name="keywords"
                                type="text"
                                component={CreatableMultiSelectRender}
                                label="Keywords"
                                col1={4}
                                col2={8}
                                placeholder="Add new keywords"
                                zIndex={2000}
                            />
                        )}
                        {fields.petitionLink && <Field name="petitionLink" type="text" component={InputRender} label="Petition Link" placeholder="" />}
                        {fields.impactAreas && (
                            <>
                                <Row>
                                    <Col sm={4}></Col>
                                    <Col sm={8}>
                                        <Button
                                            variant="light"
                                            size="sm"
                                            onClick={() => {
                                                props.handleAddMineImpactAreas();
                                            }}
                                        >
                                            Auto select my impact areas
                                        </Button>
                                    </Col>
                                </Row>
                                <Field
                                    name="impactAreas"
                                    component={CreatableMultiSelectRender}
                                    label="Impact areas"
                                    col1={4}
                                    col2={8}
                                    options={allImpactAreas}
                                    zIndex={4000}
                                    menuPlacement="top"
                                />
                            </>
                        )}
                        {fields.expectedRequiredHours && (
                            <Field
                                name="expectedRequiredHours"
                                component={InputRenderWithLargeLabel}
                                type="number"
                                unit="Hours"
                                label="Expected Required Time"
                                min={0}
                                max={100}
                                step={0.25}
                            />
                        )}
                        {fields.topNeed && <Field name="topNeed" id="topNeed" type="checkbox" component={SwitchRender} label="Top Need" col1={4} col2={8} />}
                        {fields.requiredItems && (
                            <>
                                <RequiredItemList
                                    requiredItems={requiredItems}
                                    handleItemEdit={props.handleItemEdit}
                                    handleItemDelete={props.handleItemDelete}
                                    handleItemPosition={props.handleItemPosition}
                                />
                                <Row>
                                    <Col>
                                        <Button
                                            size="sm"
                                            onClick={() => {
                                                props.handleAddItem();
                                            }}
                                        >
                                            Add New Required Item
                                        </Button>
                                    </Col>
                                    <Col sm="8"></Col>
                                </Row>
                            </>
                        )}
                        {fields.images && (
                            <>
                                <div style={{ height: 50 }} />
                                <PostImageList
                                    images={images}
                                    handleImageDescriptionEdit={props.handleImageDescriptionEdit}
                                    handleImageDelete={props.handleImageDelete}
                                    handleImagePosition={props.handleImagePosition}
                                />
                                {/* Upload Image */}
                                <Row>
                                    <Col>
                                        <div className="custom-file">
                                            <input type="file" className="custom-file-input" id="inputGroupFile02" onChange={props.handlePictureUpload} />
                                            <label className="custom-file-label" htmlFor="inputGroupFile02">
                                                Choose profile picture
                                            </label>
                                        </div>
                                    </Col>
                                    <Col sm="8"></Col>
                                </Row>
                            </>
                        )}
                        <br />
                        {fields.address && (
                            <>
                                <Field
                                    name="address.fullAddress"
                                    type="text"
                                    component={InputRender}
                                    label="Location Name"
                                    placeholder="House, Road, Area, Sector, ZIP, City, State, Country..."
                                />
                                <Row>
                                    <Col sm={4}></Col>
                                    <Col sm={8}>
                                        <PostGeoCoding selectedLocation={props.location} handleLocationChange={props.setLocation} />
                                    </Col>
                                </Row>
                            </>
                        )}
                        {/* <Field
                            name="scheduledPublish"
                            id="scheduledPublish"
                            type="checkbox"
                            component={SwitchRender}
                            label="Schedule Post"
                            col1={4}
                            col2={8}
                            onChange={() => {
                                setScheduledPublish(!scheduledPublish);
                            }}
                        />
                        {scheduledPublish && (
                            <Field name="scheduledPublishDateTime" component={DateTimePickerRender} label="Publish date time" col1={4} col2={8} zIndex={1001} />
                        )} */}
                        <PostSocialMediaManagement
                            socialMediaConnectionInfo={socialMediaConnectionInfo}
                            setSelectedTemplate={props.setSelectedTemplate}
                            setTemplateModal={props.setTemplateModal}
                            setTemplatePreviewModal={props.setTemplatePreviewModal}
                            setDescriptionModal={props.setDescriptionModal}
                        />
                        <div style={{ height: 50 }} />
                        {/* Buttons */}
                        <Row>
                            <Col>
                                {/* {post.scheduledPublish && post.scheduledPublishDateTime && (
                                    <label>Post is scheduled for publish at {moment(post.scheduledPublishDateTime).format('LLL')}</label>
                                )} */}
                                <Field name="scheduledPublishDateTime" component={ScheduleTimeRender} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button
                                    variant="warning"
                                    size="sm"
                                    type="submit"
                                    onClick={async () => {
                                        await props.handleChangeReduxField('scheduledPublish', false);
                                        await props.handleChangeReduxField('scheduledPublishDateTime', '');
                                        await props.handleChangeReduxField('isActive', false);
                                    }}
                                >
                                    Save as Draft
                                </Button>
                                &nbsp;
                                <Button
                                    variant="primary"
                                    size="sm"
                                    type="submit"
                                    onClick={async () => {
                                        await props.handleChangeReduxField('scheduledPublish', false);
                                        await props.handleChangeReduxField('scheduledPublishDateTime', '');
                                        await props.handleChangeReduxField('isActive', true);
                                    }}
                                >
                                    {editMode ? 'Update and Publish' : 'Save and Publish'}
                                </Button>
                                &nbsp;
                                <Button
                                    size="sm"
                                    variant="info"
                                    type="button"
                                    onClick={() => {
                                        setScheduleModal(true);
                                    }}
                                >
                                    Schedule Publish Time
                                </Button>
                            </Col>
                        </Row>
                    </form>
                </Col>
                <Col sm="1"></Col>
            </Row>
        </Container>
    );
};
export default PostForm;
