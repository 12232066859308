import * as Types from '../constants/reducer-types';

const initialState = {
    getOneConnection: {},
    getOneOppositeConnection: {},
    updateOneConnection: {},
    updateOneOppositeConnection: {},
    getAllConnections: {},
};

const ConnectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.GET_ONE_CONNECTION:
            return { ...state, getOneConnection: action.payload };
        case Types.GET_ONE_OPPOSITE_CONNECTION:
            return { ...state, getOneOppositeConnection: action.payload };
        case Types.UPDATE_ONE_CONNECTION:
            return { ...state, updateOneConnection: action.payload };
        case Types.UPDATE_ONE_OPPOSITE_CONNECTION:
            return { ...state, updateOneOppositeConnection: action.payload };
        case Types.GET_ALL_CONNECTIONS:
            return { ...state, getAllConnections: action.payload };

        default:
            return { ...state };
    }
};
export default ConnectionReducer;
