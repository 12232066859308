import axios from 'axios';
import * as APIPaths from '@Constants/api-paths';
class PasswordDA {
    change_password = (passwords) => {
        return axios
            .put(APIPaths.changePassword, passwords, APIPaths.apiConfig())
            .then((response) => response.data)
            .catch((err) => err.response.data);
    };
    recover_password = (email) => {
        return axios
            .post(APIPaths.recoverPassword, email)
            .then((response) => response.data)
            .catch((err) => err.response.data);
    };
    reset_password = (passwords) => {
        return axios
            .post(APIPaths.resetPassword, passwords)
            .then((response) => response.data)
            .catch((err) => err.response.data);
    };
}

export default new PasswordDA();
