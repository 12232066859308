import React from 'react';
import { Field } from 'redux-form';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { InputRender } from '../form_template/input-render';
import { required, stringLengthRange } from '@Actions/validate';

const stringRange6To100 = stringLengthRange(6, 100);

const ChangePasswordForm = (props) => {
    return (
        <Container className="saLoginForm">
            <form onSubmit={props.handleOnSubmit}>
                <Row>
                    <Col></Col>
                    <Col md="6" className="sign-ing-form">
                        <h4>Change Password</h4>

                        <br />
                        {props.userLoggedIn && (
                            <Field name="oldPassword" type="password" component={InputRender} label="Old Password" validate={[required, stringRange6To100]} />
                        )}
                        <Field name="password" type="password" component={InputRender} label="New Password" validate={[required, stringRange6To100]} />

                        <Field
                            name="confirmPassword"
                            type="password"
                            component={InputRender}
                            label="Confirm Password"
                            validate={[required, stringRange6To100]}
                        />
                        <Row>
                            <Col sm={4}></Col>
                            <Col sm={8}>
                                <Button type="submit" size="sm" variant="primary">
                                    SUBMIT
                                </Button>
                            </Col>
                        </Row>
                        <div style={{ height: 25 }} />
                    </Col>
                    <Col></Col>
                </Row>
            </form>
        </Container>
    );
};
export default ChangePasswordForm;
