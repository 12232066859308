/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { likePost, cancelLikePost, interestedPost, cancelInterestedPost, goingPost, cancelGoingPost, changePostInterest } from '@Actions/post-action';
import PostListView from '@Components/post/post-card-view';
import { getUserPublicInfo } from '@Actions/user-action';
import { getAllPostsByFilter } from '@Actions/post-action';
import { defaultIndividualProfilePicture } from '@Constants/default-images';
import { followUser, unfollowUser, checkIfFollower, getAllFollowings } from '@Actions/follow-action';
import { searchUsersByName } from '@Actions';
import SideSubMenu from '@Components/organization/organization-sub-menu';
import { connect } from 'react-redux';
import LoadingAnim from '@Components/form_template/loading-anim';
import { postDetailsPage } from '@Constants/route-paths';
import { createOneShare } from '@Actions/share-action';
import { getAllSchedulerPosts, addPostToScheduler, removePostFromScheduler } from '@Actions/scheduler-action';
import { NotificationManager } from 'react-notifications';
import OrganizationInfoHeader from '@Components/organization/organization-info-header';
// INVITATION IMPORT
import PostInviteModal from '@Components/invitation/post-invite-modal';
import { getAllConnectedUsersId, getCoinSpendingSummary, inviteUsersToViewPost } from '@Actions/user-action';
import { countConnectedAndUnConnectedUsers, filterOnlyConnectedUsers } from '@Actions/wallet-action';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
const OrganizationFeed = (props) => {
    const [loading, setLoading] = useState(false);
    const [follows, setFollows] = useState(false);
    const [userId, setUserId] = useState('');
    const [organizationId, setOrganizationId] = useState('');
    const [allPosts, setAllPosts] = useState([]);
    const [schedulerPostsObject, setSchedulerPostsObject] = useState({});
    //INVITATION
    const [postInviteModal, setPostInviteModal] = useState(false);
    const [userSearchText, setUserSearchText] = useState('');
    const [toBeInvitedUsers, setToBeInvitedUsers] = useState([]);
    const [invitedPost, setInvitedPost] = useState({ postId: '', postType: '' });
    const gotoPostDetails = (postType, postId) => {
        props.history.push(postDetailsPage(postType, postId));
    };
    useEffect(() => {
        const getInitialInfo = () => {
            setLoading(true);
            //console.log('Came here');
            const organizationId = props.match.params.userId;
            props.dispatch(getUserPublicInfo(organizationId));
            const user = props.auth.user;
            setUserId(user._id);
            props.dispatch(checkIfFollower(user._id, props.match.params.userId));
            props.dispatch(getAllSchedulerPosts(user._id));
            props.dispatch(getAllFollowings(user._id));
            props.dispatch(getCoinSpendingSummary());
            props.dispatch(getAllConnectedUsersId(user._id));
            setLoading(false);
        };
        getInitialInfo();
    }, []);
    useEffect(() => {
        setLoading(true);
        const userId = props.match.params.userId;
        setOrganizationId(userId);
        props.dispatch(getAllPostsByFilter({ creatorId: userId, isActive: true }));
        setLoading(false);
    }, [props.match.params.userId]);
    useEffect(() => {
        const success = props.getCheckIfFollowerResponse;
        if (success) {
            setFollows(props.getCheckIfFollowerResponse.follows);
        }
    }, [props.getCheckIfFollowerResponse]);

    const handleFollowClick = () => {
        setLoading(true);
        const user = props.auth.user;
        props.dispatch(followUser({ followerId: user._id, followingId: props.match.params.userId }));
        setLoading(false);
    };
    const handleUnfollowClick = () => {
        setLoading(true);
        const user = props.auth.user;
        props.dispatch(unfollowUser({ followerId: user._id, followingId: props.match.params.userId }));
        setLoading(false);
    };
    useEffect(() => {
        const { success } = props.getFollowResponse;
        if (success) {
            setFollows(true);
        } else if (success === false) {
        }
    }, [props.getFollowResponse]);
    useEffect(() => {
        const { success } = props.getUnfollowResponse;

        if (success) {
            setFollows(false);
            // NotificationManager.success('You are unfollowing', 'success');
        } else if (success === false) {
            // NotificationManager.success('Server connection error', 'failed');
        }
    }, [props.getUnfollowResponse]);
    useEffect(() => {
        const { success } = props.getAllPostsResponse;
        if (success) {
            let allFilteredPosts = props.getAllPostsResponse.allPosts;
            setAllPosts([...allFilteredPosts]);
        } else {
            setAllPosts([]);
        }
    }, [props.getAllPostsResponse]);
    const gotoPage = (url) => {
        props.history.push(url);
    };
    const handleLikePost = (postId) => {
        props.dispatch(likePost(postId));
        props.dispatch(changePostInterest(postId, userId, 'like'));
    };
    const handleCancelLikePost = (postId) => {
        props.dispatch(cancelLikePost(postId));
        props.dispatch(changePostInterest(postId, userId, 'unlike'));
    };

    const handleInterestedPost = (postId) => {
        props.dispatch(interestedPost(postId));
        props.dispatch(changePostInterest(postId, userId, 'interested'));
    };
    const handleCancelInterestedPost = (postId) => {
        //console.log('🚀 ~ file: home.js ~ line 46 ~ handleCancelInterestedPost ~ postId', postId);
        props.dispatch(cancelInterestedPost(postId));
        props.dispatch(changePostInterest(postId, userId, 'uninterested'));
    };

    const handleGoingPost = (postId) => {
        props.dispatch(goingPost(postId));
        props.dispatch(changePostInterest(postId, userId, 'going'));
    };
    const handleCancelGoingPost = (postId) => {
        props.dispatch(cancelGoingPost(postId));
        props.dispatch(changePostInterest(postId, userId, 'ungoing'));
    };
    useEffect(() => {
        const { success } = props.getAllSchedulerPostsResponse;
        if (success) {
            const cards = {};
            const posts = props.getAllSchedulerPostsResponse.allSchedulerPosts;
            for (let i = 0; i < posts.length; i++) {
                cards[posts[i].postId] = true;
            }
            setSchedulerPostsObject(cards);
        }
    }, [props.getAllSchedulerPostsResponse]);
    const handleAddPostToScheduler = (postId) => {
        setLoading(true);
        props.dispatch(addPostToScheduler(userId, postId));
        const schedulerPostsObject_ = schedulerPostsObject;
        schedulerPostsObject_[postId] = true;
        setSchedulerPostsObject({ ...schedulerPostsObject_ });
        setLoading(false);
    };
    const handleRemovePostFromScheduler = (postId) => {
        setLoading(true);
        props.dispatch(removePostFromScheduler(userId, postId));
        const schedulerPostsObject_ = schedulerPostsObject;
        schedulerPostsObject_[postId] = false;
        setSchedulerPostsObject({ ...schedulerPostsObject_ });
        setLoading(false);
    };
    const handleCreateOneShare = (postId) => {
        props.dispatch(
            createOneShare({
                userId: userId,
                postId,
            }),
        );
        NotificationManager.success('Post is shared successfully', 'success');
    };
    // INVITATION
    const getUsers = async (searchText) => {
        let connectedUsersId = {};
        let { success } = props.getAllConnectedUsersIdResponse;
        if (success) {
            connectedUsersId = props.getAllConnectedUsersIdResponse.connectedUsersId;
        }
        //console.log('🚀 ~ file: display-post.js ~ line 124 ~ getUsers ~ connectedUsersId', connectedUsersId);
        if (searchText.length > 2) {
            const users = await searchUsersByName(searchText).then((response) => {
                if (response.success) {
                    return response.users;
                } else return [];
            });
            if (users && users.length > 0) {
                let allUsers = [];

                if (users[0]) {
                    allUsers.push(...users[0].users.slice(0, 20));
                }
                if (users[1]) {
                    allUsers.push(...users[1].users.slice(0, 20));
                }
                //console.log('223', connectedUsersId);
                //console.log('🚀 ~ file: individual-membership.js ~ line 43 ~ newPromise ~ organizations', allUsers);
                allUsers = allUsers.map((user) => {
                    let connected = false;
                    if (connectedUsersId.includes(user._id)) connected = true;
                    return {
                        value: user._id,
                        label: (
                            <Row>
                                <Col sm={2}>
                                    <img src={user.profilePicture ? user.profilePicture : defaultIndividualProfilePicture} height="30px" width="30px" />
                                </Col>
                                <Col sm={10}>
                                    {user.userType === 'individual' ? user.concatNameWithSpace : user.name}&nbsp;
                                    <small>{connected ? 'Connected' : 'Not Connected'}</small>
                                </Col>
                            </Row>
                        ),
                    };
                });
                //console.log('🚀 ~ file: individual-membership.js ~ line 252 ~ newPromise ~ organizations', allUsers);
                return allUsers;
            } else return [];
        } else return [];
    };
    const promiseOptions = (inputValue) =>
        new Promise((resolve) => {
            setTimeout(() => {
                resolve(getUsers(inputValue));
            }, 1000);
        });
    const handleChangeSearchText = async (text) => {
        //console.log(text);
        setUserSearchText(text);
    };
    const handleAddUserToInvitationList = (user) => {
        const toBeInvitedUsers_ = toBeInvitedUsers;
        toBeInvitedUsers_.push(user);
        setToBeInvitedUsers([...toBeInvitedUsers_]);
    };
    const handleDeleteUserFromInvitationList = (idx) => {
        const toBeInvitedUsers_ = toBeInvitedUsers;
        toBeInvitedUsers_.splice(idx, 1);
        setToBeInvitedUsers([...toBeInvitedUsers_]);
    };
    const handleInviteUsers = (users, message) => {
        //console.log('🚀 ~ file: display-post.js ~ line 168 ~ DisplayPost ~ users, message', users, message);
        if (!users || (users && users.length === 0)) {
            alert('No user was selected to send invitation');
        } else {
            const userList = users.map((user) => user.value);
            let { success } = props.getAllConnectedUsersIdResponse;
            if (success) {
                const { connectedUsersId } = props.getAllConnectedUsersIdResponse;
                const { totalConnected, totalNotConnected } = countConnectedAndUnConnectedUsers(userList, connectedUsersId);
                success = props.getCoinSpendingSummaryResponse;
                if (success) {
                    const { coinPerInvitation } = props.getCoinSpendingSummaryResponse.coinSpendingSummary;
                    const totalCoins = parseInt(totalNotConnected * coinPerInvitation);
                    if (totalCoins > 0) {
                        confirmAlert({
                            title: 'Warning',
                            message: `You are inviting total ${totalNotConnected} users. This will cost you ${parseInt(
                                totalNotConnected * coinPerInvitation,
                            )} Coins. Are you sure?`,
                            buttons: [
                                {
                                    label: 'YES',
                                    onClick: () => {
                                        props.dispatch(
                                            inviteUsersToViewPost(userId, {
                                                usersToBeInvited: userList,
                                                postLink: `/post/${invitedPost.postType}/details/${invitedPost.postId}`,
                                                invitationMessage: message,
                                            }),
                                        );
                                    },
                                },
                                {
                                    label: 'No',
                                    onClick: () => {
                                        setPostInviteModal(true);
                                    },
                                },
                                {
                                    label: 'Only Send to Connected Users',
                                    onClick: () => {
                                        props.dispatch(
                                            inviteUsersToViewPost(userId, {
                                                usersToBeInvited: filterOnlyConnectedUsers(userList, connectedUsersId),
                                                postLink: `/post/${invitedPost.postType}/details/${invitedPost.postId}`,
                                                invitationMessage: message,
                                            }),
                                        );
                                    },
                                },
                            ],
                        });
                    } else {
                        props.dispatch(
                            inviteUsersToViewPost(userId, {
                                usersToBeInvited: userList,
                                postLink: `/post/${invitedPost.postType}/details/${invitedPost.postId}`,
                                invitationMessage: message,
                            }),
                        );
                    }
                }
            }
        }
    };
    useEffect(() => {
        const { success, message } = props.inviteUsersToViewPostResponse;
        if (success) {
            NotificationManager.success(message, 'success');
        } else if (success === false) {
            NotificationManager.error(message, 'Failed');
        }
    }, [props.inviteUsersToViewPostResponse]);
    if (loading) return <LoadingAnim />;
    return (
        <Container>
            <PostInviteModal
                postInviteModal={postInviteModal}
                setPostInviteModal={setPostInviteModal}
                promiseOptions={promiseOptions}
                handleChangeSearchText={handleChangeSearchText}
                userSearchText={userSearchText}
                toBeInvitedUsers={toBeInvitedUsers}
                setToBeInvitedUsers={setToBeInvitedUsers}
                handleAddUserToInvitationList={handleAddUserToInvitationList}
                handleDeleteUserFromInvitationList={handleDeleteUserFromInvitationList}
                handleInviteUsers={handleInviteUsers}
            />
            <Row>
                <Col className="parent-page">
                    <Row>
                        <Col className="right-align" sm="2">
                            <SideSubMenu
                                activePage="Feed"
                                organization={props.getPublicInfoResponse.success ? props.getPublicInfoResponse.user : {}}
                                handleFollowClick={handleFollowClick}
                                handleUnfollowClick={handleUnfollowClick}
                                follows={follows}
                                gotoPage={gotoPage}
                            />
                        </Col>
                        <Col sm="9" className="left-border">
                            <OrganizationInfoHeader
                                props={props}
                                organization={props.getPublicInfoResponse && props.getPublicInfoResponse.success ? props.getPublicInfoResponse.user : {}}
                            />
                            <PostListView
                                // allPosts={props.getAllPostsResponse.success ? props.getAllPostsResponse.allPosts : []}
                                allPosts={allPosts}
                                gotoPostDetails={gotoPostDetails}
                                userId={userId}
                                handleLikePost={handleLikePost}
                                handleCancelLikePost={handleCancelLikePost}
                                handleInterestedPost={handleInterestedPost}
                                handleCancelInterestedPost={handleCancelInterestedPost}
                                handleGoingPost={handleGoingPost}
                                handleCancelGoingPost={handleCancelGoingPost}
                                followOrganizationButton={false}
                                schedulerPostsObject={schedulerPostsObject}
                                handleAddPostToScheduler={handleAddPostToScheduler}
                                handleRemovePostFromScheduler={handleRemovePostFromScheduler}
                                handleCreateOneShare={handleCreateOneShare}
                                followingObject={{ [organizationId]: follows }}
                                setPostInviteModal={setPostInviteModal}
                                setInvitedPost={setInvitedPost}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};
const mapStateToProps = (state) => {
    const getAllPostsResponse = state.Post.getAllPosts;
    //console.log('🚀 ~ file: organization-post-list.js ~ line 95 ~ mapStateToProps ~ getAllPostsResponse', state);
    const getPublicInfoResponse = state.User.getUserPublicInfo;
    const getCheckIfFollowerResponse = state.Follow.checkIfFollower;
    const getFollowResponse = state.Follow.followUser;
    const getUnfollowResponse = state.Follow.unfollowUser;
    const getAllSchedulerPostsResponse = state.Scheduler.getAllSchedulerPosts;
    const setPostShareResponse = state.Share.setShare;
    const getAllFollowingsResponse = state.Follow.getAllFollowings;
    const getAllConnectedUsersIdResponse = state.User.getAllConnectedUsersId;
    const getCoinSpendingSummaryResponse = state.User.getCoinSpendingSummary;
    const inviteUsersToViewPostResponse = state.User.inviteUsersToViewPost;
    return {
        getPublicInfoResponse,
        getCheckIfFollowerResponse,
        getFollowResponse,
        getUnfollowResponse,
        getAllPostsResponse,
        getAllSchedulerPostsResponse,
        setPostShareResponse,
        getAllFollowingsResponse,
        getCoinSpendingSummaryResponse,
        inviteUsersToViewPostResponse,
        getAllConnectedUsersIdResponse,
    };
};
export default connect(mapStateToProps, null)(OrganizationFeed);
