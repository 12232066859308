/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import IndividualInfoHeader from '@Components/individual/individual-info-header';
import UserCardList from '@Components/user/user-card-list';
import LoadingAnim from '@Components/form_template/loading-anim';
import * as RoutePaths from '@Constants/route-paths';
import { getAllEndorsees } from '../../actions/endorsement-action';
import IndividualSideMenu from './individual-side-menu';

const IndividualEndorsees = (props) => {
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [individualId, setIndividualId] = useState('');
    const [userType, setUserType] = useState('');
    const [userList, setUserList] = useState([]);

    useEffect(() => {
        const getInitialInfo = (individualId) => {
            setLoading(true);
            props.dispatch(getAllEndorsees(individualId));
        };
        const individualId = props.match.params.userId;
        setIndividualId(individualId);
        const user = props.auth.user;
        if (user && user._id) {
            setUserId(user._id);
            setUserType(user.userType);
            getInitialInfo(individualId);
        }
    }, [props.match.params.userId]);
    useEffect(() => {
        const { success } = props.getAllEndorseesResponse;
        if (success) {
            let userList = [];
            const endorsees = props.getAllEndorseesResponse.endorsees;
            for (let i = 0; i < endorsees.length; i++) {
                userList.push({
                    _id: endorsees[i].endorseeId,
                    userType: endorsees[i].endorseeUserType,
                    firstName: endorsees[i].endorseeFirstName,
                    lastName: endorsees[i].endorseeLastName,
                    name: endorsees[i].endorseeName,
                    profilePicture: endorsees[i].endorseeProfilePicture,
                });
            }
            setUserList([...userList]);
            setLoading(false);
        }
    }, [props.getAllEndorseesResponse]);

    const handleGotoUserDetails = (userType, userId) => {
        props.history.push(RoutePaths.userDetailsPage(userType, userId));
    };

    if (loading) return <LoadingAnim />;
    else {
        return (
            <Container>
                <Row>
                    <Col className="parent-page">
                        <Row>
                            <Col className="right-align" sm="2">
                                <IndividualSideMenu activePage="Endorsees" {...props} />
                            </Col>
                            <Col sm="10" className="left-border">
                                <IndividualInfoHeader
                                    individual={props.getPublicInfoResponse.success ? props.getPublicInfoResponse.user : {}}
                                    pageTitle="Endorsees"
                                />
                                <UserCardList users={userList} userId={userId} handleGotoUserDetails={handleGotoUserDetails} title="ENDORSEES" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
};
const mapStateToProps = (state) => {
    const getAllEndorseesResponse = state.Endorsement.getAllEndorsees;
    const getPublicInfoResponse = state.User.getUserPublicInfo;
    return { getAllEndorseesResponse, getPublicInfoResponse };
};
export default connect(mapStateToProps, null)(IndividualEndorsees);
